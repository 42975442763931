import { CheckCircleIcon, XMarkIcon } from '../svg'
import clsx from 'clsx'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Alert({ alert, closeAlert }) {
  const { text, error } = alert;

  // const closeAlert = () => {
  //   setalert(prevState => {
  //     return {
  //       ...prevState,
  //       isAlert: false
  //     }
  //   })
  // }

  return (
    <div className={classNames(
      error ? 'bg-red-50' : 'bg-green-50',
      'rounded-md  p-4'
    )} >
      <div className="flex">
        {!error && <div className="flex-shrink-0">

          <CheckCircleIcon className={clsx("h-5 w-5", `text-green-400`)} aria-hidden="true" />
        </div>}
        <div className="ml-3">
          <p className={classNames(
            error ? 'text-red-800' : 'text-green-800',
            'text-sm font-medium'
          )}>{text}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={classNames(
                error ? 'bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50' : 'bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50',
                'inline-flex rounded-md   focus:outline-none focus:ring-2  focus:ring-offset-2'
              )}
            >
              <span className="sr-only">Fermer</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" onClick={closeAlert} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}



