/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description user  preview
 * @component UserPreviewPage
 */

import React from "react";

import UserPreview from "../../components/layouts/main/users/UserPreview";

const UserPreviewPage = () => {
	return <UserPreview />;
};

export default UserPreviewPage;
