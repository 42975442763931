/**
 * @author assan zidan
 * @email nsangouassanzidan@gmail.com
 * @description composant select parametrable via ces props 
 * @component Select
 * @props label, onChange, valueOption, defaultValue, className, onBlur, id, name, read
 */

import { forwardRef } from "react"

import { classNames } from "../../styles"
import { styles } from "./styles"

const Select = forwardRef((props, ref) => {
    const { label, onChange, valueOption, defaultValue, className, onBlur, id, name, read,disabled } = props

    return (
        <div>

            {   /* affiche le label si le props read est à false */
                !read && <label htmlFor={id} className={styles.label}>{label}</label>
            }

            <select ref={ref} className={classNames(styles.select, className)} defaultValue={defaultValue} aria-label={label} onChange={onChange} id={id} name={name} onBlur={onBlur} disabled={disabled} required>
                {/* oprtion par defaut  */}
                <option disabled value={"default"} >{label}</option>

                {/* liste de options  */}
                {valueOption && valueOption.length > 0 ?
                    valueOption.map((value) => (
                        <option key={value.id} value={value.id} title={value?.description ? value.description : ""}>{value.name}</option>
                    ))
                    : null
                }
            </select>
        </div>
    )
})

export const SelectProfile = forwardRef((props, ref) => {
    const { name, selected, onChange, defaultValue, valueOption, } = props

    return (

        <select ref={ref} className={styles.selectProfil} defaultValue={defaultValue} aria-label="profil" onChange={onChange} name={name} >
            <option disabled value={"default"} >{selected}</option>
            {valueOption && valueOption.length > 0 ?
                valueOption.map((value, index) => (
                    <option className="bg-white text-cyan-900 hover:bg-cyan-900 hover:text-cyan-100" key={index} value={value.id}>{value.name}</option>

                ))
                : null
            }
        </select>
    )
})

export default Select