/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  process header with many state step
 * @component ProcessHeader
 */
import { CheckIcon } from "@heroicons/react/24/solid";

const ProcessHeader = ({ steps, setStep }) => {
	return (
		<>
			<nav
				aria-label="Progress"
				className="mt-8 mb-4 mx-2  lg:mx-8">
				<ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
					{steps?.map((step, stepIdx) => (
						<li
							key={step.name}
							className="relative md:flex md:flex-1 cursor-pointer">
							{step.status === "complete" ? (
								<a
									href={step.href}
									className="group flex w-full items-center">
									<span className="flex items-center px-6 py-4 text-sm font-medium">
										<span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-secondary-600 group-hover:bg-secondary-800">
											<CheckIcon
												className="h-6 w-6 text-white"
												aria-hidden="true"
											/>
										</span>
										<span className="ml-4 text-sm font-medium text-gray-900">
											{step.name}
										</span>
									</span>
								</a>
							) : step.status === "current" ? (
								<a
									href={step.href}
									className="flex items-center px-6 py-4 text-sm font-medium"
									aria-current="step">
									<span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-secondary-600">
										<span className="text-secondary-600">
											{step.id}
										</span>
									</span>
									<span className="ml-4 text-sm font-medium text-secondary-600">
										{step.name}
									</span>
								</a>
							) : (
								<a
									href={step.href}
									className="group flex items-center">
									<span className="flex items-center px-6 py-4 text-sm font-medium">
										<span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
											<span className="text-gray-500 group-hover:text-gray-900">
												{step.id}
											</span>
										</span>
										<span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
											{step.name}
										</span>
									</span>
								</a>
							)}

							{stepIdx !== steps.length - 1 ? (
								<>
									{/* Arrow separator for lg screens and up */}
									<div
										className="absolute top-0 right-0 hidden h-full w-5 md:block"
										aria-hidden="true">
										<svg
											className="h-full w-full text-gray-300"
											viewBox="0 0 22 80"
											fill="none"
											preserveAspectRatio="none">
											<path
												d="M0 -2L20 40L0 82"
												vectorEffect="non-scaling-stroke"
												stroke="currentcolor"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</>
							) : null}
						</li>
					))}
				</ol>
			</nav>
		</>
	);
};

export default ProcessHeader;
