/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  select ville for consultation
 * @component VillePage
 */
import Main from "../../../components/layouts/main";
import VilleConsultation from "../../../components/layouts/main/consultations/VilleConsultation";

const VillePage = () => {
	return <Main children={<VilleConsultation />} />;
};

export default VillePage;
