/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurance of insurer page
 * @component ListAssurancesOwnerPages
 */

import React from "react";
import ListSinistre from "../../../components/layouts/main/assurances/provider/sinisterList/listSinistre";
import styled from "styled-components";

const StyledMain = styled.div`
	position: relative;
	height: 100vh;
	width: 100%;
	min-width: 250px;
	max-width: 1820px;
	padding: 30px 70px;
	font-size: 10px;
	font-family: 'Montserrat', sans-serif;
	@media screen and (max-width: 500px) {
		padding: 20px;
	}
`;

const ListSinistreOwnerPages = () => {
	return (
		<StyledMain>
			<ListSinistre />
		</StyledMain>
	);
};

export default  ListSinistreOwnerPages;
