/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description select piece insurance product
 * @component SelectPieces
 */
import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useGetAssurancesPiecesQuery } from "../../../../../features/api/apiSlice";
import Spinner from "../../../../../UI/spinner/Spinner";

const SelectPieces = ({ ariaLabel, onChange }) => {
	// get insurance piece request
	const { data, isLoading, isSuccess } = useGetAssurancesPiecesQuery();

	// options state
	const [options, setoptions] = useState([]);

	// set options state
	useEffect(() => {
		if (isSuccess) {
			setoptions(
				data?.data?.map((item) => {
					return {
						value: item.nom,
						description: item.description,
						label: item.nom,
						id: item.id,
					};
				})
			);
		}

		return () => {};
	}, [data, isSuccess]);

	return (
		<>
			{isLoading && (
				<div className="flex flex-1 justify-center">
					<Spinner className="h-8 w-8 text-primary-700 m-8 " />{" "}
				</div>
			)}

			{isSuccess && (
				<Select
					options={options}
					isClearable
					aria-label={ariaLabel}
					onChange={onChange}
				/>
			)}
		</>
	);
};

export default SelectPieces;
