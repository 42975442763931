import styled from "styled-components";
import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import Button from "../../button/Button";

const StyledButton = styled(Button)`
    ${({ lastStep }) => lastStep && "display: none;"}
	color: white;
	padding: 10px 20px;
	border-radius: 8px;
`;

function StepNavigator({ activeStep, setActiveStep, lastStep, disabled }) {

    return (
        <div className="flex justify-between">
            <button className="rounded-md flex justify-center py-2 px-4 text-sm font-medium text-secondary-600 hover:text-secondary-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 z-10 disabled:opacity-75 disabled:cursor-not-allowed"
                disabled={activeStep === 0}
                onClick={ () => setActiveStep(activeStep - 1) }
            >
                <KeyboardDoubleArrowLeft />
            </button>

            <StyledButton
                className="bg-secondary-600"
                onClick={() => { setActiveStep(activeStep + 1) }}
                lastStep={lastStep}
                disabled={disabled}
            >
                CONFIRMER
            </StyledButton>
        </div>
    );
};

export default StepNavigator;
