import styled from "styled-components";
import BackIllustration from "../../../../../Utilities/BackIllustration";
import SubscriptionBodyTitles from "../../../../../Utilities/subscriptionBodyTitles";
import { useGetAllSinistresByproductQuery } from "../../../../../../features/api/apiSlice";
import SubscriptionCardsTitle from "../../../../../Utilities/subscriptionCardsTitle";
import { useSelector } from "react-redux";
import { getUserCode } from "../../../../../../features/user/user.slice";
import Spinner from "../../../../../../UI/spinner/Spinner";
import SinisterCard from "./SinisterCard";
import productIllustration from "../../../../../../assets/productIllustrated.svg";
import DeclaretedCard from "../../client/sinisters/DeclaratedCard";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    margin: auto;
    @media screen and (max-width: 500px) {
      gap: 2em;
    }
    @media screen and (max-width: 1023px) {
      gap: 1.3em;
    }
`;

const StyledBody = styled.div`
    width: 850px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    @media screen and (max-width: 1023px) {
      width: 100%;
      min-width: 800px;
    }
`;

const DescriptionPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5em;
  width: 60%;
`;

const StyledMessage = styled.p`
    text-align: justify;
    margin: 0 0 2em 0;
    font-size: 1.5em;
    font-weight: 400;
`;

const Illustration = styled.img`
    width: 23em;
    height: auto;
    scale: 1.2;
    @media screen and (max-width: 1023px) {
        scale: 1.1;
    }
    @media screen and (max-width: 500px) {
        scale: 1;
    }
`;

function ListInistre() {
	const code = useSelector(getUserCode);

	const {
		data: sinistres,
		isSuccess,
		isLoading,
	} = useGetAllSinistresByproductQuery(code);

	return (
		<div>
			<BackIllustration />

			<Container>
				<SubscriptionBodyTitles
					bodyText={[`Gérez les déclarations de sinistre ici...`]}
					title="Sinistres Déclarés"
				/>

				<DescriptionPanel>
					<StyledMessage>Vous trouverez çi-dessous la liste des sinistres déclarés par les utilisateurs de vos produits.</StyledMessage>
					<Illustration src={productIllustration} alt="Product page illustration" />
				</DescriptionPanel>

				<StyledBody>
					<SubscriptionCardsTitle content="Liste des sinstres déclarés" />
					
					{isLoading ? (
						<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700 h-full" />
					) : (
						isSuccess ? (
							<>
								<DeclaretedCard
									isTable={true}
									code="XDGHXD3021"
								/>

								{(sinistres?.data && sinistres.data.length > 0) ? (
									sinistres.data.map((item, index) => (
										<DeclaretedCard
											key={index}
											idSinistre={item?.idSinistre}
											isProvider={true}
											code={item?.code}
											title={item?.titre}
											type={item?.type}
											status={item.etat}
											description={item.description}
											documents={item.documents}
											images={item.images}
											chat={item.idConversation}
										/>
									))
								) : (
									<div className="mx-auto my-10 max-w-2xl px-4 lg:max-w-7xl lg:px-8">
										<p className="text-2xl text-center text-gray-500">
											{" "}
											<i>Aucun sinistre déclaré pour un de vos produits...</i>{" "}
										</p>
									</div>
								)}
							</>
						) : (
							<h1>An error occured</h1>
						)
					)}
				</StyledBody>
			</Container>
		</div>
	);
};

export default ListInistre;


// ----------------------------------------------------------------------------------------------------------------

/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list all  insurance product
 * @component ListAssurances
 */
// import { useLayoutEffect, Fragment, useCallback,useState } from "react";
// import { Alert } from "../../UserRecharge/Recharge";
// import { Link, useNavigate } from "react-router-dom";
// import {

// 	useGetAllSinistresByproductQuery,
// } from "../../../../../features/api/apiSlice";
// import {
// 	getUserCode,
	
// } from "../../../../../features/user/user.slice";

// import { setModal } from "../../../../../features/feedBack/modal.slice";
// import { useDispatch, useSelector } from "react-redux";
// import Spinner from "../../../../../UI/spinner/Spinner";
// import {
// 	getModalProps,
// 	getModalType,
// 	initialModal,
// } from "../../../../../features/feedBack/modal.slice";
// import { FilterMatchMode, FilterOperator } from 'primereact/api';
// import { DataTable } from 'primereact/datatable';
// import { InputText } from 'primereact/inputtext';
// import { Column } from 'primereact/column';

// function classNames(...classes) {
// 	return classes.filter(Boolean).join(" ");
// }

// const ListInistre = () => {
// 	const modalType = useSelector(getModalType);
// 	const modalProps = useSelector(getModalProps);

// 	const dispatch = useDispatch();
    
// 	// user code
// 	const code = useSelector(getUserCode);
// 	// get all sinistre request
// 	const {
// 		data: sinistres,
// 		isSuccess,
// 		isLoading,
// 	} = useGetAllSinistresByproductQuery(code);
// console.log(sinistres)
// 	const itemsPerPage = 10;

// 	//data tabele
// 	const [filters, setFilters] = useState({
// 		global: {value: null, matchMode: FilterMatchMode.CONTAINS}
// 	});
//     const [globalFilterValue, setGlobalFilterValue] = useState('');
// 	const onGlobalFilterChange = (e) => {
//         const value = e.target.value;
//         let _filters = { ...filters };

//         _filters['global'].value = value;

//         setFilters(_filters);
//         setGlobalFilterValue(value);
//     };
// 	const [selectedsinistre, setSelectedsinistre] = useState(null);
// 	const renderHeader = () => {
//         return (
//             <div className="flex justify-content-end">
//                 <span className="p-input-icon-left">
//                     <i className="pi pi-search" />
//                     <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
//                 </span>
//             </div>
//         );
//     };
	

// 	const navigate = useNavigate();

// 	// close alert
// 	const closeAlert = useCallback(() => {
// 		dispatch(initialModal());
// 	}, [dispatch]);

// 	// close alert after 10 s
// 	useLayoutEffect(() => {
// 		if (modalProps?.isOpen) {
// 			setTimeout(closeAlert, 10000);
// 		}

// 		return () => {
// 			clearTimeout();
// 		};
// 	}, [modalProps?.isOpen, closeAlert]);
	
// 	const header = renderHeader();

// 	return (
// 		<>
// 			{/* alert component  */}
// 			{modalType === "assur" && modalProps?.isOpen ? (
// 				<div className="px-4">
// 					<Alert
// 						closeAlert={closeAlert}
// 						text={modalProps?.text}
// 						status={modalProps?.status}
// 					/>
// 				</div>
// 			) : null}

// 			<div className="px-8 sm:px-6 lg:px-8 mt-8">
// 			<div className="sm:flex sm:items-center">
// 					<div className="sm:flex-auto">
// 						<h1 className="text-xl font-semibold text-primary-800">
// 							Sinistres 
// 						</h1>
						
// 					</div>
					
// 				</div>
// 				{/* header  */}
// 				{isSuccess && (
//               <div className="card">
//             <DataTable value={sinistres?.data} paginator showGridlines  selectionsinistres="checkbox" selection={selectedsinistre} onSelectionChange={(e) => setSelectedsinistre(e.value)} rows={10} 
//                     filters={filters} globalFilterFields={['nom','description']} header={header}
//                     emptyMessage="No customers found.">
//                     <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
// 				<Column  sortable  header="titre" field="titre" style={{ minWidth: '12rem' }}  />
//                 <Column sortable  header="description" field="description" style={{ minWidth: '10rem' }}   /> 
                
//             </DataTable> 
//         </div>
// 			)}
				

// 					<>
					
// 					</>
				
// 				{/* loader  */}
// 				{isLoading && (
// 					<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
// 				)}
// 			</div>
// 		</>
// 	);
// };

// export default ListInistre;
