import styled from "styled-components"

export const styledH1 = styled.h1`
    font-size: 5em;
    font-weight: 700;
    @media screen and (max-width: 1023px) {
        font-size : 4em;
    }
`;

export const TitleParagraph = styled.p`
    font-size: 1.7em;
    font-weight: 500;
    @media screen and (max-width: 1023px) {
        font-size : 1.5em;
    }
`;

export const StyledH2 = styled.h2`
    font-size: 2.2em;
    font-weight: 600;
    @media screen and (max-width: 1023px) {
        font-size : 2em;
    }
    @media screen and (max-width: 500px) {
        font-size : 1.7em;
    }
`;

export const StyledH3 = styled.h3`
    font-size: 2em;
    font-weight: 500;
`;

export const Paragraph = styled.p`
    font-size: 1.5em;
    font-weight: 400;
`;
