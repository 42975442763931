/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description select city for consultation
 * @component VilleConsultation
 */
import React, { useState, useEffect } from "react";
import { useGetCityByCanalQuery } from "../../../../features/api/apiSlice";
import { Combobox } from "@headlessui/react";
import Spinner from "../../../../UI/spinner/Spinner";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import {
	villeConsultation,
	getconsultation,
} from "../../../../features/consultation/consultation.slice";
import Button from "../../../../UI/button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const VilleConsultation = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { canal } = useSelector(getconsultation);

	// get city by channel request
	const {
		data: vileCanal,
		isLoading: cityIsLoading,
		isSuccess: cityIsSuccess,
		isError: cityIsError,
	} = useGetCityByCanalQuery(canal.id);

	const [query, setQuery] = useState("");
	const [city, setCity] = useState([]);
	const [selectedVille, setSelectedVille] = useState(null);

	const filteredVille =
		query === ""
			? city
			: city.filter((city) => {
					return city.nomVille
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	useEffect(() => {
		if (cityIsSuccess) {
			setCity(vileCanal?.data);
		}

		return () => {};
	}, [cityIsSuccess, vileCanal]);

	// select ville
	const handleChangeSelectedVille = (value) => {
		setSelectedVille(value);
		dispatch(villeConsultation(value));
	};

	return (
		<>
			<div className="mx-auto max-w-3xl py-2 px-4 text-center sm:py-2 sm:px-6 lg:px-8">
				<h2 className="text-3xl font-bold tracking-tight text-primary-800 sm:text-4xl">
					Veillez choisir la ville dans laquelle vous souhaitez vous
					faire consulter
				</h2>
			</div>

			<div className="max-w-lg mx-auto mt-8">
				{cityIsLoading && (
					<Spinner className="h-10 w-10 text-primary-700 text-center " />
				)}
				{cityIsSuccess && (
					<>
						<Combobox
							as="div"
							value={selectedVille}
							onChange={handleChangeSelectedVille}>
							<Combobox.Label className="block text-sm font-medium text-gray-700">
								Saisir ou selectionner la ville
							</Combobox.Label>
							<div className="relative mt-1">
								<Combobox.Input
									placeholder="Veillez saisir ou selectionner la ville pour votre consultation"
									className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
									onChange={(event) =>
										setQuery(event.target.value)
									}
									displayValue={(city) => city?.nomVille}
								/>
								<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
									<ChevronUpDownIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</Combobox.Button>

								{filteredVille.length > 0 && (
									<Combobox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
										{filteredVille.map((city) => (
											<Combobox.Option
												key={city.id_ville}
												value={city}
												className={({ active }) =>
													classNames(
														"relative cursor-default select-none py-2 pl-8 pr-4",
														active
															? "bg-secondary-600 text-white"
															: "text-gray-900"
													)
												}>
												{({ active, selected }) => (
													<>
														<span
															className={classNames(
																"block truncate",
																selected &&
																	"font-semibold"
															)}>
															{city.nomVille}
														</span>

														{selected && (
															<span
																className={classNames(
																	"absolute inset-y-0 left-0 flex items-center pl-1.5",
																	active
																		? "text-white"
																		: "text-secondary-600"
																)}>
																<CheckIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															</span>
														)}
													</>
												)}
											</Combobox.Option>
										))}
									</Combobox.Options>
								)}
							</div>
						</Combobox>

						{filteredVille.length === 0 && (
							<p className=" text-gray-500 text-xl text-center">
								Aucune ville trouvée
							</p>
						)}
					</>
				)}
				{cityIsError && (
					<p className=" text-gray-500 text-xl text-center">
						Aucune ville trouvée
					</p>
				)}

				<div className="mt-8 flex items-center justify-between">
					<Button
						children="Etape précédent"
						type="button"
						onClick={() => navigate("/consultations/canal")}
					/>
					{selectedVille ? (
						<Button
							children="Continuer"
							type="button"
							button="primary"
							disabled={filteredVille.length === 0}
							onClick={() =>
								navigate(`/consultations/prendre-rdv`)
							}
							className="max-w-max"
						/>
					) : null}
				</div>
			</div>
		</>
	);
};

export default VilleConsultation;
