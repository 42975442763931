/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description medecin filter
 * @component Filter
 */
import { Fragment, useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/20/solid";
import Input from "../../../../UI/form/Input";
import Button from "../../../../UI/button/Button";
import { Combobox } from "@headlessui/react";
import Spinner from "../../../../UI/spinner/Spinner";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid";

import {
	useGetSkillsQuery,
	useGetCityQuery,
	useGetCanauxConsultationQuery,
} from "../../../../features/api/apiSlice";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Filter = ({
	setquery,
	searchParams,
	setSearchParams,
	isLoading,
	isFetching,
}) => {
	// get skills request
	const {
		data: skills,
		isSuccess: skillsIsSuccess,
		isLoading: skillsIsLoading,
	} = useGetSkillsQuery();

	// get city request
	const {
		data,
		isLoading: cityIsLoading,
		isSuccess: cityIsSuccess,
	} = useGetCityQuery();

	// get channel request
	const {
		data: canaux,
		isLoading: canauxLoading,
		isSuccess: canauxIsSuccess,
	} = useGetCanauxConsultationQuery();

	const [queryCity, setQueryCity] = useState("");
	const [city, setCity] = useState([]);
	const [selectedVille, setSelectedVille] = useState(null);

	const [querySkills, setQuerySkills] = useState("");
	const [skill, setSkill] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState(null);

	const filteredVille =
		queryCity === ""
			? city
			: city.filter((city) => {
					return city.ville
						.toLowerCase()
						.includes(queryCity.toLowerCase());
			  });

	const filteredSkills =
		querySkills === ""
			? skill
			: skill.filter((item) => {
					return item.nom
						.toLowerCase()
						.includes(querySkills.toLowerCase());
			  });

	useEffect(() => {
		if (cityIsSuccess) {
			setCity(data);
		}

		return () => {};
	}, [cityIsSuccess, data]);

	useEffect(() => {
		if (skillsIsSuccess) {
			setSkill(skills?.data);
		}

		return () => {};
	}, [skillsIsSuccess, skills]);

	// handle change skill
	const handlesetSelectedSkills = (value) => {
		setSelectedSkills(value);
		setSearchParams((prevState) => {
			const query = Object.fromEntries(prevState);
			return {
				...query,
				skill: value.nom,
			};
		});
	};

	// handle change ville
	const handlesetSelectedVille = (value) => {
		setSelectedVille(value);
		setSearchParams((prevState) => {
			const query = Object.fromEntries(prevState);
			return {
				...query,
				ville: value.ville,
			};
		});
	};

	// handle change canal and name
	const handleChange = (e) => {
		const { name, type, value } = e.target;
		if (type === "radio") {
			setSearchParams((prevState) => {
				const query = Object.fromEntries(prevState);
				return {
					...query,
					[name]: value,
				};
			});
		} else if (type === "text") {
			setSearchParams((prevState) => {
				const query = Object.fromEntries(prevState);
				return {
					...query,
					[name]: value,
				};
			});
		}
	};

	// set query params
	const applyFilterHandleClick = () => {
		setquery(Object.fromEntries([...searchParams]));
	};

	return (
		<div className="bg-white">
			{/* Filters */}
			<Disclosure
				as="section"
				aria-labelledby="filter-heading"
				className="grid items-center border-t border-b border-gray-200 mt-8">
				<h2
					id="filter-heading"
					className="sr-only">
					Filtres
				</h2>
				<div className="relative col-start-1 row-start-1 py-4">
					<div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
						<div>
							<Disclosure.Button className="group flex items-center font-medium text-gray-700">
								<FunnelIcon
									className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
									aria-hidden="true"
								/>
								Filtres
							</Disclosure.Button>
						</div>
					</div>
				</div>
				<Disclosure.Panel className="border-t border-gray-200 py-10">
					<div className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
						{/* skills  */}
						<fieldset>
							<legend className="block font-medium">
								Spécialités
							</legend>
							{skillsIsLoading && (
								<Spinner className="h-10 w-10 text-primary-700 " />
							)}
							{skillsIsSuccess && (
								<Combobox
									as="div"
									value={selectedSkills}
									onChange={handlesetSelectedSkills}>
									<div className="relative mt-4">
										<Combobox.Input
											placeholder="Veillez saisir ou selectionner la spécialité pour votre consultation"
											className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
											onChange={(event) =>
												setQuerySkills(
													event.target.value
												)
											}
											displayValue={(skill) => skill?.nom}
										/>
										<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
											<ChevronUpDownIcon
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</Combobox.Button>

										{filteredSkills.length > 0 && (
											<Combobox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
												{filteredSkills.map((skill) => (
													<Combobox.Option
														key={skill.id_skill}
														value={skill}
														className={({
															active,
														}) =>
															classNames(
																"relative cursor-default select-none py-2 pl-8 pr-4",
																active
																	? "bg-secondary-600 text-white"
																	: "text-gray-900"
															)
														}>
														{({
															active,
															selected,
														}) => (
															<>
																<span
																	className={classNames(
																		"block truncate",
																		selected &&
																			"font-semibold"
																	)}>
																	{skill.nom}
																</span>

																{selected && (
																	<span
																		className={classNames(
																			"absolute inset-y-0 left-0 flex items-center pl-1.5",
																			active
																				? "text-white"
																				: "text-secondary-600"
																		)}>
																		<CheckIcon
																			className="h-5 w-5"
																			aria-hidden="true"
																		/>
																	</span>
																)}
															</>
														)}
													</Combobox.Option>
												))}
											</Combobox.Options>
										)}
									</div>
								</Combobox>
							)}
						</fieldset>

						{/* city  */}
						<fieldset>
							<legend className="block font-medium">Ville</legend>
							{cityIsLoading && (
								<Spinner className="h-10 w-10 text-primary-700 " />
							)}
							{cityIsSuccess && (
								<Combobox
									as="div"
									value={selectedVille}
									onChange={handlesetSelectedVille}>
									<div className="relative mt-4">
										<Combobox.Input
											placeholder="Veillez saisir ou selectionner la ville pour votre consultation"
											className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
											onChange={(event) =>
												setQueryCity(event.target.value)
											}
											displayValue={(city) => city?.ville}
										/>
										<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
											<ChevronUpDownIcon
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</Combobox.Button>

										{filteredVille.length > 0 && (
											<Combobox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
												{filteredVille.map((city) => (
													<Combobox.Option
														key={city.ville}
														value={city}
														className={({
															active,
														}) =>
															classNames(
																"relative cursor-default select-none py-2 pl-8 pr-4",
																active
																	? "bg-secondary-600 text-white"
																	: "text-gray-900"
															)
														}>
														{({
															active,
															selected,
														}) => (
															<>
																<span
																	className={classNames(
																		"block truncate",
																		selected &&
																			"font-semibold"
																	)}>
																	{city.ville}
																</span>

																{selected && (
																	<span
																		className={classNames(
																			"absolute inset-y-0 left-0 flex items-center pl-1.5",
																			active
																				? "text-white"
																				: "text-secondary-600"
																		)}>
																		<CheckIcon
																			className="h-5 w-5"
																			aria-hidden="true"
																		/>
																	</span>
																)}
															</>
														)}
													</Combobox.Option>
												))}
											</Combobox.Options>
										)}
									</div>
								</Combobox>
							)}
						</fieldset>

						{/* medecin name  */}
						<fieldset>
							<legend className="block font-medium">
								Nom du medecin
							</legend>
							<Input
								className="mt-4"
								placeholder={"entrer le nom du medecin"}
								onChange={handleChange}
								input={{
									id: "nom",
									type: "text",
									maxLength: "80",
								}}
								name={"nom"}
								disabled={false}
							/>
						</fieldset>

						{/* channel  */}
						<fieldset>
							<legend className="block font-medium">Canal</legend>
							{canauxLoading && (
								<Spinner className="h-10 w-10 text-primary-700 " />
							)}
							{canauxIsSuccess && (
								<div className="space-y-2 pt-6 sm:space-y-4 sm:pt-4">
									{canaux?.data?.map((option, optionIdx) => (
										<div
											key={option.id}
											className="flex items-center text-base sm:text-sm">
											<input
												id={`canal-${optionIdx}`}
												name="canal"
												value={option.name}
												type="radio"
												onChange={handleChange}
												className="h-4 w-4 flex-shrink-0 rounded-full border-gray-300 text-secondary-600 focus:ring-secondary-500"
											/>
											<label
												htmlFor={`canal-${optionIdx}`}
												className="ml-3 min-w-0 flex-1 text-gray-600">
												{option.name}
											</label>
										</div>
									))}
								</div>
							)}
						</fieldset>
					</div>
					<div className="flex justify-end mt-4  pr-4">
						<Button
							button="primary"
							children="Appliquer le filtre"
							type="button"
							loading={isFetching || isLoading}
							className="max-w-max"
							onClick={applyFilterHandleClick}
						/>
					</div>
				</Disclosure.Panel>
			</Disclosure>
		</div>
	);
};

export default Filter;
