/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description formulaire de nouveau mot de passe 
 * @component NewpasswordForm
 * @props setOpen, setalert, 
 */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import sha256 from "sha256"

import Input from '../../UI/form/Input'
import Button from '../../UI/button/Button';

import useInput from '../../hooks/use-input';
import usePasswordToggle from '../../hooks/usePasswordToggle';

import { isPassword } from '../../lib/inputValidation';

import axiosInstance from '../../apis/inchAssur';


const NewpasswordForm = ({ setalert }) => {
  // navigate 
  const navigate = useNavigate()

  const [loading, setloading] = useState(false)

  // pasword control
  const {
    value: enteredpassword,
    isValid: enteredpasswordIsValid,
    hasError: passwordInputHasError,
    valueChangeHandler: passwordHandleChange,
    inputBlurHandler: passwordHandleBlur,
  } = useInput(isPassword)

  // password verif control
  const {
    value: enteredpasswordVerified,
    isValid: passwordVerifiedIsValid,
    hasError: passwordVerifiedInputHasError,
    valueChangeHandler: passwordVerifiedHandleChange,
    inputBlurHandler: passwordVerifiedhandleBlur,
  } = useInput(value => value.trim() !== '')


  const [passwordType1, Eye1] = usePasswordToggle()
  const [passwordType2, Eye2] = usePasswordToggle()

  // password is equal
  const isPasswordIsEqual = (enteredpassword === enteredpasswordVerified)

  // verifie la validation du formulaire 
  const formIsValid = [passwordVerifiedIsValid, enteredpasswordIsValid, isPasswordIsEqual].every(currentValue => currentValue === true)

  // obtenir le token en parametre 
  const { token } = useParams()

  // sousmettre le formulaire 
  const handlersubmit = (e) => {
    e.preventDefault()

    // data 
    const data = {
      password: sha256(enteredpassword),
      pass_confirm: sha256(enteredpasswordVerified)
    }

    setloading(true)
    axiosInstance.post('/account/setPassword', { ...data }, {
      headers: {
        'Authorization': "Bearer " + token
      }
    }).then((res) => {
      // affiche le message de success 
      setalert(prevState => {
        return {
          ...prevState,
          isAlert: true,
          error: false,
          text: res.data.message,
        }
      })
      // localStorage.setItem('token', res.data.token)
      navigate(`/connexion`)
    }).catch((err) => {
      // affiche le message d'erreur 
      setalert(prevState => {
        return {
          ...prevState,
          isAlert: true,
          error: true,
          text: err.response?.data?.message,
        }
      })
    }).finally(() => setloading(false))

  }
  return (
    // formulaire 
    <form onSubmit={handlersubmit} className="space-y-6">
      {/* mot de passe  */}
      <Input label="Mot de passe" inputError={passwordInputHasError} value={enteredpassword} onChange={passwordHandleChange} onBlur={passwordHandleBlur} placeholder={"entrer votre mot de passe"} input={{ id: "password", type: passwordType1 }} Eye={Eye1} name={"password"} disabled={false} required={true} errorText="le mot de passe doit contenir au moins une majuscule, une miniscule, un chiffre, ou un caractere speciale #, ?, !, @, $, %, ^, &, *, -, :, ;, <, >... " ariaInputError="password" />

      {/* verificationde mot de passe  */}
      <Input label="Vérification de mot de passe" inputError={(!isPasswordIsEqual && enteredpasswordVerified.length >= 1) || passwordVerifiedInputHasError} onChange={passwordVerifiedHandleChange} onBlur={passwordVerifiedhandleBlur} placeholder={"entrer le meme mot de passe"} input={{ id: "passwordVerif", type: passwordType2 }} Eye={Eye2} value={enteredpasswordVerified} errorText={"les mots de passe  ne corresondent pas"} name={"passwordVerif"} disabled={false} required={true} ariaInputError="passwordVerif" />

      {/* bouton d'envoie  */}
      <div>
        <Button type="submit" children="Envoyer" button="primary" loading={loading} error={!formIsValid} />
      </div>
    </form>
  )
}

export default NewpasswordForm