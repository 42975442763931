import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
	selectedChat: undefined,
};

// chat slice
const chatSlice = createSlice({
	name: "chat",
	initialState,
	reducers: {
		selectChat: (state, action) => {
			state.selectedChat = action.payload;
		},
		resetChat: (state) => {
			return initialState;
		},
	},
});

const { selectChat, resetChat } = chatSlice.actions;

// get selected chat
const getSelectedChat = (state) => state.chats.selectedChat;

export { selectChat, resetChat, getSelectedChat };

export default chatSlice.reducer;
