/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description selected price option of question
 * @component SelectPriceModification
 */
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const price = ["Addition", "Pourcentage"];

const SelectPriceModification = (props) => {
	// props
	const { setCurrentQuestion, currentQuestion } = props;

	// Handle changes
	function handleTypeTarifChange(value) {
		setCurrentQuestion({
			...currentQuestion,
			tarifType: value
		})
	};

	return (
		<Listbox
			value={currentQuestion?.tarifType}
			onChange={handleTypeTarifChange}>
			<div className="relative ">
				<Listbox.Button className="relative w-full underline hover:text-secondary-700 cursor-pointer  bg-white  pl-3 pr-8 text-left focus:outline-none focus-visible:border-secondary-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-secondary-300 text-sm">
					<span className="block truncate">{currentQuestion?.tarifType ? currentQuestion?.tarifType : "Addition"}</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<ChevronUpDownIcon
							className="h-4 w-4 text-gray-400 hover:text-secondary-700"
							aria-hidden="true"
						/>
					</span>
				</Listbox.Button>
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<Listbox.Options className="absolute mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{price.map((item, personIdx) => (
							<Listbox.Option
								key={personIdx}
								className={({ active }) =>
									`relative cursor-default select-none py-2 pl-10 pr-4 ${
										active
											? "bg-secondary-100 text-secondary-900"
											: "text-gray-900"
									}`
								}
								value={item}
							>
								{({ selected }) => (
									<>
										<span
											className={`block truncate ${
												selected
													? "font-medium"
													: "font-normal"
											}`}>
											{item}
										</span>
										{selected ? (
											<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-secondary-600">
												<CheckIcon
													className="h-5 w-5"
													aria-hidden="true"
												/>
											</span>
										) : null}
									</>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Transition>
			</div>
		</Listbox>
	);
};

export default SelectPriceModification;
