/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description   transaction list
 * @component AdminTransaction
 */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../../../../UI/pagination";
import UserDetailTransaction from "./UserDetailTransaction";
import { useGetAllTransactionQuery } from "../../../../features/api/apiSlice";
import Spinner from "../../../../UI/spinner/Spinner";
import usePagination from "../../../../hooks/usePagination";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const AdminTransaction = () => {
	// location hook
	const { hash } = useLocation();
	const id_transaction = hash.substring(1);

	// get all transaction request
	const {
		data: transactions,
		isLoading,
		isSuccess,
	} = useGetAllTransactionQuery();

	// transaction  per page
	const itemsPerPage = 10;

	// pagination hook
	const {
		next,
		prev,
		jump,
		currentData,
		currentPage,
		maxPage,
		dataLength,
		beginItem,
		endItem,
	} = usePagination(transactions?.data, itemsPerPage);

	return (
		<>
			<div className="px-4 sm:px-6 lg:px-8 mt-8">
				{/* header  */}
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-bold text-primary-900">
							Transactions
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							La liste de toutes les transactions de la
							plateforme.
						</p>
					</div>
				</div>

				{/* loader componenet */}
				{isLoading && (
					<div className="flex justify-center text-center">
						<Spinner className="h-10 w-10 text-primary-700  " />
					</div>
				)}

				{isSuccess &&
					(transactions?.data?.length === 0 ? (
						<div className="flex justify-center text-2xl font-medium text-gray-400 my-4">
							{" "}
							aucune transaction effectuée
						</div>
					) : (
						<>
							<div className="mt-8 flow-root">
								<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
											<table className="min-w-full divide-y divide-gray-300">
												<thead className="bg-gray-50">
													<tr>
														<th
															scope="col"
															className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
															Transaction ID
														</th>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
															Libelé
														</th>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
															Date
														</th>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
															Status
														</th>
														<th
															scope="col"
															className="relative py-3.5 pl-3 pr-4 sm:pr-6">
															<span className="sr-only">
																Detail
															</span>
														</th>
													</tr>
												</thead>
												<tbody className="divide-y divide-gray-200 bg-white">
													{currentData?.map(
														(transaction) => (
															<tr
																key={
																	transaction.id_transaction
																}
																className={classNames(
																	id_transaction ===
																		transaction.id_transaction
																		? "bg-secondary-100"
																		: null
																)}>
																<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
																	{
																		transaction.id_transaction
																	}
																</td>
																<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																	{
																		transaction.motif
																	}
																</td>
																<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																	{
																		transaction.dateCreation
																	}
																</td>
																<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																	{
																		transaction.statut
																	}
																</td>
																<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
																	<Link
																		to={`#${transaction.id_transaction}`}
																		className="text-secondary-600 hover:text-secondary-900">
																		detail
																		<span className="sr-only">
																			{
																				transaction.id_transaction
																			}
																		</span>
																	</Link>
																</td>
															</tr>
														)
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>

							{/* pagination  */}
							{transactions?.data?.length > itemsPerPage && (
								<Pagination
									next={next}
									prev={prev}
									jump={jump}
									currentData={currentData}
									currentPage={currentPage}
									maxPage={maxPage}
									dataLength={dataLength}
									itemsPerPage={itemsPerPage}
									beginItem={beginItem}
									endItem={endItem}
								/>
							)}
						</>
					))}
			</div>

			{/* { console.log("hash value :", hash) } */}

			{/* transaction detail and reglement  */}
			{transactions?.data?.length > 0 && hash && (
				<UserDetailTransaction />
			)}
		</>
	);
};

export default AdminTransaction;
