/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  not selected chat
 * @component WelcomeChat
 */

import React from "react";
import { classNames } from "../../../../styles";

const WelcomeChat = () => {
	return (
		<div
			className={classNames(
				"md:flex flex-col justify-center items-center md:w-3/5 xl:w-4/6 hidden"
			)}>
			<div className="text-base lg:text-lg font-semibold text-gray-600">
				Selectionner une discussion pour lancer la conversation.
			</div>
		</div>
	);
};

export default WelcomeChat;
