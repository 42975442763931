/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description channel consultation page
 * @component CanalPage
 */
import Canaux from "../../../components/layouts/main/consultations/Canaux";
import Main from "../../../components/layouts/main";

const CanalPage = () => {
	return <Main children={<Canaux />} />;
};

export default CanalPage;
