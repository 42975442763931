/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description medecin localisation profil
 * @component DoctorRdv
 */
import profile from "../../../../assets/profile.png";
import { CiHospital1 } from "../../../../UI/svg";

const DoctorRdv = ({ nom, prenom, image, localisation }) => {
	return (
		<div className="p-4 max-w-max">
			<div className="flex items-start h-20 w-80">
				<div className="h-20 w-24">
					<img
						className="h-full w-20 rounded-full object-cover "
						src={image || profile}
						alt={`${prenom}-profil`}
						height="80"
						width="80"
					/>
				</div>
				<div className="flex flex-col gap-y-2  ml-4">
					<div className="flex justify-between flex-wrap">
						<h3 className=" font-normal  text-secondary-700">
							Cabinet du Dr {`${nom}, ${prenom}`}
						</h3>
					</div>
					<div className="flex items-start gap-x-2">
						<CiHospital1 className="w-8 h-8 text-secondary-600 font-bold" />
						<h4 className="font-semibold text-gray-700 text-sm ">
							{localisation[0].etablissement}
						</h4>
					</div>
				</div>
			</div>

			<div className="ml-24 flex items-start text-base text-gray-600 ">
				<div>{/* add icon  */}</div>
				<div>
					<span> {localisation[0].adresse}</span>
					<h5>{localisation[0].ville}</h5>
				</div>
			</div>
		</div>
	);
};

export default DoctorRdv;
