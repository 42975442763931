/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description Agenda of medecin
 * @component MonAgenda
 */

import React, { useRef, useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import FullCalendar, { EventApi } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Transition, Dialog } from "@headlessui/react";
import moment from "moment"


import Input from "../../../../../UI/form/Input";
import { MinusIcon, PencilSquareIcon } from "../../../../../UI/svg";
import Button from "../../../../../UI/button/Button";
import Spinner from "../../../../../UI/spinner/Spinner";

import { getUserCode } from "../../../../../features/user/user.slice";
import {
	useAddDisponibilityMutation,
	useGetAgendaQuery,
	useDeleteAgendaMutation,
	useUpdateAgendaMutation,
} from "../../../../../features/api/apiSlice";
import { data } from "autoprefixer";

// modal
const MyDialog = ({ open, setOpen, dateEvent, title, buttonText, TaskCode, isAction,dateJour }) => {
	const cancelButtonRef = useRef(null);
	const finDateRef = useRef();
	const debutDateRef = useRef();
	const descriptionRef = useRef();
	
	const options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	// get date
	const event = new Date(dateEvent?.dateStr);
	
	const toString = event.toLocaleDateString("fr-FR", options);

	// user code
	const code = useSelector(getUserCode);

	// agenda requestF
	const { data: eventData, isSuccess } = useGetAgendaQuery(code);

	//get skill to update
	const task = eventData?.data?.filter(task => task.code === TaskCode)
	
	
	

	const op = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	
	// delete agenda request
	const [deleteAgenda, { isLoading: deleteAgendaLoading }] = useDeleteAgendaMutation();

	// deleteAgenda
	const DeleteDisponibility = (e, code) => {
		e.preventDefault();
		
		deleteAgenda(code)
			.unwrap()
			.then(() => setOpen(false))

			.catch((err) => {
				console.log(err);
			});
	};


	// update agenda request
	const [UpdateAgenda, { isLoading: updateAgendaLoading }] = useUpdateAgendaMutation();

	// add disponibility
	const UpdateDisponibility = (e,id) => {
		e.preventDefault();
		
		
		const label = descriptionRef.current.value;
		const debut = `${dateJour}${" "}${debutDateRef.current.value}`;
		const fin = `${dateJour}${" "}${finDateRef.current.value}`;

		const data = {
			label,
			debut,
			fin,
		};
		
		UpdateAgenda({ id, data })
			.unwrap()
			.then(() => setOpen(false));
	};

	// add disponibility request
	const [addDisponibility, { isLoading }] = useAddDisponibilityMutation();

	// add disponibility
	const SendDisponibility = (e) => {
		e.preventDefault();
		
		const label = descriptionRef.current.value;
		const debut = `${dateEvent?.dateStr}${" "}${debutDateRef.current.value
			}`;
		const fin = `${dateEvent?.dateStr}${" "}${finDateRef.current.value}`;

		const data = {
			label,
			debut,
			fin,
		};

		addDisponibility({ code, data })
			.unwrap()
			.then(() => setOpen(false));
	};

	return (
		<>


			{/* modal pour l'ajout */}
			{isAction === "add" && (<Transition.Root
				show={open}
				as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelButtonRef}
					onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<Dialog.Panel
									onSubmit={(e) => SendDisponibility(e)}
									as="form"
									className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div>
										<div className=" text-center">
											<Dialog.Title
												as="h2"
												className="text-lg font-medium leading-6 text-secondary-600">
												{toString}
											</Dialog.Title>
											<Dialog.Description
												as="p"
												className="text-sm leading-6 text-gray-700">
												{title}
											</Dialog.Description>
										</div>
									</div>
									<div className="mt-8  sm:grid-cols-2 grid gap-4">
										<div className="col-span-2 ">
											<Input
												ref={descriptionRef}
												label="Titre"
												input={{
													id: "debut",
													type: "text",
												}}
												placeholder="Ajouter un titre"
												name={"debut"}
												disabled={false}
											/>
										</div>
										<div className="col-span-2 sm:col-span-1">
											<Input
												ref={debutDateRef}
												label="Heure de debut"
												input={{
													id: "debut",
													type: "time",
												}}
												name={"debut"}
												disabled={false}
												required={true}
											/>
										</div>
										<div className="col-span-2 sm:col-span-1">
											<Input
												ref={finDateRef}
												label="Heure de fin"
												input={{
													id: "fin",
													type: "time",
												}}
												name={"fin"}
												disabled={false}
												required={true}
											/>
										</div>
									</div>
									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
										<div className="sm:col-start-2 ">
											<Button
												type="submit"
												children={buttonText}
												button="primary"
												loading={isLoading}
											/>
										</div>

										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
											onClick={() => setOpen(false)}>
											Annuler
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>)};

			{/* modal pour la suppression */}
			{isAction === "delete" && (<Transition.Root
				show={open}
				as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelButtonRef}
					onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<Dialog.Panel
									onSubmit={(e) => DeleteDisponibility(e, task[0].code)}
									as="form"
									className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div>
										<div className=" text-center">
											<Dialog.Title
												as="h2"
												className="text-lg font-medium leading-6 text-secondary-600">

											</Dialog.Title>
											<Dialog.Description
												as="p"
												className="text-sm leading-6 text-gray-700">
												{title}
											</Dialog.Description>
										</div>
									</div>
									<div className="mt-2">
										<p className="ml-9 text-lg text-dark extraBold">
											Voulez-vous vraiment supprimer la tache {task[0].label}
										</p>
									</div>
									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
										<div className="sm:col-start-2 ">
											<Button
												type="submit"
												children={buttonText}
												button="primary"
												loading={deleteAgendaLoading}
											/>
										</div>

										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
											onClick={() => setOpen(false)}>
											Annuler
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>)};
			{/* modal pour la modification */}
			{isAction === "update" && (<Transition.Root
				show={open}
				as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelButtonRef}
					onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<Dialog.Panel
									onSubmit={(e) => UpdateDisponibility(e, task[0].code)}
									as="form"
									className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div>
										<div className=" text-center">
											<Dialog.Title
												as="h2"
												className="text-lg font-medium leading-6 text-secondary-600">

											</Dialog.Title>
											<Dialog.Description
												as="p"
												className="text-sm leading-6 text-gray-700">
												{title}
											</Dialog.Description>
										</div>
									</div>
									<div className="mt-8  sm:grid-cols-2 grid gap-4">
										<div className="col-span-2 ">
											<Input
												ref={descriptionRef}
												label="Titre"
												input={{
													id: "debut",
													type: "text",
												}}
												placeholder="Ajouter un titre"
												name={"debut"}
												disabled={false}
												defaultValue={task[0].label}
											/>
										</div>
										<div className="col-span-2 sm:col-span-1">
											<Input
												ref={debutDateRef}
												label="Heure de debut"
												input={{
													id: "debut",
													type: "time",
												}}
												name={"debut"}
												disabled={false}
												required={true}
												defaultValue={moment(task[0].debut).format('HH:mm')}
											/>
										</div>
										<div className="col-span-2 sm:col-span-1">
											<Input
												ref={finDateRef}
												label="Heure de fin"
												input={{
													id: "fin",
													type: "time",
												}}
												name={"fin"}
												disabled={false}
												required={true}
												defaultValue={moment(task[0].fin).format('HH:mm')}
											/>
										</div>
									</div>
									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
										<div className="sm:col-start-2 ">
											<Button
												type="submit"
												children={buttonText}
												button="primary"
												loading={updateAgendaLoading}

											/>
										</div>

										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
											onClick={() => setOpen(false)}>
											Annuler
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>)};



		</>
	);
};

// get hour slot
const getHourRdv = (slot) => {
	const options = {
		hour: "numeric",
		minute: "numeric",
	};

	const dateRdv = new Date(slot);
	const hourRdv = new Intl.DateTimeFormat("fr-FR", options).format(dateRdv);



	return hourRdv;
};





export default function MonAgenda() {


	// get code of connected user
	const code = useSelector(getUserCode);

	// agenda request
	const { data: eventData, isSuccess, isLoading } = useGetAgendaQuery(code);



	// infos specifiques à l action à faire (ajouetr,modifier,supprimer)
	const [actionInfo, setActionInfo] = useState({});
	// disponibilities
	const [events, setEvents] = useState([]);

	// modal state
	const [isOpen, setIsOpen] = useState(false);

	// data state
	const [date, setDate] = useState({});



	// event content component
	const EventContent = (eventInfo) => {
		
		const options = {
			year: 'numeric', // Format numérique pour l'année (ex: "2023")
			month: 'long', // Format long pour le mois (ex: "août")
			day: 'numeric', // Format numérique pour le jour (ex: "28")
			hour: 'numeric',
			minute: 'numeric',
		};

		const dateCreation = (eventInfo.event._def.extendedProps.dateCreation)
		const code = (eventInfo.event._def.extendedProps.code)
		const datejour =(eventInfo.event._instance.range.end)
		const jour = datejour.toLocaleDateString("en-CA", { year: 'numeric', month: '2-digit', day: '2-digit' })
		
		
		
		




		return (
			<>
				<div className="group w-full  rounded-xl py-1" title={`la tache ${eventInfo.event.title} a été créé le ${dateCreation} `}>
					<div>
						<span className="flex gap-x-1 items-center">

							<PencilSquareIcon onClick={() => handleUpdateClick(code,jour)}
								title="modifier la tache"
								className="ml-2 hover:w-8 hover:text-green-700 text-green-700  h-5 w-5"
								aria-hidden="true"
							/>

							<p className="ml-2 truncate font-medium text-gray-900">{eventInfo.event.title.length > 8 ? eventInfo.event.title.substr(0, 8) + "..." : eventInfo.event.title}</p>
							<span onClick={() => handleDeleteClick(code,jour)}>
								<MinusIcon

									title="supprimer la tache"
									className="ml-8 md:hidden md:group-hover:block hover:w-6 hover:text-green-900 text-green-700 cursor-pointer h-6 w-4 justify-self-end"
								/>
							</span>

						</span>

					</div>
					<div
						dateTime={eventInfo.timeText}
						className="ml-9 text-gray-500 xl:block" style={{ marginTop: '-5px' }}
					>
						{`${getHourRdv(
							eventInfo.event._instance.range.start
						)}-${getHourRdv(eventInfo.event._instance.range.end)}`}
					</div>
				</div>











			</>
		);
	};

	// open modal to add
	const handleDateClick = (arg) => {

		
		setActionInfo({
			title: "Ajouter une plage de disponibilité",
			buttonText: "submit",
			type: "add",

		});
		setIsOpen(true);
		setDate(arg);
	};

	

	// open modal to update
	const handleUpdateClick = (code, jour) => {
	
		setActionInfo({
			title: "Modifier une plage de disponibilité",
			buttonText: "Update",
			type: "update",
			TaskCode: code,
			dateJour:jour
		


		});
		setIsOpen(true);
		
	};

	// open modal to delete
	const handleDeleteClick = (code, jour) => {

		setActionInfo({
			title: "Supprimer une plage de disponibilité",
			buttonText: "Delete",
			type: "delete",
			TaskCode: code,
			dateJour:jour
			


		});
		setIsOpen(true);
		

	};

	// set events data after request is succecss
	useEffect(() => {
		if (isSuccess) {
			
			const event = eventData?.data?.map((item) => {
				return {
					code: item.code,
					start: item.debut,
					end: item.fin,
					title: item.label,
					dateCreation: item.dateCreation,
				};
			});
			setEvents([event]);
		}

		return () => { };
	}, [isSuccess, eventData?.data]);

	return (
		<>
			{/* Modal  */}
			{isOpen && (
				<MyDialog
					open={isOpen}
					setOpen={setIsOpen}
					dateEvent={date}
					title={actionInfo.title}
					buttonText={actionInfo.buttonText}
					onSubmit={actionInfo.onSubmit}
					TaskCode={actionInfo.TaskCode}
					isAction={actionInfo.type}
					dateJour={actionInfo.dateJour}
					
				/>
			)}

			{/* Agenda  */}
			<div>
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-bold text-primary-900">
							Mes disponibilités.
						</h1>
						<p className="mt-2 text-sm text-gray-700"></p>


					</div>
				</div>

				{isLoading ? (
					<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
				) : (
					<div className="mt-8">
						<FullCalendar
							plugins={[
								dayGridPlugin,
								interactionPlugin,
								timeGridPlugin,
							]}
							headerToolbar={{
								left: "prev,next today",
								center: "title",
								right: "dayGridMonth,timeGridWeek,timeGridDay",
							}}

							eventSources={events}
							initialEvents={events}
							// events={events}
							initialView="dayGridMonth"
							dateClick={handleDateClick}
							eventContent={EventContent}
							

							locale="fr"
							timeZone="local"
							buttonText={{
								today: "Aujourd'hui",
								month: "Mois",
								week: "Semaine",
								day: "Jour",
							}}
						/>
					</div>
				)}
			</div>
		</>
	);
}


