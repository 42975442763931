/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description member list
 * @component ListMembersPage
 */
import React from "react";

import ListMembers from "../../components/layouts/main/users/Listmembers";

const ListMembersPage = () => {
	return <ListMembers />;
};

export default ListMembersPage;
