/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description user transaction page
 * @component UserTransactionPage
 */

import Main from "../../components/layouts/main";

import AddModepaiement from "../../components/layouts/main/user/addpaiement";

const AddpaiementPage = () => {
	return <AddModepaiement  />;
};

export default AddpaiementPage;
