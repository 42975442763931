import CreateSinister from "../../../components/layouts/main/assurances/client/createSinister";
import styled from "styled-components";

const StyledMain = styled.div`
	position: relative;
	height: 100vh;
	width: 100%;
	min-width: 250px;
	max-width: 1820px;
	padding: 30px 70px;
	font-size: 10px;
	font-family: 'Montserrat', sans-serif;
	@media screen and (max-width: 500px) {
		padding: 20px;
	}
`;

const CreateSinisterPage = () => {
	return (
		<StyledMain>
			<CreateSinister />
		</StyledMain>
	);
};

export default CreateSinisterPage;