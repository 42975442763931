/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add image message
 * @component AddfileMessage
 */
import { PhotoIcon } from "@heroicons/react/24/outline";
import Input from "../../../../UI/form/Input";
import React from "react";

const AddfileMessage = ({ setfile }) => {
	// handle change images
	const handleFileChange = (e) => {
		const { files } = e.target;
		if (files && files.length > 0) {
			setfile((prevState) => ({
				...prevState,
				images: files
			}));
		};
	};

	return (
		<div className="flow-root">
			<button
				type="button"
				className="inline-flex h-5 w-5 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
				<label htmlFor="images">
					<PhotoIcon
						className="h-6 w-6"
						aria-hidden="true"
					/>
				</label>
				{/* input images  */}
				<Input
					onChange={handleFileChange}
					input={{
						id: "images",
						type: "file",
						accept: "image/*",
					}}
					className="hidden"
					hidden={true}
					name="images"
				/>

				<span className="sr-only">Ajouter une image</span>
			</button>
		</div>
	);
};

export default AddfileMessage;
