/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description Addresse information of user
 * @component Addresse
 */

import React, { Fragment, useState, useEffect } from "react";
import { Transition, Dialog, Combobox } from "@headlessui/react";
import { useSelector } from "react-redux";
import {
	MapPinIcon,
	BuildingOfficeIcon,
	ChevronUpDownIcon,
	CheckIcon,
} from "@heroicons/react/20/solid";

import Input from "../../../../UI/form/Input";
import Select from "../../../../UI/form/Select";
import Button from "../../../../UI/button/Button";
import Spinner from "../../../../UI/spinner/Spinner";

import {
	useAddAddresseMedecinMutation,
	useGetAddressseConsultationQuery,
	useGetCanauxConsultationQuery,
	useGetCityQuery,
} from "../../../../features/api/apiSlice";
import { getUserDefaultProfil } from "../../../../features/user/user.slice";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

// add addresse modal
const AddAddressModal = ({ open, setOpen }) => {
	// user default profil
	const defaultProfil = useSelector(getUserDefaultProfil);

	// skip request state
	const [skip, setskip] = useState(true);

	// addresse state
	const [addresse, setAddresse] = useState({});

	// get channel consultation request
	const {
		data: canaux,
		isLoading: canauxLoading,
		isSuccess: canauxIsSuccess,
	} = useGetCanauxConsultationQuery(null, {
		skip,
	});

	// get city request
	const {
		data,
		isLoading: cityIsLoading,
		isSuccess: cityIsSuccess,
	} = useGetCityQuery();

	// add addresse request
	const [addAddresse, { isLoading: addAddresseIsLoading }] =
		useAddAddresseMedecinMutation();

	// skip request
	useEffect(() => {
		if (defaultProfil.id === "IA7" || defaultProfil.id === "IA10") {
			setskip(false);
		}
		return () => {};
	}, [defaultProfil]);

	// handle change
	const handleChange = (e) => {
		const { name, value } = e.target;
		setAddresse((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	// add addresse
	const handleSubmit = (e) => {
		e.preventDefault();
		const ville = selectedVille.id;
		const data = { ...addresse, ville };

		addAddresse(data)
			.unwrap()
			.then(() => {
				setOpen(false);
			})
			.catch();
	};

	const [queryCity, setQueryCity] = useState("");
	const [city, setCity] = useState([]);
	const [selectedVille, setSelectedVille] = useState(null);

	// set city data
	useEffect(() => {
		if (cityIsSuccess) {
			setCity(data?.data);
		}

		return () => {};
	}, [cityIsSuccess, data]);

	// filtered ville
	const filteredVille =
		queryCity === ""
			? city
			: city.filter((city) => {
					return city.ville
						.toLowerCase()
						.includes(queryCity.toLowerCase());
			  });

	return (
		<>
			<Transition.Root
				show={open}
				as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
						onClose={setOpen}>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<Dialog.Panel
									onSubmit={(e) => handleSubmit(e)}
									as="form"
									className="relative transform  rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
									<div>
										<div className="">
											<Dialog.Title
												as="h2"
												className="text-lg font-medium leading-6 text-primary-800">
												Ajouter un Lieu de Consultation.
			 									
											</Dialog.Title>
											<Dialog.Description
												as="p"
												className="text-sm leading-6 text-gray-700">
												Bien vouloir Remplir le
												formulaire ci-dessous pour
												ajouter un lieu de consultation.
											</Dialog.Description>
										</div>
									</div>

									<div className="my-8 grid grid-cols-4 gap-4">
										<div className="col-span-4 sm:col-span-2">
											<Input
												label="Nom de l'établissement"
												input={{
													id: "ets",
													type: "text",
													maxLength: "200",
												}}
												onChange={handleChange}
												name="etablissement"
											/>
										</div>

										<div className="col-span-4 sm:col-span-2">
											{canauxLoading && (
												<Spinner className="w-8 h-8 text-secondary-600 " />
											)}
											{canauxIsSuccess && (
												<Select
													id="canal"
													defaultValue="default"
													name="canal"
													label="Choisir un canal de consultation "
													valueOption={canaux?.data}
													className="sm:max-w-xs w-full text-gray-700"
													onChange={handleChange}
												/>
											)}
										</div>

										<div className="col-span-4 sm:col-span-2">
											{cityIsLoading && (
												<Spinner className="h-10 w-10 text-primary-700 " />
											)}
											{cityIsSuccess && (
												<Combobox
													as="div"
													value={selectedVille}
													onChange={setSelectedVille}>
													<div className="relative mt-4">
														<Combobox.Input
															placeholder="Veillez saisir ou selectionner la ville pour votre consultation"
															className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
															onChange={(event) =>
																setQueryCity(
																	event.target
																		.value
																)
															}
															displayValue={(
																city
															) => city?.ville}
														/>
														<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
															<ChevronUpDownIcon
																className="h-5 w-5 text-gray-400"
																aria-hidden="true"
															/>
														</Combobox.Button>

														{filteredVille.length >
															0 && (
															<Combobox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
																{filteredVille.map(
																	(city) => (
																		<Combobox.Option
																			key={
																				city.id
																			}
																			value={
																				city
																			}
																			className={({
																				active,
																			}) =>
																				classNames(
																					"relative cursor-default select-none py-2 pl-8 pr-4",
																					active
																						? "bg-secondary-600 text-white"
																						: "text-gray-900"
																				)
																			}>
																			{({
																				active,
																				selected,
																			}) => (
																				<>
																					<span
																						className={classNames(
																							"block truncate",
																							selected &&
																								"font-semibold"
																						)}>
																						{
																							city.ville
																						}
																					</span>

																					{selected && (
																						<span
																							className={classNames(
																								"absolute inset-y-0 left-0 flex items-center pl-1.5",
																								active
																									? "text-white"
																									: "text-secondary-600"
																							)}>
																							<CheckIcon
																								className="h-5 w-5"
																								aria-hidden="true"
																							/>
																						</span>
																					)}
																				</>
																			)}
																		</Combobox.Option>
																	)
																)}
															</Combobox.Options>
														)}
													</div>
												</Combobox>
											)}
										</div>

										<div className="col-span-4 sm:col-span-2">
											<Input
												label="Addresse"
												input={{
													id: "addresse",
													type: "text",
													maxLength: "200",
												}}
												onChange={handleChange}
												name="adresse"
											/>
										</div>
									</div>

									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
										<div className="sm:col-start-2 ">
											<Button
												type="submit"
												children="Envoyer"
												button="primary"
												loading={addAddresseIsLoading}
											/>
										</div>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
											onClick={() => setOpen(false)}>
											Annuler
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

const Addresse = (props) => {
	// open add addresse modal state
	const [open, setOpen] = useState(false);

	// addresse consultation request
	const {
		data: adresses,
		isSuccess,
		isLoading,
	} = useGetAddressseConsultationQuery();

	return (
		<>
			{/* add addresse modal  */}
			{open && (
				<AddAddressModal
					open={open}
					setOpen={setOpen}
				/>
			)}

			<section
				aria-labelledby="payment-details-heading "
				className="px-4 sm:p-8">
				{/* header  */}
				<div>
					<h2
						id="payment-details-heading"
						className="text-lg font-medium leading-6 text-gray-900 mt-6">
						Mes lieux de consultation
					</h2>
					<p className="mt-1 text-sm text-gray-500">
						Ces informations seront affichées publiquement, alors
						faites attention à ce que vous partagez.
					</p>
				</div>

				{/* loading componenet  */}
				{isLoading && <Spinner className="w-6 h-6 text-primary-600" />}

				{/* list addresse  */}
				{isSuccess && (
					<div className="overflow-hidden bg-white shadow sm:rounded-md mt-6">
						<ul className="divide-y divide-gray-200">
							{adresses?.data?.map((position) => (
								<li key={position.id_medecinCannal}>
									<div className="block py-4 hover:bg-gray-50">
										<div className="px-4  sm:px-6">
											<div className="flex items-center justify-between">
												<p className="truncate text-md font-medium text-secondary-600">
													{position.etablissement}
												</p>
												<div className="ml-2 flex flex-shrink-0">
													<p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
														{position.canal}
													</p>
												</div>
											</div>
											<div className="sm:flex sm:gap-x-8 mt-2">
												<p className="flex items-center text-sm text-gray-500">
													<BuildingOfficeIcon
														className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
														aria-hidden="true"
													/>
													{position.ville}
												</p>
												<p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
													<MapPinIcon
														className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400"
														aria-hidden="true"
													/>
													{position.adresse}
												</p>
											</div>
										</div>

										{/* <div className=" pl-4 sm:pl-6 mt-3 flex gap-x-4">
											{position.competences?.map(
												(item, index) => (
													<p
														key={index}
														className="mt-2  py-0 flex items-center text-sm text-gray-500 sm:mt-0">
														<CheckCircleIcon
															className="mr-1.5 h-5 w-5 flex-shrink-0 text-indigo-400"
															aria-hidden="true"
														/>
														{item}
													</p>
												)
											)}
										</div> */}
									</div>
								</li>
							))}
						</ul>
					</div>
				)}

				{/* button */}
				<div className="mt-6 grid grid-cols-4 gap-6">
					<div className="col-span-2 sm:col-span-1">
						<Button
							type="button"
							children="Ajouter un lieu de consultation"
							onClick={() => setOpen(true)}
						/>
					</div>
				</div>
			</section>
		</>
	);
};

export default Addresse;
