/**
 * @author assan zidan
 * @email nsangouassanzidan@gmail.com
 * @description maintenance page
 * @component  Construction
 */

import React from "react";
import { FaCogs } from "react-icons/fa";
import styled from "styled-components";

const Construction = () => {
	const Container = styled.div`
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 30%);
	`

	return (
		<Container>
			<div className="flex flex-col justify-center items-center gap-y-8">
				<FaCogs className="text-gray-700 w-72 h-72 " />
				<p className="text-secondary-600 text-3xl font-semibold">
					Page en construction
				</p>
			</div>
		</Container>
	);
};

export default Construction;
