import { useLayoutEffect, Fragment, useCallback } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../../sidebar";
import DashboardHeader from "../../../header/DashboardHeader";
import Notification from "../../../../../UI/notification";
import {React,useState,useEffect} from "react";
import { useGetAllMotifsQuery} from "../../../../../features/api/apiSlice";
import { useDeleteMotifMutation, } from "../../../../../features/api/apiSlice";
import usePagination from "../../../../../hooks/usePagination";
import Pagination from "../../../../../UI/pagination";
import Spinner from "../../../../../UI/spinner/Spinner";
import { Link } from "react-router-dom";
import SpinnerEllipsi from "../../../../../UI/spinner/SpinnerEllipsi";
import { Alert } from "../../UserRecharge/Recharge";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import {
	ArchiveBoxIcon,
} from "@heroicons/react/20/solid";
import {
	getModalProps,
	getModalType,
	initialModal,
	setModal,
} from "../../../../../features/feedBack/modal.slice";
import { EllipsisHorizontalIcon } from "../../../../../UI/svg";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';
import { Menu, Transition } from "@headlessui/react";
import { data } from "autoprefixer";



export default function Liste_de_motifs() {
    const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);

        const [sidebarOpen, setSidebarOpen] = useState(false);
        const [show, setShow] = useState(false);
        const dispatch = useDispatch();
        const [selectedMotifs, setSelectedMotifs] = useState(null);
        const [rowClick, setRowClick] = useState(true);


	useLayoutEffect(() => {
		if (show) {
			setTimeout(() => {
				setShow(false);
			}, 4000);
		}

		return () => {
			clearTimeout();
		};
	}, [show]);

    const [loading, setLoading] = useState(true);

      // close alert
      const closeAlert = useCallback(() => {
        dispatch(initialModal());
    }, [dispatch]);
// close alert after 10 secondes
useLayoutEffect(() => {
    if (modalProps?.isOpen) {
        setTimeout(closeAlert, 10000);
    }

    return () => {
        clearTimeout();
    };
}, [modalProps?.isOpen, closeAlert]);

	// get motifs request
	const { data: motifs, isLoading, isSuccess } = useGetAllMotifsQuery();
	
    const itemsPerPage = 1;

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id_motif: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        nomMotif: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        description: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
   

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
 

    

    const header = renderHeader();

// delete motif request
const [deleteMotif, { isLoading: deleteMotifIsLoading }] = useDeleteMotifMutation();
// id motif state
const [id_motifMotifAction, setid_motifMotifAction] = useState();     
// deleteMotif
     const deleteMotifHandleClick = (motif) => {
        const { nomMotif, description, id_motif } = motif;

	    setid_motifMotifAction(id_motif);
        deleteMotif(id_motif)
			.unwrap()
			.then((res) => {
                dispatch(
					setModal({
						modalType: "motif",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le motif ${nomMotif} ${description} a été archiver avec success`,
						},
					})
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

const actionBodyTemplate = (rowData) => {
    return (
        
            deleteMotifIsLoading 
            ) &&
            id_motifMotifAction ===
            rowData.id_motif? (
            <SpinnerEllipsi
                className="inline-block text-left"
                classNameEllipsi="h-6 w-6 "
                classNameSpin="h-7 w-7 text-secondary-500"
            />
        ) : (
            <Menu
            as="div"
            className=" inline-block text-left">
            <div>
                <Menu.Button className=" relative flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">
                        Open
                        options
                    </span>
                    <EllipsisHorizontalIcon
                        className="h-7 w-7"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1">
                    
                            <>
                            <Menu.Item>
                            {({
                                active,
                            }) => (

                                <Link 
                                to={`${rowData.id_motif}`}

                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "cursor-pointer group flex items-center px-4 py-2 text-sm"
                                    )}>
                                    <PencilSquareIcon
                                    className=" mr-3 h-5 w-5 text-yellow-400 group-hover:text-yellow-500"
                                        aria-hidden="true"

                                    />
                                    Modifier
                                    </Link>

                            )}
                        </Menu.Item>
                                <Menu.Item>
                                    {({
                                        active,
                                    }) => (
                                        <span
                                            onClick={() =>
                                                deleteMotifHandleClick(
                                                    rowData
                                                )
                                            }
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "cursor-pointer group flex items-center px-4 py-2 text-sm"
                                            )}>
                                            <ArchiveBoxIcon
                                                className=" mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                                                aria-hidden="true"
                                            />
                                            Supprimer
                                        </span>
                                    )}
                                </Menu.Item>
                            
                                </>   
                    </Menu.Items>
                 </Transition>
            </Menu> 
        
    );
};

  return (
    <>	
     {/* alert componenet  */}
			{modalType === "motif" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}
            
				
            <div className="card">
				{/* header  */}
				<div className="sm:flex sm:items-center mb-6">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							Liste des motifs
						</h1>
						<p className="mt-2 text-sm text-gray-700"></p>
					</div>

					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<Link to="addmotif">
							<button
								type="button"
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
								Ajouter un motif
							</button>
						</Link>
					</div>
                    </div> 
                    {/* table  */}
                    {isSuccess && (
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className=" inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className=" overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                         
                                <DataTable value={motifs?.data} showGridlines selectionMode={rowClick ? null : 'checkbox'} selection={selectedMotifs} onSelectionChange={(e) => setSelectedMotifs(e.value)} removableSort paginator rows={10} filters={filters} 
                                    globalFilterFields={['id_motif', 'nomMotif', 'description']} header={header} emptyMessage="No customers found.">
                                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                                    <Column field="id_motif" header="id_motif" sortable filterField="id_motif" style={{ minWidth: '14rem' }} />
                                    <Column field="nomMotif" header="nomMotif" sortable filterField="nomMotif" style={{ minWidth: '14rem' }} />
                                    <Column field="description" header="description" filterField="description" style={{ minWidth: '14rem' }} />
                                    <Column field="action" header="action" style={{ minWidth: '14rem' }} body={actionBodyTemplate} />			
                                </DataTable>
                                </div>
                                </div>
                                </div>
				
				)}
               
{/* spinner  */}
{isLoading && (
					<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
				)}
				</div>
			
				
				
			
               
         
		
    
		</>
        
		
		
	);
	
   
}

