/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add user
 * @component UserAdd
 */

import Input from "../../../../UI/form/Input";
import {
	useUpdateMotifMutation,
	useGetAllMotifsQuery
} from "../../../../features/api/apiSlice";


import { useDispatch, useSelector} from "react-redux";
import {  useState,useCallback,useLayoutEffect } from "react";

import Button from "../../../../UI/button/Button";

import { useNavigate,useParams } from "react-router-dom";
import { setModal, getModalProps, getModalType, initialModal
 } from "../../../../features/feedBack/modal.slice";


const MotifAddPage = () => {
	const modalProps = useSelector(getModalProps);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	// get users request
	const { data: motifs, isLoading, isSuccess } = useGetAllMotifsQuery();
	// add user request
	const [updateMotif, { isLoading: loading }] = useUpdateMotifMutation();
	const {id_motif } =  useParams();
    


	// close alert
	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// close alert after 10 s
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);
	const newlist = motifs?.data?.filter((motif) => motif.id_motif === id_motif);
	const [motifInfo, setmotifInfo] = useState(newlist[0]);
	// handle change
	const handleChange = (e) => {
		
			const name = e.target.name;
			const value = e.target.value.trim();

			setmotifInfo((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		
	};

	// add user
	const handleSubmit = (e) => {
		e.preventDefault();
		
		updateMotif({ data:motifInfo, id_motif: id_motif })
			.unwrap()
			.then((res) => {
				// alert success
				dispatch(
					setModal({
						modalType: "motif",
						modalProps: {
							isOpen: true,
							status: "success",
							text: " motif  modifier avec succès",
						},
					})
				);
				navigate("/Consultations/listes-des-motifs");
			})
			.catch((err) => {
				// alert error
			});

			
	};
    return (
		<form
			onSubmit={handleSubmit}
			className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
			<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
				<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
					<div>
						<h3 className="text-lg font-medium leading-6 text-gray-900">
							Ajouter un motif
						</h3>
						<p className="mt-1 max-w-2xl text-sm text-gray-500">
							Bien vouloir remplir ce formulaire pour créer un
							motif.
						</p>
					</div>

					<div className="space-y-6 sm:space-y-5">
						

						{/* last name  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="nom"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                nom
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										input={{
											id: "nomMotif",
											type: "text",
											maxLength: "208",
										}}
										name="nomMotif"
										disabled={false}
										required={false}
										onChange={handleChange}
										value={motifInfo.nomMotif}
									/>
								</div>
							</div>
						</div>

						{/* first name  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="prenom"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								description
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										input={{
											id: "description",
											type: "text",
											maxLength: "80",
										}}
										name="description"
										disabled={false}
										required={false}
										onChange={handleChange}
										value={motifInfo.description}
									/>
								</div>
							</div>
						</div>
						
                        
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
					<Button
						children="Modifier un motif"
						button="primary"
						type="submit"
						className="max-w-max"
						loading={loading}
					/>
				</div>
			</div>
		</form>
	);
};


export default MotifAddPage;
