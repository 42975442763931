/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list of member for an account
 * @component Listmembers
 */
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ArchiveBoxIcon, PencilSquareIcon } from "@heroicons/react/20/solid";

import { useGetAllMembersQuery } from "../../../../features/api/apiSlice";
import {
	getUserCode,
	getUserDefaultProfil,
} from "../../../../features/user/user.slice";

import { EllipsisHorizontalIcon } from "../../../../UI/svg";
import Spinner from "../../../../UI/spinner/Spinner";
import Pagination from "../../../../UI/pagination";

import usePagination from "../../../../hooks/usePagination";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Listmembers() {
	// user code
	const code = useSelector(getUserCode);

	// user default profil
	const defaultProfil = useSelector(getUserDefaultProfil);

	// get members
	const { data: members, isLoading, isSuccess } = useGetAllMembersQuery(code);

	const itemsPerPage = 10;

	// pagination hook
	const { next, prev, jump, currentData, currentPage, maxPage, dataLength } =
		usePagination(members?.data, itemsPerPage);

	return (
		<div className="px-8 sm:px-6 lg:px-8 mt-8">
			{/* header  */}
			<div className="sm:flex sm:items-center">
				{defaultProfil?.id === "IA2" ? (
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							Liste des Membres
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							Ajoutez un ou plusieurs membres de votre famille
						</p>
					</div>
				) : (
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							Liste des employés
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							Ajoutez les employés de votre entreprise
						</p>
					</div>
				)}
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					{defaultProfil?.id === "IA2" ? (
						<Link to="addmember">
							<button
								type="button"
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
								Ajouter un membre
							</button>
						</Link>
					) : (
						<Link to="/listmembers/adduser">
							<button
								type="button"
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
								Ajouter un employé
							</button>
						</Link>
					)}
				</div>
			</div>

			{/* members list  */}
			{isSuccess && !members?.data?.length ? (
				<div className="flex flex-col items-center justify-center">
					{" "}
					<p className="text-3xl text-center text-gray-500">
						aucune compétence{" "}
					</p>
				</div>
			) : (
				<>
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
												Nom
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
												Description
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 sr-only">
												Action
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{currentData?.map((person, index) => (
											<tr key={index}>
												<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold  text-gray-700 sm:pl-6">
													{person.nomComplet}
												</td>
												<td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-700">
													{person.codeMembre}
												</td>
												<td className="py-1">
													<Menu
														as="div"
														className=" inline-block text-left">
														<div>
															<Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
																<span className="sr-only">
																	Open options
																</span>
																<EllipsisHorizontalIcon
																	className="h-7 w-7"
																	aria-hidden="true"
																/>
															</Menu.Button>
														</div>

														<Transition
															as={Fragment}
															enter="transition ease-out duration-100"
															enterFrom="transform opacity-0 scale-95"
															enterTo="transform opacity-100 scale-100"
															leave="transition ease-in duration-75"
															leaveFrom="transform opacity-100 scale-100"
															leaveTo="transform opacity-0 scale-95">
															<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																<div className="py-1">
																	<Menu.Item>
																		{({
																			active,
																		}) => (
																			<span
																				href="#"
																				className={classNames(
																					active
																						? "bg-gray-100 text-gray-900"
																						: "text-gray-700",
																					"cursor-pointer group flex items-center px-4 py-2 text-sm"
																				)}>
																				<PencilSquareIcon
																					className=" mr-3 h-5 w-5 text-yellow-400 group-hover:text-yellow-500"
																					aria-hidden="true"
																				/>
																				Modifier
																			</span>
																		)}
																	</Menu.Item>

																	<Menu.Item>
																		{({
																			active,
																		}) => (
																			<span
																				className={classNames(
																					active
																						? "bg-gray-100 text-gray-900"
																						: "text-gray-700",
																					"cursor-pointer group flex items-center px-4 py-2 text-sm"
																				)}>
																				<ArchiveBoxIcon
																					className=" mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
																					aria-hidden="true"
																				/>
																				Supprimer
																			</span>
																		)}
																	</Menu.Item>
																</div>
															</Menu.Items>
														</Transition>
													</Menu>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					{/* pagination  */}
					{dataLength > itemsPerPage && (
						<Pagination
							next={next}
							prev={prev}
							jump={jump}
							currentData={currentData}
							currentPage={currentPage}
							maxPage={maxPage}
							dataLength={dataLength}
							itemsPerPage={itemsPerPage}
						/>
					)}
				</>
			)}

			{/* loader component 			 */}
			{isLoading && (
				<div className="flex flex-1 justify-center">
					<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
				</div>
			)}
		</div>
	);
}
