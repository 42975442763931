import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {};

// user slice
const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		// set user
		getUserAction(state, action) {
			return action.payload;
		},
	},
});

// get user profil
export const getUserProfil = (state) => state.user.profils;

// get user default profil
export const getUserDefaultProfil = (state) => state.user.defaultProfil;

// get user code
export const getUserCode = (state) => state.user.code;

// get user id
export const getUserId = (state) => state.user.id_utilisateur;

// get user
export const getUser = (state) => state.user;

export const { getUserAction } = userSlice.actions;

export default userSlice.reducer;
