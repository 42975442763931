/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description form  step for insurance souscription
 * @component FormulaireStep
 */

import React, { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import StepContent from "@mui/material/StepContent";
import Button from "../../../../../../UI/button/Button";
import { useSouscriptionAnswerQuestionMutation } from "../../../../../../features/api/apiSlice";
import { styles } from "../../../../../../UI/form/styles";
import FormStepLabel from "./FormStepLabel";
import FormStepBody from "./FormStepBody";
import FormStepNavigation from "./FormStepNavigation";
import Spinner from "../../../../../../UI/spinner/Spinner";
import styled from "styled-components";

const StyledStepContent = styled.div`
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 8px;
	margin: 20px -8px 0 0;
	padding: 10px;
`;

const FormulaireStep = ({ assurance, settotalPrice, totalPrice, formAnswers, idSouscription, setIsLoading, setIsNextButtonWorking, setModalPopUp }) => {
	// Setting steps to localStorage
	localStorage.setItem('currentStep', "1");

	const [activeStep, setActiveStep] = useState(0);
	const [responses, setResponses] = useState([]);
	const [isQuestionLoading, setIsQuestionLoading] = useState(false);
	const [isStepOk, setIsStepOk] = useState(true);
	const [supplement, setSupplement] = useState(0);

	activeStep === assurance.questionnaire.length ? setIsNextButtonWorking(true) : setIsNextButtonWorking(false);

	// add souscription request
	const [AnswerQuestion, { isLoading: loading }] = useSouscriptionAnswerQuestionMutation();

	useEffect(() => {
		settotalPrice(parseFloat(totalPrice) + parseFloat(supplement));
	}, [supplement]);

	useEffect(() => {
		setIsLoading(loading);
		loading ? setIsStepOk(false) : setIsStepOk(true);
	}, [loading]);

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<div>
			<div className="sm:px-8 px-0 flex flex-col gap-y-6">
				<Stepper activeStep={activeStep} orientation="vertical">
					{assurance.questionnaire?.map((item, index) => (
						<Step key={index}>
							<StepLabel
								style={{ cursor: "pointer", padding: 0 }}
								onClick={() => { loading ? console.log("wait loading before clic !") : setActiveStep(index) }}
							>
								<FormStepLabel
									label={item.libelle}
									status={
										activeStep > index ? "done" : activeStep === index ? "current" : "pending"
									}
								/>
							</StepLabel>
							<StepContent>
								<StyledStepContent>
									<FormStepBody
										item={item}
										index={index}
										setResponses={setResponses}
										responses={responses}
										formAnswers={formAnswers}
										setIsQuestionLoading={setIsQuestionLoading}
										AnswerQuestion={AnswerQuestion}
										id_souscription={idSouscription}
										setIsStepOk={setIsStepOk}
										supplement={supplement}
										setSupplement={setSupplement}
										setModalPopUp={setModalPopUp}
									/>

									{(isQuestionLoading || loading) && (
										<div className='flex flex-1 items-center justify-center w-full'>
											<Spinner className='h-7 w-7 text-primary-700 m-2 ' />
										</div>
									)}

									<FormStepNavigation
										item={item}
										index={index}
										handleBack={handleBack}
										formLength={assurance.questionnaire.length}
										setActiveStep={setActiveStep}
										isStepOk={isStepOk}
									/>
								</StyledStepContent>
							</StepContent>
						</Step>
					))}
				</Stepper>
				{activeStep === assurance.questionnaire.length &&
					<div className="mt-7 flex justify-between">
						<button className="rounded-md flex justify-center py-2 px-4 text-sm font-medium text-secondary-600 hover:text-secondary-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 z-10 disabled:opacity-75 disabled:cursor-not-allowed"
							onClick={handleBack}
						>
							<KeyboardDoubleArrowLeft />
						</button>

						<p className="text-sm leading-5 text-gray-500">
							Vous avez achevé le remplissage du formulaire.<br /> vous pouvez passer à l'étape suivante.
						</p>
					</div>
				}
			</div>
		</div>
	);
};

export default FormulaireStep;
