import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import SubscriptionBodyTitles from "../../../../../Utilities/subscriptionBodyTitles";
import { useCallback, useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import PayingModal from "../../../../../../UI/modal/payingModal";
import { useGetAssuranceFullDetailsQuery, useGetSuscriptionQuery, usePayementMutation, useWalletPayementMutation } from "../../../../../../features/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { getModalProps, getModalType, initialModal, setModal } from "../../../../../../features/feedBack/modal.slice";
import Alert from "../../../../../../UI/alert";
import { REACT_APP_BASE_URL } from "../../../../../../config/config";
import { setOperation } from "../../../../../../features/user/payement.slice";
import pocket from "../../../../../../assets/portefeuille.png";
import Spinner from "../../../../../../UI/spinner/Spinner";

const Container = styled.div`
	display: flex;
	flex-direction: column;
    gap: 5em;
`;

const StyledBody = styled.div`
    width: 970px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    //   align-items: center;
    gap: 3em;
    align-self: center;
    @media screen and (max-width: 1023px) {
        width: 100%;
    }
`;

const StyledH3 = styled.h3`
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    color: #0156b6;
`;

const FirstParagraph = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2em;
    // width: 100%;
`;

const ParagraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em 4em;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 0 8px 8px;
    border-top: solid #0156b6 2px;
`;

const DocumentParagraph = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
    padding: 2em 4em;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 0 8px 8px;
    border-top: solid #0156b6 2px;
`;

const SubParagraph = styled.div`
    // border-top: solid #ff9903 2px;
    padding: 1em 0;
`;

const MultiSubParagraph = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2em;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Documents = styled.button`
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    border: solid #ff9903 2px;
    font-size: 1.3em;
    font-weight: 500;
    &:hover {
        border: solid #0156b6 2px;
        background: gray;
        color: white;
    }
`;

const Paragraph = styled.p`
    font-size: 1.5em;
    font-weight: 400;
    // text-align: justify;
    // color: #0156b6;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
`;

const Boldered = styled.span`
    font-weight: 500;
`;

const Coloured = styled.span`
    font-weight: 500;
    color: ${({status}) => status === "active" ? "green" : status === "warning" ? "#FFA500" : "#FF0000"};
`;

const DownButton = styled.button`
    padding: 0.5em 2em;
    border: solid #ff9903 2px;
    font-size: 1.3em;
    font-weight: 400;
    border-radius: 4px;
    &:hover {
        // border: solid #0156b6 2px;
        background: #ff9903;
        color: white;
        font-weight: 500;
    }
`;

function ProductDetails() {
    const [openPay, setOpenPay] = useState(false);
    const [amountMode, setAmountMode] = useState({
		idPayOption: 1,
        mode: "Unique",
        amount: 0
    });
	const [canal, setCanal] = useState({
        name: "Pocket",
        number: undefined,
        illustration: pocket,
		code: "PORTE_FEUILLE"
    });
    const [activeStep, setActiveStep] = useState(0);
    const [selectedMode, setSelectedMode] = useState(1);
    const [selectedCanal, setSelectedCanal] = useState(0);
    
    const params = useParams();
    const souscriptionId = parseInt(params?.id);
    const navigate = useNavigate();
    const location = useLocation();
    const currenPath = location.pathname;

    console.log("Se composant charge bien !");

    const { data, isSuccess, isFetching } = useGetSuscriptionQuery(souscriptionId);
    const souscription = isSuccess ? data.data : null;

    const { data: assurData, isSuccess: assurSuccess, isFetching: assurFetching } = useGetAssuranceFullDetailsQuery(souscription?.assurance.idAssurance);
    const assurance = assurSuccess ? assurData.data : null;

    // setting defaul amountMode value
	useEffect(() => {
		setAmountMode({
			idPayOption: 1,
			mode: "Unique",
			amount: souscription?.cout
		})
	}, [souscription?.cout]);

    // pay product insurance request
    const [walletPayementFetch, { WP_isLoading }] = useWalletPayementMutation();
	const [payementFetch, { isLoading: payLoading }] = usePayementMutation ();

    // Alert hanldling constant -----------------------------------
    const dispatch = useDispatch();
	const modalType = useSelector(getModalType);
    const modalProps = useSelector(getModalProps);
    const closeAlert = useCallback(() => {
		dispatch(initialModal());
    }, [dispatch]);

	useEffect(() => {
        if(modalProps?.isOpen) {
            setTimeout(closeAlert, 5000);
        }

        return () => {
            clearTimeout();
        }
    }, [modalProps?.isOpen, closeAlert]);

	function setModalPopUp(text, status) {
        return (
            dispatch(
                setModal({
                    modalType: "user",
                    modalProps: {
                        isOpen: true,
                        status: status,
                        text: text
                    }
                })
            )
        )
    };
	// -------------------------------------------------------------

    // handle pay
	async function handlePayement (event, url, amount, idPayOption, payCanal, number) {
		event.preventDefault();
		let errorValue = false;

		// const beneficiaires = recever?.map(item => {
		// 	return item.code
		// });

		const payement = {
			idSouscription: souscription?.idSouscription,
			prix: amount || souscription?.cout,
			avance: amount,
			idAssurance: souscription?.assurance?.idAssurance,
			idPayOption: idPayOption,
			telephone: number,
			// pays: "",
			returnURL: `${REACT_APP_BASE_URL}${url}?returnStep=${localStorage.getItem('currentStep')}`,
			// codeReduction: "",
			operateur: payCanal || 'CM_ORANGEMONEY',
			// form: formAnswers,
			// beneficiaires: beneficiaires,
			// returnStep: localStorage.getItem('currentStep')
		};

		if (payCanal === 'PORTE_FEUILLE') {
			// pay with pocket
			try {
				const payload = await walletPayementFetch(payement).unwrap();

				setModalPopUp("Opération réussi !", "success");
				navigate(url);
				// waiting thing i can do with the value of payload
			} catch (err) {
				errorValue = err;
				setModalPopUp("Une erreur est survenue, réessayez plutard !", "failed");
				throw new Error('Erreur');
			};
		} else {
			// pay whithout pocket
			try {
				const payload = await payementFetch(payement).unwrap();
				dispatch(setOperation('souscription'));
				setModalPopUp("Ouverture de la page en cours, veuillez patienter...", "success");
				window.open(payload.data.url, '_self');
			} catch (err) {
				errorValue = err;
				setModalPopUp("Une erreur est survenue, réessayez plutard !", "failed");
				throw new Error('Erreur');
			};
		};

		return errorValue;
	};

    function daysLeftProvider(endDate) {
		const dateFin = new Date(endDate);
		const dateActuelle = new Date();

		const differenceEnMillisecondes = dateFin - dateActuelle;
		const joursRestants = Math.ceil(differenceEnMillisecondes / (1000 * 60 * 60 * 24));

		return joursRestants;
	};

    function statusProvider(daysLeft) {
		if (daysLeft > 10) {
			return {
				status: "active",
				value: "Active"
			};
		} else if (daysLeft <= 10 && daysLeft > 0) {
			return {
				status: "warning",
				value: "Expire bientôt"
			}
		} else {
			return {
				status: "expired",
				value: "Expirée"
			}
		};
	};

    if (isFetching || assurFetching) {

        return (
            <Spinner className="h-10 w-10 text-primary-700 m-auto h-full" />
        );
    };

    if (isSuccess && assurSuccess) {
        return (
            <Container>
                <SubscriptionBodyTitles
                    bodyText={[`Détails de la Souscription`]}
                    title={`Code produit : ${souscription?.code ? souscription.code : "indéterminé"}`}
                />
    
                <StyledBody>
                    <FirstParagraph>
                        <div>
                            <Paragraph><Boldered>nom du produit :</Boldered> {assurance?.nom ? assurance.nom : "indéterminé"}</Paragraph>
                            <Paragraph><Boldered>Début de la souscription :</Boldered> {souscription?.dateDebutValidite ? souscription.dateDebutValidite : "indéterminé"}</Paragraph>
                            <Paragraph><Boldered>Expiration de la souscription :</Boldered> {souscription?.dateFinValidite ? souscription.dateFinValidite : "indéterminé"}</Paragraph>
                            <Paragraph><Boldered>Nombre de jours restants :</Boldered> {souscription?.dateFinValidite ? daysLeftProvider(souscription.dateFinValidite) : "indéterminé"}</Paragraph>
                            <Paragraph><Boldered>Prime :</Boldered> {souscription?.cout ? souscription.cout : "indéterminé"} Fcfa</Paragraph>
                        </div>
                        
                        <DownButton
                            onClick={() => setOpenPay(true)}
                        >Effectuer un payement</DownButton>
                    </FirstParagraph>
    
                    <Section>
                        <StyledH3>Détails de la couverture</StyledH3>
    
                        <ParagraphContainer>
                            <Paragraph>
                                <Boldered>Garanties :</Boldered>
                                {assurance.listeServices.length > 0 ? (
                                    assurance.listeServices.map((service) => (
                                        ` ${service?.nom}, `
                                    ))
                                ) : (
                                    " Aucune garantie trouvée !"
                                )}
                            </Paragraph>

                            <Paragraph>
                                <Boldered>Montant de la couverture :</Boldered>
                                {assurance.listeServices.length > 0 ? (
                                    assurance.listeServices.map((service) => (
                                        ` ${service?.prixCouverture} Fcfa, `
                                    ))
                                ) : (
                                    " Montant indéterminé !"
                                )}
                            </Paragraph>
                            <Paragraph>
                                <Boldered>Pourcentage pris en charge :</Boldered>
                                {assurance.listeServices.length > 0 ? (
                                    assurance.listeServices.map((service) => (
                                        ` ${service?.tauxCouverture} %, `
                                    ))
                                ) : (
                                    " Pourcentage indéterminé !"
                                )}
                            </Paragraph>
                        </ParagraphContainer>
                    </Section>
    
                    <Section>
                        <StyledH3>Informations sur le/s bénéficiaire/s</StyledH3>
    
                        <ParagraphContainer>
                            {souscription.beneficiaires.length > 0 ? (
                                souscription.beneficiaires.map((beneficiaire) => (
                                    <SubParagraph>
                                        <Paragraph><Boldered>Nom :</Boldered> {beneficiaire?.prenom ? beneficiaire.prenom : "non renseigné..."}</Paragraph>
                                        <Paragraph><Boldered>Adresse email :</Boldered> {beneficiaire?.email ? beneficiaire.email : "non renseigné..."}</Paragraph>
                                        <Paragraph><Boldered>Numéro de télephone :</Boldered> {beneficiaire?.number ? beneficiaire.number : "non renseigné..."}</Paragraph>
                                    </SubParagraph>
                                ))
                            ) : (
                                <Paragraph>Aucun bénéficiaire enregistré !</Paragraph>
                            )}
                        </ParagraphContainer>
                    </Section>
    
                    <Section>
                        <StyledH3>Documents associes</StyledH3>
    
                        <DocumentParagraph>
                            {assurance.documentation.length > 0 ? (
                                assurance.documentation.map((document) => (
                                    <Documents>
                                        {document.titre}
                                        <ArrowTopRightOnSquareIcon className='ml-4 w-5 h-5 text-primary-900' />
                                    </Documents>
                                ))
                            ) : (
                                <Paragraph>Aucune documentation trouvée !</Paragraph>
                            )}
                        </DocumentParagraph>
                    </Section>
    
                    <Section>
                        <StyledH3>Etat de la souscription</StyledH3>
    
                        <ParagraphContainer>
                            <Paragraph>
                                <Boldered>Statut de la souscription :</Boldered> <Coloured
                                    status={statusProvider(daysLeftProvider(souscription.dateFinValidite)).status}
                                >{statusProvider(daysLeftProvider(souscription.dateFinValidite)).value}</Coloured>
                            </Paragraph>
                            
                            <MultiSubParagraph>
                                <Paragraph>
                                    <Boldered>Prochain payement :</Boldered>
                                    {souscription.dateFinValidite}
                                </Paragraph>

                                <DownButton
                                    onClick={() => setOpenPay(true)}
                                >Effectuer le payement</DownButton>
                            </MultiSubParagraph>
                        </ParagraphContainer>
                    </Section>
                </StyledBody>
    
                {/* Paying modal */}
                {openPay && (
                    <PayingModal
                        open={openPay}
                        setOpen={setOpenPay}
                        amountMode={amountMode}
                        setAmountMode={setAmountMode}
                        payingCanal={canal}
                        setCanal={setCanal}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        selectedMode={selectedMode}
                        setSelectedMode={setSelectedMode}
                        selectedCanal={selectedCanal}
                        setSelectedCanal={setSelectedCanal}
                        handlePayement={handlePayement}
                        currenPath={currenPath}
                        totalPrice={souscription?.cout}
                        payingOptions={{
                            payingModes: assurance?.payOptions,
                            minPrice: assurance?.prix,
                            validity: assurance?.duree,
                            discount: assurance?.reductions
                        }}
                    />
                )}

                {/* insert toast here */}
                {modalType === "user" && modalProps?.isOpen ? (
                    <div className="px-4 fixed top-5 z-50 w-2/5 inset-x-0 mx-auto">
                        <Alert
                            alert={{text: modalProps?.text, error: modalProps?.status === "failed" ? true : false}}
                            closeAlert={closeAlert}
                        />
                    </div>
                ) : null}
            </Container>
        );
    } else {

        return (
            <h1>An error occure</h1>
        );
    };
    
};

export default ProductDetails;
