import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

// import slice
import auth from "../features/user/auth.slice";
import isOnlineStatus from "../features/user/isOnlineStatus.slice";
import user from "../features/user/user.slice";
import payement from "../features/user/payement.slice";
import modal from "../features/feedBack/modal.slice";
import chats from "../features/chats/chats.slice";
import { apiSlice } from "../features/api/apiSlice";
import form from "../features/form/form.slice";
import consultation from "../features/consultation/consultation.slice";

// persist configuration
const persistConfig = {
	key: "root",
	storage,
	whitelist: [
		"isOnlineStatus",
		"modal",
		"form",
		"payement",
		"user",
		"consultation",
	],
	blacklist: [apiSlice.reducerPath, "auth"],
};

// root reduecer
const rootReducer = combineReducers({
	auth,
	isOnlineStatus,
	modal,
	form,
	user,
	payement,
	consultation,
	chats,
	[apiSlice.reducerPath]: apiSlice.reducer,
});

// persist root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// store
const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}).concat(apiSlice.middleware),
	devTools: true,
});

export default store;
