import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { getToken } from "../../../../features/user/auth.slice";

import { useGetUserQuery } from "../../../../features/api/apiSlice";

import { logOut } from "../../../../features/user/auth.slice";

import Spinner from "../../../../UI/spinner/Spinner";
import { useEffect } from "react";

const RequireAuth = ({ allowedRoles }) => {
	const location = useLocation();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// get token

	const auth = useSelector(getToken);
	
	// content
	let content;

	// get user profil request
	const {
		data: user,
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetUserQuery();

	// deconnexion
	const deconnection = () => {
		dispatch(logOut());
		return (content = (
			<Navigate
				to="/"
				state={{ from: location }}
				replace
			/>
		));
	};

	if (!auth) {
		deconnection();
	}

	console.log("Require auth !")

	if (isSuccess) {
		content = allowedRoles?.includes(user?.data?.profil?.id) ? (
			<Outlet />
		) : auth ? (
			<Navigate
				to="/unauthorized"
				state={{ from: location }}
				replace
			/>
		) : (
			deconnection()
		);
	} else if (isError) {
		switch (error.status) {
			case "FETCH_ERROR":
				deconnection();
				navigate("/", { replace: true });
				break;

			default:
				break;
		}
	}

	useEffect(() => {
		if (!auth) {
			dispatch(logOut());
			navigate("/", { replace: true });
		}

		return () => {};
	}, [dispatch, navigate, auth]);

	return (
		<>
			{/* loading  compnent  */}
			{isLoading && (
				<div className="flex flex-col items-center justify-center h-full max-h-full min-h-screen">
					<Spinner className="w-32 h-32 text-primary-700" />
				</div>
			)}

			{/* display content  */}
			{content}
		</>
	);
};

export default RequireAuth;
