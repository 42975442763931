import { setModal } from "../../features/feedBack/modal.slice";

export function setModalPopUp(dispatch, text, status) {
    return (
        dispatch(
            setModal({
                modalType: "user",
                modalProps: {
                    isOpen: true,
                    status: status,
                    text: text
                }
            })
        )
    )
};
