import React from 'react';
import { useState,useEffect} from "react";
import { useParams } from "react-router-dom";

import Button from "../../../../../UI/button/Button";
import Input from "../../../../../UI/form/Input";
import { setModal } from "../../../../../features/feedBack/modal.slice";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect, useCallback } from "react";
import {
	getModalProps,
	initialModal,
} from "../../../../../features/feedBack/modal.slice";
import {
    useUpdateMotifMutation,
    useGetAllMotifsQuery,
} from "../../../../../features/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";

function Update_Motif() {
	const modalProps = useSelector(getModalProps);
    const navigate = useNavigate();
		const dispatch = useDispatch();
        const params = useParams();

    // add motif request
		const [updateMotif, { isLoading: loading }] = useUpdateMotifMutation();
	// motif state
    const { id_motif } = useParams();

    // close alert
    const closeAlert = useCallback(() => {
        dispatch(initialModal());
    }, [dispatch]);
// close alert after 10 secondes
useLayoutEffect(() => {
    if (modalProps?.isOpen) {
        setTimeout(closeAlert, 10000);
    }

    return () => {
        clearTimeout();
    };
}, [modalProps?.isOpen, closeAlert]);
  // get motifs request
  const { data: motifs, isLoading, isSuccess } = useGetAllMotifsQuery();
 const newlist = motifs?.data?.filter((motif) => motif.id_motif === id_motif);
 const [motifInfo, setmotifInfo] = useState(newlist[0]);   
console.log(motifInfo);



   // handle change
   const handleChange = (e) => {
		
    const name = e.target.name;
    const value = e.target.value.trim();

    setmotifInfo((prevState) => {
        return {
            ...prevState,
            [name]: value,
        };
    });

};

		// add motif
		const handleSubmit = (e) => {
			e.preventDefault();

			updateMotif({data:motifInfo, id_motif: id_motif})
				.unwrap()
				.then((res) => {

						// alert motif we update new motif
						dispatch(
							setModal({
								modalType: "motif",
								modalProps: {
									isOpen: true,
									status: "success",
									text: `Le motif a été modifié avec succès`,
								},
							})
						);
						navigate("/mes-liste-des-motifs");
					})
					.catch((err) => {
						// alert error
						// alert motif we update new motif
						dispatch(
							setModal({
								modalType: "motif",
								modalProps: {
									isOpen: true,
									status: "failed",
									text: `Le motif existe déjà`,
								},
							})
						);
						navigate("/mes-liste-des-motifs");
					});
		};
      

	
 

        return (
       
    <div>
        <form onSubmit={handleSubmit}

				className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
				<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

					<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
						<div>
							<h3 className="text-lg font-medium leading-6 text-gray-900">
								Modifier un motif
							</h3>
							<p className="mt-1 max-w-2xl text-sm text-gray-500">
								Bien vouloir remplir ce formulaire pour modifier un
								motif.
							</p>
						</div>
	
						<div className="space-y-6 sm:space-y-5">

							{/* last name  */}
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">

                                <label
									htmlFor="nom"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Nom Motif
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<div className="sm:max-w-xs">
										<Input

											input={{
												id: "nomMotif",
												type: "text",
												maxLength: "180",
											}}
											name="nomMotif"
											disabled={false}
											required={false}
											onChange={handleChange}
                                            value={motifInfo.nomMotif}
										/>
									</div>
								</div>
							</div>
	
							{/* first name  */}
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="description"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Description
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<div className="sm:max-w-xs">
										<Input
											input={{
												id: "description",
												type: "text",
												maxLength: "255",
											}}
											name="description"
											disabled={false}
											required={false}
											onChange={handleChange}
                                            value={motifInfo.description}

										/>
									</div>
								</div>
							</div>
	
						</div>
					</div>
				</div>
	
				<div className="pt-5">
					<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
						<Button
							children="Modifier le motif"
							button="primary"
							type="submit"
							className="max-w-max"
							loading={loading}
						/>
					</div>
				</div>
                	                    

			</form>    
            </div>
  )
}

export default Update_Motif
