import { useNavigate } from "react-router-dom";
import Button from "../../../UI/button/Button";
import styled from "styled-components";
import { useEffect, useState } from "react";

const StyledButton = styled(Button)`
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    // box-shadow: 0 1px 2px black;
`;

function SubscriptionNavigationButton({ steps, setStep, isNextButtonWorking, handleAddBeneficiary, handlePayStatus }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    

    useEffect(() => {
        if (handleAddBeneficiary.isAdding !== isLoading) {
            setIsLoading(handleAddBeneficiary.isAdding);
        };

        if (handleAddBeneficiary.addSuccess !== isSuccess) {
            setSuccess(handleAddBeneficiary.addSuccess);
        };
    }, [handleAddBeneficiary.isAdding, handleAddBeneficiary.addSuccess]);
    
    useEffect(() => {
        if (isSuccess && !isLoading) {
            setStep(steps[index + 1], index + 1);
        };
    }, [isLoading, isSuccess]);

    function getCurrentStepIndex(steps) {
        let currentIndex;

        steps.forEach((step, index) => {
            if (step.status === "current") {
                currentIndex = index
            }
        });

        return currentIndex;
    };

    const index = getCurrentStepIndex(steps);

    function handleNextStepClic() {
        if (index === 0) {
            handleAddBeneficiary.handleAddBeneficiary();
        } else if (index === 3) {
            handlePayStatus.provideStatus();
        } else {
            setStep(steps[index + 1], index + 1);
        }
    };

    return (
        <div
            style={{
                margin: "50px 0"
            }}
            className='flex items-center justify-between'
        >
            <StyledButton
                className="bg-secondary-600"
                children='RETOUR'
                type='button'
                onClick={() => index > 0 ? setStep(steps[index - 1], index - 1) : navigate(-1)}
            />
            
            <StyledButton
                className="bg-primary-700"
                children='CONTINUER'
                type='button'
                loading={isLoading || handlePayStatus.status_loading}
                disabled={!isNextButtonWorking}
                onClick={() => handleNextStepClic()}
            />
        </div>
    );
};

export default SubscriptionNavigationButton;
