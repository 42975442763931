// truncate text
export const subdescription = (des) => {
	const desctiption = des.substring(0, 30);
	if (des.length < 30) {
		return des;
	} else {
		return desctiption + "...";
	}
};

// format number to xaf currency
export const formatPrix = (prix) => {
	return new Intl.NumberFormat("fr-FR", {
		style: "currency",
		currency: "XAF",
	}).format(prix);
};
