/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  skill medecin page
 * @component MesCompetencesPage
 */
import Main from "../../../components/layouts/main";
import MesCompetences from "../../../components/layouts/main/consultations/doctor/Competences";

const MesCompetencesPage = (props) => {
	return <Main children={<MesCompetences />} />;
};

export default MesCompetencesPage;
