
// change account for add a new beneficiary
export async function changeAccountType(type, setDefaultProfil, dispatch, apiSlice, logIn, setChangingProfil, setChangingError, setChangingType, USERCODE) {

    const { FAMILY, ENTREPRISE } = USERCODE;

    if (type === FAMILY || type === ENTREPRISE) {
        setChangingProfil(true);
        setChangingError(false);
        setChangingType(type);

        try {
            await setDefaultProfil({ niveau: type })
            .unwrap()
            .then((data) => {
                // reset all global api state
                dispatch(apiSlice.util.resetApiState());
        
                dispatch(logIn(data?.token));

                window.location.reload();
            });
        } catch (err) {
            setChangingProfil(false);
            setChangingError(true);
            setChangingType("");
            console.error("Failed to save the post: ", err);
        }
    };
};

// add a new beneficiary click
export function addNewBeneficiary(user, setOpen, location, navigate, USERCODE) {

    const currePath = location.pathname;

    const { FAMILY, ENTREPRISE } = USERCODE;

    if (user?.defaultProfil?.id === FAMILY) {
        navigate(`/listmembers/addmember`, { state: { returnPath: currePath } });
    };

    if (user?.defaultProfil?.id === ENTREPRISE) {
        navigate(`/listmembers/adduser`, { state: { returnPath: currePath } });
    };

    setOpen(false);
};

// remove user to list
export const removeItem = (target, setSelectedPeople, setRecever, selectedPeople, recever) => {
    const newTableServices = recever.filter(item => item !== target);
    setSelectedPeople(newTableServices);
    setRecever(newTableServices);
    console.log("remove items")
};

// handle modal
export const handleOpen = (setOpen) => {
    setOpen(true);
};

export const handleClose = (setOpen) => {
    setOpen(false);
};
