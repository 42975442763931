/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description   reglement and detail of  transaction
 * @component UserDetailTransaction
 */
import { Tab } from "@headlessui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import TransactionDetail from "./TransactionDetail";
import TransactionsReglement from "./TransactionsReglement";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function UserDetailTransaction() {
	// location hook
	const { hash, key } = useLocation();
	const id_transaction = hash.substring(1);

	// scrool down to transaction
	useEffect(() => {
		if (id_transaction) {
			const targetElement = document.getElementById(id_transaction);
			targetElement?.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		}
	}, [key, id_transaction]);

	return (
		<div className="w-full py-8 sm:px-8 ">
			<Tab.Group
				as="div"
				id={id_transaction}>
				{/* tab list  */}
				<Tab.List
					className="flex p-1 space-x-4 rounded-sm"
					aria-label="Tabs">
					{/* detail component  */}
					<Tab
						className={({ selected }) =>
							classNames(
								"rounded-md px-3 py-2 text-sm font-medium ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
								selected
									? "bg-indigo-100 text-indigo-700"
									: "text-gray-500 hover:text-gray-700"
							)
						}>
						Details de la transaction
					</Tab>

					{/* reglement  component  */}
					<Tab
						className={({ selected }) =>
							classNames(
								"rounded-md px-3 py-2 text-sm font-medium ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
								selected
									? "bg-indigo-100 text-indigo-700"
									: "text-gray-500 hover:text-gray-700 "
							)
						}>
						Réglement de la transaction
					</Tab>
				</Tab.List>

				{/* tap panels  */}
				<Tab.Panels className="mt-2">
					<Tab.Panel
						className={classNames(
							"rounded-xl bg-white p-3",
							"ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
						)}>
						<TransactionDetail />
					</Tab.Panel>
					<Tab.Panel
						className={classNames(
							"rounded-xl bg-white p-3",
							"ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
						)}>
						<TransactionsReglement />
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
}
