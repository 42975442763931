/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description expert consultation souscription
 * @component ExpertRDV
 */
import React, { useState, Fragment } from "react";
import Button from "../../../../UI/button/Button";
import { useParams } from "react-router-dom";
import { Transition, Dialog } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import PayementStep from "../assurances/client/PayementStep";
import { setOperation } from "../../../../features/user/payement.slice";
import { useAvisExpertPayementMutation } from "../../../../features/api/apiSlice";
import { getUserCode } from "../../../../features/user/user.slice";

export const Modal = ({ open, setOpen, price, codeMedecin }) => {
	const codeUser = useSelector(getUserCode);

	const dispatch = useDispatch();
	const params = useParams();

	const [operator, setOperator] = useState("CM_ORANGEMONEY");

	// pay expert opinion consultation request
	const [avisExpertPayement, { isLoading: avisExpertPayementIsLoading }] =
		useAvisExpertPayementMutation();

	// pay consultation
	const starPayementHandleClick = () => {
		const data = {
			operator,
			prix: price,
			emetteur: codeUser,
			destinataire: codeMedecin,
			codeConsult: params.code,
			skillID: params.idSkill,
		};

		avisExpertPayement(data)
			.unwrap()
			.then((res) => {
				// do something
				dispatch(setOperation("avisExpert"));
				window.open(res.url, "_self");
			})
			.catch(() => {
				// do something
			});
	};

	return (
		<Transition.Root
			show={open}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<PayementStep
										title="Mode de payement"
										setMethodePayement={setOperator}
									/>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<Button
										className="max-w-max ml-4"
										button="primary"
										children="Continuer"
										type="button"
										loading={avisExpertPayementIsLoading}
										onClick={starPayementHandleClick}
									/>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}>
										Annuler
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

const ExpertRDV = ({ skills, codeMedecin }) => {
	const params = useParams();
	const [open, setOpen] = useState(false);

	const price = skills?.find((item) => item.id_skill === params.idSkill).prix;

	return (
		<>
			{open && (
				<Modal
					open={open}
					setOpen={setOpen}
					price={price}
					codeMedecin={codeMedecin}
				/>
			)}
			<div className="p-4 flex-1 relative flex flex-col gap-y-4 items-start ml-24 sm:m-0">
				<div className="flex items-center gap-x-2">
					<span className=" font-normal text-md text-gray-500">
						prix de l'expertise:{" "}
					</span>
					<span className="text-primary-800 font-medium">
						{price} XAF
					</span>
				</div>
				<Button
					children="Payer maintenant"
					button="primary"
					type="button"
					onClick={() => setOpen(true)}
				/>
			</div>
		</>
	);
};

export default ExpertRDV;
