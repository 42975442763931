/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description logo 
 * @component Logo
 */

import logo from "../../assets/logotext.jpeg"

import { Link } from 'react-router-dom'

const Logo = () => {
  return (
    <Link to='/connexion' className="flex justify-center"><figure><img src={logo} alt="logo inch-assur" className="h-30 w-auto" /></figure></Link>
  )
}

export default Logo