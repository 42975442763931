export function handleInputValueChange({ event, currentChanges, setEnteredValues, options, setCurrentOption, setSubQuestions, subQuestions, id_question, setIsQuestionLoading, setSubmitReady }) {
    setIsQuestionLoading(true);

    try {
        const type = event?.target?.type;
        const name = event?.target?.name;
        const value = event?.target?.value;
        const checked = event?.target?.checked;
        const id = event?.target?.id;

        if (currentChanges?.type === "text") {
            setEnteredValues(currentChanges);
        };

        if (currentChanges?.type === "textarea") {
            setEnteredValues(currentChanges);
        };

        if (type === "radio") {
            const option = options.find(option => option?.label === id);

            setEnteredValues({id, type, id_question, values: {name, value, checked}});
            setCurrentOption(option);

            if (option?.subquestions) {
                option.subquestions.forEach((subQuestion) => {
                    if (!subQuestions.includes(subQuestion)) {
                        setSubQuestions(option.subquestions);
                    }
                });
            };
        };

        if (type === "checkbox") {
            const option = options.find(option => option?.idOption === id);

            setEnteredValues({id, type, id_question, values : [{name, value, checked}]});
            setCurrentOption(option);

            if (option.subquestions) {
                option.subquestions.forEach((subQuestion) => {
                    if (!subQuestions.includes(subQuestion)) {
                        setSubQuestions(option.subquestions);
                    }
                });
            };
        };
    } catch (error) {
        console.log("handlenputValueChange error :", error);
    } finally {
        setIsQuestionLoading(false);
        setSubmitReady(true);
    }
};

export function handleCurrentChange(event, setCurrentChanges, id_question) {
    const type = event.target.type;
    const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    const id = event.target.id;

    setCurrentChanges({id, type, id_question, values: {name, value, checked}});
};

export function handleResponseObject(index, enteredValues, currentOption, responses, setResponses) {
    // ensure computing on true values
    if (currentOption?.idOption && enteredValues.id_question) {
        // checking for if current response existe
        const currentResponse = responses.find((response) => response.id_question === enteredValues.id_question);
        
        if (currentResponse) {
            // removing depending subquestions
            responses.forEach((response, index) => {
                if (response.id_options) {
                    const isDeleteble = responses.find(response => response?.response?.id === response.id_options);
                    
                    if (isDeleteble === undefined) {
                        setResponses(responses.filter(item => item.id_options !== response.id_options));
                    };
                };
            });

            // updating the response object wiht the new answers
            const filteredResponses = responses.filter(item => item.id_question !== currentResponse.id_question);
            currentResponse.response = {
                id: currentOption?.idOption,
                type: enteredValues.type,
                prix: currentOption.prix,
                values: enteredValues.values
            };
            filteredResponses.splice(index, 0, currentResponse);

            // adding sub-questions of this option to the response object
            if (currentOption.subquestions) {
                currentOption.subquestions.forEach((subQuestion) => {
                    const newResponse = {
                        id_question: subQuestion.idQuestion,
                        id_options: currentOption?.idOption,
                        response: undefined
                    };
                    filteredResponses.push(newResponse);
                });
            };

            setResponses(filteredResponses);
        } else {
            // create new response to the current question
            const newResponse = {
                id_question: enteredValues.id_question,
                id_options: null,
                response: {
                    id: currentOption?.idOption,
                    type: enteredValues.type,
                    prix: currentOption.prix,
                    values: enteredValues.values
                }
            };
    
            setResponses([...responses, newResponse]);
        }
    };
};

export function handleSbumitOption(id_question, setIsQuestionLoading, responses, setSupplement, AnswerQuestion, id_souscription, supplement, setEnteredValues, setSubmittionError, setModalPopUp) {
    const currentResponse = responses.find(response => response.id_question === id_question);
    
    switch (currentResponse?.response?.type) {
        case "radio":
            try {
                const radioResponse = {
                    question: id_question,
                    choix: {
                        idOption: currentResponse.response.id,
                        label: currentResponse.response.values.value,
                        prix: currentResponse.response.prix
                    }
                };
    
                AnswerQuestion({ id_souscription: id_souscription, data: radioResponse })
                .unwrap()
                .then((response) => {
                    if (response?.data?.supplementPrix) { setSupplement(response.data.supplementPrix) };
                    setEnteredValues({});
                    setModalPopUp(response?.message ? response.message : "Statut de la reponse inconue.", "success");
                })
                .catch((error) => {
                    setSubmittionError(error);
                    setModalPopUp("Echec d'enregistrement de la reponse.", "failed");
                });
            } catch (error) {
                setSubmittionError(error);
                setModalPopUp("Oup's ! Une erreur est survenue.", "failed");
            };
            break;
        case "text":
            try {
                const textResponse = {
                    question: id_question,
                    choix: {
                        idOption: currentResponse.response.id,
                        label: currentResponse.response.values.value,
                        prix: currentResponse.response.prix
                    }
                };

                AnswerQuestion({ id_souscription: id_souscription, data: textResponse })
                .unwrap()
                .then((response) => {
                    if (response?.data?.supplementPrix) { setSupplement(response.data.supplementPrix) };
                    setEnteredValues({});
                    setModalPopUp(response?.message ? response.message : "Statut de la reponse inconue.", "success");
                })
                .catch((error) => {
                    setSubmittionError(error);
                    setModalPopUp("Echec d'enregistrement de la reponse.", "failed");
                });
            } catch (error) {
                setSubmittionError(error);
                setModalPopUp("Oup's ! Une erreur est survenue.", "failed");
            };
            break;
        case "textarea":
            try {
                const textAreaResponse = {
                    question: id_question,
                    choix: {
                        idOption: currentResponse.response.id,
                        label: currentResponse.response.values.value,
                        prix: currentResponse.response.prix
                    }
                };
                AnswerQuestion({ id_souscription: id_souscription, data: textAreaResponse })
                .unwrap()
                .then((response) => {
                    if (response?.data?.supplementPrix) { setSupplement(response.data.supplementPrix) };
                    setEnteredValues({});
                    setModalPopUp(response?.message ? response.message : "Statut de la reponse inconue.", "success");
                })
                .catch((error) => {
                    setSubmittionError(error);
                    setModalPopUp("Echec d'enregistrement de la reponse.", "failed");
                });
            } catch (error) {
                setSubmittionError(error);
                setModalPopUp("Oup's ! Une erreur est survenue.", "failed");
            };
            break;
        case "checkbox":
            // try {
            //     const checkboxResponse = {
            //         options: checkedValues,
            //         supplement: supplement
    
            //     };
            //     AnswerQuestion({ id_souscription: souscription.id, id_question: id, data: checkboxResponse })
            //     .unwrap()
            //     .then((res) => {
            //         const retour = res.data.supplement
            //         setValues(retour);;
            //     });
            // } catch (error) {
            //     setSubmittionError(error);
            // } finally {
            //     setEnteredValues({});
            // };
            setModalPopUp("Réponse enregistrée !", "success")
            console.log("CKECKBOX SUBMITTED !");
            break;
        default:
            break;
    }
};
