import styled from "styled-components";
import ProductDetails from "../../../components/layouts/main/assurances/client/productDetails";

const StyledMain = styled.div`
	position: relative;
	height: 100vh;
	width: 100%;
	min-width: 380px;
	max-width: 1820px;
	padding: 30px 70px;
	font-size: 10px;
	font-family: 'Montserrat', sans-serif;
	@media screen and (max-width: 800px) {
		padding: 20px 40px;
	}
	@media screen and (max-width: 640px) {
		padding: 35px;
	}
`

const ProductDetailsPage = (props) => {
	return (
		<StyledMain>
			<ProductDetails />
		</StyledMain>
	);
};

export default ProductDetailsPage;