/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description sidebar
 * @component sidebar
 */

import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "../../../UI/svg";
import {
  simpleUserNavigation,
  simpleUserpriviligeNavigation,
  simpleUsersecondaryNavigation,
  adminSecondaryNavigation,
  adminNavigation,
  doctorNavigation,
  assureurNavigation,
} from "../../../lib/userAction";
import logoMini from "../../../assets/logo-mini-inch-assurance.png";
import Navigation from "./Navigation";
import ChangeProfil from "./ChangeProfil";
import profile from "../../../assets/profile.png";
import { useGetUserQuery } from "../../../features/api/apiSlice";
import { useDispatch } from "react-redux";
import { getUserAction } from "../../../features/user/user.slice";
import SideBarLoading from "./SideBarLoading";
import SecondaryNavigation from "./SecondaryNvigation";

function Sidebar(props) {
  const { sidebarOpen, setSidebarOpen, setShow } = props;

  const dispatch = useDispatch();

  // get user info request
  const { data, isSuccess, isLoading } = useGetUserQuery();

  const nom = data?.data?.nom;
  const prenom = data?.data?.prenom;
  const profils = data?.data?.profils;
  const defaultProfil = data?.data?.profil;

  useEffect(() => {
    if (isSuccess) {
      dispatch(getUserAction(data?.data));
    }
    return () => {};
  }, [isSuccess, data, dispatch]);

  	return (
		<>
			{/* MOBILE ADAPTATIOIN  */}
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-40 xl:hidden"
					onClose={setSidebarOpen}
				>

				<Transition.Child
					as={Fragment}
					enter="transition-opacity ease-linear duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
				</Transition.Child>

				<div className="fixed inset-0 z-40 flex">
					<Transition.Child
						as={Fragment}
						enter="transition ease-in-out duration-300 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<Dialog.Panel
							style={{ height: "100vh" }}
							className="relative flex w-full max-w-xs flex-1 flex-col bg-secondary-700 pt-5 pb-4"
						>
							<Transition.Child
								as={Fragment}
								enter="ease-in-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in-out duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								{/* close sidebar button  */}
								<div className="absolute top-0 right-0 -mr-12 pt-2">
									<button
										type="button"
										className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
										onClick={() =>
											setSidebarOpen(false)
										}
									>
										<span className="sr-only">
											Close sidebar
										</span>
										<XMarkIcon
											className="h-6 w-6 text-white"
											aria-hidden="true"
										/>
									</button>
								</div>
							</Transition.Child>
							{/* logo  */}
							<div className="flex flex-shrink-0 items-center px-4 gap-x-8">
								<img
									className="h-8 w-auto"
									src={logoMini}
									alt="Your Company"
								/>
								<span className="text-primary-700">
									Inch Assur
								</span>
							</div>
							{/* change profil  */}
							<ChangeProfil
								nom={nom}
								prenom={prenom}
								profils={profils}
								defaultProfil={defaultProfil}
								setShow={setShow}
								userProfile={profile}
							/>
							<div className="mt-5 h-0 flex-1 overflow-y-auto h-full">
								<nav
									style={{ height: "94%" }}
									className="mt-5 flex flex-1 flex-col justify-between divide-y divide-secondary-800 overflow-y-auto pt-4"
									aria-label="Sidebar"
								>
									{/* main navigation  */}
									<div className="space-y-1 px-2">
										{defaultProfil?.id === "IA1" ? (
											<Navigation
												setSidebarOpen={setSidebarOpen}
												userNavigation={
													simpleUserNavigation
												}
											/>
										) : defaultProfil?.id === "IA2" ? (
											<Navigation
												setSidebarOpen={setSidebarOpen}
												userNavigation={
													simpleUserpriviligeNavigation
												}
											/>
										) : defaultProfil?.id === "IA3" ? (
											<Navigation
												setSidebarOpen={setSidebarOpen}
												userNavigation={
													simpleUserpriviligeNavigation
												}
											/>
										) : defaultProfil?.id === "IA10" ? (
											<Navigation
												setSidebarOpen={setSidebarOpen}
												userNavigation={
													adminNavigation
												}
											/>
										) : defaultProfil?.id === "IA8" ? (
											<div>
												<Navigation
													setSidebarOpen={setSidebarOpen}
													userNavigation={
														assureurNavigation
													}
												/>
												
											</div>
										) : defaultProfil?.id === "IA7" ? (
											<Navigation
												setSidebarOpen={setSidebarOpen}
												userNavigation={
													doctorNavigation
												}
											/>
										) : null}
									</div>

									{/* ssecondary navigation  */}
									<div className="mt-6 pt-6">
										<div className="space-y-1 px-2">
											{defaultProfil?.id === "IA10" ? (
												<Navigation
													setSidebarOpen={setSidebarOpen}
													userNavigation={adminSecondaryNavigation}
												/>
											) : null}

											<Navigation
												setSidebarOpen={setSidebarOpen}
												userNavigation={simpleUsersecondaryNavigation}
											/>
										</div>
									</div>
								</nav>
							</div>
						</Dialog.Panel>
					</Transition.Child>
					<div
						className="w-14 flex-shrink-0"
						aria-hidden="true"
					>
						{/* Dummy element to force sidebar to shrink to fit close icon */}
					</div>
				</div>

				</Dialog>
			</Transition.Root>

			{/* Static sidebar for desktop */}
			{isLoading ? (
				<SideBarLoading />
			) : (
				<div className="hidden xl:fixed xl:inset-y-0 xl:flex lg:w-64 xl:flex-col">
					<div className="flex flex-grow flex-col overflow-y-auto bg-secondary-700 pt-5">
						{/* logo  */}
						<div className="flex flex-shrink-0 items-center px-4 gap-x-2">
							<img className="h-8 w-auto" src={logoMini} alt="inch assur" />
							<span className="text-primary-700 text-xl font-bold text">
								Inch Assur
							</span>
						</div>

						{/* change profil  */}
						<ChangeProfil
							nom={nom}
							prenom={prenom}
							profils={profils}
							defaultProfil={defaultProfil}
							setShow={setShow}
							userProfile={profile}
						/>

						<div className="flex flex-1 flex-col">
							<nav
								className="mt-5 flex flex-1 flex-col justify-between divide-y divide-secondary-800"
								aria-label="Sidebar"
							>
								{/* main navigation  */}
								<div className="space-y-1 px-2">
									{defaultProfil?.id === "IA1" ? (
										<Navigation
											setSidebarOpen={setSidebarOpen}
											userNavigation={simpleUserNavigation}
										/>
									) : defaultProfil?.id === "IA2" ? (
										<Navigation
											setSidebarOpen={setSidebarOpen}
											userNavigation={simpleUserpriviligeNavigation}
										/>
									) : defaultProfil?.id === "IA3" ? (
										<Navigation
											setSidebarOpen={setSidebarOpen}
											userNavigation={simpleUserpriviligeNavigation}
										/>
									) : defaultProfil?.id === "IA10" ? (
										<Navigation
											setSidebarOpen={setSidebarOpen}
											userNavigation={adminNavigation}
										/>
									) : defaultProfil?.id === "IA8" ? (
										<Navigation
											setSidebarOpen={setSidebarOpen}
											userNavigation={assureurNavigation}
										/>
									) : defaultProfil?.id === "IA7" ? (
										<Navigation
											setSidebarOpen={setSidebarOpen}
											userNavigation={doctorNavigation}
										/>
									) : null}
								</div>

								{/* secondary navigation  */}
								<div className="mt-6 pt-6">
									<div className="space-y-1 px-2">
										{defaultProfil?.id === "IA10" ? (
											<Navigation
												setSidebarOpen={setSidebarOpen}
												userNavigation={adminSecondaryNavigation}
											/>
										) : null}

										<Navigation
											setSidebarOpen={setSidebarOpen}
											userNavigation={simpleUsersecondaryNavigation}
										/>
									</div>
								</div>
							</nav>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Sidebar;
