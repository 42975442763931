/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description all transaction  page
 * @component AdminTransactionPage
 */

import React from "react";
import Main from "../../components/layouts/main";
import AdminTransaction from "../../components/layouts/main/user/AdminTransaction";

const AdminTransactionPage = () => {
	return <Main children={<AdminTransaction />} />;
};

export default AdminTransactionPage;
