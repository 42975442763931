
export function filterLogic(queryData, data, property) {
    
    const filteredData =
        queryData === ''
          ? data
          : data.filter(item => {
              return item[property]
                .toLowerCase()
                .includes(queryData.toLowerCase())
            });

    return filteredData;
}