/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurances  page
 * @component ListAssurancesPage
 */
import Main from "../../../components/layouts/main";

import ListAssurances from "../../../components/layouts/main/assurances/client/listAssurances/ListAssurances";

const ListAssurancesPage = () => {
	return <Main children={<ListAssurances />} />;
};

export default ListAssurancesPage;
