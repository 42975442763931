/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product services and pieces
 * @component Document
 */
import React, { useEffect, useState } from "react";
import SelectTo from "./SelectTo";
import AddDoc from "./addAssurance/AddDoc";
import AddedDoc from "./addAssurance/AddedDoc";
import PaySettings from "./addAssurance/PaySettings";
import DataRow from "../../../../Utilities/DataRow";
import { useAddAssuranceDocumentsMutation, useDeleteDocumentMutation, useGetPayOptionsQuery } from "../../../../../features/api/apiSlice";
import Spinner from "../../../../../UI/spinner/Spinner";
import { setModalPopUp } from "../../../layoutLogics";
import { useDispatch } from "react-redux";

const Services = ({ assurance, setAssurance, removeItem, handleCkeckboxChange, isEdited, setIsEdited }) => {
	const { services } = assurance;
	const dispatch = useDispatch();
	const [targetedDoc, setTargetedDoc] = useState(null);

	// add documentation to insurance request
	const [addDocuments, { isLoading: isAdding }] = useAddAssuranceDocumentsMutation();
	const [deleteDoc, {isLoading: isDeleting}] = useDeleteDocumentMutation();

	const { data: payData, isLoading: payLoading, isSuccess: PaySucces } = useGetPayOptionsQuery();
	// list of payments options
	const payOptions = payData?.data?.map((item) => {
		return {
			id: item.id,
			name: item.nom,
			description: item.description
		};
	}) || [];

	useEffect(() => {
		setIsEdited(false)
	}, []);

	// set the unique pay option as by default
	useEffect(() => {
		const isUniqueExist = assurance.payOptions.find((option) => option.id === "1");

		if (!isUniqueExist) {
			const uniquePay = payOptions.find((option) => option.id === "1");

			if (uniquePay) {
				setAssurance({
					...assurance,
					payOptions: [...assurance.payOptions, uniquePay]
				});
			};
		};
	}, [PaySucces]);

	// add services
	const selectHandleChange = (value) => {
		let newTable;
		let newSerices;
		if (value && (!services || services?.length === 0)) {
			newTable = [];
			newSerices = newTable.concat(value);
			setAssurance({
				...assurance,
				services: newSerices,
			});

			setIsEdited(true);
		} else {
			const isValue = services.filter((item) => item.id === value?.id);
			if (isValue.length === 0 && value) {
				newTable = services;
				newSerices = newTable.concat(value);
				setAssurance((prevState) => {
					return {
						...prevState,
						services: newSerices,
					};
				});

				setIsEdited(true);
			} else {
				if (isValue[0]?.id === value?.id) {
					return;
				} else {
					newTable = services;
					newSerices = newTable.concat(value);
					setAssurance((prevState) => {
						return {
							...prevState,
							services: newSerices,
						};
					});

					setIsEdited(true);
				};
			};
		};
	};

	async function addDoc(title, type, file, assurance, setAssurance, setTitle, setType, setFile, setModalPopUp, dispatch) {
		if (title.length > 0 && type.length > 0 && file) {
			const formData = new FormData();
			formData.append("titre", title);
			typeof type === "string" && type === "link" ? formData.append("url", file) : formData.append("document", file);
			formData.append("type", type);

			console.log("type :", type)

			try {
				const response = await addDocuments({idAssurance: assurance?.id, data: formData}).unwrap();

				if (response.statut === "ok") {
					setAssurance({
						...assurance,
						documentation: [...assurance.documentation, {
							title: title,
							type: type,
							file: file
						}]
					});
		
					// if (setIsEdited) {setIsEdited(true);};
					setModalPopUp(dispatch, "Document ajouté avec succes !", "success");
			
					setTitle("");
					setType("");
					setFile(null);
		
					// Réinitialisation de l'élément input de type file
					const fileInput = document.getElementById("doc-add-file");
					if (fileInput) fileInput.value = null;
				} else {
					setModalPopUp(dispatch, "Une erreur est survenue !", "failed");
				};
			} catch (error) {
				setModalPopUp(dispatch, "Une erreur est survenue !", "failed");
			}
        } else {
            setModalPopUp(dispatch, "Veuillez renseigner tous les champs !", "failed");
        };
	};

	async function removeDoc(event, docIndex, docId) {
		event.stopPropagation();
		event.preventDefault();

		try {
			setTargetedDoc(docId);
			const response = await deleteDoc({ idAssurance: assurance?.id, idDoc: docId }).unwrap();

			if (response.statut === "ok") {
				const newDocs = assurance.documentation.filter((doc, index) => index !== docIndex);
		
				setAssurance({
					...assurance,
					documentation: newDocs
				});

				setModalPopUp(dispatch, "Document retiré avec success !", "success");
				setTargetedDoc(null);
			} else {
				setModalPopUp(dispatch, "Une erreur est survenue !", "failed");
				setTargetedDoc(null);
			};
		} catch (error) {
			setModalPopUp(dispatch, "Une erreur est survenue !", "failed");
			setTargetedDoc(null);
		};
	};

	return (
		<div className="space-y-12">
			{/* Warranty form section */}
			<div className="border-b border-gray-900/10 pb-12">
				<h2 className="text-base font-semibold leading-7 text-gray-900">Garanties & contrat</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600">
					Veuillez sélectionner le type de contrat et les garanties que vous êtes prêt à fournir
				</p>

				<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
					<div className="col-span-full">
						<label htmlFor="garantie" className="block text-sm font-medium leading-6 text-gray-900">
							Garanties de l'assurance
						</label>
						<div className="mt-2">
							<SelectTo
								onChange={selectHandleChange}
								ariaLabel="garantie"
							/>
						</div>
					</div>

					{/* list guaranty  */}
					<div className="col-span-full bg-gray-50">
						<h3 className="text-base font-semibold leading-7 text-gray-500">
							Liste des garanties sélectionnées
						</h3>

						<ul className="mt-2 flex flex-col gap-4">
							{assurance?.services && assurance.services.length > 0 ? (
								assurance?.services?.map((item, index) => (
									<li key={index}>
										<DataRow
											key={index}
											title={item.value}
											fileName={item.description}
											type={`${item.taux}% ou ${item.prix} Fcfa`}
											handleRemove={() => removeItem(index, item, "services")}
										/>
									</li>
								))
							) : (
								<h3>Aucune garantie ajouté...</h3>
							)}
						</ul>
					</div>
				</div>
			</div>

			{/* Documents form section */}
			<div className="border-b border-gray-900/10 pb-12">
				<h2 className="text-base font-semibold leading-7 text-gray-900">Documentation</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600">
					Veuillez fournir les documents à disposition du client...
				</p>

				<AddDoc setAssurance={setAssurance} assurance={assurance} addDoc={addDoc} isAdding={isAdding} setIsEdited={setIsEdited} />
				<AddedDoc addedDocs={assurance.documentation} isDeleting={isDeleting} targetedDoc={targetedDoc} removeDoc={removeDoc} />
			</div>

			{/* Payement methode form section */}
			<div className="border-b border-gray-900/10 pb-12">
				<h2 className="text-base font-semibold leading-7 text-gray-900">Payements</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600">
					Indiquez les options de payement que vous acceptez
				</p>

				<div>
					<fieldset className="">
						<div className="mt-6 space-y-6">
							{payLoading ? (
								<Spinner className="h-8 w-8 text-primary-700 m-auto h-full" />
							) : payOptions && payOptions.length > 0 ? (
								payOptions.map((option, index) => (
									<div key={index} className="relative flex gap-x-3">
										<div className="flex h-6 items-center">
											<input
												id={option.name}
												name={option.name}
												type="checkbox"
												className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
												onChange={(event) => handleCkeckboxChange(event, payOptions)}
												checked={option.id === "1" || assurance.payOptions.some(payOp => payOp.id.toString() === option.id) ? true : undefined}
												disabled={option.id === "1" ? true : undefined}
											/>
										</div>
										<div className="text-sm leading-6">
											<label htmlFor={option.name} className="font-medium text-gray-900">
												{`Payement ${option.name}`}
											</label>
											<p className="text-gray-500">{option.description}</p>
											{option.id !== "1" && (
												<PaySettings />
											)}
										</div>
									</div>
								))
							) : (
								<h3>Aucune option de payement disponible...</h3>
							)}
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	);
};

export default Services;
