/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description user profile page
 * @component UserProfilPage
 */
import Main from "../../components/layouts/main";

import UserProfil from "../../components/layouts/main/userProfil";

const UserProfilPage = (props) => {
	return <Main children={<UserProfil />} />;
};

export default UserProfilPage;
