/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description user transaction page
 * @component UserTransactionPage
 */

import Main from "../../components/layouts/main";

import Modepaiement from "../../components/layouts/main/user/Modepaiement";

const Modepaiementpage = () => {
	return <Main children={<Modepaiement />} />;
};

export default Modepaiementpage;
