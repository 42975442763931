import styled from "styled-components";
import BackIllustrationImg from "../../assets/select_category.svg";

const StyledBackground = styled.img`
  position: fixed;
  width: 45%;
  min-width: 500px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -45%);
  opacity: 0.11;
  z-index: -1;
  @media screen and (max-width: 1279px) {
    width: 55%;
    transform: translate(-50%, -45%);
  }
`;

function BackIllustration() {
  return (
    <StyledBackground src={BackIllustrationImg} alt="Background illustration" />
  );
}

export default BackIllustration;
