import styled from "styled-components";
import NestedModal from "../Modal";
import Button from "../../button/Button";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import StepNavigator from "./StepNavgator";
import PayingModeInput from "./PayingModeInput";
import uniquePay from "../../../assets/terms.png";
import monthlyPay from "../../../assets/payment.png";
import diferedPay from "../../../assets/pay.png";
import orange from "../../../assets/orange-money.png";
import mtn from "../../../assets/mobile-money.png";
import pocket from "../../../assets/portefeuille.png";
import PayingCanalInput from "./PayingCanalInput";

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    algin-items: center;
    gap: 1em;
`;

const StyledH2 = styled.h2`
    font-size: 2.2em;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 800px) {
        font-size: 2em;
	}
    @media screen and (max-width: 640px) {
        font-size: 1.7em;
    }
`;

const Paragraph = styled.p`
    font-size: 1.2em;
    font-weight: 400;
    text-align: justify;
`;

const StyledButton = styled(Button)`
	color: white;
	padding: 10px 20px;
	border-radius: 8px;
    margin: 15px auto;
`;

const StyledH3 = styled.h3`
    font-size: ${({ active }) => active ? "1.7em" : "1.5em"};
    font-weight: 500;
    ${({ active }) => active ? "background-color: #0066db" : null};
    padding: ${({ active }) => active ? "3px 1em" : "0 1em"};
    color: ${({ active }) => active ? "white" : "black"};
`;

const StepBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 8px;
`;

const StepCanalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 8px;
`;

const List = styled.ul`
    font-size: 1.3em;
    font-weight: 500;
    list-style-type: disc;
    margin: 20px 10px;
`;

const Illustration = styled.img`
    width: 200px;
    margin: auto;
`;

function PayingModal({
    open,
    setOpen,
    amountMode,
    setAmountMode,
    payingCanal,
    setCanal,
    activeStep,
    setActiveStep,
    selectedMode,
    setSelectedMode,
    selectedCanal,
    setSelectedCanal,
    setIsPaid,
    setIsSignable,
    handlePayement,
    currenPath,
    totalPrice,
    setModalPopUp,
    payingOptions
}) {
    const [isLoading, setIsLoading] = useState(false);

    const payingCanals = [
        {
            label: "Pocket",
            illustration: pocket,
            code: "PORTE_FEUILLE"
        },
        {
            label: "Orange Money",
            illustration: orange,
            code: "CM_ORANGEMONEY"
        },
        {
            label: "MTN Mobile Money",
            illustration: mtn,
            code: "CM_MTNMOBILEMONEY"
        },
    ];

    // handle modal
    const handleClose = (setOpen) => {
        setOpen(false);
    };

    // handle mode selection
    function handleCkededMode(event, checked) {
        const mode = event.target.name;

        setSelectedMode(checked);
        setAmountMode({
            idPayOption: checked,
            mode: mode,
            amount: checked === 1 ? totalPrice : 0
        });
    };

    console.log("paying motal !")

    // handle canal
    function handleChekedCanal(event, checked, illustration, code) {
        const name = event.target.name;

        setSelectedCanal(checked);
        setCanal({
            name: name,
            number: undefined,
            illustration: illustration,
            code: code
        });
    };

    // handle payement click
    async function handlePayClick(event) {
        try {
            setIsLoading(true);
            const payError = await handlePayement(event, currenPath, amountMode.amount, amountMode.idPayOption, payingCanal.code, payingCanal.number);
    
            if (payError) {
                // setIsLoading(false);
                console.log("An error occured during trying payement :", payError);
            } else {
                // setIsPaid(true);
                // setIsSignable(true);
                setOpen(false);
                // setIsLoading(false);
            };
        } catch (error) {
            // setIsLoading(false);
            console.log("An error occured during trying payement :", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <NestedModal
            modalWidth={750}
            height="85vh"
            open={open}
            handleClose={() => handleClose(setOpen)}
            border="2px solid #0066DB"
            children={(
                <>
                    <TitleContainer>
                        <StyledH2>PAYEMENT</StyledH2>
                        <Paragraph>Félicitations ! Votre code a bien été validé et vous êtes sur le point de conclure la signature de votre produit. veuillez dans les champs ci-dessous procéder au payement des frais requis afin de conclure et d'obtenir votre contrat signé.</Paragraph>
                    </TitleContainer>

                    <div style={{ width: "90%" }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            <Step>
                                <StepLabel>
                                    <StyledH3 active={activeStep === 0 ? true : false}>Choix du mode de payement</StyledH3>
                                </StepLabel>

                                <StepContent>
                                    <StepBodyContainer>
                                        {payingOptions?.payingModes.length > 0 ? (
                                            payingOptions?.payingModes.map((mode, index) => (
                                                <PayingModeInput
                                                    key={`${index}-${mode.idPayOption}`}
                                                    label={mode.nom}
                                                    description={mode.description}
                                                    type={mode.type}
                                                    idPayOption={mode.idPayOption}
                                                    active={mode.idPayOption}
                                                    selectedMode={selectedMode}
                                                    handleCkededMode={handleCkededMode}
                                                    amountMode={amountMode}
                                                    setAmountMode={setAmountMode}
                                                    illustration={mode.illustration}
                                                    totalPrice={totalPrice}
                                                />
                                            ))
                                        ) : (
                                            <PayingModeInput
                                                label="Payement unique"
                                                description="Veuillez verser la somme totale çi-dessous requise en payement de votre prime d'assurance."
                                                type="Unique"
                                                idPayOption={1}
                                                active={0}
                                                selectedMode={selectedMode}
                                                handleCkededMode={handleCkededMode}
                                                amountMode={amountMode}
                                                setAmountMode={setAmountMode}
                                                illustration={uniquePay}
                                                totalPrice={totalPrice}
                                            />
                                        )}
                                    </StepBodyContainer>

                                    <StepNavigator
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                        disabled={amountMode.amount > 0 ? false : true}
                                    />
                                </StepContent>
                            </Step>

                            <Step>
                                <StepLabel>
                                    <StyledH3 active={activeStep === 1 ? true : false}>Choix du canal de payement</StyledH3>
                                </StepLabel>

                                <StepContent>
                                    <StepCanalContainer>
                                        {payingCanals.map((canal, index) => (
                                            <PayingCanalInput
                                                name={canal.label}
                                                code={canal.code}
                                                illustration={canal.illustration}
                                                active={index}
                                                handleChekedCanal={handleChekedCanal}
                                                selectedCanal={selectedCanal}
                                                setCanal={setCanal}
                                                canal={payingCanal}
                                            />
                                        ))}
                                    </StepCanalContainer>

                                    <StepNavigator
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                        disabled={payingCanal.name === "Pocket" || (payingCanal.number && payingCanal.number.length >= 9) ? false : true}
                                    />
                                </StepContent>
                            </Step>

                            <Step>
                                <StepLabel>
                                    <StyledH3 active={activeStep === 2 ? true : false}>Recapitulatif de payement</StyledH3>
                                </StepLabel>

                                <StepContent>
                                    <div>
                                        <Paragraph>Vous êtes sur le point d'effectuer un payement via {payingCanal.name === "Pocket" ? "votre Portefeuille" : "le numero"} <span className="text-primary-900" style={{fontSize: "1.1em"}}>{payingCanal.number}</span> avec les options suivantes :</Paragraph>

                                        <List>
                                            <li>
                                                <h3>Mode de payement : <span className="text-primary-900">{amountMode.mode}</span></h3>
                                            </li>

                                            <li>
                                                <h3>Montant de la transaction : <span className="text-primary-900">{amountMode.amount} Fcfa</span></h3>
                                            </li>

                                            <li>
                                                <h3>Canal de payement : <span className="text-primary-900">{payingCanal.name}</span></h3>
                                                <Illustration src={payingCanal.illustration} alt={`${payingCanal.name} illustration`} />
                                            </li>
                                        </List>

                                    </div>

                                    <StepNavigator
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                        lastStep={true}
                                    />

                                    <div>
                                        <StyledButton
                                            children="EFFECTUER LE PAYEMENT"
                                            type="button"
                                            className="bg-secondary-600"
                                            loading={isLoading}
                                            onClick={(event) => handlePayClick(event)}
                                            id="codevalidate"
                                        />
                                    </div>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </div>
                </>
            )}
        />
    );
};

export default PayingModal;
