
import React, { useState, useEffect } from "react";
import Input from "../../../../UI/form/Input";
import { Card } from 'primereact/card';
import { Fieldset } from 'primereact/fieldset';
import Button from "../../../../UI/button/Button";
import usePasswordToggle from '../../../../hooks/usePasswordToggle';
import useInput from "../../../../hooks/use-input"
import { isPassword, isEmail } from "../../../../lib/inputValidation"
import sha256 from "sha256"
import {
    useSetPasswordMutation,
} from "../../../../features/api/apiSlice";

const PasswordInformation = (props) => {

    const { code } = props


    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleButtonClick = () => {
        setIsFormVisible(!isFormVisible);

    };





    const [oldpasswordType, oldEye] = usePasswordToggle()
    const [passwordType, Eye] = usePasswordToggle()
    const [passwordType2, Eye2] = usePasswordToggle()

    // set profil user request
    const [setPassword, { isLoading: loading }] = useSetPasswordMutation();


    // oldpasword control
    const {
        value: oldenteredpassword,
        isValid: oldenteredpasswordIsValid,
        hasError: oldpasswordInputHasError,
        valueChangeHandler: oldpasswordHandleChange,
        inputBlurHandler: oldpasswordHandleBlur,
    } = useInput(isPassword)

    // pasword control
    const {
        value: enteredpassword,
        isValid: enteredpasswordIsValid,
        hasError: passwordInputHasError,
        valueChangeHandler: passwordHandleChange,
        inputBlurHandler: passwordHandleBlur,
    } = useInput(isPassword)

    // password verif control
    const {
        value: enteredpasswordVerified,
        isValid: passwordVerifiedIsValid,
        hasError: passwordVerifiedInputHasError,
        valueChangeHandler: passwordVerifiedHandleChange,
        inputBlurHandler: passwordVerifiedhandleBlur,
    } = useInput(value => value.trim() !== '')

    // password is equal
    const isPasswordIsEqual = (enteredpassword === enteredpasswordVerified)


    // validate form 
    const formIsValid = [oldenteredpasswordIsValid, passwordVerifiedIsValid, enteredpasswordIsValid, isPasswordIsEqual].every(currentValue => currentValue === true)

    // request 
    const passwordModify = (e) => {
        e.preventDefault()
        const data = {
            password: sha256(enteredpassword),
            oldpassword: sha256(oldenteredpassword),
            passwordverif: sha256(enteredpasswordVerified)
        }
        console.log(data)
        // setloading(true)
        setPassword({ data: data, code: code })
            .unwrap()
            .then((res) => {
                console.log(res.data);

            })
            .catch((err) => { });






    }


    return (
        <section
            aria-labelledby="payment-details-heading "
            className="px-4 sm:p-8">
            {/* hearder contact  */}
            <div className="flex justify-between items-center">
                <h2
                    id="payment-details-heading"
                    className="text-lg font-medium leading-6 text-gray-900 mr-9">
                    Changer son mot de passe
                </h2>
                <div className="text-right sm:px-6 mt-2" style={{ marginRight: "-35px" }}>

                    {!isFormVisible && (
                        <Button
                            button="secondary"
                            type="submit"
                            children="Modifier mon mot de passe "
                            className="max-w-max"
                            onClick={handleButtonClick}
                        />
                    )}
                </div>


            </div>

            {isFormVisible && (
                <div className="card mt-8">
                    <Fieldset>
                        <form className="space-y-6 ">

                            <div className=" flex grid grid-cols-4 gap-6">


                                <div className="col-span-4 sm:col-span-2 ">
                                    <div className="grid grid-cols-1 gap-8">



                                        <div className="col-span-1 sm:col-span-1">
                                            <Input label="Ancien mot de passe" inputError={oldpasswordInputHasError} value={oldenteredpassword} onChange={oldpasswordHandleChange} onBlur={oldpasswordHandleBlur} placeholder={"entrer votre ancien mot de passe"} input={{ id: "oldpassword", type: oldpasswordType }} Eye={oldEye} name={"password"} disabled={false} required={true} errorText="le mot de passe doit contenir au moins une majuscule, une miniscule, un chiffre, ou un caractere speciale #, ?, !, @, $, %, ^, &, *, -, :, ;, <, >... " ariaInputError="oldpassword" />
                                        </div>

                                        <div className="col-span-1 sm:col-span-1">
                                            <Input label="Nouveau mot de passe" inputError={passwordInputHasError} value={enteredpassword} onChange={passwordHandleChange} onBlur={passwordHandleBlur} placeholder={"entrer votre nouveau mot de passe"} input={{ id: "password", type: passwordType }} Eye={Eye} name={"password"} disabled={false} required={true} errorText="le mot de passe doit contenir au moins une majuscule, une miniscule, un chiffre, ou un caractere speciale #, ?, !, @, $, %, ^, &, *, -, :, ;, <, >... " ariaInputError="password" />
                                        </div>



                                    </div>


                                </div>
                                <div className="col-span-4 sm:col-span-2">
                                    <div className=" grid grid-cols-1 gap-8">
                                        {/* confirmation password  */}
                                        {(
                                            <div className="col-span-1 sm:col-span-1">
                                                <Input label="Vérification de mot de passe" inputError={(!isPasswordIsEqual && enteredpasswordVerified.length >= 1) || passwordVerifiedInputHasError} onChange={passwordVerifiedHandleChange} onBlur={passwordVerifiedhandleBlur} placeholder={"entrer votre mot de passe"} input={{ id: "passwordVerif", type: passwordType2 }} Eye={Eye2} value={enteredpasswordVerified} errorText={"les mots de passe ne corresondent pas"} name={"passwordVerif"} disabled={false} required={true} ariaInputError="passwordVerif" />
                                            </div>
                                        )}

                                        <div className="mt-5 grid grid-cols-2 gap-8 flex">
                                            <div className="">
                                                <Button type="submit" onClick={passwordModify} children="Modifier mot de passe" button="primary" loading={loading} error={!formIsValid} />
                                            </div>

                                        </div>


                                    </div>
                                </div>




                            </div>








                        </form>

                    </Fieldset>
                </div>
            )}


        </section>
    );
};

export default PasswordInformation;