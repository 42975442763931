/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurance ada
 * @component ChoixMedecin
 */
import React, { useState, useEffect } from "react";
import AssuranceCard from "../assurances/client/listAssurabceByIdCategory/AssuranceCard";
import Spinner from "../../../../UI/spinner/Spinner";
import { useGetAssurancesAdaQuery } from "../../../../features/api/apiSlice";

const InchproAssur = () => {
	// get insurance ada
	const {
		data: assuranceAda,
		isLoading: assuranceAdaIsLoading,
		isSuccess: assuranceAdaIsSuccess,
	} = useGetAssurancesAdaQuery();

	let content;

	const [assurance, setAssurance] = useState([]);

	useEffect(() => {
		if (assuranceAdaIsSuccess) {
			const data = assuranceAda?.data?.map((item) => {
				return {
					id: item.id_produit,
					code: item.code,
					description: item.description,
					duree: item.duree,
					images: item.images,
					titre: item.nom,
					statut: item.statut,
					prix: parseInt(item.prix),
					fournisseur: item.fournisseur,
					services: item.listeServices,
					pieces_a_joindre: item.pieces_a_joindre,
					type_contrat: item.type_contrat,
					categorie: item.categorie,
					documents: item.documents,
				};
			});

			setAssurance(data);
		}
		return () => {};
	}, [assuranceAdaIsSuccess, assuranceAda]);

	if (assuranceAdaIsLoading) {
		content = (
			<div className="flex flex-1 justify-center">
				<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
			</div>
		);
	} else if (assuranceAdaIsSuccess) {
		content = (
			<div className="bg-white">
				<div className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
					<h1 className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl my-8">
						Nos assurances Inch{" "}
					</h1>

					<div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
						{assurance?.map((item, index) => {
							return (
								<AssuranceCard
									key={index}
									duree={item.duree}
									description={item.description}
									titre={item.titre}
									images={item.images[0]?.uri}
									categorie={item.categorie.nom.toLowerCase()}
									code={item.code}
									prix={item.prix}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	} else {
		return;
	}

	return content;
};

export default InchproAssur;
