/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description formulaire de validation d'email 
 * @component ValidateEmailForm
 * @props  setalert, 
 */

import React, { useState } from 'react';
import Input from '../../UI/form/Input'
import Button from '../../UI/button/Button';
import useInput from '../../hooks/use-input';
import axiosInstance from '../../apis/inchAssur';
import { useNavigate, useParams } from 'react-router-dom';


const ValidateEmailForm = ({ setOpen, setalert }) => {
    const params = useParams()
    // code input control
    const {
        value: enteredCode,
        isValid: enteredCodeIsValid,
        valueChangeHandler: CodeHandleChange,
        inputBlurHandler: CodeHandleBlur,
    } = useInput(value => value.trim() !== '' && value.trim().length === 6) // && value.trim().length === 6

    // navigate 
    const navigate = useNavigate()

    // loading 
    const [loading, setloading] = useState(false)

    // send new code to user
    const sendMail = () => {
        axiosInstance.get('/account/resetConfirm', {
            headers: {
                'Authorization': 'Bearer ' + params?.token,
            }
        }).then((res) => {
            setOpen(true)
        }).catch((err) => {
            throw new Error("Code invalide")
        }).finally(() => setloading(false))
    }



    // send code to serve
    const SendCodeHAndler = (e) => {
        e.preventDefault()

        const data = {
            code: enteredCode
        }

        setloading(true)

        axiosInstance.post('/account/confirm', data, {
            headers: {
                'Authorization': 'Bearer ' + params?.token,
            }
        }).then((res) => {
            navigate(`/connexion`)
        }).catch((err) => {
            // set alert 
            setalert(prevState => {
                return {
                    ...prevState,
                    isAlert: true,
                    error: true,
                    text: "Code invalide",
                }
            })
            throw new Error("Code invalide")

        }).finally(() => setloading(false))
    }

    return (
        <form onSubmit={SendCodeHAndler} className="space-y-8">
            <div className='flex flex-col'>
                <Input label="Code" inputError={false} placeholder={"code"} input={{ id: "code", type: "text", maxLength: 6 }} name={"code"} disabled={false} required={true} errorText={"code incorect"} ariaInputError="code" value={enteredCode} onChange={CodeHandleChange} inputBlurHandler={CodeHandleBlur} />

                <p className='mt-3 text-sm font-medium text-gray-700'>Vous n'avez pas reçu de code ? <span><Button type="button" children="Renvoyer le code" loading={false} error={false} onClick={sendMail} /></span></p>
            </div>

            <div>
                <Button type="submit" children="Envoyer" button="primary" loading={loading} error={!enteredCodeIsValid} />
            </div>
        </form>
    )
}

export default ValidateEmailForm