import { useState, useLayoutEffect, useCallback, useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import squaredBackground from "../../assets/squared_background.png"

import Sidebar from './sidebar'
import DashboardHeader from './header/DashboardHeader'

import Notification from '../../UI/notification'
import Alert from "../../UI/alert";
import { useDispatch, useSelector } from "react-redux";
import { getModalProps, getModalType, initialModal } from "../../features/feedBack/modal.slice";

const StyledBody = styled.div`
	background-image: url(${squaredBackground});
	height: 100vh;
	overflow: scroll;
	align-items: center;
`

export default function Layout() {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [show, setShow] = useState(false);

	useLayoutEffect(() => {
		if (show) {
			setTimeout(() => {
				setShow(false);
			}, 4000);
		}

		return () => {
		clearTimeout()
		}
	}, [show]);

	// Alert settings constants --------------------------------------------------------------------
	const dispatch = useDispatch();
	const modalType = useSelector(getModalType);
    const modalProps = useSelector(getModalProps);
    const closeAlert = useCallback(() => {
		dispatch(initialModal());
    }, [dispatch]);

	useEffect(() => {
        if(modalProps?.isOpen) {
            setTimeout(closeAlert, 5000);
        }

        return () => {
            clearTimeout();
        }
    }, [modalProps?.isOpen, closeAlert]);
	// ---------------------------------------------------------------------------------------------

	return (
		<>
			<div>
				{/* siderbar  */}
				<Sidebar
					sidebarOpen={sidebarOpen}
					setShow={setShow}
					setSidebarOpen={setSidebarOpen}
				/>

				<StyledBody className="flex flex-1 flex-col xl:ml-64">
					{/* header */}
					<DashboardHeader
						sidebarOpen={sidebarOpen}
						setSidebarOpen={setSidebarOpen}
					/>

					{/* main  */}
					<Outlet />

					<div className="border-b border-gray-900/10 pb-12" />
				</StyledBody>
			</div>

			{/* notification  */}
			{show && (
				<Notification
					title={'Profil mis à jour avec success'}
					show={show}
					setShow={setShow}
				/>
			)}
			
			{/* insert toast here */}
			{modalType === "user" && modalProps?.isOpen ? (
				<div className="px-4 fixed top-5 z-50 w-2/5 inset-x-0 mx-auto">
					<Alert
						alert={{text: typeof modalProps?.text === "string" ? modalProps?.text : "Unknown alert", error: typeof modalProps?.status === "string" && modalProps?.status === "failed" ? true : false}}
						closeAlert={closeAlert}
					/>
				</div>
			) : null}
		</>
	)
}
