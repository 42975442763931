import { useEffect, useState } from "react";
import FormStepOptions from "./FormStepOptions";
import FormSubQuestion from "./FormSubQuestion";
import FormStepLabel from "./FormStepLabel";
import styled from "styled-components";
import { Paragraph } from "../../../../layoutStyle";

const Container = styled.div`
    padding: 10px;
`;

const StepContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

function FormStepBody({ item, index, setResponses, responses, formAnswers, setIsQuestionLoading, AnswerQuestion, id_souscription, setIsStepOk, setSupplement, supplement, setModalPopUp }) {
    const [subQuestions, setSubQuestions] = useState([]);
    const [submitReady, setSubmitReady] = useState(false);

    return (
        <Container>
            <StepContent>
                <div>
                    <Paragraph>{item?.description}</Paragraph>
                </div>

                <FormStepOptions
                    item={item}
                    index={index}
                    responses={responses}
                    formAnswers={formAnswers}
                    setResponses={setResponses}
                    subQuestions={subQuestions}
                    setSubQuestions={setSubQuestions}
                    setIsQuestionLoading={setIsQuestionLoading}
                    AnswerQuestion={AnswerQuestion}
                    id_souscription={id_souscription}
                    setIsStepOk={setIsStepOk}
                    setSubmitReady={setSubmitReady}
                    submitReady={submitReady}
                    supplement={supplement}
                    setSupplement={setSupplement}
                    setModalPopUp={setModalPopUp}
                />
            </StepContent>

            {subQuestions.forEach((item) => {
                const isSubQuestionAvailable = responses.find(response => response.id_question === item.idQuestion);

                isSubQuestionAvailable && (
                    <StepContent>
                        <FormStepLabel label={item.question} />

                        <div>
                            <Paragraph>Description attendue pour la question en générale... Et puis texte de remplissage plus plus</Paragraph>
                        </div>
        
                        <FormStepOptions
                            item={item}
                            index={index}
                            responses={responses}
                            setResponses={setResponses}
                            subQuestions={subQuestions}
                            setSubQuestions={setSubQuestions}
                            setIsQuestionLoading={setIsQuestionLoading}
                            AnswerQuestion={AnswerQuestion}
                            id_souscription={id_souscription}
                            setIsStepOk={setIsStepOk}
                            setSubmitReady={setSubmitReady}
                            submitReady={submitReady}
                        />
                    </StepContent>
                );
            })}
        </Container>
    );
};

export default FormStepBody;
