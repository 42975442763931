/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  select motif of consultation
 * @component MotifConsultationPage
 */

import Main from "../../../components/layouts/main";
import Motif from "../../../components/layouts/main/consultations/Motif";

const MotifConsultationPage = (props) => {
	return <Main children={<Motif />} />;
};

export default MotifConsultationPage;
