/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description   chat
 * @component SingleChat
 */
import React, { useRef } from "react";
import { classNames } from "../../../../styles";
import HeaderSingleChat from "./HeaderSingleChat";
import ScrollableChat from "./ScrollableChat";
import FormChat from "./FormChat";
import { useSelector } from "react-redux";
import { getSelectedChat } from "../../../../features/chats/chats.slice";

const SingleChat = () => {
  const selectedChat = useSelector(getSelectedChat);
  const messageListRef = useRef();

  return (
    <div
      className={classNames(
        selectedChat ? "block w-full" : "hidden",
        "md:flex flex-col justify-between md:w-3/5 xl:w-4/6 "
      )}
    >
      {/* header  */}
      <HeaderSingleChat selectedChat={selectedChat} />

      {/* body  */}
      <ScrollableChat
        selectedChat={selectedChat}
        messageListRef={messageListRef}
      />

      {/* footer  */}
      <FormChat selectedChat={selectedChat} messageListRef={messageListRef} />
    </div>
  );
};

export default SingleChat;
