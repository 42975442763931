// title style
export const title = {
	h1: "",
	h2: "mt-6 text-3xl font-bold tracking-tight text-gray-900",
};

// link style like button
export const btnLink = {
	primary:
		"inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 ",

	secondary:
		"flex w-full justify-center rounded-md border border-secondary-600  py-2 px-4 text-sm font-medium text-secondary-600 hover:text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 disabled:opacity-75 disabled:cursor-not-allowed",
};

// button style
export const btn = {
	primary:
		"flex w-full justify-center rounded-md border border-transparent bg-primary-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",

	secondary:
		"flex w-full justify-center rounded-md border border-secondary-600  py-2 px-4 text-sm font-medium text-secondary-600 hover:text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 disabled:opacity-75 disabled:cursor-not-allowed ",

	default:
		"rounded-md flex bg-gray-100 justify-center py-2 px-4 text-sm font-medium text-secondary-600 hover:text-secondary-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 z-10 disabled:opacity-75 disabled:cursor-not-allowed ",
};

export function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}
