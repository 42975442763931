import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function NestedModal({
  open,
  setOpen,
  handleOpen,
  handleClose,
  children,
  modalWidth=400,
  height="auto",
  overflow="auto",
  border="2px solid #000",
  background="background.paper",
  radius=4,
  shadow=24
}) {
  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 3,
    width: modalWidth,
    height: height,
    overflow: overflow,
    scrollbarWidth: "none",
    bgcolor: background,
    border: border,
    boxShadow: shadow,
    borderRadius: radius,
    pt: 2,
    px: 4,
    pb: 3,
    '@media screen and (max-width: 800px)': {
      width: 500,
    },
    '@media screen and (max-width: 640px)': {
      width: 350,
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style }}>
        { children }
      </Box>
    </Modal>
  );
}
