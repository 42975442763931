/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description recharge account and list of operation page
 * @component UserRecharge
 */

import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import {
	CheckCircleIcon,
	XMarkIcon,
	XCircleIcon,
} from "@heroicons/react/20/solid";

import { useRechargeMutation } from "../../../../features/api/apiSlice";
import { getToken } from "../../../../features/user/auth.slice";
import { getUserCode } from "../../../../features/user/user.slice";
import { useGetRechargesUserQuery } from "../../../../features/api/apiSlice";

import Spinner from "../../../../UI/spinner/Spinner";

import axiosInstance from "../../../../apis/inchAssur";

const UserRecharge = () => {
	let content;
	// alert state
	const [alert, setAlert] = useState({
		isOpen: false,
		text: "",
	});

	// add recharge state
	const [addRecharge, setAddRecharge] = useState(false);

	// operateur state
	const [operateur, setOperateur] = useState("");

	// close alert
	const closeAlert = () => {
		setAlert((prevState) => {
			return {
				...prevState,
				isOpen: false,
				text: "",
				status,
			};
		});
		navigate("/dashboard/recharge");
	};
	// token
	const token = useSelector(getToken);

	// user code
	const code = useSelector(getUserCode);

	const location = useLocation();
	const navigate = useNavigate();

	// get query params
	const param = new URLSearchParams(location.search);

	// status query
	const status = param.get("status");

	// transaction id query
	const transaction_id = param.get("transaction_id");

	const statusClassName = (statusTransaction) => {
		switch (statusTransaction) {
			case "Annulée":
				return "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap my-2";
			case "Echoué":
				return "inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800 whitespace-nowrap my-2";
			case "Validé":
				return "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap my-2 ";

			default:
				break;
		}
	};

	// get recharges request
	const {
		data: mesRecharge,
		isSuccess: isSuccessRecharge,
		isLoading: isLoadingRecharge,
	} = useGetRechargesUserQuery(code);

	useEffect(() => {
		if (status) {
			axiosInstance
				.get(
					`paiement/getresultpay/${code}?status=${status}&code=${transaction_id}`,
					{
						headers: {
							authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					setAlert((prevState) => {
						return {
							...prevState,
							isOpen: true,
							text: res.data.message,
							status: status,
						};
					});
				})
				.catch((err) => {});
		}

		return () => {};
	}, [code, token, status, transaction_id]);

	// close alert after 10 s
	useLayoutEffect(() => {
		if (status) {
			setTimeout(() => {
				setAlert((prevState) => {
					return {
						...prevState,
						isOpen: false,
						text: "",
					};
				});
			}, 10000);
			navigate("/dashboard/recharge", { replace: true, relative: false });
		}

		return () => {
			clearTimeout();
		};
	}, [status, navigate]);

	const prixRef = useRef();

	// recharge request
	const [recharge, { isLoading }] = useRechargeMutation();

	// set operateur state
	const rechargeHandleChange = (e) => {
		setOperateur(e.target.value);
	};

	// recharge
	const rechargeHAndleSubmit = (e) => {
		e.preventDefault();
		const data = {
			solde: prixRef.current.value,
			operateur: operateur,
			code,
		};

		recharge(data)
			.unwrap()
			.then((res) => {
				setAddRecharge(false);
				window.open(res.data?.url, "_self");
			})
			.catch((err) => {});
	};

	if (isLoadingRecharge) {
		// loader componenet
		content = (
			<div className="flex justify-center">
				{" "}
				<Spinner className="w-10 h-10 text-primary-700" />
			</div>
		);
	} else if (isSuccessRecharge) {
		mesRecharge?.data?.lenght === 0
			? (content = (
					<p className="text-center md:mt-8 text-2xl text-gray-300 mt-8 ">
						{" "}
						Aucune récharge effectuée
					</p>
			  ))
			: (content = (
					<div className="mt-8 flex flex-col">
						<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
													Transaction ID
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
													Operateur
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
													Montant (XAF)
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
													Initié le
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
													Status
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{mesRecharge?.data?.map(
												(transaction, index) => (
													<tr key={index}>
														<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold  text-gray-700 sm:pl-6">
															{
																transaction.id_recharge
															}
														</td>
														<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
															{
																transaction.operateur
															}
														</td>
														<td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-700">
															{
																transaction.montant
															}
														</td>
														<td className="whitespace-nowrap px-2 py-2 text-sm  text-gray-500">
															{transaction.date}
														</td>
														<td
															className={statusClassName(
																transaction.statut
															)}>
															{transaction.statut}
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
			  ));
	}

	return (
		<>
			{/* alert component  */}
			{alert.isOpen && (
				<Alert
					closeAlert={closeAlert}
					text={alert.text}
					status={alert.status}
				/>
			)}

			{/* add recharge component  */}
			{addRecharge && (
				<div className="bg-white py-16 sm:py-8">
					<div className="relative sm:py-16">
						<div
							aria-hidden="true"
							className="hidden sm:block">
							<div className="absolute inset-y-0 left-0 w-1/2 rounded-r-3xl bg-gray-50" />
							<svg
								className="absolute top-8 left-1/2 -ml-3"
								width={404}
								height={392}
								fill="none"
								viewBox="0 0 404 392">
								<defs>
									<pattern
										id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
										x={0}
										y={0}
										width={20}
										height={20}
										patternUnits="userSpaceOnUse">
										<rect
											x={0}
											y={0}
											width={4}
											height={4}
											className="text-gray-200"
											fill="currentColor"
										/>
									</pattern>
								</defs>
								<rect
									width={404}
									height={392}
									fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
								/>
							</svg>
						</div>
						<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
							<div className="relative overflow-hidden rounded-2xl bg-cyan-600 px-6 py-10 shadow-xl sm:px-12 sm:py-20">
								<div
									aria-hidden="true"
									className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
									<svg
										className="absolute inset-0 h-full w-full"
										preserveAspectRatio="xMidYMid slice"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 1463 360">
										<path
											className="text-secondary-500 text-opacity-40"
											fill="currentColor"
											d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
										/>
										<path
											className="text-secondary-700 text-opacity-40"
											fill="currentColor"
											d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
										/>
									</svg>
								</div>
								<div className="relative">
									<div className="sm:text-center">
										<h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
											Rechargez votre compte en toute
											sécurité en 1 minute
										</h2>
										<p className="mx-auto mt-6 max-w-2xl text-lg text-secondary-200"></p>
									</div>
									<form onSubmit={rechargeHAndleSubmit}>
										<div className="mt-6 sm:mx-auto sm:flex sm:max-w-lg">
											<div className="min-w-0 flex-1">
												<label
													htmlFor="Choix de l'opérateur"
													className="sr-only"></label>
												<select
													required
													aria-label="Choix de l'opérateur"
													id="Choix de l'opérateur"
													name="operateur"
													onChange={
														rechargeHandleChange
													}
													autoComplete="operateur"
													className="block w-full rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondary-600">
													<option
														value="Choix de l'opérateur"
														disabled>
														Choix de l'opérateur
													</option>
													<option value="CM_ORANGEMONEY">
														Orange Money
													</option>
													<option value="CM_MTNMOBILEMONEY">
														MTN Mobile Money
													</option>
												</select>
											</div>
										</div>
										<div className="mt-6 sm:mx-auto sm:flex sm:max-w-lg">
											<div className="min-w-0 flex-1">
												<label
													htmlFor="prix"
													className="sr-only"></label>
												<input
													required
													ref={prixRef}
													aria-label="prix"
													id="prix"
													min="1000"
													step="1000"
													type="number"
													className=" w-full block rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondary-600"
													placeholder="Min:1000 Fcfa"
												/>
											</div>
											<div className="mt-4 sm:mt-0 sm:ml-3">
												<button
													type="submit"
													className="flex items-center gap-x-4 w-full rounded-md border border-transparent bg-secondary-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondary-600 sm:px-10 text-center">
													{isLoading && (
														<Spinner className="w-6 h-6  text-white" />
													)}
													Recharger
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="px-4 sm:px-6 lg:px-8 mt-8">
				<div className="flex justify-between items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-bold text-primary-900">
							Mes recharges
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							La liste des transactions.
						</p>
					</div>
					{!addRecharge && (
						<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
							<button
								type="button"
								onClick={() => setAddRecharge(true)}
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
								Nouvelle recharge
							</button>
						</div>
					)}
				</div>
				{content}
			</div>
		</>
	);
};

export const Alert = ({ closeAlert, text, status }) => {
	const handleClose = () => {
		closeAlert();
	};

	let color;

	switch (status) {
		case "cancelled":
			color = "red";
			break;
		case "success":
			color = "green";
			break;
		case "failed":
			color = "red";
			break;

		default:
			break;
	}

	return (
		<div className={clsx(`bg-${color}-50`, "p-4 mt-4 rounded-md ")}>
			<div className="flex">
				<div className="flex-shrink-0">
					{status === "cancelled" || status === "failed" ? (
						<XCircleIcon
							className={clsx(`h-5 w-5 text-red-400`)}
							aria-hidden="true"
						/>
					) : (
						<CheckCircleIcon
							className={clsx(`h-5 w-5 text-green-400`)}
							aria-hidden="true"
						/>
					)}
				</div>
				<div className="ml-3">
					<p
						className={clsx(
							`text-sm font-medium text-${color}-800`
						)}>
						{text}
					</p>
				</div>
				<div className="ml-auto pl-3">
					<div className="-mx-1.5 -my-1.5">
						<button
							type="button"
							className={clsx(
								`inline-flex  p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-${color}-600 focus:ring-offset-2 focus:ring-offset-${color}-50`,
								`rounded-md bg-${color}-50`
							)}>
							<span className="sr-only">Dismiss</span>
							<XMarkIcon
								className="h-5 w-5"
								aria-hidden="true"
								onClick={handleClose}
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserRecharge;
