/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description select medecin for expert consultation
 * @component ChoixMedecin
 */
import React, { useState } from "react";
import Button from "../../../../UI/button/Button";
import { useNavigate } from "react-router-dom";
import { useGetMedecinForRdvQuery } from "../../../../features/api/apiSlice";
import Spinner from "../../../../UI/spinner/Spinner";
import { useSearchParams } from "react-router-dom";
import Filter from "./Filter";
import Doctor from "./Doctor";

export default function ChoixMedecin() {
	// search params
	let [searchParams, setSearchParams] = useSearchParams();

	// query params state
	const [query, setquery] = useState(() =>
		Object.fromEntries([...searchParams])
	);

	// get medecin request
	const {
		data: medecin,
		isSuccess: medIsSuccess,
		isError: medIsError,
		isLoading: medIsloading,
		isFetching: medIsFetching,
	} = useGetMedecinForRdvQuery(query);

	const navigate = useNavigate();

	return (
		<>
			<div className="mx-auto max-w-3xl py-2 px-4 text-center sm:py-2 sm:px-6 lg:px-8">
				<h2 className="text-3xl font-bold tracking-tight text-primary-800 sm:text-4xl">
					Selectionner votre médecin et la periode de votre
					consultation.
				</h2>
			</div>

			{/* medecin filter   */}
			<Filter
				setquery={setquery}
				searchParams={searchParams}
				setSearchParams={setSearchParams}
				isLoading={medIsloading}
				isFetching={medIsFetching}
			/>

			{medIsloading && (
				<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
			)}

			{medIsSuccess &&
				medecin &&
				!medIsError &&
				(medecin.data?.length ? (
					<div className="space-y-8">
						{medecin?.data?.map((item, index) => {
							return (
								<Doctor
									key={index}
									doctor={item}
								/>
							);
						})}
					</div>
				) : (
					<div className="flex text-gray-500 text-xl justify-center font-medium mt-8">
						Aucun médecin disponible
					</div>
				))}

			<div className="max-w-3xl  mt-8">
				<div className="mt-8 flex items-center justify-between">
					<Button
						children="Etape précédent"
						type="button"
						onClick={() => navigate("/consultations/specialite")}
					/>
				</div>
			</div>
		</>
	);
}
