/**
 * @author assan zidan nsangou
 * @description composant Input parametrable via ces props
 * @email nsangouassanzidan@gmail.com
 * @component Input
 * @props inputError, placeholder, input, hidden, label, className, onBlur, Eye, icon, value, onChange, name, disabled, required, errorText, ariaInputError, defaultValue, pattern, autoComplete 
 * @
 */

import React from "react"
import clsx from 'clsx'

//import styles
import { styles } from "./styles"


const Input = React.forwardRef((props, ref) => {
  const { inputError, placeholder, input, hidden, label, className, onBlur, Eye, icon, value, onChange, onKeyDown, name, disabled, required, errorText, ariaInputError, defaultValue, pattern, autoComplete } = props


  return (
    <div style={{ flexGrow: 1 }}>
      {/* affiche le label si celui-ci est renseigner */}
      {label
        &&
        <label htmlFor={input.id} className={styles.label}>
          {label}
        </label>
      }

      <div className="relative rounded-md shadow-sm">
        {/* affiche l'icone si celui-ci est renseigner */}
        {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          {icon}
        </div>}

        {/* icone permettant la previsualisation du mot de passe uniquement pour les champs d'entree de type password */}
        {Eye && <div className=" absolute inset-y-0 right-0 flex items-center pr-4">
          {
            Eye
          }
        </div>}

        {/* champs d'entree prenant en compte tous les types  */}
        <input
          aria-hidden={hidden}
          hidden={hidden}
          pattern={pattern}
          required={required}
          disabled={disabled}
          autoComplete={autoComplete}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          ref={ref}
          {...input} // input est un objet qui permet de renseigner tous les autres attributs qui n'ont pas été pris en compte comme props
          name={name}
          id={input.id}
          className={clsx(inputError ? styles.inputError : styles.input, `${icon && "pl-14"}`, className)}
          placeholder={placeholder}
          defaultValue={defaultValue} // valeur par defaut
          aria-invalid={inputError}
          aria-describedby={ariaInputError}
        />
      </div>
      {
        inputError && <p className="text-sm text-red-600" id={ariaInputError}>
          {errorText}
        </p> //input error ariaINputError
      }
    </div>
  )
})

export default Input





