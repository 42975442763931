/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description Registration page
 * @component Registration
 */
import React, { useState, useLayoutEffect } from 'react'
import { Link, } from 'react-router-dom'

import Logo from '../../components/logo/Logo'
import Banner from '../../components/auth/Banner'
import Title2 from '../../components/title/Title2'
import ResgistrationForm from '../../components/auth/ResgistrationForm'

import Alert from '../../UI/alert'

import backgroung from "../../assets/banner1.jpg"

import { btnLink } from '../../styles'


const Registration = () => {
  // alert state 
  const [alert, setalert] = useState({
    text: "",
    error: false,
    isAlert: false
  })

  // close alert after 10 secondes
  useLayoutEffect(() => {
    if (alert.isAlert) {
      setTimeout(() => {
        setalert(prevState => {
          return {
            ...prevState,
            isAlert: false,
            error: false,
            text: ""
          }
        })
      }, 10000)
    }

    return () => {
      clearTimeout()
    };
  }, [alert.isAlert])

  return (
    <>
      <div className="flex  min-h-screen">
        <div className="flex relative  flex-1 flex-col justify-center  px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
          <div className=" mx-auto w-full max-w-lg lg:max-w-md">
            {alert.isAlert && <div className='absolute top-0 left-0 w-full'>
              <Alert alert={alert} setalert={setalert} />
            </div>}

            <div>
              <Logo />
              <Title2 text="Inscription" />
            </div>

            <div className="mt-8">
              {/* Registration form  */}
              <ResgistrationForm setalert={setalert} />

              <div className="relative mt-6">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">Vous avez déjà un  compte ?</span>
                </div>
              </div>

              <div className='mt-4'>
                <Link to="/connexion" className={btnLink.secondary}>Se connecter</Link>
              </div>
            </div>
          </div>
        </div>
        {/* banner  */}
        <Banner banner={backgroung} />
      </div>

    </>
  )
}

export default Registration