/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description preview and update user information
 * @component UserInfo
 */

import { useParams } from "react-router-dom";
import {
	Fragment,
	useEffect,
	useRef,
	useState,
	useCallback,
	useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import {
	useAddProfilMutation,
	useGetProfilsQuery,
	useSetProfilMutation,
	useGetUserInfoQuery,
} from "../../../../features/api/apiSlice";
import {
	getModalProps,
	getModalType,
	initialModal,
	setModal,
} from "../../../../features/feedBack/modal.slice";

import profile from "../../../../assets/profile.png";

import PersonnalInformation from "../userProfil/PersonnalInformation";
import ContactInformation from "../userProfil/ContactInformation";
import ProfessionalInformation from "../userProfil/ProfessionalInformation";
import { Alert } from "../UserRecharge/Recharge";

import Select from "../../../../UI/form/Select";
import Spinner from "../../../../UI/spinner/Spinner";
import Button from "../../../../UI/button/Button";

// add profil modal
function ModalProfil({ open, setOpen }) {
	const dispatch = useDispatch();
	const params = useParams();
	const profilRef = useRef();

	// profil options state
	const [valueOption, setvalueOption] = useState([]);

	// get profil request
	const { data: profils, isSuccess, isLoading } = useGetProfilsQuery();

	// add profil request
	const [addProfil, { isLoading: addProfilLoading }] = useAddProfilMutation();

	// set profil option state
	useEffect(() => {
		if (isSuccess && profils) {
			const data = profils?.data?.map((item) => {
				return {
					id: item.niveau,
					name: item.titre,
					idProfil: item.id,
				};
			});

			setvalueOption(data);
		}

		return () => {};
	}, [isSuccess, profils]);

	// add profil
	const addProfilHandleClick = (e) => {
		const niveau = profilRef.current.value;
		const data = { niveau };
		addProfil({ code: params.code, data })
			.unwrap()
			.then((res) => {
				setOpen(false);
				dispatch(
					setModal({
						modalType: "user",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le nouveau profil a été ajouté avec succéss`,
						},
					})
				);
			})
			.catch((err) => {
				// alert error message
			});
	};

	return (
		<Transition.Root
			show={open}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
										onClick={() => setOpen(false)}>
										<span className="sr-only">Close</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button>
								</div>
								<div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900">
											Attribuer un profil à un utilisateur
										</Dialog.Title>
										<div className="mt-6">
											{isLoading ? (
												<Spinner className="w-8 h-8 text-secondary-600 " />
											) : (
												<Select
													id="profil"
													defaultValue="2"
													name="profil"
													label="Sélectionner le profil"
													valueOption={valueOption}
													ref={profilRef}
												/>
											)}
										</div>
									</div>
								</div>
								<div className="mt-8 sm:mt-4 sm:flex gap-x-4 sm:flex-row-reverse"> 
									<Button
										button="primary"
										type="button"
										children="Ajouter le profil"
										className="max-w-max"
										onClick={addProfilHandleClick}
										loading={addProfilLoading}
									/>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}>
										Annuler
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

const UserInfo = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();

	// modal add profil state
	const [open, setOpen] = useState(false);

	// get user info
	const {
		data: user,
		isSuccess,
		isLoading,
	} = useGetUserInfoQuery(params?.code);

	// get profil user
	const [setMyProfil, { isLoading: loading }] = useSetProfilMutation();

	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);

	// user info state
	const [userInfo, setUserInfo] = useState();

	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// close alert after 10 s
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);

	// set user info data
	useEffect(() => {
		if (isSuccess && user) {
			setUserInfo(user?.data);
		}

		return () => {};
	}, [isSuccess, user]);

	// update profil  user
	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();

		// add item to formData
		const pushItemform = (item, key) => {
			return formData.append(`${key}`, item);
		};

		pushItemform(userInfo?.nom, "nom");
		pushItemform(userInfo?.sexe, "sexe");
		pushItemform(userInfo?.twitter, "twitter");
		pushItemform(userInfo?.prenom, "prenom");
		pushItemform(userInfo?.photo_profil, "photo_profil");
		pushItemform(userInfo?.date_naissance, "date_naissance");
		pushItemform(userInfo?.facebook, "facebook");
		pushItemform(userInfo?.profession, "profession");
		pushItemform(userInfo?.specialisation, "specialisation");

		setMyProfil({ data: formData, code: user?.data?.code })
			.unwrap()
			.then((res) => {
				dispatch(
					setModal({
						modalType: "user",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le compte de l'utilisateur (${user?.data?.code}) a été mis à jour avec succéss`,
						},
					})
				);
				navigate("/users");
			})
			.catch((err) => {
				// alert error message
			});
	};

	return (
		<>
			{/* alert componenet  */}
			{modalType === "user" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}

			<div className="min-h-full">
				{isSuccess && user && (
					<div className="py-10">
						 <h1 className="text-3xl font-bold tracking-tight text-gray-800 pl-4 mt-4">
							 compte
						</h1>
											{/* <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
							<div className="flex items-center space-x-5 ">
								<div className="">
									<div className="relative">
										<div className="space-y-6">
											<img
												className="object-cover h-24 w-24 rounded-full "
												src={
													user.data.photo_profil
														.length > 0
														? user.data.photo_profil
														: profile
												}
												alt={`${user.data.nom} ${user.data.prenom}`}
											/>
										</div>
										<span
											className="absolute inset-0 rounded-full shadow-inner"
											aria-hidden="true"
										/>
									</div>
								</div>
								<div>
									<div>
										<h1 className="text-2xl font-bold text-gray-900">
											{user.data.nom} {user.data.prenom}
										</h1>
										<p className="text-sm font-medium text-gray-500">
											Compte Cree{" "}
											{" "}
										    le{" "}
											<time dateTime="2022-11-20">
												{user.data.dateCreation}{" "}
											</time>
											<time dateTime="2022-11-20">
												{user.data.created_at}{" "}
											</time>
										</p>
									</div>
								</div>
							</div>

							<div className="flex items-center gap-x-4">
								<Button
									button="secondary"
									type="button"
									children="Attribuer un profil"
									className="max-w-max hidden"
									onClick={() => setOpen(true)}
								/>
								<Button
									button="primary"
									type="button"
									children="Modifier le compte"
									className="max-w-max"
								/>
							</div>
						</div>   */}

						{/* section informatoin */}
						<form
							className="px-4 sm:px-6"
							onSubmit={handleSubmit}>
							<div className="bg-white divide-y-blue-gray-200 space-y-8 divide-y  ">
								<PersonnalInformation
								
									{...user?.data}
									isLoading={isLoading}
									setUserInfo={setUserInfo}
									profilIsVisible={true}
								/>

								<ContactInformation
									{...user?.data}
									setUserInfo={setUserInfo}
								/>

								{(user?.data?.defaultProfil?.id === "IA10" ||
									user?.data?.defaultProfil?.id ===
										"IA7") && (
									<ProfessionalInformation
										{...user?.data}
										setUserInfo={setUserInfo}
									/>
								)}

								<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
									<Button
										button="primary"
										type="submit"
										loading={loading}
										children="Modifier le profil"
										className="max-w-max"
									/>
								</div>
							</div>
						</form>
					</div>
				)}

				{/* spinner componenent  */}
				{isLoading && (
					<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
				)}
			</div>

			{/* add profil modal  */}
			{open && (
				<ModalProfil
					open={open}
					setOpen={setOpen}
				/>
			)}
		</>
	);
};

export default UserInfo;
