import React, { useLayoutEffect } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Input from "../../../../../UI/form/Input";
import Button from "../../../../../UI/button/Button";

const StartConsult = ({
	open,
	setOpen,
	verifyCodehandleClick,
	isLoading,
	setCurrentCode,
	isAlert,
	setIsAlert,
}) => {
	const codeVerifyHandleClick = () => {
		verifyCodehandleClick();
	};

	const handleChange = (e) => {
		const { value } = e.target;
		setCurrentCode(value);
	};

	useLayoutEffect(() => {
		if (isAlert.isError) {
			setTimeout(() => {
				setIsAlert({ isError: false, message: "" });
			}, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [isAlert.isError, setIsAlert]);

	return (
		<Transition.Root
			show={open}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
								{isAlert.isError && (
									<div className="rounded-md bg-red-50 p-4 mb-4">
										<div className="flex">
											<div className="ml-3">
												<p className="text-sm font-medium text-red-800">
													{isAlert.message}
												</p>
											</div>
										</div>
									</div>
								)}
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={() => setOpen(false)}>
										<span className="sr-only">
											Fermer la fenetre
										</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button>
								</div>
								<div className="sm:flex sm:items-start ">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900">
											Vérification du code consultation
										</Dialog.Title>
										<Dialog.Description
											as="p"
											className="text-sm text-gray-500 mt-1">
											Veillez entrer le code de
											consultation du patient
										</Dialog.Description>
										<div className="mt-4">
											<Input
												label="code de la consultation"
												placeholder={
													"Entrer le code de la consultation"
												}
												input={{
													id: "code",
													type: "text",
													maxLength: "80",
												}}
												onChange={handleChange}
												name={"code"}
												disabled={false}
											/>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse gap-x-4">
									<Button
										children="Envoyer"
										type="button"
										button="primary"
										onClick={codeVerifyHandleClick}
										loading={isLoading}
										className="max-w-max"
									/>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}>
										Annuler
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default StartConsult;
