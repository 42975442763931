import styled from "styled-components";

const Container = styled.div`
    border-left: solid blue 2px;
    padding: 1px 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400
`;

function BreadCumbs(props) {
    let text = props.title;

    if (props.currentPage === "categorie") {
        text = text;
    } else if (props.currentPage === "assurance") {
        text = "Type d'assurance > " + text;
    } else if (props.currentPage === "description") {
        text = "Type d'assurance > Choix d'assurance  > " + text;
    } else if (props.currentPage === "beneficiaire") {
        text = "Type d'assurance > Choix d'assurance > Description > " + text;
    } else if (props.currentPage === "formulaire") {
        text = "Type d'assurance > Choix d'assurance > Description > Beneficiaire > " + text;
    } else if (props.currentPage === "recap") {
        text = "Type d'assurance > Choix d'assurance > Description > Beneficiaire > Questionnaire > " + text;
    } else if (props.currentPage === "conditions") {
        text = "Type d'assurance > Choix d'assurance > Description > Beneficiaire > Questionnaire > Recapitulatif > " + text;
    } else if (props.currentPage === "payement") {
        text = "Type d'assurance > Choix d'assurance > Description > Beneficiaire > Questionnaire > Recapitulatif > Contrat > " + text;
    }


    return (
        <Container className='text-secondary-800 xs:hidden mb-7'>
            <span className="hover:cursor-pointer" onClick={props.onClick}>{text}</span>
        </Container>
    );
}

export default BreadCumbs;
