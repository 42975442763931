import styled from "styled-components";
import uniquePay from "../../../assets/terms.png";

const PayingMode = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1.2em;
    font-weight: 400;
    text-align: justify;
`;

const Amount = styled.div`
    display: ${({ activated }) => activated ? "flex" : "none"};
    flex-direction: column;
    margin: 1em 1.5em;
    gap: 1.5em;
`;

const Description = styled.div`
    display: flex;
    align-items: center;
    gap: 4em;
`;

const Paragraph = styled.p`
    font-size: 1.1em;
    font-weight: 400;
    text-align: justify;
`;

const Illustration = styled.img`
    width: 100px;
`;

function PayingModeInput({ label, description, type, active, selectedMode, handleCkededMode, amountMode, setAmountMode, illustration, totalPrice }) {
    const activated = selectedMode === active ? true : false;

    function handleAmountChange(event) {
        const value = event.target.value;

        setAmountMode({
            ...amountMode,
            amount: parseFloat(value)
        });
    };

    return (
        <div>
            <PayingMode>
                <input
                    id={`pay_mode${active}`}
                    name={label}
                    type="checkbox"
                    onChange={(event) => handleCkededMode(event, active)}
                    className="h-4 w-4 border-secondary-600 text-secondary-600 focus:ring-secondary-500"
                    checked={activated}
                    style={{ cursor: "pointer" }}
                />
                <label
                    htmlFor={`pay_mode${active}`}
                    style={{ cursor: "pointer" }}
                >
                    {label}
                </label>
            </PayingMode>
            
            <Amount activated={activated}>
                <Description>
                    <Paragraph>{description}</Paragraph>
                    <Illustration src={illustration ? illustration : uniquePay} alt="payment methode illustration" />
                </Description>

                {type === "Unique" ? (
                    <Paragraph>Montant à payer: {totalPrice} Fcfa</Paragraph>
                ) : (
                    <input
                        id={`amount${active}`}
                        name={label}
                        type="number"
                        placeholder="3000 Frcfa"
                        value={amountMode?.mode === label ? amountMode?.amount : 0}
                        onChange={handleAmountChange}
                        className="border-secondary-600 text-secondary-600"
                    />
                )}
            </Amount>
        </div>
    );
};

export default PayingModeInput;
