/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add user page
 * @component UserAddPage
 */

import React from "react";

import UserAdd from "../../components/layouts/main/users/UserAdd";

const UserAddPage = () => {
	return <UserAdd />;
};

export default UserAddPage;
