/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description formulaire d'envoie de mail d'addresse mail pour la modification de mot de passe 
 * @component ForgetPasswordForm
 * @props setOpen, setalert, 
 */

import React, { useState } from 'react';

import Input from '../../UI/form/Input'
import Button from '../../UI/button/Button';

import useInput from '../../hooks/use-input';

import axiosInstance from '../../apis/inchAssur';

import { isEmail } from "../../lib/inputValidation"


const ForgetPasswordForm = ({ setOpen, setalert }) => {
  //control email
  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailHandleChange,
    inputBlurHandler: emailHandleBlur,
  } = useInput(isEmail)

  // spinner
  const [loading, setloading] = useState(false)

  // envoie de l'email 
  const sendMail = (e) => {
    e.preventDefault()

    const data = {
      email: enteredEmail,
    }

    setloading(true)

    axiosInstance.post('/account/resetPassword', {
      ...data,
    }).then((res) => {
      // localStorage.setItem('token', res.data?.token)
      setOpen(true)
    }).catch((err) => {
      // afficher l'erreur 
      setalert(prevState => {
        return {
          ...prevState,
          isAlert: true,
          error: true,
          text: err.response?.data?.message,
        }
      })
    }).finally(() => setloading(false))
  }

  return (
    // formulaire 
    <form onSubmit={sendMail} className="space-y-6">
      {/* email  */}
      <Input label="Addresse mail" inputError={emailInputHasError} placeholder={"entrer votre addresse mail"} value={enteredEmail} onChange={emailHandleChange} onBlur={emailHandleBlur} input={{ id: "email", type: "email", maxLength: "80" }} name={"email"} disabled={false} required={true} errorText={"Addresse mail incorect"} ariaInputError="email" />

      {/* bouton d'envoie  */}
      <Button type="submit" children="Envoyer" button="primary" loading={loading} error={!enteredEmailIsValid} />
    </form>
  )
}

export default ForgetPasswordForm