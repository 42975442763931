import { createSlice, nanoid } from "@reduxjs/toolkit";

// initial state
const initialState = {
	ids: [],
	entities: {},
};

const formSlice = createSlice({
	name: "formulaire",
	initialState,

	reducers: {
		initialForm: (state, action) => {
			return action.payload;
		},
		resetForm: (state, action) => {
			return initialState;
		},
		addQuestion: (state, action) => {
			const id = nanoid();
			const newIsd = state.ids.concat(id);

			const newEntities = {
				...state.entities,
				[id]: {
					id: id,
					isActive: false,
					isRequired: false,
					actionForm: "CREATE",
					name: "",
					tarifType: "addition",
					type: "text",
					options: [
						{
							id: nanoid(),
							label: "Option 1",
							prix: "Prix 1",
						},
					],
				},
			};

			return {
				ids: newIsd,
				entities: newEntities,
			};
		},
		deleteQuestion: (state, action) => {
			const idToremove = action.payload;

			const newIsd = state.ids.filter((id) => id !== idToremove);

			const newEntities = newIsd
				.map((item) => state.entities[item])
				.reduce((obj, item) => {
					return {
						...obj,
						[item.id]: item,
					};
				}, {});

			return {
				...state,
				ids: newIsd,
				entities: newEntities,
			};
		},
		setQuestionForm: (state, action) => {
			const {
				id,
				name,
				typeInput,
				type,
				prix,
				tarifType,
				isRequired,
				option,
				newOption,
				idOption,
				indexOption,
			} = action.payload;

			const deleteOptions = state.entities[id].options.filter(
				(item) => item.id !== idOption
			);

			let newEntities;
			switch (type) {
				case "NAME":
					newEntities = {
						...state.entities,
						[id]: {
							...state.entities[id],
							name: name,
						},
					};

					return {
						...state,
						entities: newEntities,
					};
				case "PRIX":
					newEntities = {
						...state.entities,
						[id]: {
							...state.entities[id],
							prix: prix,
						},
					};

					return {
						...state,
						entities: newEntities,
					};
				case "TARIF":
					newEntities = {
						...state.entities,
						[id]: {
							...state.entities[id],
							tarifType: tarifType,
						},
					};

					return {
						...state,
						entities: newEntities,
					};
				case "REQUIRED":
					newEntities = {
						...state.entities,
						[id]: {
							...state.entities[id],
							isRequired,
						},
					};

					return {
						...state,
						entities: newEntities,
					};
				case "ADDOPTIONS":
					newEntities = {
						...state.entities,
						[id]: {
							...state.entities[id],
							options: [...state.entities[id].options, newOption],
						},
					};

					return {
						...state,
						entities: newEntities,
					};
				case "DELETEOPTIONS":
					newEntities = {
						...state.entities,
						[id]: {
							...state.entities[id],
							options: deleteOptions,
						},
					};

					return {
						...state,
						entities: newEntities,
					};
				case "OPTIONSLABEL":
					state.entities[id].options[indexOption] = option;
					break;
				case "OPTIONSPRIX":
					state.entities[id].options[indexOption] = option;
					break;
				case "TYPE":
					newEntities = {
						...state.entities,
						[id]: {
							...state.entities[id],
							type: typeInput,
						},
					};

					return {
						...state,
						entities: newEntities,
					};

				default:
					break;
			}
		},
		duplicateQuestion: {
			reducer(state, action) {
				const { newId, idFormToDuplicate } = action.payload;

				const newIsd = state.ids.concat(newId);

				const newEntities = {
					...state.entities,
					[newId]: {
						...state.entities[idFormToDuplicate],
						id: newId,
						actionForm: "CREATE",
					},
				};

				return {
					ids: newIsd,
					entities: newEntities,
				};
			},
			prepare(id) {
				return {
					payload: {
						newId: nanoid(),
						idFormToDuplicate: id,
					},
				};
			},
		},
	},
});

// array of id question
export const getFormIds = (state) => state.form.ids;

// object of list of question
export const getFormsEntities = (state) => state.form.entities;

export const {
	duplicateQuestion,
	deleteQuestion,
	setQuestionForm,
	addQuestion,
	initialForm,
	resetForm,
} = formSlice.actions;

export default formSlice.reducer;
