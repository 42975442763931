/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance detail page
 * @component AssurancePreviewPage
 */

import React from "react";
import styled from "styled-components";
import AssurancePreview from "../../../components/layouts/main/assurances/client/assurancePreview";

const StyledMain = styled.div`
	position: relative;
	height: 100vh;
	width: 100%;
	min-width: 380px;
	max-width: 1820px;
	padding: 30px 70px;
	font-size: 10px;
	font-family: 'Montserrat', sans-serif;
	@media screen and (max-width: 800px) {
		padding: 20px 40px;
	}
	@media screen and (max-width: 640px) {
		padding: 35px;
	}
`

const AssurancePreviewPage = () => {
	return (
		<StyledMain>
			<AssurancePreview />
		</StyledMain>
	);
};

export default AssurancePreviewPage;
