import styled from "styled-components";
import DataRow from "../../../../../Utilities/DataRow";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
`;

const DocContainer = styled.div`
    display: flex;
    background: white;
    width: 100%;
    height: 90%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border-radius: 4px;
`;

function AddedDoc({ addedDocs, isDeleting=false, targetedDoc=null, removeDoc }) {
    
    return (
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full  bg-gray-50">
                <h3 className="text-base font-semibold leading-7 text-gray-500">Documents ajoutés</h3>
                <Container className="mt-2">
                    {addedDocs.length > 0 ? (
                        addedDocs.map((doc, index) => (
                            <DataRow key={index} docId={doc.id} isDeleting={isDeleting} targetedDoc={targetedDoc} index={index} title={doc.title} fileName={doc.file.name} type={doc.type} url={doc.url} handleRemove={removeDoc} />
                        ))
                    ) : (
                        <DocContainer className="block text-sm font-medium leading-6">Aucun document ajouté...</DocContainer>
                    )}
                </Container>
            </div>
        </div>
    );
};

export default AddedDoc;
