import { FunnelIcon } from "@heroicons/react/24/solid";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledParagraph = styled.h2`
    font-size: 2.2em;
    font-weight: 600;
    @media screen and (max-width: 1023px) {
        font-size : 2em;
    }
    @media screen and (max-width: 500px) {
        font-size : 1.7em;
    }
`;

const StyledFunnelIcon = styled(FunnelIcon)`
    width: 3em;
    @media screen and (max-width: 1023px) {
        width: 2.7em;
    }
`;

function SubscriptionCardsTitle({ content }) {

    return (
        <Container>
            <StyledParagraph className="text-secondary-800">{content}</StyledParagraph>
            <StyledFunnelIcon className="text-secondary-800" />
        </Container>
    )
};

export default SubscriptionCardsTitle;
