import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
	isOnline: false,
};

// inline statut slice
const isOnlineStatus = createSlice({
	name: "onlineStatus",
	initialState,
	reducers: {
		setOnline: (state, action) => {
			state.isOnline = action.payload;
		},
	},
});

// get online statut
export const getOnlineStatus = (state) => state.isOnlineStatus.isOnline;

export const { setOnline } = isOnlineStatus.actions;

export default isOnlineStatus.reducer;
