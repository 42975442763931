/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description doctor slot
 * @component DoctorSlot
 */
import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../../UI/svg";
import Day from "./Day";
import Button from "../../../../UI/button/Button";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const DoctorSlot = ({ agenda, selectDoctor }) => {
	const [minlength, setMinlength] = useState(3);
	let maxlenght;

	// slot per day
	const [itemPerPage] = useState(3);

	//initial page
	const [currentPage, setCurrentPage] = useState(1);

	const array = agenda?.map((item) => item?.slots.length);
	if (array?.length > 0) {
		maxlenght = Math.max(...array);
	}

	const indexOfLastItem = currentPage * itemPerPage;
	const indexOfFirstItem = indexOfLastItem - itemPerPage;

	// nomber of  pages
	let pages = [];
	for (let i = 1; i <= Math.ceil(agenda?.length / itemPerPage); i++) {
		pages?.push(i);
	}

	// current slot
	const currentSlot = agenda?.slice(indexOfFirstItem, indexOfLastItem);

	return (
		<div className="p-4 mx-8 flex-1 relative">
			<div className="w-full">
				<div className="flex items-start justify-between gap-x-1">
					<div
						aria-disabled={
							currentPage === 1 || currentPage === 0
								? true
								: false
						}
						disabled={
							currentPage === 1 || currentPage === 0
								? true
								: false
						}
						className={classNames(
							currentPage === 1 || currentPage === 0
								? "hidden"
								: "",
							"hover:bg-secondary-200 hover:rounded-full cursor-pointer absolute top-4 -left-4 p-1"
						)}>
						<ChevronLeftIcon
							onClick={() =>
								setCurrentPage((prevState) => prevState - 1)
							}
							className="w-5 h-5 text-secondary-800 "
						/>
					</div>
					{currentSlot?.map((itemAgenda, indexAgenda) => {
						return (
							<Day
								day={itemAgenda?.day}
								slots={itemAgenda?.slots}
								key={indexAgenda}
								maxlenght={maxlenght}
								minlength={minlength}
								selectDoctor={selectDoctor}
							/>
						);
					})}

					<div
						aria-disabled={
							currentPage === pages[pages?.length - 1] ||
							currentPage === itemPerPage ||
							pages?.length === 0
								? true
								: false
						}
						disabled={
							currentPage === pages[pages?.length - 1] ||
							currentPage === itemPerPage ||
							pages?.length === 0
								? true
								: false
						}
						className={classNames(
							currentPage === pages[pages?.length - 1] ||
								currentPage === itemPerPage ||
								pages?.length === 0
								? "hidden"
								: "",
							"hover:bg-secondary-200 hover:rounded-full p-1 cursor-pointer absolute top-4 -right-4"
						)}>
						<ChevronRightIcon
							className="w-5 h-5 text-secondary-800 "
							onClick={() =>
								setCurrentPage((prevState) => prevState + 1)
							}
						/>
					</div>
				</div>
			</div>
			{agenda?.length > 0 ? (
				minlength === maxlenght ? null : (
					<>
						<hr className="mt-4" />
						<div className="flex justify-center mt-1">
							<Button
								children="VOIR PLUS D'HORAIRE"
								type="button"
								button="default"
								onClick={() => {
									setMinlength(maxlenght);
								}}
							/>
						</div>
					</>
				)
			) : null}
		</div>
	);
};

export default DoctorSlot;
