import styled from "styled-components";

const StyledP = styled.p`
    color: ${({status}) => status === "pending" ? "#263238" : status === "current" ? "white" : "#1976d2"};
    font-size: 1.3em;
    font-weight: 600;
    padding: 10px 20px;
    width: 100%;
    background-color: ${({status}) => status === "current" && "rgba(255, 153, 3, 0.8)"};
`;

function FormStepLabel({ label, status }) {
    return (
        <StyledP status={status}>{label}</StyledP>
    );
};

export default FormStepLabel;
