/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description banniere des pages d'authentification
 * @component Banner
 * @props banner, 
 */

const Banner = ({ banner }) => {
  return (
    <div className="relative hidden h-ful w-full flex-1 lg:block bg-cover bg-center" style={{ backgroundImage: `url(${banner})` }}>

    </div>
  )
}

export default Banner