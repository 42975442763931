/**
 * @author assan zidan nsangou
 * @email tchamakoagossialisonprincesse@gmail.com
 * @description modepaiement list
 * @component 
 */
import React,  { useLayoutEffect,  useCallback, useState,Fragment ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import { Link, Navigate } from "react-router-dom";
import {
	LockOpenIcon,
	PencilSquareIcon,
	CheckCircleIcon,
} from "@heroicons/react/20/solid";

import {

	useGetAllPaiementQuery,
	useSetPaiementMutation,
	
} from "../../../../features/api/apiSlice";
import {
	getModalProps,
	getModalType,
	initialModal,
	setModal,
} from "../../../../features/feedBack/modal.slice";

import { EllipsisHorizontalIcon } from "../../../../UI/svg";
import SpinnerEllipsi from "../../../../UI/spinner/SpinnerEllipsi";

import { Alert } from "../UserRecharge/Recharge";

import usePagination from "../../../../hooks/usePagination";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Listusers() {
	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);
	const dispatch = useDispatch();
	const [selectedMode, setSelectedMode] = useState(null);
	// get paiement request
	const { data: paiements, isLoading, isSuccess } = useGetAllPaiementQuery();
	
	//data tabele
	const [filters, setFilters] = useState({
		global: {value: null, matchMode: FilterMatchMode.CONTAINS}
	});
    const [globalFilterValue, setGlobalFilterValue] = useState('');
	const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = {
	 ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
	
	const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
		const imageBodyTemplate = (rowData) => {
			const image = rowData.image;
	
			return (
				<div className="flex align-items-center gap-2">
					<img alt={image} src={image} width="66" />
					
				</div>
			);
		};

  
	const [idModeAction, setidModeAction] = useState();

	const itemsPerPage = 10;

	// pagination hook
	const {
		next,
		prev,
		jump,
		currentData,
		currentPage,
		maxPage,
		dataLength,
		beginItem,
		endItem,
	} = usePagination(paiements?.data, itemsPerPage);



	const statusClassName = (statusTransaction) => {
		switch (statusTransaction) {
			case "Actif":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "inActif":
				return "inline-flex rounded-full bg-red-100 px-2 my-4 text-xs font-semibold leading-5 text-gray-800 whitespace-nowrap";
			
			default:
				break;
		}
	};
	const statusItemTemplate = (rowData) => {
		return  (<div className={statusClassName(rowData.statut)}>
		{rowData.statut}
  </div>
)};

	const [setMode, { isLoading: loading }] = useSetPaiementMutation();
    const [PaiementInfo, setpaiementInfo] = useState({});
	
    	// unLockMode Paiement
	const handleModeChange = (mode) => {
		
		const { nom, statut, id } = mode;
		
       const statutBol = statut === 'Actif'? true : false;
	   console.log(statutBol)
	  setpaiementInfo({...mode, "statut":false})
      console.log(PaiementInfo)
		// mode.statut=1
		setMode({data:PaiementInfo,id_modePaiement:mode.id_modePaiement})
			.unwrap()
			.then((data) => {
				dispatch(
					setModal({
						modalType: "paiement",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le Mode de paiement a été mis à jour avec succéss`,
						},
					})
				);
				console.log('testtest')
				// setpaiementInfo({...mode, "statut":statutBol===true?'Actif':'inActif'})
				// console.log('index1', PaiementInfo)
				paiements.data.index = PaiementInfo
				console.log('index')
				console.log('index', paiements.data.index)
			})
			.catch((err) => {
				// alert error message
			});

	
		};
			// lockMode Paiement
	const handleModeChange1 = (mode) => {
		
		const { nom, statut, id } = mode;
		
       const statutBol = statut === 'Actif'? true : true;
	   console.log(statutBol)
	  setpaiementInfo({...mode, "statut":true})
      console.log(PaiementInfo)
		// mode.statut=1
		setMode({data:PaiementInfo,id_modePaiement:mode.id_modePaiement})
			.unwrap()
			.then((data) => {
				dispatch(
					setModal({
						modalType: "paiement",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le Mode de paiement a été mis à jour avec succéss`,
						},
					})
				);
				console.log('testtest')
				// setpaiementInfo({...mode, "statut":statutBol===true?'Actif':'inActif'})
				// console.log('index1', PaiementInfo)
				paiements.data.index = PaiementInfo
				console.log('index')
				console.log('index', paiements.data.index)
			})
			.catch((err) => {
				// alert error message
			});

	
		};
	// close alert
	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// close alert after 10 secondes
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);

	const actionBodyTemplate =(rowData)=>{
		
	return (
        <div>
		{
		idModeAction ===
		rowData.id_modePaiement ? (
			<SpinnerEllipsi
				className="inline-block text-left"
				classNameEllipsi="h-6 w-6 "
				classNameSpin="h-7 w-7 text-secondary-500"
			/>
		) : (
			<Menu
				as="div"
				className=" inline-block text-left">
				<div>
					<Menu.Button className=" relative flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
						<span className="sr-only">
							Open
							options
						</span>
						<EllipsisHorizontalIcon
							className="h-7 w-7"
							aria-hidden="true"
						/>
					</Menu.Button>
				</div>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95">
					<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1">
						

						<Menu.Item>
							{({
								active,
							})=>(
								<Link to={`/operations/${rowData.id_modePaiement}`} className= "bg-gray-100 text-gray-900 text-gray-700 cursor-pointer group flex items-center px-4 py-2 text-sm"
									>
						<PencilSquareIcon
													className=" mr-3 h-5 w-5 text-yellow-400 group-hover:text-yellow-500"
													aria-hidden="true"
												/>
						Modifier
						
	</Link>
		
							)}
						</Menu.Item>
						{rowData.statut ==="inActif" ? (
							<>
								<Menu.Item>
									{({
										active,
									}) => (
										
										<span
											onClick={() =>
												handleModeChange1(
													rowData
												)
											}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"cursor-pointer group flex items-center px-4 py-2 text-sm"
											)}>
											<LockOpenIcon
												className=" mr-3 h-5 w-5 text-green-400 group-hover:text-green-500"
												aria-hidden="true"
											/>
											activer
										</span>
									)}
								</Menu.Item>
								
							</>
						) : null}
						{rowData.statut ==="Actif" ? (
							<>
								<Menu.Item>
									{({
										active,
									}) => (
										<span
											onClick={() =>
												handleModeChange(
												rowData
												)
											}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"cursor-pointer group flex items-center px-4 py-2 text-sm"
											)}>
											<CheckCircleIcon
												className=" mr-3 h-5 w-5 text-green-400 group-hover:text-green-500"
												aria-hidden="true"
											/>
											Desactiver
										</span>
									)}
								</Menu.Item>
								
							</>
						) : null}
					</Menu.Items>
				</Transition>
			</Menu>
		)}

		<span className="sr-only">
			, {rowData.nom}
		</span>
	</div>

	)	
	}
        

	const header = renderHeader();

	return (

		<>
		
			{/* alert componenet  */}
			{modalType === "user" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}
			
			<div className="px-8 sm:px-6 lg:px-8 mt-8">
				{/* header  */}
				<div className="sm:flex sm:items-center mb-6">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							Modes de Paiement
						</h1>
						<p className="mt-2 text-sm text-gray-700"></p>
					</div>

					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<Link to="/operations/Addmode">
							<button
								type="button"
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
								Ajouter un mode de paiement
							</button>
						</Link>
					</div>
				</div>
			{isSuccess && (
              <div className="card">
            <DataTable value={paiements?.data} paginator showGridlines  selectionMode="checkbox" selection={selectedMode} onSelectionChange={(e) => setSelectedMode(e.value)} rows={10} loading={loading}  
                    filters={filters} globalFilterFields={['nom','statut']} header={header}
                    emptyMessage="No customers found.">
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
				<Column   header="image"  style={{ minWidth: '12rem' }} body={imageBodyTemplate} />
                <Column header="id" field="id_modePaiement" style={{ minWidth: '10rem' }}   /> 
                 <Column field="nom" header="nom" sortable  style={{ minWidth: '12rem' }}  />
                <Column header="Statut"sortable   style={{ minWidth: '12rem' }} body={statusItemTemplate}  />
             <Column header="actions"  style={{ minWidth: '12rem' }} body={actionBodyTemplate}  />
            </DataTable> 
        </div>
			)}
		</div>
			
		</>
	);
}
