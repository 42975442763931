/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description member step for insurance souscription
 * @component ListAssurances
 */
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { USERCODE } from '../../../../../../config/config';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUser } from '../../../../../../features/user/user.slice';
import { useDispatch } from 'react-redux';
import { useSetUserDefaultProfilMutation, useGetAllMembersQuery, apiSlice } from '../../../../../../features/api/apiSlice';
import { logIn } from '../../../../../../features/user/auth.slice';
import Spinner from '../../../../../../UI/spinner/Spinner';
import NestedModal from '../../../../../../UI/modal/Modal';

// subfiles imports
import SelectBeneficiary from './SelectBeneficiary';
import { Container, StyledH2, StyledH3, Description, ModalButton, StyledUList, StyledListElements, StyledEmptyListElements, StyledMinusIcon } from './computedStyle';
import { addNewBeneficiary, changeAccountType, removeItem, handleOpen, handleClose } from './logic';

export default function Membres({ recever, setRecever, setIsNextButtonWorking }) {
  const navigate = useNavigate();
  const location = useLocation();

  // Setting steps to localStorage
  localStorage.setItem('currentStep', "0");

  // useHook for set default profil
	const [setDefaultProfil] = useSetUserDefaultProfilMutation();
  const dispatch = useDispatch();

  // user profil
  const { FAMILY, ENTREPRISE, CLIENT } = USERCODE;

  // getting user
  const user = useSelector(getUser);

  const [query, setQuery] = useState('');
  const [people, setPeople] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  // const [skip, setskip] = useState(true)
  const [changingProfil, setChangingProfil] = useState(false);
  const [changinError, setChangingError] = useState(false);
  const [changingType, setChangingType] = useState("");

  const [clearInput, setClearInput] = useState(false);

  // modal state
  const [open, setOpen] = useState(false);

  function availableAccounts(user) {
    if (user?.profil?.id === FAMILY || user?.profil?.id === ENTREPRISE) {
      return {
        description: "Attention! Vous allez être invités à renseigner l'ensemble des informations requises pour le nouveau bénéficiaire que vous souhaitez ajouter à votre liste.",
        content: (
          <ModalButton
            usage="none"
            className='bg-primary-800'
            onClick={() => addNewBeneficiary(user, setOpen, location, navigate, USERCODE)}
          >OK</ModalButton>
        )
      };
    } else if (user?.profils) {
      const availableProfiles = user?.profils.filter((userProfile) => userProfile?.value === "Famille" || userProfile?.value === "Entreprise");
      return {
        description: "Désolé! Ce profil ne vous permet pas d'ajouter un bénéficiaire à votre liste, vous pouvez opter pour un des profils çi-dessous 👇, ou poursuivre sans changement.",
        content: (
          <>
            {availableProfiles.map((profil, index) => (
              <ModalButton
                key={`${index}-${profil}`}
                usage="change"
                className='bg-primary-800'
                onClick={() => changeAccountType(profil.id, setDefaultProfil, apiSlice, logIn, dispatch, setChangingProfil, setChangingError, setChangingType, USERCODE)}
              >
                {changingProfil && changingType === profil.id && <Spinner className='w-7 h-7 text-primary-700' />}
                { profil.value }
              </ModalButton>
            ))}

            <ModalButton
              usage="cancel"
              onClick={() => setOpen(false)}
            >poursuivre</ModalButton>
          </>
        )
      };
    } else {
      console.log("user :", user);
      
      return {
        description: "An error occurred that not allow us to identify your account type for now!",
        content: null
      }
    }
  };
  
  // get user member request
  const {
    data: membres,
    isLoading,
    isSuccess
  } = useGetAllMembersQuery(user.code);

  // default user
  const userMenbre = {
    code: user?.code,
    dateNaissance: user?.dateNaissance,
    etatCivil: user?.etatCivil,
    idUtilisateur: user?.idUtilisateur,
    nbrEnfant: user?.nbrEnfant,
    nom: user?.nom,
    photoCni: user?.photoCni,
    photoProfil: user?.photoProfil,
    prenom: user?.prenom,
    profil: user?.profil,
    nomComplet: `${user?.nom} ${user?.prenom}`,
  };

  useEffect(() => {
    if (query.length > 0) {
      const addedPeople = people.filter((item) => item.nomComplet === query);
      let isPeopleExist = false;

      recever.forEach((item) => item.code === addedPeople[0].code ? isPeopleExist = true : null);

      if (addedPeople.length > 0 && !isPeopleExist) {
        setSelectedPeople([...selectedPeople, addedPeople[0]]);
        setClearInput(true);
        setRecever([...recever, addedPeople[0]]);
      };
    };
  }, [query, people, selectedPeople, recever, setRecever]);
  
  
  useEffect(() => {
    if (isSuccess) {
      if (user?.profil?.id === FAMILY) {
        setPeople(prevState => {
          return [...prevState, userMenbre, ...membres?.data]
        })
      } else if (user?.profil?.id === CLIENT) {
        setPeople(prevState => {
          return [...prevState, userMenbre]
        })
      } else {
        setPeople(membres?.data)
      }
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, membres, user?.profil?.id]);

  // is benificiare
  if (recever?.length > 0) {
    setIsNextButtonWorking(true);
  } else {
    setIsNextButtonWorking(false);
  };

  // loader spiner
  if (isLoading) {
    return (
      <Spinner className='mt-8 w-10 h-10 m-auto text-primary-700' />
    )
  };

  return (
    <Container>
      <SelectBeneficiary setQuery={setQuery} people={people} clearInput={clearInput} handleOpen={() => handleOpen(setOpen)} />

      {/* list user  */}
      <div>
        <StyledH3 className='text-white bg-primary-800'>MES BENEFICIARES</StyledH3>

        <StyledUList>
          {recever.length === 0 ? (
            <StyledEmptyListElements>
              <li>Aucun bénéficiare sélectionné</li>
            </StyledEmptyListElements>
          ) : (
            recever?.map((item, index) => (
              <StyledListElements key={index}>
                <span>{`${item.nom} ${item.prenom}`}</span>

                <StyledMinusIcon
                  onClick={() => removeItem(item, setSelectedPeople, setRecever, selectedPeople, recever)}
                />
              </StyledListElements>
            ))
          )}
        </StyledUList>
      </div>

      <NestedModal
        modalWidth={500}
        open={open}
        handleClose={() => handleClose(setOpen)}
        border="2px solid #0066DB"
        children={(
          <>
            <StyledH2 id="parent-modal-title">AJOUT DE BENEFICIARES</StyledH2>
            <Description id="parent-modal-description">
              { availableAccounts(user, USERCODE).description }
            </Description>
            { availableAccounts(user, USERCODE).content }
          </>
        )}
      />
    </Container>
  );
}
