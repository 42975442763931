import styled, { keyframes } from "styled-components";
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

const Action = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	padding: 2em;
	font-weight: 600;
	border-radius: 0 8px 8px 0;
	position: relative;
`;

const bounce = keyframes`
	from {
		scale: 1.1;
	}
	to {
		scale: 1;
	}
`;

const ActionIcon = styled(EllipsisHorizontalCircleIcon)`
	width: 40px;
	cursor: pointer;
	color: gray;
	&:hover {
		color: #ff9903;
	}
	&:active {
		animation: ${bounce} 0.3s forwards;
	}
`;

const fadeIn = keyframes`
	from {
		opacity: 0;
		transform: translateY(70px);
	}
	to {
		opacity: 1;
		transform: translateY(90px);
	}
`;

const ActionMenu = styled.div`
	position: absolute;
	width: 150px;
	text-align: center;
	background: #0156b6;
	transform: translateY(30px);
	border-radius: 4px;
	border: solid #0156b6 2px;
	animation: ${fadeIn} 0.3s forwards;
	z-index: 3;
`;

const StyledH4 = styled.h4`
	font-size: 1.6em;
	font-weight: 500;
	color: white;
	padding: 5px;
`;

const ActionMenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
	z-index: 3;
`;

const ActionButton = styled.button`
	font-size: 1.5em;
	font-weight: 500;
	background: white;
	padding: 3px;
	border-radius: 4px;
	&:hover {
		background: #ff9903;
		color: white;
	}
`;

function ActionMenuCicle({ title, children, isActionOpen, setIsActionOpen }) {
	function handleClick(event) {
		event.stopPropagation();
		event.preventDefault();
		setIsActionOpen(!isActionOpen);
	};

    return (
        <Action>
            <ActionIcon
                onClick={(event) => handleClick(event)}
            />
            
            {isActionOpen && (
                <ActionMenu>
                    <StyledH4>{title}</StyledH4>
                    <ActionMenuContainer>
                        {children.map((child, index) => (
                            <ActionButton
								key={`${index}-${child.title}`}
								onClick={child.onButtonClicked}
							>
								{child.title}
							</ActionButton>
                        ))}
                    </ActionMenuContainer>
                </ActionMenu>
            )}
        </Action>
    );
};

export default ActionMenuCicle;
