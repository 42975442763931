/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description user transaction page
 * @component UserTransactionPage
 */

import Main from "../../components/layouts/main";

import SetModepaiement from "../../components/layouts/main/user/setModepaiement";

const setModepaiementpage = () => {
	return <Main children={<SetModepaiement />} />;
};

export default setModepaiementpage;
