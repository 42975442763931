import { AiOutlineCloseCircle } from "react-icons/ai";
import styled from "styled-components";
import Spinner from "../../UI/spinner/Spinner";

const DocContainer = styled.a`
    display: flex;
    background: white;
    width: 100%;
    height: 90%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border-radius: 4px;
`;

const DocTitle = styled.h3`
    width: 30%;
    overflow-wrap: break-word;
`;

const DocFileName = styled.h3`
    width: 40%;
    font-style: italic;
    overflow-wrap: break-word;
`;

const DocFileType = styled.h3`
    width: 20%;
    font-style: italic;
    overflow-wrap: break-word;
`;

function DataRow({ docId, isDeleting=false, targetedDoc=null, index, title, fileName, type, url, handleRemove }) {

    return (
        <DocContainer href={url} target="_blank">
            <DocTitle className="block text-sm font-medium leading-6">{title}</DocTitle>
            {fileName?.length > 0 && (<DocFileName className="block text-sm font-medium leading-6">{fileName}</DocFileName>)}
            {type?.length > 0 && (<DocFileType className="block text-sm font-medium leading-6">{type}</DocFileType>)}
            {isDeleting && targetedDoc === docId ? (
                <Spinner className="w-5 h-5 text-primary-600 block right-1 top-1" />
            ) : (
                <AiOutlineCloseCircle
                    onClick={(event) => handleRemove(event, index, docId)}
                    className="w-5 h-5 text-gray-600 hover:text-gray-900 group-hover:block block right-1 top-1 cursor-pointer"
                />
            )}
        </DocContainer>
    );
};

export default DataRow;
