/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance souscription
 * @component AssurancePreview
 */
import { REACT_APP_API_CHAT_BASE_URL } from '../../../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import beneficiaire from '../../../../../../assets/beneficiary.png';
import form from '../../../../../../assets/beneficiaire.png';
import terms from '../../../../../../assets/terms.png';
import recap from '../../../../../../assets/recap.png';
import ProcessHeader from '../../../../header/ProcessHeader';
import styled from 'styled-components';

import BackIllustration from '../../../../../Utilities/BackIllustration';
import BreadCumbs from '../../../../../Utilities/BreadCumbs';
import SubscriptionBodyTitles from '../../../../../Utilities/subscriptionBodyTitles';
import PricingBoard from '../../../../../Utilities/pricingBoard';
import { REACT_APP_BASE_URL } from '../../../../../../config/config';

import {
	usePayementMutation,
	useWalletPayementMutation,
	// useGetAssuranceByIdQuery,
	useGetOrCreateSuscriptionQuery,
	useAddBeneficiaryMutation,
	usePayementStatutMutation
} from '../../../../../../features/api/apiSlice';

import { setOperation } from '../../../../../../features/user/payement.slice';

import Membres from '../Beneficiaire/Membres';
import ConditionStep from '../ConditionStep';
import FormulaireStep from '../formulaire/FormulaireStep';
import Spinner from '../../../../../../UI/spinner/Spinner';
import Recapdocumentstep from '../Recapitulatif/Recapdocumentstep';

import SubscriptionNavigationButton from "../../../../../Utilities/subscriptionNavigationButtons/SubscriptionNavigationButton";
import { getModalProps, getModalType, initialModal, setModal } from '../../../../../../features/feedBack/modal.slice';
import Alert from '../../../../../../UI/alert';

// steps
const InitialSteps = {
	currentStep: '01',
	steps: [
		{ id: '01', name: 'Bénéficiaires', status: 'current' },
		{ id: '02', name: 'Questionnaire', status: 'upcoming' },
		{ id: '03', name: 'Recapdocument', status: 'upcoming' },
		{ id: '04', name: "Conditions d'utilisations", status: 'upcoming' }
	]
}

// step reducer
const stepReducer = (state, action) => {
  switch (action.type) {
    case 'COMPLETE':
		const changeStep = state.steps.map((item, index) => {
			if (index === action.step.index) {
				return {
					...item,
					status: 'complete'
				};
			};

			return {
				...item,
				status: 'upcoming'
			};
		});

		return {
			steps: changeStep,
			currentStep: action.step.id
		};
    case 'CURRENT':
		const nextStep = state.steps.map((item, index) => {
			if (index < action.step.index) {
				return {
					...item,
					status: 'complete'
				}
			} else if (index === action.step.index) {
				return {
					...item,
					status: 'current'
				}
			} else {
				return {
					...item,
					status: 'upcoming'
				}
			};
		});

		return {
			steps: nextStep,
			currentStep: action.step.id
		};
    default:
      	break;
  	}
};

const FormBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 5em;
	margin: auto;
	@media screen and (max-width: 640px) {
		gap: 2em;
	}
`;

const StyledBody = styled.div`
	width: 970px;
	min-width: 350px;
	display: flex;
	flex-direction: row;
	align-self: center;
	gap: 5em;
	@media screen and (max-width: 1023px) {
		width: 100%;
		flex-direction: column-reverse;
		align-items: center;
		align-self: unset;
		gap: 3em;
	}
`;

const FormContent = styled.div`
	width: 50%;
	flex-grow: 1;
	@media screen and (max-width: 1023px) {
		width: 100%;
	}
`;

const AssurancePreview = () => {
	const host = REACT_APP_API_CHAT_BASE_URL;
	const returnParams = useParams();
	
	// Alert hanldling constant -----------------------------------
    const dispatch = useDispatch();
	const modalType = useSelector(getModalType);
    const modalProps = useSelector(getModalProps);
    const closeAlert = useCallback(() => {
		dispatch(initialModal());
    }, [dispatch]);

	useEffect(() => {
        if(modalProps?.isOpen) {
            setTimeout(closeAlert, 5000);
        }

        return () => {
            clearTimeout();
        }
    }, [modalProps?.isOpen, closeAlert]);

	function setModalPopUp(text, status) {
        return (
            dispatch(
                setModal({
                    modalType: "user",
                    modalProps: {
                        isOpen: true,
                        status: status,
                        text: text
                    }
                })
            )
        )
    };
	// -------------------------------------------------------------
	
	const navigate = useNavigate();
	const location = useLocation();
	const locationParams = new URLSearchParams(location.search);
	const URLParams = [
		{name: "currentStep", value: locationParams.get('returnStep')},
		{name: "itemRef", value: locationParams.get('item_ref')},
		{name: "payementRef", value: locationParams.get('payment_ref')},
		{name: "status", value: locationParams.get('status')},
		{name: "transactionId", value: locationParams.get('transaction_id')},
		{name: "user", value: locationParams.get('user')},
		{name: "sign", value: locationParams.get('sign')}
	];

	// get payement request
	const {
		data: assurance,
		isSuccess,
		isFetching
	} = useGetOrCreateSuscriptionQuery(returnParams?.id);

	// get assurance details
	// const assuranceDetails = useGetAssuranceFullDetailsQuery(params?.id);

	const data = isSuccess ? assurance?.data : null;

	// Souscription process handle requests
	const [ AddBeneficiary, { isLoading: isAdding, isSuccess: addSuccess, isError: addingError } ] = useAddBeneficiaryMutation();

	// pay product insurance request
	const [payementFetch, { isLoading }] = usePayementMutation();
	const [payementStatusFetch, { isLoading: status_loading }] = usePayementStatutMutation();
	const [walletPayementFetch, { WP_isLoading }] = useWalletPayementMutation();
	const [totalPrice, settotalPrice] = useState(data?.cout || data?.assurance?.prix || 0);

	// insurance price
	const [IsLoading, setIsLoading] = useState(false);
	const [description, setdescription] = useState(data?.description);
	const [type_contrat, settype_contrat] = useState(data?.type_contrat);
	const [dure, setduree] = useState(data?.duree);
	const [nom, setnom] = useState(data?.nom);
	const [fournisseur, setfournisseur] = useState(data?.fournisseur);
	const [categorie, setcategorie] = useState(data?.categorie);
	const [methodePayement, setMethodePayement] = useState('CM_ORANGEMONEY');
	const [recever, setRecever] = useState([]);
	// form answer state
	const [formAnswers, setFormAnswers] = useState([]);
	// step state
	const [stepState, dispatchStep] = useReducer(stepReducer, InitialSteps);
	//next button state
	const [isNextButtonWorking, setIsNextButtonWorking] = useState(false);
	// step destructuration
	const { steps, currentStep } = stepState;
	// set step
	const setStep = (step, index) => {
		dispatchStep({ type: 'CURRENT', step: { ...step, index: index }})
	};

	let isReturn = false;

	
	function setParams(params) {
		if (params[0].value) {
			params.forEach((param) => {
				if (param.value) {
					localStorage.setItem(param.name, param.value);
				};
			});
			
			navigate(`/assurances/${returnParams?.id}`);
		};
	};

	function convertStatus(status) {
		if (status === "success") {
			return 1
		} else if (status === "failed") {
			return -1
		} else {
			return 0
		};
	};

	async function provideStatus() {
		if (localStorage.getItem('status')) {
			const data = {
				item_ref: localStorage.getItem('itemRef'),
				payment_ref: localStorage.getItem('payementRef'),
				payment_status: convertStatus(localStorage.getItem('status')),
				transaction_id: localStorage.getItem('transactionId'),
			};

			console.log("data", data);

			try {
				const response = await payementStatusFetch(data).unwrap();
				if (response.statut === "ok") {
					setModalPopUp("Souscriptoin validée", "success");
					navigate("/assurances/mes-produits");
				} else {
					setModalPopUp("La souscprition à échoué", "failed");
				};
			} catch (error) {
				setModalPopUp("La souscprition à échoué", "failed");
			}
		};
	};

	setParams(URLParams);

	useEffect(() => {
		provideStatus();
	}, []);

	useEffect(() => {
		const currentLocalStep = localStorage.getItem('currentStep');
		if (currentLocalStep) {
			setStep(steps[parseInt(currentLocalStep)], parseInt(currentLocalStep));
		};

		return () => {
			if (isReturn) {
				localStorage.setItem('currentStep', "0");
				localStorage.removeItem('itemRef');
				localStorage.removeItem('payementRef');
				localStorage.removeItem('status');
				localStorage.removeItem('transactionId');
				localStorage.removeItem('user');
				localStorage.removeItem('sign');
				console.log("composant démonté");
			};

			isReturn = true;
		};
	}, []);

	// Presetting all pré-needed data
	useEffect(() => {
		// Adding benéficiary if they exist
		if (data?.beneficiaires.length > 0) {
			setRecever(data.beneficiaires);
		};

		// Adding amount
		if (data?.cout && data?.cout !== 0) {
			settotalPrice(data.cout);
		} else if (data?.assurance?.prix) {
			settotalPrice(data.assurance.prix);
		}

		// Adding existing form answers
		if (data?.questionAnswers.length > 0) {
			setFormAnswers(data.questionAnswers);
		}
	}, [data]);

	// content
	let content;
	let image;
	let displayedEverywhere = {};

	switch (currentStep) {
		case '01':
			// member
			displayedEverywhere = {
				title: 'Qui sont les bénéficiaires ?',
				bodyText: [`Très bon choix, commençons à configurer votre produit.`],
				cumbTitle: 'Bénéficiaires',
				currentPage: 'beneficiaire',
				previousStep: -1,
				pricingMessage:
				'Chaque bénéficiaire ajouté entrainera un coût supplémentaire allant du coût de base après configuration de votre produit a plus selon les spécificités de chaque nouveau bénéficiaire.'
			};

			content = (
				<Membres
					setRecever={setRecever}
					recever={recever}
					setStep={setStep}
					steps={steps}
					setIsNextButtonWorking={setIsNextButtonWorking}
					setModalPopUp={setModalPopUp}
				/>
			);

			image = <img src={beneficiaire} alt='beneficiare' width='1500px' />
			break;
		case '02':
			// form
			displayedEverywhere = {
				title: 'Remplissage du formulaire',
				bodyText: [`Maintenant l'étape la plus importante;`],
				cumbTitle: 'Formlaire',
				currentPage: 'beneficiaire',
				previousStep: -1,
				pricingMessage:
				'Texte générique, valeur encore a fournir... Lorem ipsum dolor almet omni pas.'
			};

			content = (
				<FormulaireStep
					{...data}
					settotalPrice={settotalPrice}
					totalPrice={totalPrice}
					formAnswers={formAnswers}
					setIsLoading={setIsLoading}
					setIsNextButtonWorking={setIsNextButtonWorking}
					setModalPopUp={setModalPopUp}
				/>
			);

			image = <img src={form} alt='beneficiare' width='1500px' />
			break;
		case '03':
			// recapitulatif
			displayedEverywhere = {
				title: 'Fiche Produit',
				bodyText: [`Verifiez votre fiche produit avant de passer à l'étape suivante`],
				cumbTitle: 'Recapitulatif',
				currentPage: 'Recapitulatif',
				previousStep: -1,
				pricingMessage:
				'Texte générique, valeur encore a fournir... Lorem ipsum dolor almet omni pas.'
			};

			content = (
				<Recapdocumentstep
					{...data}
					setdescription={setdescription}
					setfournisseur={setfournisseur}
					setcategorie={setcategorie}
					// setfournisseur={setfournisseur}
					setnom={setnom}
					setduree={setduree}
					settype_contrat={settype_contrat}
					formAnswers={formAnswers}
					setIsNextButtonWorking={setIsNextButtonWorking}
				/>
			);

			image = <img src={recap} alt='recap' />
			break;
		case '04':
			// condition
			displayedEverywhere = {
				title: "Conditions d'utilisation",
				bodyText: [`Merci de prendre le temps de consulter la documentation !`],
				cumbTitle: 'Bénéficiaires',
				currentPage: 'beneficiaire',
				previousStep: -1,
				pricingMessage:
				'Texte générique, valeur encore a fournir... Lorem ipsum dolor almet omni pas.'
			};

			content = (
				<ConditionStep
					{...data}
					setStep={setStep}
					steps={steps}
					setIsNextButtonWorking={setIsNextButtonWorking}
					handlePayement={handlePayement}
					totalPrice={totalPrice}
					setModalPopUp={setModalPopUp}
				/>
			);

			image = <img src={terms} alt='terms' style={{ marginTop: '200px' }} />
			break;
		default:
			break;
	};

	// add beneficiary function
	async function handleAddBeneficiary() {
		const requestBody = {
			idSoucription: data?.idSouscription,
			members: { beneficiaires: recever.map((item) => item.idUtilisateur) }
		};

		try {
		  	const response = await AddBeneficiary(requestBody).unwrap();
	
		  	console.log("response :", response);
		  	setModalPopUp(response.message, "success");
		} catch (error) {
			console.log("error :", error);
		  	setModalPopUp(error?.data?.message ? error.data.message : "Une erreur est survenue", "failed");
		};
	  };

	// handle pay
	async function handlePayement (event, url, amount, idPayOption, payCanal, number) {
		event.preventDefault();
		let errorValue = false;

		// const beneficiaires = recever?.map(item => {
		// 	return item.code
		// });

		const payement = {
			idSouscription: data?.idSouscription,
			prix: amount || totalPrice || data.assurance?.prix,
			avance: amount,
			idAssurance: data?.assurance?.idAssurance,
			idPayOption: idPayOption,
			telephone: number,
			// pays: "",
			returnURL: `${REACT_APP_BASE_URL}${url}?returnStep=${localStorage.getItem('currentStep')}`,
			// codeReduction: "",
			operateur: payCanal || methodePayement,
			// form: formAnswers,
			// beneficiaires: beneficiaires,
			// returnStep: localStorage.getItem('currentStep')
		};

		if (payCanal === 'PORTE_FEUILLE') {
			// pay with pocket
			try {
				const payload = await walletPayementFetch(payement).unwrap();

				setModalPopUp("Opération réussi !", "success");
				navigate(url);
				// waiting thing i can do with the value of payload
			} catch (err) {
				errorValue = err;
				setModalPopUp("Une erreur est survenue, réessayez plutard !", "failed");
				throw new Error('Erreur');
			};
		} else {
			// pay whithout pocket
			try {
				const payload = await payementFetch(payement).unwrap();
				dispatch(setOperation('souscription'));
				setModalPopUp("Ouverture de la page en cours, veuillez patienter...", "success");
				window.open(payload.data.url, '_self');
			} catch (err) {
				console.log("paying response :", err)
				errorValue = err;
				setModalPopUp("Une erreur est survenue, réessayez plutard !", "failed");
				throw new Error('Erreur');
			};
		};

		return errorValue;
	};

	const handleClick = () => {
		// Code à exécuter lorsque le composant BreadCumbs est cliqué
		navigate(displayedEverywhere.previousStep)
	};

	return (
		<div className="mt-2  h-full">
			{/* process header  */}
			<div className="hidden">
				<ProcessHeader steps={steps} setStep={setStep} />
			</div>
			
			{isFetching ? (
				<div className="flex items-center justify-center h-full">
					<Spinner className="h-10 w-10 text-primary-700 m-8" />
				</div>
			) : (
				<div className="w-full">
					<BackIllustration />

					<BreadCumbs
						title={displayedEverywhere.cumbTitle}
						onClick={handleClick}
						currentPage={displayedEverywhere.currentPage}
					/>

					<FormBody>
						<SubscriptionBodyTitles
							bodyText={displayedEverywhere.bodyText}
							title={displayedEverywhere.title}
						/>

						<StyledBody>
							<FormContent>
								{content}

								<SubscriptionNavigationButton
									steps={steps}
									setStep={setStep}
									isNextButtonWorking={isNextButtonWorking}
									handleAddBeneficiary={{ handleAddBeneficiary, isAdding, addSuccess }}
									handlePayStatus={{ provideStatus, status_loading }}
								/>
							</FormContent>
							
							{/* pay card  */}
							<PricingBoard
								totalPrice={totalPrice}
								data={data}
								currentStep={currentStep}
								IsLoading={IsLoading}
								handlePayement={handlePayement}
								image={beneficiaire}
								message={displayedEverywhere.pricingMessage}
							/>
						</StyledBody>
					</FormBody>
				</div>
			)}

			{/* insert toast here */}
			{modalType === "user" && modalProps?.isOpen ? (
				<div className="px-4 fixed top-5 z-50 w-2/5 inset-x-0 mx-auto">
					<Alert
						alert={{text: modalProps?.text, error: modalProps?.status === "failed" ? true : false}}
						closeAlert={closeAlert}
					/>
				</div>
			) : null}
		</div>
	);
};

export default AssurancePreview;
