/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  consultation preview
 * @component MesRendezVousPage
 */
import Main from "../../../components/layouts/main";
import SingleConsultation from "../../../components/layouts/main/consultations/doctor/SingleConsultation";

const MesRendezVousPage = (props) => {
	return <Main children={<SingleConsultation />} />;
};

export default MesRendezVousPage;
