/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component RadioGroup
 * @description composant qui affiche une question à choix multiple pour un formulaire
 * @props options, question, onChange, id
 * @
 */

import { useState } from "react";

export default function CheckBox({ question, options, onChange }) {
	return (
		<div>
			{/* label  */}
			<label className="text-base font-medium text-gray-900">
				{question}
			</label>

			<fieldset className="space-y-5 mt-4">
				<legend className="sr-only">{question}</legend>

				{/* inputs de type checkbox  */}
				{options.map((option, index) => {
					return (
						<div
							key={option.id}
							className="relative flex items-start"
						>
							<div className="flex h-5 items-center">
								<input
									id={option?.id || option.label}
									name={option.label}
									type="checkbox"
									onChange={onChange}
									// checked={option.selected ? "checked" : undefined}
									className="h-4 w-4 rounded border-gray-300 text-secondary-600 focus:ring-secondary-500"
								/>
							</div>
							<div className="ml-3 text-sm">
								<label
									htmlFor={option?.id || option.label}
									className=" text-gray-700">
									{option.label}
								</label>
							</div>
						</div>
					);
				})}
			</fieldset>
		</div>
	);
}
