import styled from "styled-components";

const StyledH1 = styled.h1`
    font-weight: 700;
    font-size: 5em;
    @media screen and (max-width: 1023px) {
        font-size : 4em;
    }
`

function PageTitle({ content }) {

    return (
        <StyledH1
            className='text-primary-800'
        >
            { content }
        </StyledH1>
    )
};

export default PageTitle;
