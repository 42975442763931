import styled from "styled-components";
import ControllableStates from "./AutocompleteFilter";

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3em;
    @media screen and (max-width: 1023px) {
        flex-direction: column-reverse;
        gap: 1em;
    }
`;

const Illustration = styled.img`
    width: 30em;
    height: auto;
    scale: 1.2;
    @media screen and (max-width: 1023px) {
        scale: 1.1;
    }
    @media screen and (max-width: 500px) {
        scale: 1;
    }
`;

const StyledMessage = styled.p`
    text-align: justify;
    margin: 0 0 2em 0;
    font-size: 1.5em;
    font-weight: 400;
`;

const FilterContainer = styled.div`
    width: 40em;
    z-index: 40;
    @media screen and (max-width: 1023px) {
        width: 45em;
    }
    @media screen and (max-width: 479px) {
        width: 100%;
    }
`;

function SubscriptionBodyFilterIllustrated({ setQueryData, data, filterProperty, filterLabel, filterIllustration, message }) {

    return (
        <Container>
            <FilterContainer>
                <StyledMessage>{ message }</StyledMessage>
                <ControllableStates setQueryData={setQueryData} data={data} filterProperty={filterProperty} filterLabel={filterLabel} />
            </FilterContainer>
            
            <Illustration src={filterIllustration} alt="Fillter illustration" />
        </Container>
    );
};

export default SubscriptionBodyFilterIllustrated;
