import {  useEffect, useState } from "react";
import Input from "../../../../../../UI/form/Input";
import TextArea from "../../../../../../UI/form/TextArea";
import RadioGroup from "../../../../../../UI/form/RadioGroup";
import CheckBox from "../../../../../../UI/form/CheckBox";
import { handleInputValueChange, handleCurrentChange, handleResponseObject, handleSbumitOption } from "./logic";
import styled from "styled-components";
import Button from "../../../../../../UI/button/Button";

const OptionContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    @media screen and (max-width: 640px) {
        align-items: normal;
		flex-direction: column;
        gap: 2px;
	}
`;

const StyledButton = styled(Button)`
    color: white;
    padding: auto;
    align-items: center;
    margin: 10px 0;
    @media screen and (max-width: 640px) {
        width: 100%;
	}
`;

function FormStepOptions({ item, index, setResponses, responses, formAnswers, setSubQuestions, subQuestions, setIsQuestionLoading, AnswerQuestion, id_souscription, setIsStepOk, setSubmitReady, submitReady, setSupplement, supplement, setModalPopUp }) {
    const [currentChanges, setCurrentChanges] = useState({});
    const [enteredValues, setEnteredValues] = useState({});
    const [currentOption, setCurrentOption] = useState({});
    const [submittionError, setSubmittionError] = useState({});

    console.log("Answering error :", submittionError);

    useEffect(() => {
        if (item.isRequired === true) {
            const oneSelected = item.options.find(option => option.selected === true);
            
            if (oneSelected === undefined && enteredValues.id === undefined) {
                setIsStepOk(false);
            };
        } else {
            setIsStepOk(true);
        };
    }, [item, enteredValues]);
    
    useEffect(() => {
        handleResponseObject(index, enteredValues, currentOption, responses, setResponses);
    }, [enteredValues, currentOption]);

    useEffect(() => {
        if (submitReady) {
            handleSbumitOption(item.idQuestion, setIsQuestionLoading, responses, setSupplement, AnswerQuestion, id_souscription, supplement, setEnteredValues, setSubmittionError, setModalPopUp);
            setSubmitReady(false);
        }
    }, [responses]);
    
    // console.log("entered values :", enteredValues);
    // console.log("current option :", currentOption);
    // console.log("responses :", responses);
    // console.log("submit ready :", submitReady);
    // console.log("sub-question :", subQuestions);
    // console.log("supplement :", supplement);
    
    // form preview
    const formPreview = (item, index) => {
        switch (item.fieldType) {
            case "text":
                const textAnswer = formAnswers.find((answer) => answer.question.idQuestion === item.idQuestion);

                return (
                    <>
                        {item.options.map((option) => (
                            <OptionContainer key={option.idOption}>
                                <Input
                                    key={`input-${option.idOption}`}
                                    onChange={(event) =>
                                        handleCurrentChange(event, setCurrentChanges, item.idQuestion )
                                    }
                                    placeholder={option.label}
                                    defaultValue={textAnswer ? textAnswer.choix.label : undefined}
                                    input={{
                                        id: option.label,
                                        type: `${item.fieldType}`,
                                    }}
                                    name={option.label}
                                    disabled={false}
                                    required={false}
                                />

                                <StyledButton
                                    className="bg-primary-600"
                                    key={`button-${option.idOption}`}
                                    onClick={() => {
                                        handleInputValueChange({
                                            currentChanges: currentChanges,
                                            setEnteredValues: setEnteredValues,
                                            setIsQuestionLoading: setIsQuestionLoading,
                                            setSubmitReady: setSubmitReady
                                        });

                                        setCurrentOption(option);
                                        if (option.subquestions) {
                                            option.subquestions.forEach((subQuestion) => {
                                                if (!subQuestions.includes(subQuestion)) {
                                                    setSubQuestions(option.subquestions);
                                                }
                                            });
                                        };
                                    }}
                                >
                                    Valider
                                </StyledButton>
                            </OptionContainer>
                        ))}
                    </>
                );
            case "textarea":
                const textAreaAnswer = formAnswers.find((answer) => answer.question.idQuestion === item.idQuestion);

                return (
                    <>
                        {item.options.map((option) => (
                            <div key={option.id}>
                                <TextArea
                                    key={`textArea-${option.id}`}
                                    name={option.label}
                                    rows="4"
                                    placeholder={option.label}
                                    defaultValue={textAreaAnswer ? textAreaAnswer.choix.label : undefined}
                                    id={option.label}
                                    onChange={(event) =>
                                        handleCurrentChange(event, setCurrentChanges, item.id_question )
                                    }
                                />

                                <StyledButton
                                    className="bg-primary-600"
                                    key={`button-${option.id}`}
                                    onClick={() => {
                                        handleInputValueChange({
                                            currentChanges: currentChanges,
                                            setEnteredValues: setEnteredValues,
                                            setIsQuestionLoading: setIsQuestionLoading,
                                            setSubmitReady: setSubmitReady
                                        });

                                        setCurrentOption(option);
                                        if (option.subquestions) {
                                            option.subquestions.forEach((subQuestion) => {
                                                if (!subQuestions.includes(subQuestion)) {
                                                    setSubQuestions(option.subquestions);
                                                }
                                            });
                                        };
                                    }}
                                >
                                    Valider
                                </StyledButton>
                            </div>
                        ))}
                    </>
                );
            case "radio":
                const radioAnswer = formAnswers.find((answer) => answer.question.idQuestion === item.idQuestion);

                const newOptions = item?.options.map((option) => {
                    if (radioAnswer?.choix.idOption === option.idOption) {
                        return { ...option, selected: true }
                    } else {
                        return { ...option, selected: false }
                    };
                });

                return (
                    <RadioGroup
                        question={item.question}
                        options={newOptions}
                        id={`${item.idQuestion}`}
                        onChange={(event) => {
                            handleInputValueChange({
                                event: event,
                                setEnteredValues: setEnteredValues,
                                options: newOptions,
                                setCurrentOption: setCurrentOption,
                                setSubQuestions: setSubQuestions,
                                subQuestions: subQuestions,
                                id_question: item.idQuestion,
                                setIsQuestionLoading: setIsQuestionLoading,
                                setSubmitReady: setSubmitReady
                            });
                        }}
                    />
                );
            case "checkbox":
                return (
                    <CheckBox
                        options={item.options}
                        // question={item.question}
                        key={`${item.idQuestion}`}
                        onChange={(event) => {
                            handleInputValueChange({
                                event: event,
                                setEnteredValues: setEnteredValues,
                                options: item.options,
                                setCurrentOption: setCurrentOption,
                                setSubQuestions: setSubQuestions,
                                subQuestions: subQuestions,
                                id_question: item.idQuestion,
                                setIsQuestionLoading: setIsQuestionLoading,
                                setSubmitReady: setSubmitReady
                            });
                        }}
                    />
                );
    
            default:
                break;
        }
    };

    return (
        <div
            key={index}
            style={{ margin: "10px 0" }}
        >
            {formPreview(item, index)}
        </div>
    );
};

export default FormStepOptions;
