/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add chat group modal
 * @component NewChatGroupModal
 */
import { Fragment, useEffect, useState } from "react";
import Input from "../../../../UI/form/Input";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import {
	CheckIcon,
	ChevronUpDownIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../../../styles";
import Spinner from "../../../../UI/spinner/Spinner";
import Button from "../../../../UI/button/Button";
import {
	useAddChatGroupMutation,
	useGetAllUsersQuery,
} from "../../../../features/api/apiSlice";

const NewChatGroupModal = ({ setopenNewChatGroup, openNewChatGroup }) => {
	// get users request
	const { data: users, isLoading, isSuccess } = useGetAllUsersQuery();

	// add chat group request
	const [addChat, { isLoading: addchatIsLoading }] =
		useAddChatGroupMutation();

	const [query, setQuery] = useState("");
	const [usersData, setusersData] = useState([]);
	const [usersToAdd, setusersToAdd] = useState([]);
	const [data, setdata] = useState({});
	const [selectedUser, setselectedUser] = useState(null);

	useEffect(() => {
		if (isSuccess) {
			const newUsers = users.data.map((items) => {
				return {
					name: `${items.nom} ${items.prenom}`,
					code: items.code,
				};
			});

			setusersData(newUsers);
		}

		return () => {};
	}, [isSuccess, users]);

	const filterdUser =
		query === ""
			? usersData
			: usersData.filter((user) => {
					return user.name
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	const handleChange = (e) => {
		const { name, value } = e.target;

		setdata((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	// select user
	const handleChangeselectedUser = (value) => {
		const isValue = usersToAdd.find((item) => item.code === value.code);

		if (isValue?.code === value.code) {
			setselectedUser(isValue);
		} else {
			setusersToAdd((prevState) => {
				return [...prevState, value];
			});
			setselectedUser(value);
		}
	};

	const handleCloseModal = () => {
		setopenNewChatGroup(false);
	};

	// remove user
	const removeUserHandleClick = (value) => {
		setusersToAdd((prevState) => {
			return prevState.filter((item) => item.code !== value.code);
		});
	};

	// add chat group
	const newChatGroupHandleClick = (e) => {
		e.preventDefault();

		const destinataire = usersToAdd.map((item) => {
			return item.code;
		});

		addChat({ destinataire, name: data.name })
			.unwrap()
			.then((res) => {
				handleCloseModal();
			})
			.catch((err) => {
				// to do
			});
	};

	return (
		<>
			<Transition.Root
				show={openNewChatGroup}
				as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={setopenNewChatGroup}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<Dialog.Panel
									as="form"
									onSubmit={newChatGroupHandleClick}
									className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
										<button
											type="button"
											className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={() =>
												setopenNewChatGroup(false)
											}>
											<span className="sr-only">
												Close
											</span>
											<XMarkIcon
												className="h-6 w-6"
												aria-hidden="true"
											/>
										</button>
									</div>
									<Dialog.Title
										as="h1"
										className="text-xl font-semibold mb-2">
										Créer un nouveau groupe
										<p className="mt-2 text-sm text-gray-700">
											Veillez selectionner les
											utilisateurs à ajouter au groupe
										</p>
									</Dialog.Title>

									<div className="space-y-4 my-6">
										<Input
											label="Nom du groupe"
											onChange={handleChange}
											input={{
												id: "name",
												type: "text",
												maxLength: "250",
											}}
											name="name"
											placeholder="Entrer le nom du groupe"
											required={true}
										/>

										{isSuccess && (
											<Combobox
												as="div"
												value={selectedUser}
												onChange={
													handleChangeselectedUser
												}>
												<Combobox.Label className="block text-sm font-medium text-gray-700">
													Saisir ou selectionner les
													utilisateurs
												</Combobox.Label>
												<div className="relative mt-1">
													<Combobox.Input
														placeholder="Veillez saisir ou selectionner l'utilisateur "
														className="w-full rounded-sm border border-gray-300 bg-white py-3 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
														onChange={(event) =>
															setQuery(
																event.target
																	.value
															)
														}
														displayValue={(user) =>
															user?.name
														}
													/>
													<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
														<ChevronUpDownIcon
															className="h-5 w-5 text-gray-400"
															aria-hidden="true"
														/>
													</Combobox.Button>

													{filterdUser.length > 0 && (
														<Combobox.Options className="absolute z-50 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
															{filterdUser.map(
																(user) => (
																	<Combobox.Option
																		key={
																			user.code
																		}
																		value={
																			user
																		}
																		className={({
																			active,
																		}) =>
																			classNames(
																				"relative cursor-default select-none py-2 pl-8 pr-4",
																				active
																					? "bg-secondary-600 text-white"
																					: "text-gray-900"
																			)
																		}>
																		{({
																			active,
																			selected,
																		}) => (
																			<>
																				<span
																					className={classNames(
																						"block truncate",
																						selected &&
																							"font-semibold"
																					)}>
																					{
																						user.name
																					}
																				</span>

																				{selected && (
																					<span
																						className={classNames(
																							"absolute inset-y-0 left-0 flex items-center pl-1.5",
																							active
																								? "text-white"
																								: "text-secondary-600"
																						)}>
																						<CheckIcon
																							className="h-5 w-5"
																							aria-hidden="true"
																						/>
																					</span>
																				)}
																			</>
																		)}
																	</Combobox.Option>
																)
															)}
														</Combobox.Options>
													)}
												</div>
											</Combobox>
										)}

										<ul className=" flex gap-2 flex-wrap items-center">
											{usersToAdd?.map((item, index) => {
												return (
													<li
														key={index}
														className="flex items-center  bg-secondary-100 group px-3 py-1 rounded-2xl gap-x-2">
														<span className="flex items-center text-xs text-secondary-900">
															{item.name}
														</span>

														<XMarkIcon
															onClick={removeUserHandleClick.bind(
																null,
																item
															)}
															title="Retirer l'utilisateur"
															className=" hover:text-secondary-900 text-secondary-700 cursor-pointer h-4 w-4 hover:scale-105"
														/>
													</li>
												);
											})}
										</ul>
									</div>

									<div className=""></div>

									{isLoading && (
										<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
									)}

									<div className=" flex items-center justify-end  gap-x-6">
										<Button
											children="Annuler"
											type="button"
											className="max-w-max px-6"
											onClick={() => handleCloseModal()}
										/>
										<Button
											children="Creer le groupe"
											className="max-w-max px-6"
											button="secondary"
											loading={addchatIsLoading}
											type="submit"
										/>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default NewChatGroupModal;
