// status classeName
export const statusClassName = (statusTransaction) => {
	switch (statusTransaction) {
		case "Actif":
			return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
		case "Désactivé":
			return "inline-flex rounded-full bg-red-100 px-2 my-4 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap ";
		case "Initié":
			return "inline-flex rounded-full bg-blue-100 px-2 my-4 text-xs font-semibold leading-5 text-blue-800 whitespace-nowrap ";

		default:
			break;
	}
};
