/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance product card
 * @component ProduitCard
 */

import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import ActionMenuCicle from "../../../../../../UI/actionMenuCircle";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: 8px;
	${({overflow}) => !overflow ? "overflow: hidden;" : ""}
	@media screen and (max-width: 800px) {
		flex-direction: column;
	}
`;

const Cover = styled.img`
	width: 200px;
	height: 150px;
	cover: fit;
	background: white;
	@media screen and (max-width: 800px) {
		display: none;
	}
`;

const CardBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: white;
	flex-grow: 1;
	padding: 2em 3em;
	min-width: 300px;
	@media screen and (max-width: 800px) {
		gap: 2em;
	}
`;

const StyledH2 = styled.h2`
	font-size: 22px;
	font-weight: 600;
`;

const StyledH3 = styled.h3`
	font-size: 20px;
	font-weight: 500;
	color: gray;
`;

const CardStatus = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media screen and (max-width: 800px) {
		flex-direction: column;
		align-items: center;
	}
`;

const Status = styled.p`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	color: ${({status}) => status === "active" ? "green" : status === "warning" ? "#FFA500" : "#FF0000"};
`;

const StatusIcon = styled(MinusCircleIcon)`
	width: 20px;
	margin-right: 5px;
`;

const Validity = styled.p`
	font-size: 15px;
	font-weight: 400;
`;

function ProduitCard ({ titre, code, images, id, isTotalCard=true, restants }) {
	// const currency = formatPrix(prix);
	const [isActionOpen, setIsActionOpen] = useState(false);
	const navigate = useNavigate();

	function statusProvider(daysLeft) {
		if (daysLeft > 10) {
			return {
				status: "active",
				value: "Active"
			};
		} else if (daysLeft <= 10 && daysLeft > 0) {
			return {
				status: "warning",
				value: "Expire bientôt"
			}
		} else {
			return {
				status: "expired",
				value: "Expirée"
			}
		};
	};

	function onDetailsClick(event) {
		event.preventDefault();
		navigate(`/assurances/details/${id}`);
	};

	return (
		<Container
			overflow={isTotalCard}
			onClick={() => setIsActionOpen(false)}
		>
			<Cover src={images} alt="suscription cover" />

			<CardBody>
				<div>
					<StyledH2>{titre}</StyledH2>
					<StyledH3><span style={{ fontWeight: 700 }}>Code :</span> {code}</StyledH3>
				</div>

				<CardStatus>
					<Status status={statusProvider(restants).status}>
						<StatusIcon />
						{statusProvider(restants).value}
					</Status>
					<Validity><span style={{ fontWeight: 600 }}>Validité :</span> {restants} jour(s)</Validity>
				</CardStatus>
			</CardBody>

			{isTotalCard && (
				<ActionMenuCicle
					title="Actions"
					children={[
						{title: "Détails", icon: null, onButtonClicked: onDetailsClick},
						{title: "Echéancier", icon: null, onClick: null},
						{title: "Renouveller", icon: null, onClick: null}
					]}
					isActionOpen={isActionOpen}
					setIsActionOpen={setIsActionOpen}
				/>
			)}
		</Container>
	);
};

export default ProduitCard;
