/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description question card
 * @component Question
 */
import React, { useEffect, useState } from "react";
import SwitchInput from "../../../../../UI/form/SwitchInput";
import { HiOutlineTrash, PlusIcon, CheckIcon } from "../../../../../UI/svg";
import SelectLabel from "./SelectLabel";
import SelectPriceModification from "./SelectPriceModification";
import { useDispatch } from "react-redux";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { useAddNewQuestionMutation } from "../../../../../features/api/apiSlice";
import { setModalPopUp } from "../../../layoutLogics";
import Spinner from "../../../../../UI/spinner/Spinner";

const Question = ({ index, questions, setQuestions, question, assurance }) => {
	const dispatch = useDispatch();
	const [currentQuestion, setCurrentQuestion] = useState({});

	const [addQuestion, { isLoading: addingLoading }] = useAddNewQuestionMutation();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(addingLoading);
	}, [addingLoading]);

	// add new empty question to the list
	function addQuestionToListHandler() {
		const newQuestions = questions.slice();
		newQuestions.splice(newQuestions.length - 1, 0, currentQuestion);
		setQuestions(newQuestions);
		setCurrentQuestion({
			id: 0,
			submited: false,
			libelle: "",
			fieldType: "text",
			tarifType: "Addition",
			isRequired: false,
			description: "",
			options: [
				{
					label: "",
					prix: 0,
					format: null,
					subquestions: []
				}
			]
		})
	};
	
	// submit request question to the database
	async function submitQuestionToListHandler() {
		try {
			const response = await addQuestion(currentQuestion).unwrap();

			if (response.statut === "ok") {
				setModalPopUp(dispatch, "Reponse soumise avec success", "success");
				setCurrentQuestion({
					...currentQuestion,
					id: response.data.idQuestion,
					submited: true
				});
			} else {
				setModalPopUp(dispatch, response?.data?.message && typeof response.data.message === "string" ? response.data.message : "Une erreur est survenue! veuilez rééssayer plus tard.", "failed");
			}
		} catch (error) {
			setModalPopUp(dispatch, error?.data?.message && typeof error.data.message === "string" ? error.data.message : "Une erreur est survenue! veuilez rééssayer plus tard.", "failed");
		}
	};

	// edit submited question
	function editQuestionToListHandler() {
		setCurrentQuestion({
			...currentQuestion,
			submited: false
		})
	};

	// delete question to the list
	function deleteQuestionToListHandler() {
		if (questions.length > 1) {
			const newQuestions = questions.filter((currQuestion) => currQuestion.id !== question.id);
			setQuestions(newQuestions);
		} else {
			console.log("Il s'agit de la dernière question, modifiez là");
		};
	};

	useEffect(() => {
		setCurrentQuestion(question);
	}, [question]);

	return (
		<div className="grid grid-cols-4 gap-6 p-0 ">
			<div className="col-span-4 lg:col-span-4 border border-gray-50 shadow-md border-l-4 border-l-secondary-500 rounded md:p-4 p-2 divide-y divide-gray-300 bg-white">
				{/* selected question type  */}
				<SelectLabel
					setCurrentQuestion={setCurrentQuestion}
					currentQuestion={currentQuestion}
				/>

				<div className="flex justify-between items-start sm:items-center pt-4 sm:flex-row flex-col  ">
					<div className="font-medium text-sm flex items-center text-gray-700">
						<span>Modifier le prix initial par:</span>{" "}
						<div>
							{/* price  */}
							<SelectPriceModification
								setCurrentQuestion={setCurrentQuestion}
								currentQuestion={currentQuestion}
							/>
						</div>{" "}
					</div>
					<div className="flex  items-center md:divide-x divide-gray-300">
						<div className="flex items-center  justify-between mr-4 mt-6 sm:mt-0">
							{/* delete question  */}
							<HiOutlineTrash
								className="h-6 w-6 mx-2 text-gray-500 hover:text-gray-700 cursor-pointer"
								onClick={deleteQuestionToListHandler}
								title="supprimer cette question de la liste pour cette assurance"
							/>

							{/* add question or edit  */}
							{currentQuestion?.submited ? (
								<PencilSquareIcon
									className="h-6 w-6 mx-2 text-gray-500 hover:text-gray-700 cursor-pointer"
									onClick={editQuestionToListHandler}
									title="Modifier cette question"
								/>
							) : (
								isLoading ? (
									<Spinner className="h-6 w-6 text-primary-700 m-auto h-full" />
								) : (
									<CheckIcon
										className="h-6 w-6 mx-2 text-gray-500 hover:text-gray-700 cursor-pointer"
										onClick={submitQuestionToListHandler}
										title="valider et soumettre cette question"
									/>
								)
							)}

							{/* create question  */}
							{(questions.length === index + 1 && currentQuestion?.submited) && (
								<PlusIcon
									className="h-6 w-6 mx-2 text-gray-500 hover:text-gray-700 cursor-pointer"
									onClick={addQuestionToListHandler}
									title="Ajouter une nouvelle question"
								/>
							)}
						</div>
						{/* is required  */}
						<SwitchInput
							setCurrentQuestion={setCurrentQuestion}
							currentQuestion={currentQuestion}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Question;
