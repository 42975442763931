import React, { useCallback, useState } from "react";
import { Link, useLocation, } from "react-router-dom";
import Pagination from "../../../../UI/pagination";
import UserDetailTransaction from "./UserDetailTransaction";
import { useGetTransactionsByUserQuery } from "../../../../features/api/apiSlice";
import Spinner from "../../../../UI/spinner/Spinner";
import usePagination from "../../../../hooks/usePagination";
import { classNames } from "../../../../styles";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import styled from "styled-components";
import SubscriptionBodyTitles from "../../../Utilities/subscriptionBodyTitles";

const Container = styled.div`
	display: flex;
	flex-direction: column;
    gap: 5em;
	// height: 100%;
`;

const StyledBody = styled.div`
    min-width: 350px;
	// height: 100%;
    // display: flex;
    // flex-direction: column;
    // // align-items: center;
    // gap: 3em;
    // @media screen and (max-width: 1023px) {
    //     width: 100%;
    // }
`;

const UserTransaction = () => {
	// location hook
	const { hash } = useLocation();
	const id_transaction = hash.substring(1);

	// transaction request
	const {
		data: transactions,
		isLoading,
		isSuccess,
	} = useGetTransactionsByUserQuery();
	const [selectedTransaction, setselectedTransaction] = useState(null);
	//data tabele
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const renderHeader = () => {
		return (
			<div className="flex justify-content-end">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
				</span>
			</div>
		);
	};
	const statusClassName = (statusTransaction) => {
		switch (statusTransaction) {
			case "En Cours":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "Initiée":
				return "inline-flex rounded-full bg-red-100 px-2 my-4 text-xs font-semibold leading-5 text-gray-800 whitespace-nowrap";
			case "Terminée":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "Echouée":
				return "inline-flex rounded-full bg-green-1000 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			default:
				break;
		}
	};
	const detailTemplate = (rowData) => {
		return (
			<Link
				to={`#${rowData.idTransaction}`}
				className="text-secondary-600 hover:text-secondary-900"
			>
				<div className="text-center">
					detail
					<span className="sr-only">
						{
							rowData.idTransaction
						}
					</span>
				</div>
			</Link>
		)
	}
	const dateTemplet = (rowData) => {
		return (
			<div> {rowData.dateCreation} {""} {rowData.createdAt}</div>
		)
	}
	const statusItemTemplate = (rowData) => {
		return (<div className={statusClassName(rowData.etat)}>
			{rowData.etat}
		</div>
		)
	};

	const itemsPerPage = 10;


	const header = renderHeader();

	// pagination hook
	const {
		next,
		prev,
		jump,
		currentData,
		currentPage,
		maxPage,
		dataLength,
		beginItem,
		endItem,
	} = usePagination(transactions?.data, itemsPerPage);

	console.log("transaction table :", transactions?.data);

	return (
		<Container>
			<SubscriptionBodyTitles
				bodyText={[`Gardez une traçe de toutes vos transactions`]}
				title={`Mes transactions`}
			/>

			<StyledBody>
				<div className="px-4 sm:px-6 lg:px-8 h-full">
					{/* loader componenet */}
					{isLoading && (
						<div className="flex justify-center text-center items-center h-full">
							<Spinner className="h-10 w-10 text-primary-700" />
						</div>
					)}

					{isSuccess && (
						<div className="card">
							<DataTable value={transactions?.data} paginator showGridlines selectedTransaction="checkbox" selection={selectedTransaction} onSelectionChange={(e) => setselectedTransaction(e.value)} rows={10}
								filters={filters} globalFilterFields={['nom', 'statut']} header={header}
								emptyMessage="Aucune transaction .">
								<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
								<Column header="TransactionId" field="idTransaction" style={{ minWidth: '12rem' }} />
								<Column header="Libele" field="motif" style={{ minWidth: '10rem' }} />
								<Column header="Date" sortable body={dateTemplet} style={{ minWidth: '12rem' }} />
								<Column header="Statut" sortable style={{ minWidth: '12rem' }} body={statusItemTemplate} />
								<Column header="" body={detailTemplate} style={{ minWidth: '12rem' }} />
							</DataTable>
						</div>
					)}

					{(!isSuccess && !isLoading) && (
						<div className="border-b border-gray-900/10 pb-12">
							<h2 className="text-base font-semibold leading-7 text-gray-900">O'ops</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">Nous ne trouvons aucune transaction éffectuée vous consernant</p>
						</div>
					)}
				</div>

				{/* transaction detail and reglement  */}
				{transactions?.data?.length > 0 && hash && (
					<UserDetailTransaction />
				)}
			</StyledBody>
		</Container>
	);
};

export default UserTransaction;
