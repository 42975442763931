import Button from "../../../../../../UI/button/Button";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import styled from "styled-components";

const StyledButton = styled(Button)`
    color: white;
    padding: auto;
    align-items: center;
`;

function FormStepNavigation({ item, index, handleBack, handleSbumitOption, formLength, setActiveStep, isStepOk }) {

    return (
        <div className="flex justify-between">
            <button className="rounded-md flex justify-center py-2 px-4 text-sm font-medium text-secondary-600 hover:text-secondary-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 z-10 disabled:opacity-75 disabled:cursor-not-allowed"
                disabled={index === 0 || !isStepOk}
                onClick={handleBack}
            >
                <KeyboardDoubleArrowLeft />
            </button>

            <StyledButton
                className="bg-secondary-600"
                onClick={() => {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
                disabled={!isStepOk}
            >
                {index === formLength - 1 ? "TERMINER" : "SUIVANT"}
            </StyledButton>
        </div>
    );
};

export default FormStepNavigation;
