/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add skill
 * @component AddCompetence
 */
import {
	
    useGetSkillsQuery,
	useUpdateSkillMutation,
} from "../../../../../features/api/apiSlice";
import Select from "../../../../../UI/form/Select";
import TextArea from "../../../../../UI/form/TextArea";
import Button from "../../../../../UI/button/Button";
import Spinner from "../../../../../UI/spinner/Spinner";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate ,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModalProps, getModalType, initialModal, setModal } from "../../../../../features/feedBack/modal.slice";
import { Alert } from "../../UserRecharge/Recharge";
import { isExists } from "date-fns";


const UpdateCompetence = () => {

    const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);

    const {id} = useParams();
    // get skill request
     const { data: skills, isLoading } = useGetSkillsQuery();
    
     //get skill to update
     const existingSkill = skills?.data?.filter(skill => skill.id_skill === id)
        // skill state
     const [skill, setSkill] = useState(existingSkill[0]);
     
     const dispatch = useDispatch();

    // add skill request
	const [updateskill, { isLoading: loading }] = useUpdateSkillMutation();


	// handle change
	const handleChange = (e) => {
        
		const { name, value } = e.target;

		setSkill((prevState) => {
            
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	const navigate = useNavigate();

	

	// add skill
	const handleSubmit = (e) => {
		 e.preventDefault();
        
        // const response= Axios.post()
        //                 .then((response =>response.data),
        //                 console.log(response.data))
        updateskill({ id: skill.id_skill , data: skill} )
        .unwrap()
			.then((res) => {
				// alert skill we add new skill
				dispatch(
					setModal({
						modalType: "skill",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `La competence ${skill.nom} a modifiée avec succès`,
						},
					})
				);
                
				navigate("/consultations/les-competences");
                
			})
			.catch((err) => {});
		
	};

	return (
        <>


            {/* alert componenet  */}
			{/* {modalType === "skill" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null} */}

            <div>
                <h2 className=" text-center py-4 sm:py-10 text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl">
                    Modifier une competence.
                </h2>
                <form className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Modifier une compétence 
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Bien vouloir modifier la compétence
                                    correspondante
                                </p>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                            

                                {/* nom  */}
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="description"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Nom de la competence
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            value={skill.nom}
                                            type="text"
                                            name="nom"
                                            id="nom"
                                            onChange={handleChange}
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>

                                {/* description  */}
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="description"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Description
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <TextArea
                                            name="description"                                            
                                            value={skill.description}
                                            id="description"
                                            onChange={handleChange}
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="bg-gray-50 flex justify-end px-4 py-3 text-right sm:px-6">
                            <Button
                                children="Modifier"
                                type="submit"
                                onClick={handleSubmit}
                                button="primary"
                                className=" max-w-max"
                                loading={loading}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
	);
};

export default UpdateCompetence;
