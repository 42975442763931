/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add incident chat modal
 * @component IncidentModal
 */
import { XMarkIcon } from "@heroicons/react/24/solid";
import Input from "../../../../UI/form/Input";
import Select from "../../../../UI/form/Select";
import Spinner from "../../../../UI/spinner/Spinner";
import Button from "../../../../UI/button/Button";
import TextArea from "../../../../UI/form/TextArea";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import {
	useAddIncidentsMutation,
	useGetTypeIncidentQuery,
} from "../../../../features/api/apiSlice";
import { useDispatch } from "react-redux";
import { setModalPopUp } from "../../layoutLogics";

export const IncidentModal = ({ openIncidentModal, setopenIncidentModal }) => {
	const dispatch = useDispatch();

	// get incident type request
	const {
		data: typeIncident,
		isLoading,
		isSuccess,
	} = useGetTypeIncidentQuery(null, { skip: !openIncidentModal });

	// add incident request
	const [addIncident, { isLoading: addIncidentIsLoading }] =
		useAddIncidentsMutation();

	const [data, setdata] = useState({});

	const [valueOption, setvalueOption] = useState([]);

	useEffect(() => {
		if (isSuccess) {
			setvalueOption(
				typeIncident?.data?.map((item) => {
					return {
						name: item.nom,
						id: item.idTypeIncident,
						description: item.description
					};
				})
			);
		}

		return () => {};
	}, [isSuccess, typeIncident]);

	const handleCloseModal = () => {
		setopenIncidentModal(false);
	};

	const handleChange = (e, type="text") => {
		const { name, value } = e.target;

		setdata((prevState) => {
			return {
				...prevState,
				[name]: type === "select" ? parseInt(value) : value,
			};
		});
	};

	const addIncidentHandleClick = (e) => {
		e.preventDefault();

		const sentData = {
			idTypeIncident: data?.type,
			sujet: data?.sujet,
			description: data?.description
		};

		addIncident(sentData)
		.unwrap()
		.then((res) => {
			handleCloseModal();
		})
		.catch(err => {
			setModalPopUp(dispatch, "Une erreur est survenue", "failed");
			console.log(err)
		});
	};

	return (
		<Transition.Root
			show={openIncidentModal}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setopenIncidentModal}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel
								as="form"
								onSubmit={addIncidentHandleClick}
								className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-3 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:px-6">
								<Dialog.Title
									as="h1"
									className="text-xl  ">
									Déclarer un incident
								</Dialog.Title>
								<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={() =>
											setopenIncidentModal(false)
										}>
										<span className="sr-only">Close</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button>
								</div>

								<div className=" mt-8 space-y-4">
									{/* select  */}
									{isLoading ? (
										<Spinner className="w-8 h-8 text-primary-600" />
									) : (
										<Select
											label="Selectionner le type d'incident"
											valueOption={valueOption}
											name="type"
											defaultValue="default"
											disabled={false}
											required={false}
											onChange={(event) => handleChange(event, "select")}
										/>
									)}
									{/* libele  */}
									<Input
										label="sujet"
										input={{
											id: "sujet",
											type: "text",
											maxLength: "250",
										}}
										name="sujet"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>
									{/* product code  */}
									<Input
										label="Code du produit (facultatif) "
										input={{
											id: "produitCode",
											type: "text",
											maxLength: "250",
										}}
										name="produitCode"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>
									{/* description  */}
									<TextArea
										name="description"
										label="Description"
										rows="6"
										id="description"
										required={true}
										onChange={handleChange}
									/>
								</div>
								<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
									<Button
										type="submit"
										button="primary"
										className="max-w-max sm:ml-3 			"
										children="Enregistrer"
										loading={addIncidentIsLoading}
									/>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										onClick={() =>
											setopenIncidentModal(false)
										}>
										Annuler
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
