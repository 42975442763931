/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description assurance card souscription
 * @component AssuranceCard
 */
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const CardBodyTitle = styled.h3`
    text-align: left;
    flex-grow: 1;
    font-size: 2em;
    font-weight: 500;
    text-transform: capitalize;
`;

const Description = styled.p`
    text-align: justify;
    font-size: 1.5em;
    font-weight: 400;
`;

const AssuranceCard = (props) => {
  const { titre, images, categorie, code, prix, id, description } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const categoryCover = location.state;

  return (
    <div
      style={{ cursor: "pointer" }}
      className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white xs:my-3  hover:scale-105 shadow-lg hover:animate-wiggle animate-once animate-duration-[600ms] animate-normal animate-fill-forwards"
      onClick={() => navigate(`/assurances/categorie/product/${code}`)}
    >
      <div className="aspect-w-2 aspect-h-2 bg-gray-200 sm:aspect-none sm:h-40 ">
        <img
          src={images ? images : categoryCover}
          alt="images produits"
          className="h-full w-full object-cover object-center sm:h-full sm:w-full"
        />
      </div>

      <div className="flex flex-1 flex-col space-y-2 p-4">
        <div className="flex flex-1 gap-3 flex-col py-2">
          <CardBodyTitle className="text-secondary-600">{titre}</CardBodyTitle>

          <Description>
            {/* {description} */} 
            <strong>Description: </strong>{description}
          </Description>
        </div>
      </div>
    </div>
  );
};

export default AssuranceCard;
