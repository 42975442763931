/**
 * @author assan zidan
 * @description custom hook for input component
 * @email nsangouassanzidan@gmail.com
 */

import { useReducer, useCallback } from "react";

// initial state
const initialSelectState = {
	value: "", // value state
	valid: false, // is valid state
};

const selectStateReducer = (state, action) => {
	if (action.type === "SELECT") {
		// select action
		return { value: action.value, valid: true };
	}
	if (action.type === "BLUR") {
		// blur action
		return { valid: true, value: state.value };
	}
	return selectStateReducer;
};

const useSelect = () => {
	// select state
	const [selectState, dispatch] = useReducer(
		selectStateReducer,
		initialSelectState
	);

	// select is valid
	const validSelect = selectState.valid;

	// handle change select
	const valueChangeHandler = useCallback((event) => {
		dispatch({ type: "SELECT", value: event.target.value });
	}, []);

	//  handle blur select
	const selectBlurHandler = useCallback((event) => {
		dispatch({ type: "BLUR" });
	}, []);

	return {
		value: selectState.value,
		validSelect,
		valueChangeHandler,
		selectBlurHandler,
	};
};

export default useSelect;
