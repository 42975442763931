/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  list of Expert opinion consultation
 * @component MesAvisExpertPage
 */
import Main from "../../../components/layouts/main";
import MesAvisExpert from "../../../components/layouts/main/consultations/doctor/MesAvisExpert";

const MesAvisExpertPage = () => {
	return <Main children={<MesAvisExpert />} />;
};

export default MesAvisExpertPage;
