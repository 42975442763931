/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  sidebar loading
 * @component SideBarLoading
 */
import React from "react";
import logoMini from "../../../assets/logo-mini-inch-assurance.png";

const SideBarLoading = () => {
	return (
		<>
			{/* Static sidebar for desktop */}
			<div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
				<div className="flex flex-grow flex-col overflow-y-auto bg-secondary-700 pt-5">
					<div className="flex flex-shrink-0 items-center px-4 gap-x-2">
						<img
							className="h-8 w-auto"
							src={logoMini}
							alt="inch assur"
						/>
						<span className="text-primary-700 text-xl font-bold text">
							Inch Assur
						</span>
					</div>

					<div className="mt-5 px-2  ">
						<span className="animate-pulse flex min-w-0 items-center border border-secondary-500 shadow justify-between space-x-3 pl-2 pr-1 py-2 text-left rounded-md bg-secondary-500 ">
							<div className="rounded-full bg-secondary-400  h-10 w-10">
								{" "}
							</div>
							<span className="flex min-w-0 flex-1 flex-col gap-y-2">
								<span className="bg-secondary-400 rounded h-2"></span>
								<span className="bg-secondary-400 rounded h-2 w-2/3"></span>
							</span>
						</span>
					</div>

					<div className="flex flex-1 flex-col">
						<nav
							className="mt-5 flex flex-1 flex-col divide-y divide-secondary-800 overflow-y-auto animate-pulse"
							aria-label="Sidebar">
							<div className=" px-2 space-y-2">
								<div className=" h-10 flex gap-x-2 items-center">
									<div className="bg-secondary-400 h-8 w-8 rounded-md"></div>
									<div className="bg-secondary-400 h-4 w-4/5 rounded-md"></div>
								</div>
								<div className=" h-10 flex gap-x-2 items-center">
									<div className="bg-secondary-400 h-8 w-8 rounded-md"></div>
									<div className="bg-secondary-400 h-4 w-4/5 rounded-md"></div>
								</div>
								<div className=" h-10 flex gap-x-2 items-center">
									<div className="bg-secondary-400 h-8 w-8 rounded-md"></div>
									<div className="bg-secondary-400 h-4 w-3/5 rounded-md"></div>
								</div>
							</div>

							<div className="mt-6 pt-6">
								<div className="space-y-1 px-2">
									<div className=" h-10 flex gap-x-2 items-center">
										<div className="bg-secondary-400 h-8 w-8 rounded-md"></div>
										<div className="bg-secondary-400 h-4 w-4/5 rounded-md"></div>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</>
	);
};

export default SideBarLoading;
