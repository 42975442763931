/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  header dashoard
 * @component DashboardHeader
 */
import { Link, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { useState } from "react";
import {
	setModal
} from "../../../features/feedBack/modal.slice";
import { Menu, Transition } from "@headlessui/react";
import {
	BellIcon,
	Bars3BottomLeftIcon,
	MagnifyingGlassIcon,
} from "../../../UI/svg";
import { userNavigation } from "../../../lib/userAction";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice } from "../../../features/api/apiSlice";
import { logOut } from "../../../features/user/auth.slice";
import profile from "../../../assets/profile.png";
import { useGetPocketUserQuery } from "../../../features/api/apiSlice";
import { getUser } from "../../../features/user/user.slice";
import {  useEffect } from "react";



import {
	useGetUserQuery,
	useSetMyProfilMutation,
} from "../../../features/api/apiSlice";

import { getUserAction } from "../../../features/user/user.slice";

import Button from "../../../UI/button/Button";

import React from "react";
import { Dialog } from "@headlessui/react";
const DashboardHeader = (props) => {
	const { setSidebarOpen } = props;

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { photo_profil } = useSelector(getUser);

	// get pocket request
	const { data: possa } = useGetPocketUserQuery();

	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}
	const [open, setOpen] = useState(false);

	// action menu user
	const handlerClickUser = (e, item) => {
		e.preventDefault();

		const { name, to } = item;
		// dispatch(apiSlice.util.resetApiState());
		// 		dispatch(logOut()
		// 		);
		// 		navigate("/", { replace: true });
		switch (name ) {
			case "Déconnexion":
				dispatch(setOpen(true));
				
				navigate("/", { replace: true });
				break;
			case "Mon compte":
				navigate(to);
				break;
			case "Paramétre":
				navigate(to);
				break;
			case "Recharge":
				navigate(to);
				break;
			default:
				break;
		}
	};
	// action menu user
	const handlerClickUsers = (e) => {
		e.preventDefault();
						dispatch(apiSlice.util.resetApiState());
						dispatch(logOut()
							);
						navigate("/", { replace: true });
		};
	

	

	return (
		<div className="shadow sm:rounded-md w-full">

			<>
				<Transition.Root
					show={open}
					as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setOpen}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
							onClose={setOpen}>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
									<Dialog.Panel
									
										as="form"
										className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div>
											<div className="">
												<Dialog.Title
													as="h2"
													className="text-lg font-medium leading-6 text-primary-800">
													Voullez vous vraiment  déconnecter votre compte utilisateur sur cette application?
												</Dialog.Title>
												
											</div>
										</div>
										

										{/* button  */}
										<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
											<div className="sm:col-start-2 ">
												<Button
													type="submit"
													id="Déconnexion"
													children="Déconnexion"
													button="primary"
													name="déconnexion"
													onClick={(e) =>
														handlerClickUsers(e)	
													}
												/>
											</div>
											<button
												type="button"
												name="annuler"
												className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
												onClick={() => setOpen(false)}>
												Annuler
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</>
			<div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
				{/* open sidebar button for small screen  */}
				<button
					type="button"
					className="border-r box-border border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-500 xl:hidden"
					onClick={() => setSidebarOpen(true)}>
					<span className="sr-only">Ouvrir la barre latéralle</span>
					<Bars3BottomLeftIcon
						className="h-6 w-6"
						aria-hidden="true"
					/>
				</button>

				<div className="flex flex-1 justify-between px-4">
					<div className="flex flex-1">
					
					</div>

					<div className="ml-4 flex items-center md:ml-6 gap-x-4">
						{/* notification button  */}
						<button
							type="button"
							className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2">
							<span className="sr-only"> notifications</span>
							<BellIcon
								className="h-6 w-6"
								aria-hidden="true"
							/>
						</button>

						{/* Profile dropdown */}
						<Menu
							as="div"
							className="relative ml-3">
							<div>
								<Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2">
									<span className="sr-only">
										Ouverture du menu utilisateur
									</span>
									<img
										className="h-8 w-8 rounded-full object-cover"
										src={photo_profil || profile}
										alt=""
									/>
								</Menu.Button>
							</div>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95">
								<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
									{possa?.data ? (
										<div className=" px-4 py-2 flex items-center justify-between text-sm text-gray-500">
											<span>Solde:</span>
											<span className="flex items-center gap-x-2 font-bold text-gray-700 ">
												<span>{possa?.data?.solde}</span>
												<span>{possa?.data?.devise}</span>
											</span>
										</div>
									) : null}
									{userNavigation.map((item) => (
										<Menu.Item key={item.name}>
											{({ active }) => (
												<Link
													to={item.to}

													className={classNames(
														active ? "bg-gray-100" : "",
														" px-4 py-2 flex items-center text-sm text-gray-700"
													)}
													onClick={(e) =>
														handlerClickUser(e, item)	
													}>
													<item.icon
														className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
														aria-hidden="true"
													/>

													{item.name}

												</Link>
											)}
										</Menu.Item>
									))}
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</div>
			</div>
		</div>

	);
};

export default DashboardHeader;
