/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description consultation souscription
 * @component Fiche
 */
import React, { useState } from "react";
import Button from "../../../../UI/button/Button";
import profile from "../../../../assets/profile.png";
import {
	getconsultation,
	resetConsultation,
} from "../../../../features/consultation/consultation.slice";
import { setModal } from "../../../../features/feedBack/modal.slice";
import { getUser } from "../../../../features/user/user.slice";
import { useDispatch, useSelector } from "react-redux";
import {
	useAddRDVwithMedecinMutation,
	useAddRDVwithMedecinWithoutAssurMutation,
} from "../../../../features/api/apiSlice";
import { useNavigate } from "react-router-dom";
import Fiche from "./Fiche";
import PayementStep from "../assurances/client/PayementStep";
import { setOperation } from "../../../../features/user/payement.slice";

const Fiche_rdv = () => {
	const [operator, setOperator] = useState("CM_ORANGEMONEY");

	const navigate = useNavigate();
	const dispatch = useDispatch();

	// add rdv with medecin request
	const [addRDVwithMedecin, { isLoading: withAssurLoading }] =
		useAddRDVwithMedecinMutation();

	// add rdv without medecin request
	const [addRDVwithoutMedecin, { isLoading: withoutAssurLoading }] =
		useAddRDVwithMedecinWithoutAssurMutation();

	// get date
	const getDateTimeRdv = (slot) => {
		const options = {
			day: "numeric",
			weekday: "long",
			month: "long",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
		};

		const dateRdv = new Date(slot);
		const dateTime = new Intl.DateTimeFormat("fr-FR", options).format(
			dateRdv
		);

		return dateTime;
	};

	const {
		medecin,
		periode,
		motif,
		isAssur,
		canal,
		ville,
		skill,
		assurance,
		type,
	} = useSelector(getconsultation);

	// get user
	const { nom, prenom, code } = useSelector(getUser);

	const skillMedecin = medecin.skills?.find(
		(item) => item.id_skill === skill.skill_id
	);

	// take  rdv with insurance
	const addRDVHandleclickWithAssur = () => {
		const data = {
			isSecondAdvice: type.isSecondAdvice,
			previousCode: type.previousCode,
			destinataire: medecin.code,
			heure: periode,
			canal: canal.id,
			ville: ville?.id_ville,
			emetteur: code,
			objet: motif.nomMotif,
			prix: skillMedecin?.prix,
			addresse: medecin.localisation[0].adresse,
			withAssur: isAssur,
			assuranceID: assurance.id_produit,
			souscriptionID: assurance.soucription_id,
			skill: skill.skill_id,
		};
		addRDVwithMedecin(data)
			.unwrap()
			.then((res) => {
				dispatch(resetConsultation());
				dispatch(
					setModal({
						modalType: "consultation",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Votre rendez-vous avec le Dr ${medecin.nom
								}, le ${getDateTimeRdv(
									periode
								)} a été pris avec succéss  `,
						},
					})
				);
				navigate("/consultations/mes-consultations");
			})
			.catch();
	};

	// take  rdv without insurance
	const addRDVHandleclickWithoutAssur = () => {
		const data = {
			isSecondAdvice: type.isSecondAdvice,
			previousCode: type.previousCode,
			destinataire: medecin.code,
			heure: periode,
			canal: canal.id,
			ville: ville?.id_ville,
			addresse: medecin.localisation[0].adresse,
			emetteur: code,
			objet: motif.nomMotif,
			prix: skillMedecin?.prix || "pas de prix",
			withAssur: isAssur,
			operator: operator,
			skill: skill.skill_id,
		};

		addRDVwithoutMedecin(data)
			.unwrap()
			.then((res) => {
				dispatch(setOperation("consultation"));
				window.open(res.url, "_self");
			})
			.catch();
	};

	return (
		<>
			<div className="py-3">
				<Button
					children="Retour"
					type="button"
					onClick={() => navigate(-1)}
				/>
			</div>
			<div className="overflow-hidden bg-white shadow sm:rounded-lg">
				<div className="px-4 py-5 sm:px-6">
					<h3 className="text-lg font-medium leading-6 text-gray-900">
						Fiche de rendez-vous
					</h3>
				</div>
				<div className="border-t border-gray-200  pt-5 ">
					<div className="flex items-center space-x-4 lg:space-x-6 px-4 sm:px-6">
						<img
							className="h-16 w-16 rounded-full lg:h-20 lg:w-20 object-cover"
							src={medecin?.photo_profil || profile}
							alt=""
						/>
						<div className="space-y-1 text-lg font-medium leading-6">
							<h3>Dr {`${medecin.nom} ${medecin.prenom}`}</h3>
							<p className="text-primary-600">
								{skillMedecin?.nom}
							</p>
						</div>
					</div>
					{/* fiche  */}
					<Fiche
						nom={nom}
						prenom={prenom}
						periode={periode}
						prix={skillMedecin?.prix}
						canal={canal.name}
						ville={ville.nomVille}
						motif={motif.nomMotif}
						isAssur={isAssur}
						code={code}
						duree={30}
					/>

					

				</div>
				<div className=" overflow-hidden bg-white shadow mt-5 px-4 py-5 sm:px-6">
					<h3 className="text-lg font-medium leading-6 text-gray-900">
						Procedez au Paiement
					</h3>
				</div>
				<div className="border-t border-gray-200  pt-5 ">
					

					{!isAssur && (
						<div className="mt-6 ml-8">
							<PayementStep setMethodePayement={setOperator} />
						</div>
					)}

					<div className="flex mt-6  bg-gray-50 justify-end py-3 pr-4  text-sm">
						{isAssur ? (
							<Button
								button="primary"
								className="max-w-max"
								onClick={addRDVHandleclickWithAssur}
								loading={withAssurLoading}>
								Valider mon rendez-vous
							</Button>
						) : (
							<Button
								button="primary"
								loading={withoutAssurLoading}
								onClick={addRDVHandleclickWithoutAssur}
								className="max-w-max">
								Valider mon rendez-vous
							</Button>
						)}
					</div>
				</div>
			</div>
			
		</>
	);
};

export default Fiche_rdv;
