/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description bilan of consultation
 * @component SingleBilanRDVConsultation
 */

const SingleBilanRDVConsultation = ({ bilan }) => {
	return (
		<>
			{bilan ? (
				bilan?.length === 0 ? null : (
					<div className="space-y-4 rounded-md border shadow-sm  mt-6  divide-y divide-gray-200 ">
						{bilan?.map((item, index) => {
							return (
								<div
									key={index}
									className="p-4">
									<h2 className="text-xl font-medium mb-2 text-gray-500">
										{item.titre}
									</h2>
									<p className="text-md ">
										{" "}
										{item.description}
									</p>
								</div>
							);
						})}
					</div>
				)
			) : null}
		</>
	);
};

export default SingleBilanRDVConsultation;
