import React from "react";
import styled from "styled-components";

import DescriptionAssurance from "../../../components/layouts/main/assurances/client/descriptionAssurance/DescriptionAssurance";

const StyledMain = styled.div`
    position: relative;
    height: 100vh;
    width: 100%;
    min-width: 250px;
    max-width: 1820px;
    padding: 30px 70px;
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
    @media screen and (max-width: 500px) {
        padding: 20px;
    }
`

export default function DescriptionAssurancePage() {
    return (
        <StyledMain>
            <DescriptionAssurance />
        </StyledMain>
    );
};
