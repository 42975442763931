/**
 * @author assan zidan
 * @description custom hook for password input type
 * @email nsangouassanzidan@gmail.com
 */

import React, { useState } from "react";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/outline";

const usePasswordToggle = () => {
	// visibility state
	const [eyeVisibility, setEyeVisibility] = useState(false);

	// input type
	const typeInput = eyeVisibility ? "text" : "password";

	// icon type
	const Eye = eyeVisibility ? EyeIcon : EyeSlashIcon;

	// icon component
	const Icon = (
		<Eye
			onClick={() => setEyeVisibility((prevState) => !prevState)}
			className="h-5 w-5 text-gray-500 cursor-pointer z-50"
			aria-hidden="true"
		/>
	);

	return [typeInput, Icon];
};

export default usePasswordToggle;
