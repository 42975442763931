/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add user
 * @component UserAdd
 */

import Input from "../../../../UI/form/Input";
import {
	useCreateUserMutation,
	useGetProfilsQuery,
	useCreateMemberMutation,
} from "../../../../features/api/apiSlice";
import {
	getUserCode,
	getUserDefaultProfil,
} from "../../../../features/user/user.slice";
import Select from "../../../../UI/form/Select";
import Spinner from "../../../../UI/spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import profile from "../../../../assets/profile.png";
import Button from "../../../../UI/button/Button";
import sha256 from "sha256";
import { useNavigate, useLocation } from "react-router-dom";
import { setModal } from "../../../../features/feedBack/modal.slice";
import VilleComponent from "../../../VilleComponent";

const UserAdd = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
  	const returnPath = location.state && location.state.returnPath ? location.state.returnPath : false;

	console.log("return path :", returnPath);

	// user default profil
	const defaultProfil = useSelector(getUserDefaultProfil);
	// user code
	const code = useSelector(getUserCode);

	// get profil request
	const { data: profils, isSuccess, isLoading } = useGetProfilsQuery();

	// add user request
	const [addUser, { isLoading: loading }] = useCreateUserMutation();

	console.log("loading :", loading)

	// add memeber request
	const [addMember, { isLoading: loadingcreatemember }] =
		useCreateMemberMutation(code);

	const [valueOption, setvalueOption] = useState([]);

	// user state
	const [userInfo, setuserInfo] = useState({
		sexe: "Homme",
	});

	// city state
	const [villeSelected, setvilleSelected] = useState({});

	// set profil
	useEffect(() => {
		if (isSuccess && profils) {
			const data = profils?.data?.map((item) => {
				return {
					id: item.id_profil,
					name: item.titre,
				};
			});

			setvalueOption(data);
		}

		return () => {};
	}, [isSuccess, profils]);

	// handle change
	const handleChange = (e) => {
		const type = e.target.type;
		if (type === "file") {
			let file = e.target.files[0];
			let imgUrl = URL.createObjectURL(file);

			setuserInfo((prevState) => {
				return {
					...prevState,
					imgUrl,
					photo_profil: file,
				};
			});
		} else {
			const name = e.target.name;
			const value = e.target.value.trim();

			setuserInfo((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		}
	};

	// add user
	const handleSubmit = (e) => {
		e.preventDefault();
		const formDate = new FormData();

		const pushItemform = (item, key) => {
			return formDate.append(`${key}`, item);
		};
		const password = sha256("inchassur");
		pushItemform(userInfo?.nom, "nom");
		pushItemform(userInfo?.sexe, "sexe");
		pushItemform(userInfo?.prenom, "prenom");
		pushItemform(userInfo?.photo_profil, "photo_profil");
		pushItemform(userInfo?.date_naissance, "date_naissance");
		pushItemform(userInfo?.profession, "profession");
		pushItemform(userInfo?.email, "email");
		pushItemform(userInfo?.civilite, "civilite");
		pushItemform(userInfo?.nbr_enfant, "nbr_enfant");
		pushItemform(villeSelected?.ville, "ville");
		if (defaultProfil.id === "IA10") {
			pushItemform(userInfo?.profil, "categorie");
		} else {
			pushItemform("IA1", "categorie");
		}
		pushItemform(password, "password");
		pushItemform(userInfo?.tel1, "tel1");

		addUser(formDate)
			.unwrap()
			.then((res) => {
				if (defaultProfil.id === "IA3") {
					const formDate = {
						code: res.data.code,
					};
					addMember({ code, formDate })
						.unwrap()
						.then((res) => {
							// alert success
							dispatch(
								setModal({
									modalType: "membre",
									modalProps: {
										isOpen: true,
										status: "success",
										text: "L'employé a été ajouté avec succès",
									},
								})
							);

							if (returnPath) {
								navigate(returnPath);
							} else {
								navigate("/listmembers");
							}
						})
						.catch((err) => {
							// alert error
						});
				} else {
					// alert user we add new user
					dispatch(
						setModal({
							modalType: "user",
							modalProps: {
								isOpen: true,
								status: "success",
								text: "L'utilisateur a ajouté avec succès",
							},
						})
					);
					navigate("/users");
				}
			})
			.catch((err) => {});
	};

	return (
		<form
			onSubmit={handleSubmit}
			className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
			<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
				<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
					<div>
						<h3 className="text-lg font-medium leading-6 text-gray-900">
							Ajouter un utilisateur
						</h3>
						<p className="mt-1 max-w-2xl text-sm text-gray-500">
							Bien vouloir remplir ce formulaire pour créer un
							compte utilisateur.
						</p>
					</div>

					<div className="space-y-6 sm:space-y-5">
						{/* profil */}
						{defaultProfil?.id === "IA10" && (
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="profil"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Type de profil:
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									{isLoading ? (
										<Spinner className="w-8 h-8 text-secondary-600 " />
									) : (
										<Select
											id="profil"
											defaultValue="2"
											name="profil"
											label="choississez un profil"
											valueOption={valueOption}
											className="sm:max-w-xs w-full"
											read={true}
											onChange={handleChange}
										/>
									)}
								</div>
							</div>
						)}

						{/* last name  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="nom"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Nom
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										input={{
											id: "nom",
											type: "text",
											maxLength: "80",
										}}
										name="nom"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						{/* first name  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="prenom"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Prénom
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										input={{
											id: "prenom",
											type: "text",
											maxLength: "80",
										}}
										name="prenom"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						{/* email 				 */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								email						</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										input={{
											id: "email",
											type: "email",
											maxLength: "280",
										}}
										name="email"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						{/* sexe */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="sexe"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Sexe:
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<select
									onChange={handleChange}
									id="sexe"
									name="sexe"
									className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-secndary-500 focus:ring-secndary-500 sm:max-w-xs sm:text-sm">
									<option
										selected=""
										disabled="true">
										Sexe
									</option>
									<option value="Homme">Homme</option>
									<option value="Femme">Femme</option>
								</select>
							</div>
						</div>

						{/* birthday */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="date_naissance"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Date de naissance
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										input={{
											id: "date_naissance",
											type: "date",
										}}
										name="date_naissance"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						{/* phone number */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="tel1"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Télephone
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										icon="+237"
										input={{
											id: "tel1",
											type: "tel",
											maxLength: "9",
											minLength: "9",
										}}
										name="tel1"
										disabled={false}
										required={true}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						{/* city  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="ville"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Ville de residence
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<VilleComponent
										setVilleSelected={setvilleSelected}
									/>
								</div>
							</div>
						</div>

						{/* profession  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="profession"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Proféssion
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										input={{
											id: "profession",
											type: "text",
											maxLength: "80",
										}}
										name="profession"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						{/* Civility  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<span className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Civilité
							</span>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:min-w-xs flex flex-col sm:flex-row gap-x-10 gap-y-6 pl-14 sm:p-0">
									<label
										className="flex gap-x-2 items-center  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
										htmlFor="monsieur">
										<input
											id="monsieur"
											value="monsieur"
											name="civilite"
											type="radio"
											className="h-5 w-5 border-3 inline-block "
											defaultChecked={true}
											onChange={handleChange}
										/>
										<span>M</span>
									</label>
									<label
										className="flex gap-x-2 items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
										htmlFor="madame">
										<input
											value="madame"
											id="madame"
											name="civilite"
											type="radio"
											className="h-5 w-5 border-3 inline-block "
											defaultChecked={false}
											onChange={handleChange}
										/>
										<span className="">Mme</span>
									</label>
									<label
										className="flex gap-x-2 items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
										htmlFor="mademoiselle">
										<input
											value="mademoiselle"
											id="mademoiselle"
											name="civilite"
											type="radio"
											className="h-5 w-5 border-3 inline-block "
											defaultChecked={false}
											onChange={handleChange}
										/>
										<span className="">Mlle</span>
									</label>
								</div>
							</div>
						</div>

						{/* number of chid  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="nbr_enfant"
								className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
								Nombre d'enfants
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="sm:max-w-xs">
									<Input
										input={{
											id: "nbr_enfant",
											type: "number",
											min: "0",
											max: "40",
										}}
										name="x"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						{/* photo  */}
						<div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
							<label
								htmlFor="photo"
								className="block text-sm font-medium text-gray-700">
								Photo
							</label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="flex items-center">
									<div className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
										<img
											className="h-full  w-full object-cover rounded-md"
											src={userInfo?.imgUrl || profile}
											alt=""
										/>
									</div>
									<div className="ml-5 rounded-md shadow-sm">
										<div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
											<label
												htmlFor="photo_profil"
												className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700">
												<span>Ajouter une image</span>
												<span className="sr-only">
													photo de profil
												</span>
											</label>
											<input
												onChange={handleChange}
												id="photo_profil"
												name="photo_profil"
												type="file"
												className="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
					<Button
						children="Ajouter l'utilisateur"
						button="primary"
						type="submit"
						className="max-w-max"
						loading={loading || loadingcreatemember}
					/>
				</div>
			</div>
		</form>
	);
};

export default UserAdd;
