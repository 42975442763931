/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list skill
 * @component ListCompetences
 */
import { Fragment, useEffect,useState,useCallback, useLayoutEffect } from "react";
import Spinner from "../../../../../UI/spinner/Spinner";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
	ArchiveBoxIcon,
	PencilSquareIcon,
	EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { getModalProps, getModalType, initialModal, setModal } from "../../../../../features/feedBack/modal.slice";
import { Alert } from "../../UserRecharge/Recharge";
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useGetSkillsMedecinQuery ,useDeleteUserSkillMutation} from "../../../../../features/api/apiSlice";


function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const ListCompetences = () => {
	// get skill request

	const { data: skills, isLoading } = useGetSkillsMedecinQuery();

	const [selectedSkills, setSelectedSkills] = useState(null);
    const [rowClick, setRowClick] = useState(true);

    const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);
    // delete skill request
	const [deleteUserSkill, { isLoading: deleteUserSkillIsLoading }] =
    useDeleteUserSkillMutation();


    const dispatch = useDispatch();
	

	// close alert
	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// close alert after 10 secondes
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);

// deleteSkill
const deleteUserSkillHandleClick = (skill) => {
    const id = skill.id_skill
    deleteUserSkill(id)
        .unwrap()
        .then((res) => {

            dispatch(
                setModal({
                    modalType: "skill",
                    modalProps: {
                        isOpen: true,
                        status: "success",
                        text: `La competence ${skill.nom} a été supprimée avec success`,
                    },
                })
            );
            

        })

        .catch((err) => {
            console.log(err);
        });
};

	// open options parameters(edit and delete)
	const actionBodyTemplate = (rowData) => {

        const data = JSON.stringify(rowData)

        return <Menu
            as="div"
            className=" inline-block text-left">
            <div>
                <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">
                        Open
                        options
                    </span>
                    <EllipsisHorizontalIcon
                        className="h-7 w-7"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>

            <Transition
                as={
                    Fragment
                }
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({
                                active,
                            }) => (
                                <Link to={`/consultations/addcompetence?type=update`}
                                    state={{ from: rowData }}


                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "cursor-pointer group flex items-center px-4 py-2 text-sm"
                                    )}>
                                    <PencilSquareIcon
                                        className=" mr-3 h-5 w-5 text-yellow-400 group-hover:text-yellow-500"
                                        aria-hidden="true"
                                    />
                                    Modifier
                                </Link>
                            )}
                        </Menu.Item>

                        <Menu.Item>
                            {({
                                active,
                            }) => (
                                <span
                                 onClick={()=>{
                                    deleteUserSkillHandleClick(rowData)
                                }}

                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "cursor-pointer group flex items-center px-4 py-2 text-sm"
                                    )}>
                                    <ArchiveBoxIcon
                                        className=" mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                                        aria-hidden="true"
                                    />
                                    Supprimer
                                </span>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>;
    };

	const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;
    

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

	//show header
    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-end align-items-end" style={{ justifyContent: 'end' }}>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="rechercher" />
                </span>
            </div>
        );
    };

	//set value to filter

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
	//show skill.description
    const descriptionBodyTemplate = (rowData) => {

        

            if (rowData.description_perso)
            {
               return rowData.description_perso.substring(0, 100) 
            }
            else if (rowData.desscription) {
               return rowData.description.substring(0, 100)
                
            } else {
               return 'pas de description'
            }
            

        


    }

	const header = renderHeader();
	
	

	return (
		<>


            {/* alert componenet  */}
			{modalType === "skill" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}
			<div className="bg-white">
				<div className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h2 className="text-xl font-bold tracking-tight text-primary-800 sm:text-2xl mb-8">
								Mes Compétences{" "}
							</h2>
						</div>
						<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
							<Link to="/consultations/addcompetence?type=add">
								<button
									type="button"
									className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
									Assigner une compétence
								</button>
							</Link>
						</div>
					</div>

					{!skills?.data?.length ? (
						<div className="flex flex-col items-center justify-center">
							{" "}
							<p className="text-3xl text-center text-gray-500">
								aucune compétence{" "}
							</p>
						</div>
					) : (
						<>
							<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <DataTable value={skills?.data} selectionMode={rowClick ? null : 'checkbox'} selection={selectedSkills} onSelectionChange={(e) => setSelectedSkills(e.value)} header={header}  paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                    filters={filters} globalFilterFields={['nom', 'description']}
                                    emptyMessage="No customers found."  
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} style={{borderWidth:'1px', borderStyle:'solid'}}>
                                    <Column selectionMode="multiple" headerStyle={{ width: '1%' }}></Column>
                                    <Column field="nom" header="Nom" sortable style={{ width: '10%' }}></Column>
                                    <Column header="Description" body={descriptionBodyTemplate} style={{ width: '25%' }}></Column>
                                    <Column style={{ width: '1%' }} body={actionBodyTemplate} />

                                </DataTable>

                            </div>
							</div>
						</>
					)}

					{isLoading && (
						<div className="flex flex-1 justify-center">
							<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default ListCompetences;
