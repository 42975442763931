

const Main = ({ children }) => {
	return (
		<main>
			{/* Breadcrumb  */}
			{/* {breadCrum} */}
			{/* End breadcrumb  */}

			{/* Replace with your content */}
			{children}
			{/* /End content */}
		</main>
	);
};

export default Main
