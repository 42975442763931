/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  consultation of medecin
 * @component MesConsultationsPage
 */
import Main from "../../../components/layouts/main";
import MesConsultations from "../../../components/layouts/main/consultations/doctor/Mes_consultations";

const MesConsultationsPage = (props) => {
	return <Main children={<MesConsultations />} />;
};

export default MesConsultationsPage;
