/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description personnal information of user
 * @component PersonnalInformation
 */
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect,Fragment, useRef} from "react";
import { getUserCode,getUserDefaultProfil } from "../../../../../src/features/user/user.slice";
import Input from "../../../../../src/UI/form/Input";
import Select from "../../../../../src/UI/form/Select";
import { CheckCircleIcon, MinusIcon } from "../../../../UI/svg";

import { useDeleteProfilMutation,useAddProfilMutation,
	useGetProfilsQuery, } from "../../../../features/api/apiSlice";
import { getModalProps,
	getModalType,
	initialModal,
	setModal} from "../../../../features/feedBack/modal.slice";

import profile from "../../../../assets/profile.png";
import Spinner from "../../../../UI/spinner/Spinner";
import Button from "../../../../UI/button/Button";
import VilleComponent from "../../../VilleComponent";
const sexe = [
	{ id: "homme", name: "homme" },
	{ id: "femme", name: "femme" },
];



// add profil modal
function ModalProfil({ open, setOpen }) {
	const dispatch = useDispatch();
	const profilRef = useRef();
	const params = useParams();
// user code
const code = useSelector(getUserCode);
	// profil options state
	const [valueOption, setvalueOption] = useState([]);

	// get profil request
	const { data: profils, isSuccess, isLoading } = useGetProfilsQuery();

	// add profil request
	const [addProfil, { isLoading: addProfilLoading }] = useAddProfilMutation();

	// set profil option state
	useEffect(() => {
		if (isSuccess && profils) {
			const data = profils?.data?.map((item) => {
				return {
					id: item.niveau,
					name: item.titre,
					idProfil: item.id,
				};
			});

			setvalueOption(data);
		}

		return () => {};
	}, [isSuccess, profils]);

	// add profil
	const addProfilHandleClick = (e) => {
		const niveau = profilRef.current.value;
		const data = { niveau };
		addProfil({ code: params.code, data })
			.unwrap()
			.then((res) => {
				setOpen(false);
				dispatch(
					setModal({
						modalType: "user",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le nouveau profil a été ajouté avec succéss`,
						},
					})
				);
			})
			.catch((err) => {
				// alert error message
			});
	};

	return (
		<Transition.Root
			show={open}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
										onClick={() => setOpen(false)}>
										<span className="sr-only">Close</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button>
								</div>
								<div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900">
											Attribuer un profil à un utilisateur
										</Dialog.Title>
										<div className="mt-6">
											{isLoading ? (
												<Spinner className="w-8 h-8 text-secondary-600 " />
											) : (
												<Select
													id="profil"
													defaultValue="2"
													name="profil"
													label="Sélectionner le profil"
													valueOption={valueOption}
													ref={profilRef}
												/>
											)}
										</div>
									</div>
								</div>
								<div className="mt-8 sm:mt-4 sm:flex gap-x-4 sm:flex-row-reverse"> 
									<Button
										button="primary"
										type="button"
										children="Ajouter le profil"
										className="max-w-max"
										onClick={addProfilHandleClick}
										loading={addProfilLoading}
									/>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}>
										Annuler
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}







const PersonnalInformation = (props) => {
	const dispatch = useDispatch();

	// props
	const {
		nom,
		prenom,
		setUserInfo,
		photo_profil,
		date_naissance,
		profils,
		dateCreation,
		created_at,
		profilIsVisible,
		code,
		civilite,
		nbr_enfant,
	} = props;

	// city state
	const [villeSelected, setvilleSelected] = useState({});

	// user default profil
	const defaultProfil = useSelector(getUserDefaultProfil);

	// user profil state
	const [userProfile, setUserProfile] = useState("");

	// delete profil request
	const [deleteProfil] = useDeleteProfilMutation();

	// set city state
	useEffect(() => {
		setUserInfo((prevState) => {
			return {
				...prevState,
				ville: villeSelected.ville,
			};
		});

		return () => {};
	}, [villeSelected, setUserInfo]);

	// handle change
	const HandleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		if (name === "photo_profil") {
			let file = e.target.files[0];
			let imgUrl = URL.createObjectURL(file);

			// let img = file
			setUserInfo((prevState) => {
				return {
					...prevState,
					[name]: file,
				};
			});

			setUserProfile(imgUrl);
		} else {
			setUserInfo((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		}
	};
// modal add profil state
const [open, setOpen] = useState(false);
	// remove profils to user
	const removeProfileHandleClick = (item) => {
		deleteProfil({ code, idProfil: item.id })
			.unwrap()
			.then((res) => {
				// add alert active assur
				dispatch(
					setModal({
						modalType: "user",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le profil ${item?.value} de l'utilisateur (${code}) à été supprimer`,
						},
					})
				);
			})
			.catch((err) => {
				dispatch(
					setModal({
						modalType: "user",
						modalProps: {
							isOpen: true,
							status: "failed",
							text: `${err?.data?.message}`,
						},
					})
				);
			});
	};

	return (
		<section
			aria-labelledby="payment-details-heading "
			className="px-4 sm:p-8">
			{/* header  */}

			<div className="min-h-full">
			<div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
							<div className="flex items-center space-x-5 ">
								<div className="">
									<div className="relative">
										<div className="space-y-6">
											<img
												className="object-cover h-24 w-24 rounded-full "
												src={
													userProfile ||
													photo_profil ||
													profile
												}
												alt=""
											/>
										</div>
										<span
											className="absolute inset-0 rounded-full shadow-inner"
											aria-hidden="true"
										/>
									</div>
								</div>
								<div>
									<div>
										<h1 className="text-2xl font-bold text-gray-900">
											{nom} {prenom}
										</h1>
										<p className="text-sm font-medium text-gray-500">
											Compte Cree{" "}
											{" "}
										    le{" "}
											<time dateTime="2022-11-20">
												{dateCreation}{" "}
											</time>
											<time dateTime="2022-11-20">
												{created_at}{" "}
											</time>
										</p>
									</div>
								</div>
							</div>

							<div className="flex items-center gap-x-4">
								<Button
									button="secondary"
									type="button"
									children="Attribuer un profil"
									className="max-w-max hidden"
									onClick={() => setOpen(true)}
								/>
								<Button
									button="primary"
									type="button"
									children="Modifier le compte"
									className="max-w-max"
								/>
							</div>
						</div> 
			</div>
			<div>
				<h2
					id="payment-details-heading"
					className="text-lg font-medium leading-6 text-gray-900 mt-6">
					Informations Personnelles
				</h2>
				
			</div>

			{/* civility  */}
			<div className="mt-8 mb-8">
				<span className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
					Civilité
				</span>
				<div className="mt-1 sm:col-span-2 sm:mt-0">
					<div className="sm:min-w-xs flex flex-col sm:flex-row gap-x-10 gap-y-6 pl-14 sm:p-0">
						<label
							className="flex gap-x-2 items-center  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
							htmlFor="monsieur">
							<input
								id="monsieur"
								value="monsieur"
								name="civilite"
								type="radio"
								className="h-5 w-5 border-3 inline-block "
								defaultChecked={civilite === "monsieur"}
								onChange={HandleChange}
							/>
							<span>M</span>
						</label>
						<label
							className="flex gap-x-2 items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
							htmlFor="madame">
							<input
								value="madame"
								id="madame"
								name="civilite"
								type="radio"
								className="h-5 w-5 border-3 inline-block "
								defaultChecked={civilite === "madame"}
								onChange={HandleChange}
							/>
							<span className="">Mme</span>
						</label>
						<label
							className="flex gap-x-2 items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
							htmlFor="mademoiselle">
							<input
								value="mademoiselle"
								id="mademoiselle"
								name="civilite"
								type="radio"
								className="h-5 w-5 border-3 inline-block "
								defaultChecked={civilite === "mademoiselle"}
								onChange={HandleChange}
							/>
							<span className="">Mlle</span>
						</label>
					</div>
				</div>
			</div>

			<div className=" grid grid-cols-4 gap-6">
				<div className="col-span-4 sm:col-span-3">
					<div className=" grid grid-cols-2 gap-8">
						{/* last name  */}
						{nom !== undefined && (
							<div className="col-span-2 sm:col-span-1">
								<Input
									label="Nom"
									input={{
										id: "nom",
										type: "text",
										maxLength: "80",
									}}
									onChange={HandleChange}
									defaultValue={nom}
									name={"nom"}
									disabled={false}
									required={false}
								/>
							</div>
						)}

						{/* first name  */}
						{prenom !== undefined && (
							<div className="col-span-2 sm:col-span-1">
								<Input
									label="Prénom"
									input={{
										id: "prenom",
										type: "text",
										maxLength: "80",
									}}
									onChange={HandleChange}
									defaultValue={prenom}
									name={"prenom"}
									disabled={false}
									required={false}
								/>
							</div>
						)}

						{/* birthday  */}
						{/* la date doit etre sur ce format yyyy-mm-dd defaultValue="2000-10-01" */}
						<div className="col-span-2 sm:col-span-1">
							<Input
								label="Date de naissance"
								input={{ id: "date", type: "date" }}
								onChange={HandleChange}
								defaultValue={date_naissance}
								name={"date_naissance"}
								disabled={false}
								required={false}
							/>
						</div>

						{/* sexe 			 */}
						<div className="col-span-2 sm:col-span-1">
							<Select
								valueOption={sexe}
								label="Sexe"
								defaultValue="default"
								id="Sexe"
								name="sexe"
								onChange={HandleChange}
							/>
						</div>

						{/* city  */}
						<VilleComponent setVilleSelected={setvilleSelected} />

						{/* children number  */}
						<div className="col-span-2 sm:col-span-1">
							<Input
								label="Nombre d'enfants"
								input={{
									id: "nbr_enfant",
									type: "number",
									min: "0",
									max: "40",
								}}
								name="nbr_enfant"
								disabled={false}
								defaultValue={nbr_enfant}
								required={false}
								onChange={HandleChange}
							/>
						</div>
					</div>
				</div>

				{/* photo  */}
				<div className=" col-span-4 sm:col-span-1 ">
					<div className="flex-grow lg:mt-0 lg:ml-6 lg:flex-shrink-0 lg:flex-grow-0">
						<p
							className="text-sm font-medium text-gray-700"
							aria-hidden="true">
							Photo
						</p>
						{/* small screen  */}
						<div className="mt-1 lg:hidden">
							<div className="flex items-center">
								<div
									className="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-full"
									aria-hidden="true">
									<img
										className="h-full w-full rounded-full object-cover"
										src={
											userProfile ||
											photo_profil ||
											profile
										}
										alt=""
									/>
								</div>
								<div className="ml-5 rounded-md shadow-sm">
									<div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
										<label
											htmlFor="mobile-user-photo"
											className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700">
											<span>Change</span>
											<span className="sr-only">
												{" "}
												user photo
											</span>
										</label>
										<input
											onChange={HandleChange}
											id="mobile-user-photo"
											name="photo_profil"
											type="file"
											className="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
										/>
									</div>
								</div>
							</div>
						</div>

						{/* large scremm  */}
						<div className="relative hidden overflow-hidden rounded-full lg:block h-32 w-32 ">
							<img
								className="relative h-full w-full object-cover rounded-full"
								src={userProfile || photo_profil || profile}
								alt=""
							/>
							<label
								htmlFor="desktop-user-photo"
								className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100">
								<span>Change</span>
								<span className="sr-only"> user photo</span>
								<input
									onChange={HandleChange}
									type="file"
									id="desktop-user-photo"
									name="photo_profil"
									className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
								/>
							</label>
						</div>
					</div>
				</div>

				{/* profils  */}
				{defaultProfil?.id === "IA10" && profilIsVisible && (
					<div className="col-span-4 sm:col-span-3">
					<div className="flex items-center gap-x-4">
						<span className="text-sm font-medium text-gray-700 mb-2">
							Mes profils
						</span>
						<Button
									button="secondary"
									type="button"
									children="Attribuer un profil"
									className="max-w-max"
									onClick={() => setOpen(true)}
						/>
					</div>			
						<dd className="mt-1 text-sm text-gray-900">
							<ul className=" flex gap-8 flex-wrap items-center">
								{profils?.map((item, index) => {
									return (
										<li
											key={index}
											className="flex items-center justify-between bg-green-100 group pr-4 rounded-xl py-1">
											<span className="flex gap-x-1 items-center">
												<CheckCircleIcon
													className="h-6 w-6 text-green-700 "
													aria-hidden="true"
												/>
												<p className=" text-xs text-green-900">
													{item.value}{" "}
												</p>
											</span>

											<MinusIcon
												onClick={removeProfileHandleClick.bind(
													null,
													item
												)}
												title="Retirer le profil"
												className="ml-8 md:hidden md:group-hover:block hover:w-6 hover:text-green-900 text-green-700 cursor-pointer h-6 w-4 justify-self-end	"
											/>
										</li>
									);
								})}
							</ul>
						</dd>
					</div>
				)}
			</div>
			{/* add profil modal  */}
			{open && (
				<ModalProfil
					open={open}
					setOpen={setOpen}
				/>
			)} 
		</section>
	);
};

export default PersonnalInformation;
