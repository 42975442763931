/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add member for family account
 * @component AddMembers
 */

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Input from "../../../../UI/form/Input";
import Button from "../../../../UI/button/Button";

import { useCreateMemberMutation } from "../../../../features/api/apiSlice";
import { getUserCode } from "../../../../features/user/user.slice";
import { setModal } from "../../../../features/feedBack/modal.slice";

const AddMembers = () => {
	// user code
	const code = useSelector(getUserCode);

	// add member request
	const [addMember, { isLoading: loading }] = useCreateMemberMutation(code);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
  	const returnPath = location.state && location.state.returnPath ? location.state.returnPath : false;

	console.log("return path :", returnPath);

	// member data
	const [memberInfo, setuserInfo] = useState({});

	// handle change
	const handleChange = (e) => {
		const type = e.target.type;
		if (type === "file") {
			let file = e.target.files[0];
			let imgUrl = URL.createObjectURL(file);

			setuserInfo((prevState) => {
				return {
					...prevState,
					imgUrl,
					file,
				};
			});
		} else {
			const name = e.target.name;
			const value = e.target.value.trim();

			setuserInfo((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		}
	};

	// add member
	const handleSubmit = (e) => {
		e.preventDefault();
		const formDate = new FormData();

		const pushItemform = (item, key) => {
			return formDate.append(`${key}`, item);
		};

		pushItemform(memberInfo?.nom, "nom");

		addMember({ formDate, code })
			.unwrap()
			.then((res) => {
				// alert user we add new user
				dispatch(
					setModal({
						modalType: "membre",
						modalProps: {
							isOpen: true,
							status: "success",
							text: "Le membre a ajouté avec succès",
						},
					})
				);

				if (returnPath) {
					navigate(returnPath);
				} else {
					navigate("/listmembers");
				}
			})
			.catch((err) => {});
	};

	return (
		<>
			<div className="p-4">
				<Button
					children="Retour"
					type="button"
					onClick={() => navigate("/listmembers")}
					className="max-w-max"
				/>
			</div>
			<form
				onSubmit={handleSubmit}
				className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
				<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
					<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
						{/* header  */}
						<div>
							<h3 className="text-lg font-medium leading-6 text-gray-900">
								Ajouter un Membre
							</h3>
							<p className="mt-1 max-w-2xl text-sm text-gray-500">
								Bien vouloir remplir ce formulaire pour ajouter
								un membre de votre famille.
							</p>
						</div>

						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="nom"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Nom complet
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<div className="sm:max-w-xs">
										<Input
											input={{
												id: "nom",
												type: "text",
												maxLength: "80",
											}}
											name="nom"
											disabled={false}
											required={false}
											onChange={handleChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
						<Button
							children="Ajouter un membre"
							button="primary"
							type="submit"
							className="max-w-max"
							loading={loading}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default AddMembers;
