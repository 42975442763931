/**
 * @author assan zidan
 * @email nsangouassanzidan@gmail.com
 * @description maintenance page
 * @component  Unauthorized
 */
import React from "react";
import { useNavigate } from "react-router-dom";

import forbidden from "../assets/403.png";

const Unauthorized = () => {
	const navigate = useNavigate();

	const goBack = () => navigate(-1);

	return (
		<div className="min-h-screen flex flex-col items-center justify-center gap-y-4">
			<figure>
				<img
					src={forbidden}
					alt="page non authorisée"
					className="object-cover "
					height="400"
					width="400"
				/>
			</figure>
			<h1 className="text-3xl text-secondary-700 font-bold -mt-8">
				{" "}
				Nous sommes désolés...{" "}
			</h1>
			<div className="text-gray-400">
				<p className="text-base text-center">
					La page à laquelle vous essayez d'accéder a un accès
					restreint.
				</p>
				<p className="text-base text-center">
					Veuillez consulter votre administrateur système.
				</p>
			</div>
			<button
				className="text-center text-white bg-secondary-600 mt-6 px-6 py-2 rounded-md "
				onClick={goBack}>
				Retour
			</button>
		</div>
	);
};

export default Unauthorized;
