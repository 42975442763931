/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list all insurances page
 * @component ListAssurancesPages
 */

import React from "react";
import ListAssurances from "../../../components/layouts/main/assurances/provider/ListAssurances";

const ListAssurancesPages = () => {
	return <ListAssurances />;
};

export default ListAssurancesPages;
