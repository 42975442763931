/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description title 2 
 * @component Title2
 */

import { title } from "../../styles"

const Title2 = ({ text }) => {
  return (
    <h2 className={title.h2}>{text}</h2>
  )
}

export default Title2