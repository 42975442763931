/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description mailto link
 * @component Mailto
 */
import React from "react";

const Mailto = ({ email, className, subject, body, ...props }) => {
	return (
		<a
			className={`mx-2 max-w-max ${className}`}
			href={`mailto:${email}?subject=${subject || ""}&body=${
				body || ""
			}`}>
			{props.children}
		</a>
	);
};

export default Mailto;
