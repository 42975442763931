import styled from "styled-components";
import ControllableStates from "../../../../../Utilities/subscriptionBodyFilterIllustrated/AutocompleteFilter";

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 70px 50px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
`;

const AddMemberButton = styled.button`
  width: 15em;
  padding: 7px;
  border-radius: 8px;
  font-size: 1.5em;
  color: white;
  transition: all 0.3s ease-in-out;
  &:hover {
    scale: 1.03;
  }
`;

function SelectBeneficiary({ setQuery, people, clearInput, handleOpen }) {
    return (
        <Container>
            <ControllableStates setQueryData={setQuery} data={people} filterProperty="nomComplet" filterLabel="Veuillez choisir un bénéficiaire" clearInput={clearInput} />
            <AddMemberButton
                className='bg-primary-800'
                onClick={() => {
                    handleOpen();
                }}
            >
                Ajouter un Bénéficiaire
            </AddMemberButton>
        </Container>
    );
};

export default SelectBeneficiary;
