/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product document
 * @component Document
 */

import { useReducer, useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";

import Button from "../../../../../UI/button/Button";
import Input from "../../../../../UI/form/Input";

import axiosInstance from "../../../../../apis/inchAssur";

import { getToken } from "../../../../../features/user/auth.slice";

const documentReducer = (state, action) => {
	switch (action.type) {
		case "ADDDOC":
			return {
				...state,
				[action.document]: {
					id: action.id,
					preview: undefined,
					title: "",
					file: "",
					old: false,
				},
			};
		case "REMOVEDOC":
			delete state[action.document];
			return {
				...state,
			};
		case "ADDDOCTITLE":
			return {
				...state,
				[action.document]: {
					...state[action.document],
					title: action.value,
					id: action.id,
				},
			};
		case "ADDDOCFILE":
			return {
				...state,
				[action.document]: {
					...state[action.document],
					file: action.file,
					id: action.id,
					preview: action.preview,
				},
			};
		default:
			break;
	}
};

const Document = (props) => {
	// props
	const { SetDataAssurance, dataAssurance, setSelectedIndex, modif, code } =
		props;

	// token
	const token = useSelector(getToken);

	// document reducer
	const [documentState, dispatch] = useReducer(
		documentReducer,
		dataAssurance?.document
	);

	// total document
	const [total, settotal] = useState(0);

	// add document block
	const addDocumentHandleClick = () => {
		const newtotal = total + 1;

		// add document block dispacth
		dispatch({
			type: "ADDDOC",
			id: newtotal,
			document: `document${total + 1}`,
		});

		settotal(newtotal);
	};

	// remove document
	const handleDelete = (document, id, remove) => {
		if (remove) {
			// remove old document
			axiosInstance
				.delete(`product/${code}/doc/${id}`, {
					headers: {
						authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					dispatch({ type: "REMOVEDOC", document });
					const newTotal = total - 1;
					settotal(newTotal);
				})
				.catch(() => {});
		} else {
			dispatch({ type: "REMOVEDOC", document });
			const newTotal = total - 1;
			settotal(newTotal);
		}
	};

	// handleChange file
	const handlerchange = (e, id, document) => {
		// get name and value of input file
		let name = e.target.name;
		let value = e.target.value;

		if (e.target.files) {
			let file = e.target.files[0];
			const preview = URL.createObjectURL(file);
			// add file
			dispatch({ type: "ADDDOCFILE", id, name, file, document, preview });
		} else {
			// add title
			dispatch({ type: "ADDDOCTITLE", id, name, value, document });
		}
	};

	// set data insurance
	useEffect(() => {
		SetDataAssurance((prevState) => {
			return {
				...prevState,
				document: documentState,
			};
		});

		return () => {};
	}, [dispatch, documentState, SetDataAssurance]);

	return (
		<>
			<div className="sm:px-4 py-0 grid grid-cols-4 gap-4">
				{/* list document  */}
				{Object.values(dataAssurance?.document).map((item, index) => {
					return (
						<div
							className="col-span-4 sm:col-span-1 flex flex-col gap-y-4 hover:bg-gray-50 relative p-4 group"
							md={3}
							key={index}>
							<div className="flex flex-col gap-y-4">
								<Input
									onChange={(e) =>
										handlerchange(
											e,
											item.id,
											`document${item.id}`
										)
									}
									label={`Document N°${index + 1}`}
									type="text"
									value={item.title}
									placeholder="Veillez entrer le nom du document"
									input={{
										type: "text",
										id: `nom${item.id}`,
									}}
									name={`nom${item.id}`}
									required={false}
								/>
								<div className=" rounded-md shadow-sm">
									<div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
										<label
											htmlFor={`document${item.id}`}
											className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700">
											<span>Télecharger un document</span>
											<span className="sr-only">
												Télecharger un document
											</span>
										</label>
										<input
											onChange={(e) =>
												handlerchange(
													e,
													item.id,
													`document${item.id}`
												)
											}
											id={`document${item.id}`}
											name={`document${item.id}`}
											type="file"
											className="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
										/>
									</div>
								</div>
								{item.id >= 0 && (
									<AiOutlineCloseCircle
										className="absolute w-5 h-5 text-gray-600 hover:text-gray-900 group-hover:block block right-1 top-1 cursor-pointer lg:hidden"
										onClick={() =>
											handleDelete(
												`document${item.id}`,
												item.id,
												modif
											)
										}
									/>
								)}
							</div>
							{item.preview && (
								<embed
									type="application/pdf"
									src={item.preview}
								/>
							)}
						</div>
					);
				})}

				{/* add document button  */}
				<div className="col-span-4 sm:col-span-1 flex">
					{" "}
					<button
						type="button"
						className="rounded-md mx-4 py-2 sm:p-0 sm:m-0 sm:w-16 sm:h-16 flex-1 sm:flex-initial  bg-secondary-600 sm:self-center text-lg text-center text-white transition-all hover:bg-secondary-900"
						onClick={addDocumentHandleClick}>
						+
					</button>{" "}
				</div>

				<div className="col-span-4 sm:col-span-3 gap-x-4 flex justify-end items-center ">
					<Button
						type="button"
						children="Précedent"
						button="default"
						onClick={() => setSelectedIndex(1)}
					/>
					<Button
						type="button"
						children="Suivant"
						button="default"
						onClick={() => setSelectedIndex(3)}
					/>
				</div>
			</div>
		</>
	);
};

export default Document;
