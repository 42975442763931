/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  get assurance for consultation page
 * @component ConsultAvecAssurPage
 */
import Main from "../../../components/layouts/main";
import ConsultAvecAssur from "../../../components/layouts/main/consultations/Consult_avec_assur";

const ConsultAvecAssurPage = (props) => {
	return <Main children={<ConsultAvecAssur />} />;
};

export default ConsultAvecAssurPage;
