/**
 * @author assan zidan
 * @description custom hook for input component
 * @email nsangouassanzidan@gmail.com
 */

import { useReducer, useCallback } from "react";

const initialInputState = {
	value: "",
	isTouched: false,
};

const inputStateReducer = (state, action) => {
	if (action.type === "INPUT") {
		// input action
		return { value: action.value, isTouched: state.isTouched };
	}
	if (action.type === "BLUR") {
		// blur action
		return { isTouched: true, value: state.value };
	}
	if (action.type === "RESET") {
		// reset action
		return { isTouched: false, value: "" };
	}
	return initialInputState;
};

const useInput = (validateValue) => {
	// input state
	const [inputState, dispatch] = useReducer(
		inputStateReducer,
		initialInputState
	);

	// validate input value
	const valueIsValid = validateValue(inputState.value);

	// input value is not valid
	const hasError = !valueIsValid && inputState.isTouched;

	// handlchange
	const valueChangeHandler = useCallback((event) => {
		dispatch({ type: "INPUT", value: event.target.value });
	}, []);

	// handlebkur
	const inputBlurHandler = useCallback((event) => {
		dispatch({ type: "BLUR" });
	}, []);

	// reset input state
	const reset = () => {
		dispatch({ type: "RESET" });
	};

	return {
		value: inputState.value,
		isValid: valueIsValid,
		hasError,
		valueChangeHandler,
		inputBlurHandler,
		reset,
	};
};

export default useInput;
