/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description users list
 * @component ListUsers
 */
import { useLayoutEffect, useState, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import {
	ArchiveBoxIcon,
	LockOpenIcon,
	EyeIcon,
	LockClosedIcon,
	CheckCircleIcon,
} from "@heroicons/react/20/solid";

import {
	useDeleteUserMutation,
	useGetAllUsersQuery,
	useUnLockUserMutation,
	useLockUserMutation,
} from "../../../../features/api/apiSlice";
import {
	getModalProps,
	getModalType,
	initialModal,
	setModal,
} from "../../../../features/feedBack/modal.slice";

import { EllipsisHorizontalIcon } from "../../../../UI/svg";
import Spinner from "../../../../UI/spinner/Spinner";
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import SpinnerEllipsi from "../../../../UI/spinner/SpinnerEllipsi";

import { Alert } from "../UserRecharge/Recharge";



function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Listusers() {
	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);

	const dispatch = useDispatch();

	// get users request
	const { data: users, isLoading, isSuccess } = useGetAllUsersQuery();

	// unlock user request
	const [unLockUser, { isLoading: unLockUserIsLoading }] =
		useUnLockUserMutation();

	const [selectedUser, setSelectedUser] = useState(null);
	const [rowClick, setRowClick] = useState(true);

	// lock user request
	const [lockUser, { isLoading: lockUserIsLoading }] = useLockUserMutation();

	// delete user request
	const [deleteUser, { isLoading: deleteUserIsLoading }] =
		useDeleteUserMutation();

	// code user state
	const [codeUserAction, setcodeUserAction] = useState();



	const statusClassName = (statusTransaction) => {
		switch (statusTransaction) {
			case "Actif":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "Inactif":
				return "inline-flex rounded-full bg-gray-100 px-2 my-4 text-xs font-semibold leading-5 text-gray-800 whitespace-nowrap";
			case "Bloqué":
				return "inline-flex rounded-full bg-yellow-100 px-2 my-4 text-xs font-semibold leading-5 text-yellow-800 whitespace-nowrap";
			case "Archivé":
				return "inline-flex rounded-full bg-red-100 px-2 my-4 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap ";
			case "En Ligne":
				return "inline-flex rounded-full bg-secondary-100 px-2 my-4 text-xs font-semibold leading-5 text-secondary-800 whitespace-nowrap ";
			case "Hors Ligne":
				return "inline-flex rounded-full bg-red-100 px-2 my-4 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap ";

			default:
				break;
		}
	};

	const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
	const paginatorRight = <Button type="button" icon="pi pi-download" text />;


	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },

	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	//show header
	const renderHeader = () => {
		return (
			<div className="flex flex-wrap gap-2 justify-content-end align-items-end" style={{ justifyContent: 'end' }}>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="rechercher" />
				</span>
			</div>
		);
	};

	//set value to filter

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	//show user.status
	const statusTemplate = (rowData) => {

		return (

			<td
				className={statusClassName(
					rowData.statut
				)}>
				{rowData.statut}
			</td>

		)


	}


	const header = renderHeader();

	// open options parameters(edit and delete)
	const actionBodyTemplate = (rowData) => {
		return <td className="whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
			{(unLockUserIsLoading ||
				deleteUserIsLoading ||
				lockUserIsLoading) &&
				codeUserAction ===
				rowData.code ? (
				<SpinnerEllipsi
					className="inline-block text-left"
					classNameEllipsi="h-6 w-6 "
					classNameSpin="h-7 w-7 text-secondary-500"
				/>
			) : (
				<Menu
					as="div"
					className=" inline-block text-left">
					<div>
						<Menu.Button className=" relative flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
							<span className="sr-only">
								Open
								options
							</span>
							<EllipsisHorizontalIcon
								className="h-7 w-7"
								aria-hidden="true"
							/>
						</Menu.Button>
					</div>

					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95">
						<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1">
							<Menu.Item>
								{({
									active,
								}) => (
									<Link
										to={`${rowData.code}`}
										className={classNames(
											active
												? "bg-gray-100 text-gray-900"
												: "text-gray-700",
											"group flex items-center px-4 py-2 text-sm"
										)}>
										<EyeIcon
											className="mr-3 h-5 w-5 text-indigo-400 group-hover:text-indigo-500"
											aria-hidden="true"
										/>
										Consulter
									</Link>
								)}
							</Menu.Item>
							{rowData.statut ===
								"Bloqué" ? (
								<>
									<Menu.Item>
										{({
											active,
										}) => (
											<span
												onClick={() =>
													unLockUserHandleClick(
														rowData

													)
												}
												className={classNames(
													active
														? "bg-gray-100 text-gray-900"
														: "text-gray-700",
													"cursor-pointer group flex items-center px-4 py-2 text-sm"
												)}>
												<LockOpenIcon
													className=" mr-3 h-5 w-5 text-green-400 group-hover:text-green-500"
													aria-hidden="true"
												/>
												Debloquer
											</span>
										)}
									</Menu.Item>
									<Menu.Item>
										{({
											active,
										}) => (
											<span
												onClick={() =>
													deleteUserHandleClick(
														rowData

													)
												}
												className={classNames(
													active
														? "bg-gray-100 text-gray-900"
														: "text-gray-700",
													"cursor-pointer group flex items-center px-4 py-2 text-sm"
												)}>
												<ArchiveBoxIcon
													className=" mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
													aria-hidden="true"
												/>
												Supprimer
											</span>
										)}
									</Menu.Item>
								</>
							) : null}
							{rowData.statut ===
								"Inactif" ||
								rowData.statut ===
								"Archivé" ? (
								<>
									<Menu.Item>
										{({
											active,
										}) => (
											<span
												onClick={() =>
													unLockUserHandleClick(
														rowData

													)
												}
												className={classNames(
													active
														? "bg-gray-100 text-gray-900"
														: "text-gray-700",
													"cursor-pointer group flex items-center px-4 py-2 text-sm"
												)}>
												<CheckCircleIcon
													className=" mr-3 h-5 w-5 text-green-400 group-hover:text-green-500"
													aria-hidden="true"
												/>
												Activer
											</span>
										)}
									</Menu.Item>
									<Menu.Item>
										{({
											active,
										}) => (
											<span
												onClick={() =>
													lockUserHandleClick(
														rowData

													)
												}
												className={classNames(
													active
														? "bg-gray-100 text-gray-900"
														: "text-gray-700",
													"cursor-pointer group flex items-center px-4 py-2 text-sm"
												)}>
												<LockClosedIcon
													className=" mr-3 h-5 w-5 text-yellow-400 group-hover:text-yellow-500"
													aria-hidden="true"
												/>
												Bloquer
											</span>
										)}
									</Menu.Item>
								</>
							) : null}
							{rowData.statut ===
								"Actif" ? (
								<>
									<Menu.Item>
										{({
											active,
										}) => (
											<span
												onClick={() =>
													lockUserHandleClick(
														rowData
													)
												}
												className={classNames(
													active
														? "bg-gray-100 text-gray-900"
														: "text-gray-700",
													"cursor-pointer group flex items-center px-4 py-2 text-sm"
												)}>
												<LockClosedIcon
													className=" mr-3 h-5 w-5 text-yellow-400 group-hover:text-yellow-500"
													aria-hidden="true"
												/>
												Bloquer
											</span>
										)}
									</Menu.Item>
									<Menu.Item>
										{({
											active,
										}) => (
											<span
												onClick={() =>
													deleteUserHandleClick(
														rowData
													)
												}
												className={classNames(
													active
														? "bg-gray-100 text-gray-900"
														: "text-gray-700",
													"cursor-pointer group flex items-center px-4 py-2 text-sm"
												)}>
												<ArchiveBoxIcon
													className=" mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
													aria-hidden="true"
												/>
												Supprimer
											</span>
										)}
									</Menu.Item>
								</>
							) : null}
						</Menu.Items>
					</Transition>
				</Menu>
			)}

			<span className="sr-only">
				, {rowData.nom}
			</span>
		</td>
	};

	// close alert
	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// close alert after 10 secondes
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);

	// unLockUser
	const unLockUserHandleClick = (user) => {
		const { nom, prenom, code } = user;

		setcodeUserAction(code);

		unLockUser(code)
			.unwrap()
			.then((res) => {
				dispatch(
					setModal({
						modalType: "user",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `L'utilisateur ${nom} ${prenom} a été débloqué avec success`,
						},
					})
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// lockUser
	const lockUserHandleClick = (user) => {
		const { nom, prenom, code } = user;

		setcodeUserAction(code);

		lockUser(code)
			.unwrap()
			.then((res) => {
				dispatch(
					setModal({
						modalType: "user",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `L'utilisateur ${nom} ${prenom} a été bloqué avec success`,
						},
					})
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// deleteUser
	const deleteUserHandleClick = (user) => {
		const { nom, prenom, code } = user;

		setcodeUserAction(code);

		deleteUser(code)
			.unwrap()
			.then((res) => {
				dispatch(
					setModal({
						modalType: "user",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `L'utilisateur ${nom} ${prenom} a été archiver avec success`,
						},
					})
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			{/* alert componenet  */}
			{modalType === "user" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}

			<div className="px-8 sm:px-6 lg:px-8 mt-8">
				{/* header  */}
				<div className="sm:flex sm:items-center mb-6">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							Liste des utilisateurs
						</h1>
						<p className="mt-2 text-sm text-gray-700"></p>
					</div>

					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<Link to="adduser">
							<button
								type="button"
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
								Ajouter un utilisateur
							</button>
						</Link>
					</div>
				</div>

				{/* table  */}
				{isSuccess && (
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<DataTable value={users?.data} selectionMode={rowClick ? null : 'checkbox'} selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)} header={header} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
								paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
								filters={filters} globalFilterFields={['nom', 'prenom', 'role', 'email', 'statut']}
								emptyMessage="No customers found."
								currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} style={{ borderWidth: '1px', borderStyle: 'solid' }}>
								<Column selectionMode="multiple" headerStyle={{ width: '1%' }}></Column>

								<Column field="nom" sortable header="Nom" style={{ width: '1%' }}></Column>

								<Column field="prenom" header="Prenom" style={{ width: '2%' }}></Column>

								<Column field="email" header="Email" style={{ width: '2%' }}></Column>

								<Column field="defaultProfil.value" header="Role" style={{ width: '2%' }}></Column>


								<Column header="Status" style={{ width: '1%' }} body={statusTemplate} />
								<Column field="etat" header="En ligne" style={{ width: '1%' }}></Column>

								<Column header="Action" style={{ width: '1%' }} body={actionBodyTemplate} />

							</DataTable>

						</div>
					</div>
				)}

				{/* spinner  */}
				{isLoading && (
					<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
				)}
			</div>
		</>
	);
}
