/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description payement  step for insurance souscription
 * @component PayementStep
 */
import Button from "../../../../../UI/button/Button";
import OM from "../../../../../assets/orange-money.png";
import PORTE from "../../../../../assets/portefeuille.png";
import MOMO from "../../../../../assets/mobile-money.png";

import {
  useGetPaiementQuery,
} from "../../../../../features/api/apiSlice";


const PayementStep = ({ setMethodePayement, title, setStep, steps }) => {


  // get methodpaiement request
  const { data: methodPaiement, isSuccess } = useGetPaiementQuery();


  let paiement;

  // if (isSuccess) {
  // 	paiement = methodPaiement?.data

  // }


  // select payement method
  const handleChangeMethodePayement = (e) => {
    setMethodePayement(e.target.value);
  };

  return (
    <div className="sm:flex-auto">
      <p className="text-sm leading-5 text-gray-500">
        Choississez votre methode de payement{" "}
      </p>
      <fieldset className="mt-4">
        <legend className="sr-only">methode de payement</legend>
        <div className="space-y-4 sm:flex sm:items-start sm:space-y-0 sm:space-x-10">
          {/* list payement method  */}
          {methodPaiement?.data?.map((methode) => (
            <div key={methode.id_modePaiement} className="flex items-start">
              <input
                onChange={handleChangeMethodePayement}
                id={methode.id_modePaiement}
                name="operator"
                type="radio"
                // disabled={methode.id === "posa"}
                value={methode.nom}
                defaultChecked={methode.nom === "CM_ORANGEMONEY"}
                className="h-4 w-4 border-gray-300 text-secondary-600 focus:ring-secondary-500"
              />
              <label
                htmlFor={methode.id_modePaiement}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                <figure>
                  <figcaption>{methode.nom}</figcaption>
                  <img src={methode.image} alt={methode.nom} className="w-70 h-70 object-cover" height="70px" width="100px" />
                </figure>
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default PayementStep;
