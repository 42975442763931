/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list all sinistre page
 * @component IncidentAdminPage
 */

import React, { Fragment } from "react";
import {
	EllipsisHorizontalIcon,
	EyeIcon,
	TrashIcon,
} from "@heroicons/react/24/solid";
import { Transition, Menu } from "@headlessui/react";

import Spinner from "../UI/spinner/Spinner";

import { statusClassName } from "../lib/status";
import { subdescription } from "../lib/Assurance";

import { useGetAdminIncidentQuery } from "../features/api/apiSlice";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const IncidentAdminPage = () => {
	// get all incident request
	const {
		data: incidents,
		isLoading,
		isSuccess,
	} = useGetAdminIncidentQuery();

	return (
		<>
			<div className="px-8 sm:px-6 lg:px-8 mt-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-primary-800">
							Incidents
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							Liste de tous les incidents.
						</p>
					</div>
				</div>

				{isSuccess ? (
					incidents?.data?.length === 0 ? (
						<div className="flex flex-col items-center justify-center">
							{" "}
							<p className="text-3xl text-center text-gray-500">
								aucun incident{" "}
							</p>
						</div>
					) : (
						<div className=" pt-8 sm:pt-6 lg:pt-8 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full table-fixed divide-y divide-gray-300 z-0">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="sm:pl-6 py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
													incidentID
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													titre
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													description
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													produitCode
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													Date de création
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													Status
												</th>
												<th
													scope="col"
													className="relative py-3.5 pl-3 pr-4 sm:pr-6">
													<span className="sr-only">
														Action
													</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{incidents?.data?.map((item) => (
												<tr key={item.incidentID}>
													<td
														className={classNames(
															"whitespace-nowrap px-3 py-2 text-sm text-gray-800 font-medium sm:pl-6"
														)}>
														{item.incidentID}
													</td>
													<td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
														{item.titre}
													</td>
													<td className="whitespace-nowrap px-3 py-2 text-sm text-gray-800 font-medium">
														{subdescription(
															item.description
														)}
													</td>
													<td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
														{item.produitCode}
													</td>
													<td className="whitespace-nowrap px-3 py-2 text-sm text-gray-800 font-medium">
														{item.dateCreation}
													</td>
													<td
														className={statusClassName(
															item.statut
														)}>
														{item.statut}
													</td>

													<td className="whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
														<Menu
															as="div"
															className=" inline-block text-left">
															<div>
																<Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
																	<span className="sr-only">
																		Open
																		options
																	</span>
																	<EllipsisHorizontalIcon
																		className="h-7 w-7"
																		aria-hidden="true"
																	/>
																</Menu.Button>
															</div>

															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95">
																<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																	<div className="py-1">
																		<Menu.Item>
																			{({
																				active,
																			}) => (
																				<button
																					// onClick={updateAssue.bind(
																					// 	null,
																					// 	item.code
																					// )}
																					// href="#"
																					className={classNames(
																						active
																							? "bg-gray-100 text-gray-900"
																							: "text-gray-700",
																						"group flex items-center w-full px-4 py-2 text-sm"
																					)}>
																					<EyeIcon
																						className="mr-3 h-5 w-5 text-secondary-400 group-hover:text-secondary-500"
																						aria-hidden="true"
																					/>
																					Suivre
																				</button>
																			)}
																		</Menu.Item>
																		<Menu.Item>
																			{({
																				active,
																			}) => (
																				<button
																					// onClick={updateAssue.bind(
																					// 	null,
																					// 	item.code
																					// )}
																					// href="#"
																					className={classNames(
																						active
																							? "bg-gray-100 text-gray-900"
																							: "text-gray-700",
																						"group flex items-center w-full px-4 py-2 text-sm"
																					)}>
																					<TrashIcon
																						className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
																						aria-hidden="true"
																					/>
																					Terminer
																				</button>
																			)}
																		</Menu.Item>
																	</div>
																</Menu.Items>
															</Transition>
														</Menu>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)
				) : null}

				{isLoading && (
					<div className="flex justify-center">
						<Spinner className="w-10 h-10 text-primary-700" />
					</div>
				)}
			</div>
		</>
	);
};

export default IncidentAdminPage;
