/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance inch souscribe by user page
 * @component ListAssuranceByIdCategorie
 */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../../../features/user/user.slice';
import styled from 'styled-components';

import { useGetAssurancesByCategorieQuery } from '../../../../../../features/api/apiSlice';

import AssuranceCard from './AssuranceCard';
import Pagination from '../../../../../../UI/pagination';
import Spinner from '../../../../../../UI/spinner/Spinner';
import usePagination from '../../../../../../hooks/usePagination';
import BackIllustration from '../../../../../Utilities/BackIllustration';
import BreadCumbs from '../../../../../Utilities/BreadCumbs';
import WriteAnimation from '../../../../../Utilities/WriteAnimation';
import SubscriptionBodyTitles from '../../../../../Utilities/subscriptionBodyTitles';
import SubscriptionBodyFilterIllustrated from '../../../../../Utilities/subscriptionBodyFilterIllustrated';
import SubscriptionCardsTitle from '../../../../../Utilities/subscriptionCardsTitle';
import FilterIllustration from '../../../../../../assets/Select-amico.svg';

import { filterLogic } from '../assuranceCategories/logic';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    margin: auto;
    @media screen and (max-width: 500px) {
      gap: 2em;
    }
    @media screen and (max-width: 1023px) {
      gap: 1.3em;
    }
`;

const StyledBody = styled.div`
    width: 850px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    @media screen and (max-width: 1023px) {
      width: 100%;
      max-width: 620px;
    }
`;

const CardContainer = styled.div`
    display: grid;
    gap: 5em;
    grid-template-columns: 250px 250px 250px;
    grid-auto-rows: auto;
    @media screen and (max-width: 1023px) {
      grid-template-columns: 285px 285px;
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: 350px;
      gap: 3em;
    }
`;

const ListAssurancesByIdCategory = () => {
  // getting user
  const user = useSelector(getUser);

  const navigate = useNavigate()
  const params = useParams()
  // insurances state
  const [assurances, setassurance] = useState([])
  // search stata
  const [queryAssurance, setQueryAssurance] = useState('')
  // get insurance by category request
  const { data, isSuccess, isLoading } = useGetAssurancesByCategorieQuery(
    params?.id
  );

  console.log("Assurances by category !")

  // set data insurances
  useEffect(() => {
    if (isSuccess) {
      setassurance(
        data?.data?.map(item => {
          return {
            id: item.idAssurance,
            code: item.code,
            description: item.shortDescription,
            duree: item.duree,
            images: item.image,
            titre: item.nom,
            etat: item.statut,
            prix: parseInt(item.prix),
            categorie: item.categorie,
          }
        })
      )
    }

    return () => {}
  }, [isSuccess, data])

  // transaction  per page
  const itemsPerPage = 9;

  const filteredAssurance = filterLogic(queryAssurance, assurances, "titre");

  // pagination hook
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    dataLength,
    beginItem,
    endItem
  } = usePagination(filteredAssurance, itemsPerPage);

  const handleClick = () => {
    // Code à exécuter lorsque le composant BreadCumbs est cliqué
    navigate('/assurances/categorie')
  }

  if (isLoading) {
    return (
      <div className='flex flex-1 items-center justify-center w-full h-full'>
        <Spinner className='h-10 w-10 text-primary-700 m-8 ' />
      </div>
    );
  }
  
  if (isSuccess) {
    return (
      <div>
        <BackIllustration />

        <BreadCumbs
          title="Choix d'assurance"
          onClick={handleClick}
          currentPage='assurance'
        />

        <Container>
          <SubscriptionBodyTitles
            bodyText={[`Bravo ${user?.prenom}! Maintenant passons à l'étape suivante.`]}
            title="Choisis une Assurance"
          />

          {/* <WriteAnimation text="Bienvenue mr dzede, quel type d'assurance vous interesse"/> */}

          <SubscriptionBodyFilterIllustrated
            setQueryData={setQueryAssurance}
            data={assurances}
            filterProperty="titre"
            filterLabel="Choisissez une assurance"
            filterIllustration={FilterIllustration}
            message="Nous avons une pléthore d'assurances à te proposer 🤪; choisis dans la liste celle qui correspond le mieux à ton besoin ! 👍."
          />

          <StyledBody>
            <SubscriptionCardsTitle content="Liste des produits d'assurance" />

            <CardContainer className='mb-2 xs:mx-auto'>
              {filteredAssurance.map((item, index) => {
                return (
                  <AssuranceCard
                    key={index}
                    duree={item.duree}
                    description={item.description}
                    titre={item.titre}
                    images={item.images.url}
                    categorie={item.categorie.nom.toLowerCase()}
                    id={item.id}
                    code={item.code}
                    prix={item.prix}
                  />
                )
              })}
            </CardContainer>
          </StyledBody>

          {/* pagination  */}
          {filteredAssurance?.length > itemsPerPage && (
            <Pagination
              next={next}
              prev={prev}
              jump={jump}
              currentData={currentData}
              currentPage={currentPage}
              maxPage={maxPage}
              dataLength={dataLength}
              itemsPerPage={itemsPerPage}
              beginItem={beginItem}
              endItem={endItem}
            />
          )}
        </Container>
      </div>
    );
  } else {

    return (
      <h1>An Error Occured</h1>
    )
  };
}

export default ListAssurancesByIdCategory
