/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description login page
 * @component Login
 */
import React, { useState, useLayoutEffect, } from 'react'
import { Link } from 'react-router-dom'

import Logo from '../../components/logo/Logo'
import Banner from '../../components/auth/Banner'
import Title2 from '../../components/title/Title2'
import LoginForm from '../../components/auth/LoginForm'

import Alert from '../../UI/alert'

import { btnLink } from '../../styles'

import backgroung from "../../assets/banner1.jpg"

const Login = () => {
  // alert state 
  const [alert, setalert] = useState({
    text: "",
    error: false,
    isAlert: false
  })


  // close alert after 10 secondes 
  useLayoutEffect(() => {
    if (alert.isAlert) {
      setTimeout(() => {
        setalert(prevState => {
          return {
            ...prevState,
            isAlert: false,
            error: false,
            text: ""
          }
        })
      }, 10000)
    }

    return () => {
      clearTimeout()
    };
  }, [alert.isAlert])



  return (
    <div className="flex  min-h-screen">
      <div className="flex relative  flex-1 flex-col justify-start pt-12  px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
        <div className=" mx-auto w-full max-w-sm lg:w-96">
          <div className='absolute top-0 left-0 w-full mb-4'>
            {/* alert componenet  */}
            {alert.isAlert && <Alert alert={alert} setalert={setalert} />}
          </div>

          <div>
            <Logo />
            <Title2 text={"Connexion"} />
            </div>
            
          <div className="mt-8  ">
            {/* login form  */}
            <LoginForm setalert={setalert} />

            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">Vous n'avez pas de compte ?</span>
              </div>
            </div>

            <div className='mt-4'>
              <Link to="/inscription" className={btnLink.secondary}> S'inscrire</Link>
            </div>
          </div>
        </div>
      </div>

      {/* banner  */}
      <Banner banner={backgroung} />
    </div>
  )
}

export default Login