/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance inch souscribe by user page
 * @component AssurancesInchSouscribePage
 */

import Main from "../../../components/layouts/main";
import AssurancesInchSouscribe from "../../../components/layouts/main/assurances/client/AssurancesInchSouscribe";

const AssurancesInchSouscribePage = () => {
	return <Main children={<AssurancesInchSouscribe />} />;
};

export default AssurancesInchSouscribePage;
