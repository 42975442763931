/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description update insurance page
 * @component SetAssurancePage
 */

import React from "react";

import SetAssurance from "../../../components/layouts/main/assurances/provider/SetAssurance";

const SetAssurancePage = () => {
	return <SetAssurance />;
};

export default SetAssurancePage;
