/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component InputTextQuestion
 * @description composant qui genere les champs de formulaire de type radio ou checkbox personnaliser
 * @props Icon, idform, formsEntities
 * @
 */
import { XMarkIcon } from "../svg";

const RadioQuestion = ({ Icon, currentQuestion, setCurrentQuestion }) => {
	function addOptionHandler() {
		const addDefault = {
			"label": "",
			"prix": 0,
			"format": null,
			"subquestions": []
		};

		const updatedOptions = currentQuestion?.options;
		updatedOptions.push(addDefault);

		setCurrentQuestion({
			...currentQuestion,
			options: updatedOptions
		})
	};

	function optionChangeHandler(event, index, type) {
		const value = event.target.value;

		const currentOption = currentQuestion?.options[index];
		
		if (type === "label") {
			currentOption.label = value;
		};

		if (type === "prix") {
			currentOption.prix = parseInt(value);
		};

		setCurrentQuestion(prevQuestion => {
			const updatedOptions = [...prevQuestion.options];
			updatedOptions[index] = currentOption;
			
			return {
				...prevQuestion,
				options: updatedOptions
			};
		});
	};

	function optionRemoveHandler(index) {
		const updatedOptions = currentQuestion?.options;
		updatedOptions.splice(index, 1);

		setCurrentQuestion({
			...currentQuestion,
			options: updatedOptions
		})
	};

	return (
		<div>
			<div className="sm:space-y-4 space-y-8">
				{currentQuestion?.options?.map((option, index) => {
					return (
						<div
							className="flex sm:items-center items-start group gap-y-4 sm:gap-x-4 flex-col sm:flex-row "
							key={index}
						>
							<div className="flex items-center group grow">
								{/* icon d'identification du type de champs d'entree ( radio ou checkbox ) */}
								<Icon className="sm:h-6 sm:w-6 h-4 w-4  text-gray-500 " />

								{/* label option */}
								<label
									htmlFor={option.label}
									className="md:grow"
								>
									{/*champs de saisi de l'option  */}
									<input
										type="text"
										onChange={(event) => optionChangeHandler(event, index, "label")}
										value={option.label}
										name={option.label}
										id={option.label}
										className="block w-full border-0 focus:border-0 focus:border-b-2  focus:border-secondary-600 focus:ring-0 sm:text-md hover:border-gray-400 hover:border-0 hover:border-b"
										placeholder={`Option - ${index + 1}...`}
									/>
								</label>
							</div>

							<div className="flex items-center">
								<label
									htmlFor="prix"
									className="sm:pl-8 ">
									{/*champs de saisi du prix  */}
									<input
										onChange={(event) => optionChangeHandler(event, index, "prix")}
										type="number"
										name="prix"
										id="prix"
										min={0}
										value={option.prix > 0 ? option.prix : ""}
										className="block w-full border-0 focus:border-b bg-gray-50 focus:border-secondary-600 focus:ring-0 sm:text-md text-gray-700 h-8 sm:h-10"
										placeholder={"Prix (Fcfa / %)"}
									/>
								</label>

								{/* affiche le button supprimer uniquement si le nombre d'option est superieur à 0 */}
								{index !== 0 || currentQuestion?.options?.length > 1 ? (
									<XMarkIcon
										className="h-6 w-6  ml-4 sm:ml-0 hover:text-gray-700 text-gray-500 cursor-pointer"
										onClick={optionRemoveHandler}
									/>
								) : null}
							</div>
						</div>
					);
				})}

				{/* ajouter une nouvelle option  */}
				<div className="flex items-center gap-x-3 w-full">
					<Icon className="sm:h-6 sm:w-6 h-4 w-4 text-gray-500 " />
					<button
						type="button"
						onClick={addOptionHandler}
						className="border-0 outline-one text-gray-500 cursor-text text-sm  hover:border-gray-400 hover:border-0 hover:border-b">
						{" "}
						Ajouter une option
					</button>
				</div>
			</div>
		</div>
	);
};

export default RadioQuestion;
