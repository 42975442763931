/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add chat modal
 * @component NewChatModal
 */
import { Fragment, useEffect, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import {
	CheckIcon,
	ChevronUpDownIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../../../styles";
import Spinner from "../../../../UI/spinner/Spinner";
import Button from "../../../../UI/button/Button";
import {
	useAddChatMutation,
	useGetAllUsersQuery,
} from "../../../../features/api/apiSlice";

const NewChatModal = ({ openNewChat, setopenNewChat }) => {
	// get user request
	const { data: users, isLoading, isSuccess } = useGetAllUsersQuery();

	// add chat request
	const [addChat, { isLoading: addchatIsLoading }] = useAddChatMutation();

	const [query, setQuery] = useState("");
	const [usersData, setusersData] = useState([]);
	const [selectedUser, setselectedUser] = useState(null);

	useEffect(() => {
		if (isSuccess) {
			const newUsers = users.data.map((items) => {
				return {
					name: `${items.nom} ${items.prenom}`,
					id: items.code,
				};
			});
			setusersData(newUsers);
		}
		return () => {};
	}, [isSuccess, users]);

	const filterdUser =
		query === ""
			? usersData
			: usersData.filter((user) => {
					return user.name
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	//   select user
	const handleChangeselectedUser = (value) => {
		setselectedUser(value);
	};

	// add chat
	const newChatHandleClick = (e) => {
		e.preventDefault();
		addChat({ destinataire: [selectedUser.id] })
			.unwrap()
			.then((res) => {
				handleCloseModal();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// close modal

	const handleCloseModal = () => {
		setopenNewChat(false);
	};

	return (
		<Transition.Root
			show={openNewChat}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setopenNewChat}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel
								as="form"
								onSubmit={newChatHandleClick}
								className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={() => setopenNewChat(false)}>
										<span className="sr-only">Close</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button>
								</div>
								<Dialog.Title
									as="h1"
									className="text-xl font-semibold mb-2">
									Créer une nouvelle discussion
									<p className="mt-2 text-sm text-gray-700">
										Veillez selectionner l'utilisateur pour
										demarreer une nouvelle discussioin.
									</p>
								</Dialog.Title>
								{isSuccess && (
									<Combobox
										as="div"
										value={selectedUser}
										className="pb-6 my-8"
										onChange={handleChangeselectedUser}>
										<Combobox.Label className="block text-sm font-medium text-gray-700">
											Saisir ou selectionner l'utilisateur
										</Combobox.Label>
										<div className="relative mt-1">
											<Combobox.Input
												placeholder="Veillez saisir ou selectionner l'utilisateur "
												className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
												onChange={(event) =>
													setQuery(event.target.value)
												}
												displayValue={(user) =>
													user?.name
												}
											/>
											<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
												<ChevronUpDownIcon
													className="h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
											</Combobox.Button>
											{filterdUser.length > 0 && (
												<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
													{filterdUser.map((user) => (
														<Combobox.Option
															key={user.id}
															value={user}
															className={({
																active,
															}) =>
																classNames(
																	"relative cursor-default select-none py-2 pl-8 pr-4",
																	active
																		? "bg-secondary-600 text-white"
																		: "text-gray-900"
																)
															}>
															{({
																active,
																selected,
															}) => (
																<>
																	<span
																		className={classNames(
																			"block truncate",
																			selected &&
																				"font-semibold"
																		)}>
																		{
																			user.name
																		}
																	</span>
																	{selected && (
																		<span
																			className={classNames(
																				"absolute inset-y-0 left-0 flex items-center pl-1.5",
																				active
																					? "text-white"
																					: "text-secondary-600"
																			)}>
																			<CheckIcon
																				className="h-5 w-5"
																				aria-hidden="true"
																			/>
																		</span>
																	)}
																</>
															)}
														</Combobox.Option>
													))}
												</Combobox.Options>
											)}
										</div>
									</Combobox>
								)}
								{isLoading && (
									<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
								)}
								<div className=" flex items-center justify-end  gap-x-6">
									<Button
										children="Annuler"
										type="button"
										className="max-w-max px-6"
										onClick={() => handleCloseModal()}
									/>
									<Button
										children="Ajouter"
										className="max-w-max px-6"
										button="secondary"
										loading={addchatIsLoading}
										type="submit"
									/>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default NewChatModal;
