/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  Expert opinion consultation
 * @component AvisExpertPage
 */
import Main from "../../../components/layouts/main";
import AvisExpert from "../../../components/layouts/main/consultations/doctor/AvisExpert";

const AvisExpertPage = () => {
	return <Main children={<AvisExpert />} />;
};

export default AvisExpertPage;
