/**
 * @author assan zidan
 * @description user authorisation
 * @email nsangouassanzidan@gmail.com
 */

import {
  CalendarIcon,
  HomeIcon,
  UsersIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  MdHealthAndSafety,
  ArrowsRightLeftIcon,
  ChatBubbleOvalLeftIcon,
  // BuildingStorefrontIcon,
  // MdOutlineDoNotDisturbOff,
} from "../UI/svg";

import {
  BanknotesIcon,
  UserIcon,
  WrenchScrewdriverIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/20/solid";

// default authorization
export const dashboardNavigation = [
  {
    name: "Tableau de bord",
    icon: HomeIcon,
    current: false,
    to: "/dashboard",
  },
];

// Admin user authorization
export const adminNavigation = [
  ...dashboardNavigation,
  {
    name: "Comptes",
    icon: UsersIcon,
    current: false,
    children: [
      { name: "Tous les utilisateurs", to: "/users" },
      { name: "Ajouter un utilisateur", to: "/users/adduser" },
    ],
  },
  {
    name: "Assurances",
    icon: MdHealthAndSafety,
    current: false,
    children: [
      { name: "Mes assurances", to: "/mes-assurances" },
      { name: "Toutes Les assurances", to: "/all-assurances" },
      { name: "Tous Les sinistres", to: "/assurances/all-sinistres" },
    ],
  },
  {
    name: "Consultations",
    icon: CalendarIcon,
    current: false,
    /*to: "/consultations/mes-consultations",*/
    children: [
      { name: "Liste des Consultations", to: "/consultations/mes-consultations" },
      { name: "Liste des Compétences", to: "/consultations/les-competences" },
      { name: "Liste des motifs", to: "/mes-liste-des-motifs" },
    ],
  },
  {
    name: "Echanges",
    icon: ChatBubbleOvalLeftIcon,
    current: false,
    to: "/echanges",
  },
  {
    name: "Operations",
    icon: ArrowsRightLeftIcon,
    current: false,
    children: [
      { name: "Mes transactions", to: "/operations" },
      { name: "Toutes les Transactions", to: "/all-operations" },
      { name: "Mes règlements", to: "/operations/reglements" },
      { name: "Mode de paiement", to: "/operations/Modepaiement" },
      { name: "Ajouter un mode de paiement", to: "/operations/Addmode" },
    ],
  },
  // {
  // 	name: "Incidents",
  // 	icon: MdOutlineDoNotDisturbOff,
  // 	current: false,
  // 	to: "/all-incidents",
  // },
  // {
  // 	name: "Boutique",
  // 	icon: BuildingStorefrontIcon,
  // 	current: false,
  // 	to: "/boutique",
  // },
];

// Insurer user authorization
export const assureurNavigation = [
  ...dashboardNavigation,
	{
		name: "Assurances",
		icon: MdHealthAndSafety,
		current: false,
		children: [
			{ name: "Mes assurances", to: "/mes-assurances" },
      { name: "Ajouter une assurance", to: "/mes-assurances/nouveau/add" },
			{ name: "Ajouter des medecins", to: "/add-doctor" },
			{ name: "sinistre declares", to: "/sinistrebyassurance" },
		],
	},
	{
		name: "Echanges",
		icon: ChatBubbleOvalLeftIcon,
		current: false,
		to: "/echanges",
	},
	{
		name: "Operations",
		icon: ArrowsRightLeftIcon,
		current: false,
		children: [
			{ name: "Mes transactions", to: "/operations" },
			{ name: "Mes règlements", to: "/operations/reglements" },	
		],
	},
	// {
	// 	name: "Incidents",
	// 	icon: MdOutlineDoNotDisturbOff,
	// 	current: false,
	// 	to: "/incidents",
	// },
	// {
	// 	name: "Boutique",
	// 	icon: BuildingStorefrontIcon,
	// 	current: false,
	// 	to: "/boutique",
	// },
];

// Client user authorization
export const simpleUserNavigation = [
  ...dashboardNavigation,
  {
    name: "Assurances",
    icon: MdHealthAndSafety,
    current: false,
    children: [
      {
        name: "Souscrire à une assurance",
        to: "/assurances/categorie",
      },
      {
        name: "Mes souscriptions",
        to: "/assurances/mes-produits",
      },
      {
        name: "Sinistres",
        to: "/assurances/sinistres",
      },
    ],
  },
  {
    name: "Consultations",
    icon: CalendarIcon,
    current: false,
    children: [
      {
        name: "Prendre un RDV",
        to: "/consultations/canal",
      },
      {
        name: "Second avis",
        to: "/consultations/canal?ref=second-avis",
      },
      {
        name: "Mes consultations",
        to: "/consultations/mes-consultations",
      },
    ],
  },
  {
    name: "Echanges",
    icon: ChatBubbleOvalLeftIcon,
    current: false,
    to: "/echanges",
  },
  {
    name: "Operations",
    icon: ArrowsRightLeftIcon,
    current: false,
    children: [
      { name: "Mes transactions", to: "/operations" },
      { name: "Mes règlements", to: "/operations/reglements" },
    ],
  },
  // {
  // 	name: "Incidents",
  // 	icon: MdOutlineDoNotDisturbOff,
  // 	current: false,
  // 	to: "/incidents",
  // },
  // {
  // 	name: "Boutique",
  // 	icon: BuildingStorefrontIcon,
  // 	current: false,
  // 	to: "/boutique",
  // },
];

// Family user authorization
export const simpleUserpriviligeNavigation = [
  ...simpleUserNavigation,
  {
    name: "Membres",
    icon: UsersIcon,
    current: false,
    to: "/listmembers",
  },
];

// Doctor user authorization
export const doctorNavigation = [
  ...dashboardNavigation,
  {
    name: "Consultations",
    icon: CalendarIcon,
    current: false,
    children: [
      {
        name: "Mes Compétences",
        to: "/consultations/competences",
      },
      {
        name: "Mon Agenda",
        to: "/consultations/mon-agenda",
      },
      {
        name: "Mes Consultations",
        to: "/consultations/mes-consultations",
      },
      {
        name: "Mes demandes d'avis",
        to: "/consultations/mes-demandes-avis",
      },
      {
        name: "Mes avis experts",
        to: "/consultations/mes-avis-expert",
      },
    ],
  },
  {
    name: "Operations",
    icon: ArrowsRightLeftIcon,
    current: false,
    children: [
      { name: "Mes transactions", to: "/operations" },
      { name: "Mes règlements", to: "/operations/reglements" },
    ],
  },
  // {
  // 	name: "Incidents",
  // 	icon: MdOutlineDoNotDisturbOff,
  // 	current: false,
  // 	to: "/incidents",
  // },
];

// menu user item
export const userNavigation = [
  {
    name: "Mon compte",
    to: "/dashboard/mon-compte",
    icon: UserIcon,
  },
  {
    name: "Recharge",
    to: "/dashboard/recharge",
    icon: BanknotesIcon,
  },
  {
    name: "Paramètre",
    to: "/parametres",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Déconnexion",
    to: "/",
    type: "button",
    onClick: "{() => setOpen(true)}",
    icon: ArrowUpOnSquareIcon,
  },
];

// secondary navigation
export const simpleUsersecondaryNavigation = [
  {
    name: "Aide",
    to: "/dashboard/aide",
    icon: QuestionMarkCircleIcon
  },
  {
    name: "Régle de confidentialité",
    to: "/dashboard/regle-confidentialite",
    icon: ShieldCheckIcon,
  },
];

// admin secondary navigation
export const adminSecondaryNavigation = [
  {
    name: "Paramètres",
    icon: CogIcon,
    current: false,
    children: [
      { name: "Categories-Assurances", to: "/parametres/categories-assurance" },
    ]
  }
];
