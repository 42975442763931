/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component Spinner
 * @description spinner simple
 * @props  className
 * @
 */

import React from "react";

import { classNames } from "../../styles";
import { AiOutlineLoading3Quarters } from "../svg";

const Spinner = ({ className }) => {
	return (
		<AiOutlineLoading3Quarters
			className={classNames("animate-spin", className)}
		/>
	);
};

export default Spinner;
