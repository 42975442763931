import { createSlice } from "@reduxjs/toolkit";

// initial paiement
const initialState = {
	operation: null,
};

// payement slice
const payement = createSlice({
	name: "payement",
	initialState,
	reducers: {
		// set operation mode
		setOperation(state, action) {
			state.operation = action.payload;
		},
		// reset operation mode
		resetOperation(state, action) {
			return initialState;
		},
	},
});

// get operation mode
export const getOperation = (state) => state.payement.operation;

export const { setOperation, resetOperation } = payement.actions;

export default payement.reducer;
