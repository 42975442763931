import SelectPieces from "../SelectPieces";
import Formulaire from "../Formulaire";
import { useGetAssurancesReductionsQuery } from "../../../../../../features/api/apiSlice";
import DataRow from "../../../../../Utilities/DataRow";
import Spinner from "../../../../../../UI/spinner/Spinner";
import { useEffect } from "react";

function DonneesClient({ removeItem, assurance, setAssurance, handleSelectChange, isEdited, setIsEdited }) {
    const { pieces } = assurance;

    // Getting assurances réductions
    const { data: reductionData, isLoading, isSuccess } = useGetAssurancesReductionsQuery();
    // list of assurance types
	const reductions = reductionData?.data?.map((item) => {
		return {
			id: item.idReduction,
			name: item.code,
			description: item.description,
			valeur: item.valeur,
            taux: item.taux,
		};
	}) || [];

    useEffect(() => {
		setIsEdited(false)
	}, []);

    // add piece
	const addPieces = (value) => {
		let newTable;
		let newPieces;
		if (value && (!pieces || pieces?.length === 0)) {
			newTable = [];
			newPieces = newTable.concat(value);
			setAssurance({
				...assurance,
				pieces: newPieces,
			});

            setIsEdited(true);
		} else {
			const isValue = pieces.filter((item) => item.id === value?.id);
			if (isValue.length === 0 && value) {
				newTable = pieces;
				newPieces = newTable.concat(value);
				setAssurance((prevState) => {
					return {
						...prevState,
						pieces: newPieces,
					};
				});

                setIsEdited(true);
			} else {
				if (isValue[0]?.id === value?.id) {
					return;
				} else {
					newTable = pieces;
					newPieces = newTable.concat(value);
					setAssurance((prevState) => {
						return {
							...prevState,
							pieces: newPieces,
						};
					});

                    setIsEdited(true);
				};
			};
		};
	};

    let reductionDefault;
    if (assurance?.reduction) {
        reductionDefault = (
            `Code : ${assurance?.reduction[0]?.name} || Valeur : ${assurance?.reduction[0]?.valeur > 0 ? `- ${assurance?.reduction[0]?.valeur} Fcfa -` : ""} ${assurance?.reduction[0]?.taux > 0 ? `- ${assurance?.reduction[0]?.taux} % -` : ""}`
        );
    };

    function reductionAdapter(reduction) {
        if (reduction) {
            return `N°: ${reduction.name} | valeur: ${reduction?.valeur ? reduction?.valeur : 0} Fcfa - Taux: ${reduction?.taux ? reduction.taux : 0} %`;
        };
    };

    return (
        <div className="space-y-12">
            {/* questions form section */}
            <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Questionnaire de souscription</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    Veuillez renseigner les questions qui seront posées aux client a fin de configurer leur souscription
                </p>

                <div className="mt-10 col-span-full">
                    <Formulaire
                        assurance={assurance}
                        setAssurance={setAssurance}
                        setIsEdited={setIsEdited}
                    />
                </div>
            </div>

            {/* Attachement form section */}
            <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Pieces à fournir par le client</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Vous devez selectionner les listes des pieces qui seront demandées au client lors de la souscription</p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
				    <div className="col-span-full">
                        <label htmlFor="pieces" className="block text-sm font-medium leading-6 text-gray-900">
                            Pieces à joindre par le client
						</label>
                        <div className="mt-2">
                            <SelectPieces
                                onChange={addPieces}
                                ariaLabel="pieces"
                            />
                        </div>
                    </div>

                    {/* Pieces list */}
				    <div className="col-span-full bg-gray-50">
                        <h3 className="text-base font-semibold leading-7 text-gray-500">
                            Listes des pieces à joindre par le client
                        </h3>

                        <ul className="mt-2 flex flex-col gap-4">
                            {assurance?.pieces && assurance.pieces.length > 0 ? (
                                assurance?.pieces?.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <DataRow
                                                key={index}
												title={item.value}
												fileName={item.description}
												type={``}
												handleRemove={() => removeItem(index, item, "pieces")}
                                            />
                                        </li>
                                    );
                                })
                            ) : (
                                <h3>Aucune pièce ajoutée</h3>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            {/* Attachement form section */}
            <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Reductions</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Vous pouvez appliquer si vous le souhaitez une réduction à ce produit.</p>
                
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                        <label htmlFor="reduction" className="block text-sm font-medium leading-6 text-gray-900">
                            Appliquer une reduction
                        </label>
                        <div className="mt-2">
                            {isLoading ? (
                                <Spinner className="h-8 w-8 text-primary-700 m-auto h-full" />
                            ) : (
                                <select
                                    id="reduction"
                                    name="reduction"
                                    autoComplete="reduction-name"
                                    defaultValue={assurance?.reduction[0]?.name}
                                    onChange={(event) => handleSelectChange(event, reductions)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    <option disabled selected hidden>-- Choisissez un taux --</option>
                                    {reductions.length > 0 ? (
                                        reductions.map((reduction, index) => (
                                            <option key={index} className="flex gap-4 items-center" value={reduction.name} title={reduction.description} >{
                                                reductionAdapter(reduction)
                                            }</option>
                                        ))
                                    ) : (
                                        <option>-- Aucune reduction trouvée --</option>
                                    )}
                                </select>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonneesClient;
