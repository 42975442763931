import React, { useEffect } from 'react';
import Photo from '../../../../../../assets/recap.png';
import styled from 'styled-components';

const BannerImage = styled.img`
  width: 15%;
  height: auto;
  position: relative;
  object-fit: cover;
`

const StyledH1 = styled.h1`
  font-weight: 700;
  font-size: 5em;
  text-align:center;
  @media screen and (max-width: 1023px) {
      font-size : 3em;
  }
`

const StyledH2 = styled.h1`
  font-weight: 600;
  font-size: 3em;
  text-decoration:underline;
  margin-block:25px;
  @media screen and (max-width: 1023px) {
      font-size : 2em;
  }
`;

const Paragraph = styled.p`
  font-weight: 500;
  font-size: 1.7em;
  @media screen and (max-width: 1023px) {
      font-size : 1.5em;
  }
`;

const Li = styled.li`
  font-weight: 500;
  font-size: 1.7em;
  margin-inline:20px;
  margin-block:10px;
  @media screen and (max-width: 1023px) {
      font-size : 1.5em;
  }
`;

const Recapdocumentstep = ({
  settype_contrat,
  setdescription,
  setduree,
  setfournisseur,
  setcategorie,
  formAnswers,
  setnom,
  assurance,
  beneficiaires,
  setIsNextButtonWorking
}) => {
  // Setting steps to localStorage
  localStorage.setItem('currentStep', "2");

  setIsNextButtonWorking(true);

  useEffect(() => {
    setfournisseur();
    setnom();
    setdescription();
    settype_contrat();
    setcategorie();
    setduree();

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const row2 = formAnswers.map(answer => (
    <span>
      {answer.choix.label}
    </span>
  ));

  return (
    <div style={{ width: "100%" }}>
      <div className='sm:flex sm:items-center justify-center '>
        <div className='sm:flex-auto '>
          <div>
            <div className='bg-primary-800 p-5 flex'>
              <BannerImage src={Photo} alt='logo Fournisseur' />

              <StyledH1 className='text-white mx-auto'>{assurance?.assureur?.nom}</StyledH1>
            </div>

            <div className='border-2 border-primary-800 m-8 p-8 xs:m-2 xs:p-4'>
              <StyledH1 className=''>{assurance?.nom}</StyledH1>
              <StyledH2 className='text-primary-800'>Object de la couverture</StyledH2>
              <Paragraph className='mx-4'> {assurance?.description ? assurance.description : "Pas de description"} </Paragraph>
              <ul className='list-decimal '>
                <Li>
                  Durée du contrat : {assurance?.duree} jours
                </Li>
                <Li>
                  Catégorie d'assurance : {assurance?.categorie?.nom}
                </Li>
              </ul>
              <StyledH2 className='text-primary-800'>Garanties Obtenues</StyledH2>
              <ul className='list-decimal '>
                {assurance?.listeServices.length === 0 ? (
                  <h1 className='text-xl font-meduim '>Aucune Garantie</h1>
                ) : (
                  assurance?.listeServices.map((service, index) => {
                    return (
                      <Li className='mx-6 my-4 text-xl font-meduim' key={index}>
                        {service.nom}
                      </Li>
                    )
                  })
                )}
              </ul>

              <StyledH2 className='text-primary-800'>Beneficiaires</StyledH2>
              <ul className='list-decimal'>
                {beneficiaires.length === 0 ? (
                  <h1 className='text-xl font-meduim '>Aucune bénéficiaire associé</h1>
                ) : (
                  beneficiaires.map((beneficiaire, index) => {
                    return (
                      <Li className='mx-6 my-4 text-lg font-meduim' key={index}>
                        {beneficiaire.nom} {beneficiaire.prenom}
                      </Li>
                    )
                  })
                )}
              </ul>

              <StyledH2 className='text-primary-800'>Pieces jointes</StyledH2>
              <ul className='list-decimal'>
                {assurance.piecesAJoindre.length === 0 ? (
                  <h1 className='text-xl font-meduim '>Aucune Piece Jointe</h1>
                ) : (
                  assurance.piecesAJoindre.map((piece, index) => {
                    return (
                      <Li className='mx-6 my-4 text-lg font-meduim' key={index}>
                        {piece.nomPiece}
                      </Li>
                    )
                  })
                )}
              </ul>

              <StyledH2 className='text-primary-800'>Options de Paiement choisies</StyledH2>
              <ul className='list-decimal'>
                {assurance.payOptions.length === 0 ? (
                  <h1 className='text-xl font-meduim '>Payement unique par défaut</h1>
                ) : (
                  assurance.payOptions.map((option, index) => {
                    return (
                      <Li className='mx-6 my-4 text-lg font-meduim' key={index}>
                        {option.nom}
                      </Li>
                    )
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recapdocumentstep
