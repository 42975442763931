import styled from "styled-components";

const PayingMode = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1.2em;
    font-weight: 400;
`;

const Amount = styled.div`
    display: ${({ activated }) => activated ? "flex" : "none"};
    flex-direction: column;
    margin: 1em 1.5em;
    gap: 1.5em;
`;

const Description = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4em;
`;

const Paragraph = styled.p`
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 1em;
`;

const Illustration = styled.img`
    width: 200px;
`;

function PayingCanalInput({ name, code, illustration, active, selectedCanal, handleChekedCanal, setCanal, canal }) {
    const activated = selectedCanal === active ? true : false;

    function handleNumberChange(event) {
        const number = event.target.value;

        setCanal({
            ...canal,
            number: number,
        });
    };

    return (
        <div>
            <PayingMode>
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    onChange={(event) => handleChekedCanal(event, active, illustration, code)}
                    className="h-4 w-4 border-secondary-600 text-secondary-600 focus:ring-secondary-500"
                    checked={activated}
                    style={{ cursor: "pointer" }}
                />
                <label
                    htmlFor={name}
                    style={{ cursor: "pointer" }}
                >
                    {name}
                </label>
            </PayingMode>
            
            <Amount
                activated={activated}
            >
                <Description>
                    {name !== "Pocket" && (
                        <div>
                            <Paragraph>
                                Veillez saisir le numero du compte à débiter.
                            </Paragraph>

                            <input
                                id={`${name}_number`}
                                name={`${name}_number`}
                                type="tel"
                                placeholder="655555555"
                                value={canal.number && canal.name === name ? canal.number : undefined}
                                onChange={(event) => handleNumberChange(event)}
                                className="border-secondary-600 text-secondary-600"
                                // disabled
                            />
                        </div>
                    )}
                    <Illustration src={illustration} alt="payment canal illustration" />
                </Description>
            </Amount>
        </div>
    );
};

export default PayingCanalInput;
