/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description expert opinion
 * @component AvisExpert
 */
import Button from "../../../../../UI/button/Button";
import SingleConsultation from "./SingleConsultation";
import {
	Fragment,
	useState,
	useEffect,
	useLayoutEffect,
	useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
	useAddBilanConsultationMutation,
	useEndConsultationQuery,
} from "../../../../../features/api/apiSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	setModal,
	getModalProps,
	getModalType,
	initialModal,
} from "../../../../../features/feedBack/modal.slice";
import { getOperation } from "../../../../../features/user/payement.slice";
import { Alert } from "../../../../feedback/ModalWithoutAction";
import { Alert as AlertBilan } from "../../UserRecharge/Recharge";
import { getToken } from "../../../../../features/user/auth.slice";
import axiosInstance from "../../../../../apis/inchAssur";
import { MailtoModal } from "./MailtoModal";

export const Modal = ({ open, setOpen }) => {
	const [skip, setSkip] = useState(true);

	const { code } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { isSuccess, isLoading } = useEndConsultationQuery(code, {
		skip,
	});

	const endConsultationHandleClick = () => {
		setSkip(false);
	};

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				setModal({
					modalType: "consultation",
					modalProps: {
						isOpen: true,
						status: "success",
						text: `Consultation terminée  `,
					},
				})
			);
			navigate("/consultations/mes-consultations", { replace: true });
		}

		return () => {};
	}, [isSuccess, dispatch, navigate]);

	return (
		<Transition.Root
			show={open}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationTriangleIcon
												className="h-6 w-6 text-primary-700"
												aria-hidden="true"
											/>
										</div>
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-base font-semibold leading-6 text-gray-900">
												Terminer la consultation
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Voulez vous terminer la
													consultation ? Vous ne
													pouuriez plus ajouter un
													bilan aprés cette action.
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<Button
										className="max-w-max ml-4"
										button="primary"
										children="Terminer"
										type="button"
										onClick={endConsultationHandleClick}
										loading={isLoading}
									/>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}>
										Annuler
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

const AvisExpert = () => {
	// modal state
	const [open, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const params = useParams();

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);

	// bilan state
	const [bilan, setBilan] = useState({
		code: params.code,
		titre: "Bilan de la consultation",
	});

	const param = new URLSearchParams(location.search);
	const status = param.get("status");

	const item_ref = param.get("item_ref");
	const payment_ref = param.get("payment_ref");
	const transaction_id = param.get("transaction_id");
	const operation = useSelector(getOperation);

	// token
	const token = useSelector(getToken);

	// alert state
	const [alert, setAlert] = useState({
		isOpen: false,
		text: "",
	});

	// add bilan request
	const [addBilanConsultation, { isLoading: isLoadingBilan }] =
		useAddBilanConsultationMutation();

	// self close alert payement status
	useLayoutEffect(() => {
		if (alert.isOpen) {
			setTimeout(() => {
				setAlert((prevState) => {
					return {
						...prevState,
						isOpen: false,
						text: "",
					};
				});
			}, 15000);
			if (status === "cancelled") {
				navigate(`/consultations/${params.code}/Avis-expert`, {
					replace: true,
				});
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, navigate]);

	// get status payement
	useEffect(() => {
		if (status) {
			axiosInstance
				.get(
					`/paiement/status?status=${status}&code=${transaction_id}&operation=${operation}&item_ref=${item_ref}&payment_ref=${payment_ref}`,
					{
						headers: {
							authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					setAlert((prevState) => {
						return {
							...prevState,
							isOpen: true,
							text: res.data.message,
						};
					});
					if (status === "success") {
						navigate(`/consultations/mes-demandes-avis`);
						// mes demandes d'avis
					}
				})
				.catch((err) => {});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, status, transaction_id]);

	// close alert expert status payement
	const closeAlert = () => {
		setAlert((prevState) => {
			return {
				...prevState,
				isOpen: false,
				text: "",
			};
		});
		navigate(`/consultations/${params.code}/Avis-expert`, {
			replace: true,
			relative: false,
		});
	};

	// close alert bilan
	const closeAlertBilan = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// handle change bilan form
	const handleChangeBilan = (e) => {
		const { name, value } = e.target;
		setBilan((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	// add bilan
	const handleSubmitBilan = (e) => {
		e.preventDefault();
		addBilanConsultation(bilan)
			.unwrap()
			.then((res) => {
				dispatch(
					setModal({
						modalType: "bilan",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le bilan à été ajouter avec success `,
						},
					})
				);
			})
			.catch(() => {
				// do something
			});
	};

	// get expert opinion
	const avisExpertHandleClick = () => {
		navigate(`/consultations/${params.code}/avis-expert/skills`);
	};
	return (
		<>
			{alert.isOpen && (
				<div className="p-8">
					{" "}
					<Alert
						message={alert.text}
						error={status}
						onClick={closeAlert}
					/>
				</div>
			)}

			{modalType === "bilan" && modalProps?.isOpen ? (
				<div className="px-4">
					<AlertBilan
						closeAlert={closeAlertBilan}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}

			<div className="flex justify-between items-center gap-x-4 py-4">
				<Button
					children="Retour"
					type="button"
					onClick={() => navigate(`/consultations/mes-consultations`)}
				/>

				{status === "success" ? (
					<div className="flex items-center gap-x-4">
						<Button
							children="Transferer les documents"
							button="primary"
							className="max-w-max"
							onClick={() => setOpenModal(true)}
						/>
					</div>
				) : (
					<div className="flex items-center gap-x-4">
						<Button
							children="Avis expert"
							button="secondary"
							className="max-w-max bg-secondary-600 text-white"
							onClick={avisExpertHandleClick}
						/>
						<Button
							children="Terminer la consultation"
							button="primary"
							className="max-w-max"
							onClick={() => setOpen(true)}
						/>
					</div>
				)}
			</div>

			{/* consultation  */}
			<SingleConsultation goBack={false} />

			{/* bilan form  */}
			{status!="En Cours"? null :(
			<form
				onSubmit={handleSubmitBilan}
				className="relative mt-8">
				<div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
					<label
						htmlFor="title"
						className="sr-only">
						Title
					</label>
					
				
				
					<input
						type="text"
						name="titre"
						id="titre"
						disabled
						value="Bilan de la consultationhh"
						className="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0"
						placeholder="Bilan de la consultation"
					/>
					
					<label
						htmlFor="description"
						className="sr-only">
						Description
					</label>
              
					<textarea
						onChange={handleChangeBilan}
						rows={4}
						name="description"
						id="description"
						className="block w-full resize-none border-0 py-0 placeholder-gray-500 focus:ring-0 sm:text-sm"
						placeholder="Ajouter une description..."
						defaultValue={""}
					/>
				

					{/* Spacer element to match the height of the toolbar */}
					<div aria-hidden="true">
						<div className="py-2">
							<div className="h-9" />
						</div>
						<div className="h-px" />
						<div className="py-2">
							<div className="py-px">
								<div className="h-9" />
							</div>
						</div>
					</div>
				</div>

				<div className="absolute inset-x-px bottom-0">
					<div className="flex  justify-end space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
						<div className="flex-shrink-0">
							<Button
								children="Ajouter le bilan"
								button="secondary"
								className="max-w-max"
								type="submit"
								loading={isLoadingBilan}
							/>
						</div>
					</div>
				</div>
			</form>
	)}
			{open && (
			  	<Modal
					open={open}
					setOpen={setOpen}
				/>
			)}

			{openModal && (
				<MailtoModal
					openModal={openModal}
					setOpenModal={setOpenModal}
				/>
			)}
		</>
	);
};

export default AvisExpert;
