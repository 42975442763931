/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description selected question type
 * @component SelectLabel
 */
import React, { useEffect } from "react";
import {
	MdOutlineRadioButtonUnchecked,
	MdCheckBoxOutlineBlank,
} from "../../../../../UI/svg";
import SelectMenu from "../../../../../UI/form/SelectMenu";
import InputTextQuestion from "../../../../../UI/form/InputTextQuestion";
import RadioOrCheckboxQuestion from "../../../../../UI/form/RadioOrCheckboxQuestion";

const SelectLabel = (props) => {
	// props
	const { setCurrentQuestion, currentQuestion } = props;

	// resetting options value at the selection change
	useEffect(() => {
		const restDefault = {
			"label": "",
			"prix": 0,
			"format": null,
			"subquestions": []
		};

		setCurrentQuestion({
			...currentQuestion,
			options: [restDefault]
		})
	}, [currentQuestion?.fieldType]);

	let content;

	switch (currentQuestion?.fieldType) {
		case "text":
			content = (
				<InputTextQuestion
					title="Réponse courte"
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
				/>
			);
			break;
		case "textArea":
			content = (
				<InputTextQuestion
					title="Réponse longue"
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
				/>
			);
			break;
		case "checkbox":
			content = (
				<RadioOrCheckboxQuestion
					Icon={MdCheckBoxOutlineBlank}
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
				/>
			);
			break;
		case "radio":
			content = (
				<RadioOrCheckboxQuestion
					Icon={MdOutlineRadioButtonUnchecked}
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
				/>
			);
			break;

		default:
			break;
	}

	function handleLabelChange(event) {
		const name = event.target.value;
		setCurrentQuestion({
			...currentQuestion,
			libelle: name
		});
	};

	function handleDescriptionChange(event) {
		const name = event.target.value;
		setCurrentQuestion({
			...currentQuestion,
			description: name
		});
	};

	return (
		<div className=" grid grid-cols-3 gap-4 pb-8">
			{/* input name  */}
			<div className="col-span-3 sm:col-span-2">
				<label
					htmlFor="name"
					className="block text-sm font-medium text-gray-700"
				></label>

				<div className="border-b border-gray-300 focus-within:border-secondary-600">
					<input
						value={currentQuestion.libelle}
						type="text"
						name="name"
						id="name"
						className="block w-full border-0 border-b border-gray-500 bg-gray-50 focus:border-secondary-600 focus:ring-0 sm:text-md h-14"
						placeholder="Question..."
						onChange={handleLabelChange}
					/>
				</div>
			</div>

			{/* select question type  */}
			<div className="col-span-3 sm:col-span-1 mt-2 sm:mt-0">
				<SelectMenu
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
				/>
			</div>

			{/* content  */}
			<div className="col-span-3">{content}</div>

			{/* description */}
			<div className="col-span-3 sm:col-span-2">
				<label
					htmlFor="name"
					className="block text-sm font-medium text-gray-700"
				></label>

				<div className="border-b border-gray-300 focus-within:border-secondary-600">
					<textarea
						id="description"
						name="description"
						rows={2}
						className="block w-full border-0 border-b border-gray-500 bg-gray-50 focus:border-secondary-600 focus:ring-0 sm:text-md h-14"
						value={currentQuestion.description}
						placeholder="Description à la question..."
						onChange={handleDescriptionChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default SelectLabel;
