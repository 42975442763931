/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description select insurance consultation souscription
 * @component Fiche
 */

import React from "react";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import MonAssuranceCard from "../assurances/client/MonAssuranceCard";
import Spinner from "../../../../UI/spinner/Spinner";
import { useState, useEffect } from "react";
import Button from "../../../../UI/button/Button";
import {
	InformationCircleIcon,
	ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import {
	assuranceConsultation,
	isAssurConsultation,
	getconsultation,
} from "../../../../features/consultation/consultation.slice";
import { useDispatch, useSelector } from "react-redux";
import { getUserCode } from "../../../../features/user/user.slice";
import axiosInstance from "../../../../apis/inchAssur";
import { getToken } from "../../../../features/user/auth.slice";

const Alert = () => {
	return (
		<div className="rounded-md bg-blue-50 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<InformationCircleIcon
						className="h-5 w-5 text-blue-400"
						aria-hidden="true"
					/>
				</div>
				<div className="ml-3 flex-1 md:flex md:justify-between">
					<p className="text-sm text-blue-700">
						Vous devez souscrire à une assurance Inch pour
						continuer.
					</p>
					<p className="mt-3 text-sm md:mt-0 md:ml-6">
						<Link
							to="/assurances/categorie"
							className="whitespace-nowrap group font-medium text-blue-700 hover:text-blue-600 flex gap-x-2 items-center">
							Souscrire maintenant
							<ArrowLongRightIcon
								aria-hidden="true"
								className="h-4 w-4 group-hover:translate-x-1 transition-transform"></ArrowLongRightIcon>
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default function ConsultAvecAssur() {
	const dispatch = useDispatch();
	const token = useSelector(getToken);
	const [alert, setalert] = useState(false);

	const [skip, setSkip] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const code = useSelector(getUserCode);
	const { canal, ville } = useSelector(getconsultation);

	// get insurance souscribe by user
	useEffect(() => {
		if (skip) {
			setLoading(true);
			axiosInstance(`products/subscriber/${code}`, {
				headers: {
					authorization: `Bearer ${token}`,
				},
			})
				.then((res) => {
					if (res.status === 202) {
						setData([]);
						setalert(true);
					} else if (
						res.status === 200 &&
						res.data?.data?.length === 0
					) {
						setData([]);
						setalert(true);
					} else {
						setData(res.data?.data);
					}
				})
				.catch((err) => {
					// do something
				})
				.finally(() => {
					setLoading(false);
				});
		}
		return () => {};
	}, [skip, code, token]);

	const navigate = useNavigate();

	// with insurance
	const selectAssurHandle = (assur) => {
		dispatch(assuranceConsultation(assur));
		dispatch(isAssurConsultation(true));
		// add assurance to consultation and set the query params
		navigate({
			pathname: "/consultations/motif",
			search: createSearchParams({
				withassurance: true,
				ville: ville.nomVille,
				canal: canal.name,
				codeassur: assur.code,
			}).toString(),
		});
	};

	// without insurance
	const sansAssurHandle = () => {
		dispatch(isAssurConsultation(false));
		navigate({
			pathname: "/consultations/motif",
			search: createSearchParams({
				ville: ville.nomVille,
				canal: canal.name,
				withassurance: false,
			}).toString(),
		});
	};

	return (
		<>
			{alert && <Alert />}

			<div className="bg-white py-3 sm:py-8">
				<div className="mx-auto max-w-2xl py-2 px-4 text-center sm:py-2 sm:px-6 lg:px-8">
					<h2 className="text-3xl font-bold tracking-tight text-primary-800 sm:text-4xl">
						Voulez vous utiliser une assurance ?
					</h2>
				</div>

				<div className="">
					<div className=" pt-16 sm:py-8 grid  grid-cols-2  gap-4 sm:grid-cols-2 text-center">
						<div
							className=" col-span-2  gap-4 sm:col-span-1 md:col-span-1 cursor-pointer"
							onClick={() => setSkip(true)}>
							<div className=" py-10 relative flex items-center space-x-5 rounded-lg border border-gray-300 bg-white px-6  shadow-sm focus-within:ring-2 focus-within:ring-secondary-500 focus-within:ring-offset-2 hover:border-gray-400">
								<div className="min-w-0 flex-1">
									<span
										className="absolute inset-0"
										aria-hidden="true"
									/>
									<p className="text-xl  sm:text-2xl font-bold text-gray-900">
										Oui
									</p>
								</div>
							</div>
						</div>
						<div
							className="col-span-2  gap-4 sm:col-span-1 md:col-span-1 cursor-pointer"
							onClick={sansAssurHandle}>
							<div className=" py-10 relative flex items-center space-x-5 rounded-lg border border-gray-300 bg-white px-6  shadow-sm focus-within:ring-2 focus-within:ring-secondary-500 focus-within:ring-offset-2 hover:border-gray-400">
								<div className=" min-w-0 flex-1">
									<span
										className="absolute inset-0"
										aria-hidden="true"
									/>
									<p className="text-xl  sm:text-2xl font-bold text-gray-900">
										Non
									</p>
								</div>
							</div>
						</div>
					</div>

					{skip && (
						<div className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
							<h2 className="text-xl font-bold tracking-tight text-gray-500 sm:text-2xl mt-4 mb-8">
								Mes assurances{" "}
							</h2>

							{
								<div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-2 lg:gap-x-8">
									{data
										?.filter(
											(item) =>
												item.categorie.nom ===
												"assurances santé"
										)
										.map((item, index) => {
											return (
												<MonAssuranceCard
													key={index}
													titre={item.nom}
													nbreConsultation={
														item.max_consultation_nbr
													}
													duree={item.duree}
													restants={
														item.joursRestants
													}
													images={item.images[0]?.uri}
													categorie={item.categorie.nom.toLowerCase()}
													onClick={selectAssurHandle.bind(
														null,
														item
													)}
												/>
											);
										})}
								</div>
							}

							{loading && (
								<Spinner className="w-6 h-6 text-primary-700 mt-4" />
							)}
						</div>
					)}

					<div className="mt-8">
						<Button
							children="Etape Précédent"
							type="button"
							onClick={() => navigate("/consultations/ville")}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
