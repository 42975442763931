    /**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add user
 * @component UserAdd
 */
	import { useLayoutEffect } from "react";
	import { useNavigate } from "react-router-dom";
	import { useDispatch} from "react-redux";
	import { useState } from "react";
	import Button from "../../../../../UI/button/Button";
	import { setModal } from "../../../../../features/feedBack/modal.slice";
	
	import { Outlet } from "react-router-dom";
	import Sidebar from "../../../sidebar";
	import DashboardHeader from "../../../header/DashboardHeader";
	
	import Input from "../../../../../UI/form/Input";
	import {
		useCreateMotifMutation,
	} from "../../../../../features/api/apiSlice";

	const Add_Motif = () => {
		const navigate = useNavigate();
		const dispatch = useDispatch();
		const [sidebarOpen, setSidebarOpen] = useState(false);
        const [show, setShow] = useState(false);
	// motif state
	const [motifInfo, setmotifInfo] = useState({});
		

		// add motif request
		const [createMotif, { isLoading: loading }] = useCreateMotifMutation();
	
		useLayoutEffect(() => {
			if (show) {
				setTimeout(() => {
					setShow(false);
				}, 4000);
			}
	
			return () => {
				clearTimeout();
			};
		}, [show]);
	// handle change
	const handleChange = (e) => {
		
			const name = e.target.name;
			const value = e.target.value.trim();

			setmotifInfo((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		
	};
	

		// add motif
		const handleSubmit = (e) => {
			e.preventDefault();

			createMotif(motifInfo)
				.unwrap()
				.then((res) => {

						// alert motif we add new motif
						dispatch(
							setModal({
								modalType: "motif",
								modalProps: {
									isOpen: true,
									status: "success",
									text: "Le motif a été ajouté avec succès",
								},
							})
						);
						navigate("/mes-liste-des-motifs");
					})
					.catch((err) => {
						// alert error
						dispatch(
							setModal({
								modalType: "motif",
								modalProps: {
									isOpen: true,
									status: "failed",
									text: "Le motif existe déjà",
								},
							})
						);
						navigate("/mes-liste-des-motifs");
					});
		};
	
	
		return (
			
			
			<div>
			{/* siderbar  */}
			<Sidebar
				sidebarOpen={sidebarOpen}
				setShow={setShow}
				setSidebarOpen={setSidebarOpen}
			/>

			<div className="flex flex-1 flex-col xl:ml-64">
				{/* header */}
				<DashboardHeader
					sidebarOpen={sidebarOpen}
					setSidebarOpen={setSidebarOpen}
				/>

				{/* main  */}
				<Outlet />
			</div>
		
			<form
				onSubmit={handleSubmit}
				className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
				<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
					<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
						<div>
							<h3 className="text-lg font-medium leading-6 text-gray-900">
								Ajouter un motif
							</h3>
							<p className="mt-1 max-w-2xl text-sm text-gray-500">
								Bien vouloir remplir ce formulaire pour ajouter un
								motif.
							</p>
						</div>
	
						<div className="space-y-6 sm:space-y-5">
							{/* last name  */}
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="nom"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Nom Motif
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<div className="sm:max-w-xs">
										<Input
											input={{
												id: "nom",
												type: "text",
												maxLength: "180",
											}}
											name="nom"
											disabled={false}
											required={false}
											onChange={handleChange}
										/>
									</div>
								</div>
							</div>
	
							{/* first name  */}
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="nom"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Description
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<div className="sm:max-w-xs">
										<Input
											input={{
												id: "description",
												type: "text",
												maxLength: "255",
											}}
											name="description"
											disabled={false}
											required={false}
											onChange={handleChange}
										/>
									</div>
								</div>
							</div>
	
	
						</div>
					</div>
				</div>
	
				<div className="pt-5">
					<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
						<Button
							children="Ajouter le motif"
							button="primary"
							type="submit"
							className="max-w-max"
							loading={loading}
						/>
					</div>
				</div>
			</form>
			</div>
		);
	}

	
	
	
	export default Add_Motif
	


