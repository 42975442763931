import styled from "styled-components";
import Banner from "../../assets/banner1.jpg"

function PolitiquesConditions() {
    const Container = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 260px;
    `

    const StyledBanner = styled.div`
        position: relative;
        margin: 30px 0;
        width: 80%;
        height: 300px;
        overflow: hidden;

        &:before {
            background: white;
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 70%) rotate(-5deg);
            width: 200%;
            height: 50%;
            z-index: 3;
        }
    `

    const BannerImage = styled.img`
        width: 100%;
        height: auto;
        position: relative;
        top: 50%;
        transform: translateY(-35%);
        object-fit: cover;
    `

    const TextContainer = styled.div`
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 30px;
        margin-bottom: 100px;
        width: 60%;
        padding: 0 30px;
        @media screen and (max-width: 500px) {
            width: 100%;
        }
    `

    const StyledH1 = styled.h1`
        font-family: Söhne, ui-sans-serif, system-ui, -apple-system;
        font-weight: bold;
        font-size: 2.25em;
        margin-bottom: 80px;
        text-align: center;
        color: #0f0f0f;
    `

    const StyledH2 = styled.h2`
        font-family: Söhne, ui-sans-serif, system-ui, -apple-system;
        font-weight: bold;
        font-size: 1.5em;
        margin-bottom: 30px;
        text-align: left;
        color: #0f0f0f;
    `

    const StyledParagraph = styled.p`
        text-align: justify;
    `

    return (
        <Container>
            <StyledBanner>
                <BannerImage src={Banner} alt="InchClass banner" />
            </StyledBanner>

            <TextContainer>
                <div>
                    <StyledH1>Politique et Conditions d'Utilisation</StyledH1>

                    <StyledParagraph>Bienvenue sur <strong>InchDigital</strong> !</StyledParagraph>
                    <StyledParagraph>Avant d'utiliser nos services, veuillez prendre quelques instants pour lire attentivement nos politiques et conditions d'utilisation. En continuant à utiliser notre plateforme, vous acceptez automatiquement les termes énoncés ci-dessous.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>1. Confidentialité et Sécurité des Données</StyledH2>

                    <StyledParagraph>Nous nous engageons à protéger vos informations personnelles. Consultez notre Politique de Confidentialité pour en savoir plus sur la manière dont nous collectons, utilisons et protégeons vos données.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>2. Enregistrement et Sécurité du Compte</StyledH2>

                    <StyledParagraph>Pour accéder à nos services, vous devrez créer un compte. Vous êtes responsable du maintien de la confidentialité de votre mot de passe et des informations de votre compte. En cas d'utilisation non autorisée, veuillez nous en informer immédiatement.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>3. Utilisation Responsable</StyledH2>

                    <StyledParagraph>L'utilisation de notre plateforme doit respecter les lois en vigueur. Vous acceptez de ne pas utiliser nos services à des fins illégales, frauduleuses ou nuisibles. Nous nous réservons le droit de suspendre ou de résilier tout compte en cas d'activité suspecte.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>4. Produits d'Assurance</StyledH2>

                    <StyledParagraph>Les produits d'assurance proposés sur notre plateforme sont soumis aux conditions spécifiques définies par les assureurs. Veuillez lire attentivement les termes, garanties, et exclusions de chaque produit avant de souscrire.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>5. Responsabilités des Assureurs</StyledH2>

                    <StyledParagraph>Les assureurs sont responsables de l'exactitude des informations fournies lors de l'enregistrement et de la création de produits d'assurance. Ils s'engagent à respecter les réglementations en vigueur.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>6. Souscription aux Produits d'Assurance</StyledH2>

                    <StyledParagraph>En souscrivant à un produit d'assurance, vous acceptez les termes spécifiques de ce produit. Assurez-vous de comprendre les garanties, les exclusions, les franchises et les conditions générales avant de finaliser votre souscription.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>7. Modifications des Conditions</StyledH2>

                    <StyledParagraph>Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les utilisateurs seront informés des changements par le biais de notifications sur la plateforme.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>8. Résiliation du Compte</StyledH2>

                    <StyledParagraph>Vous pouvez résilier votre compte à tout moment en nous contactant. Les assureurs peuvent également résilier leurs comptes, mais cela peut entraîner la suppression des produits d'assurance associés.</StyledParagraph>
                </div>

                <div>
                    <StyledH2>9. Support Client</StyledH2>

                    <StyledParagraph>Notre équipe de support client est là pour vous aider. N'hésitez pas à nous contacter pour toute question ou préoccupation.</StyledParagraph>
                </div>

                <div>
                    <StyledParagraph>Merci de faire partie de <strong>InchDigital</strong> ! Nous sommes ravis de vous accompagner dans votre parcours d'assurance.</StyledParagraph>
                </div>
            </TextContainer>
        </Container>
    )
};

export default PolitiquesConditions;
