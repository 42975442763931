import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import { CheckIcon } from '../../UI/svg'

const ModalWithoutAction = (props) => {
  const { openFeedBack, setOpen, description, title } = props
  return (
    <Transition.Root show={openFeedBack} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}





export const Alert = ({ message, error, onClick }) => {
  return (
    <div className={clsx("rounded-md p-4", `${error === "cancelled" ? "bg-red-50" : "bg-green-50"}`)}>
      <div className="flex">
        <div className="flex-shrink-0">
          {error === "cancelled" ? <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" /> : error === "success" ? <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" /> : null}
        </div>
        <div className="ml-3">
          <h3 className={clsx("text-sm font-medium", `${error === "cancelled" ? "text-red-800" : "text-green-800"}`)}>Operation Terminer</h3>
          <div className={clsx("mt-2 text-sm", `${error === "cancelled" ? "text-red-700" : "text-green-700"}`)}>
            <p>{message}</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button
                onClick={onClick}
                type="button"
                className={clsx("ml-3 rounded-md  px-2 py-1.5 text-sm font-medium   focus:outline-none focus:ring-2  focus:ring-offset-2 ", `${error === "cancelled" ? "bg-red-50 text-red-800 hover:bg-red-100 focus:ring-red-600 focus:ring-red-yellow-50" : "focus:ring-offset-green-50 bg-green-50 text-green-800 hover:bg-green-100 focus:ring-green-600"}`)}
              >
                Fermer

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



export default ModalWithoutAction