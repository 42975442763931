/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add skill page
 * @component UpdateCompetencesPage
 */
import Main from "../../../components/layouts/main";
import UpdateCompetence from "../../../components/layouts/main/consultations/doctor/UpdateCompetence";

const UpdateCompetencesPage = (props) => {
	return <Main children={<UpdateCompetence />} />;
};

export default UpdateCompetencesPage;