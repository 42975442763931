import { PaperClipIcon } from "@heroicons/react/24/outline";
import Input from "../../../../UI/form/Input";
import React from "react";

const AddDocuments = ({ setfile }) => {
	// handle change images
	const handleFileChange = (e) => {
		const { files } = e.target;
		if (files && files.length > 0) {
			setfile((prevState) => ({
				...prevState,
				documents: files
			}));
		};
	};

	return (
		<div className="flow-root">
			<button
				type="button"
				className="inline-flex h-5 w-5 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
				<label htmlFor="documents">
					<PaperClipIcon
						className="h-6 w-6"
						aria-hidden="true"
					/>
				</label>
				{/* input documents  */}
				<Input
					onChange={handleFileChange}
					input={{
						id: "documents",
						type: "file",
						accept: ".doc, .docx, .pdf",
					}}
					className="hidden"
					hidden={true}
					name="documents"
				/>

				<span className="sr-only">Ajouter un document</span>
			</button>
		</div>
	);
};

export default AddDocuments;
