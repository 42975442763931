/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description recharge account page
 * @component UserRechargePage
 */

import Main from "../../components/layouts/main";

import UserRecharge from "../../components/layouts/main/UserRecharge/Recharge";

const UserRechargePage = () => {
	return <Main children={<UserRecharge />} />;
};

export default UserRechargePage;
