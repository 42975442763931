/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description chat content
 * @component ScrollableChat
 */
import React, { useEffect, useState } from "react";
import { useGetConversationMessagesQuery } from "../../../../features/api/apiSlice";
import Message from "./Message";
import Spinner from "../../../../UI/spinner/Spinner";
import styled from "styled-components";

const Container = styled.div`
  height: 77vh;
  @media screen and (max-width: 550px) {
    height: 62vh;
  }
  @media screen and (max-width: 500px) {
    height: 69vh;
  }
  @media screen and (max-width: 450px) {
    height: 71vh;
  }
  @media screen and (max-width: 390px) {
    height: 68vh;
  }
  @media screen and (max-width: 385px) {
    height: 60vh;
  }
  @media screen and (max-width: 360px) {
    height: 63vh;
  }
  @media screen and (max-width: 345px) {
    height: 69vh;
  }
`;

const ScrollableChat = ({ selectedChat, messageListRef }) => {
  const [messages, setMessages] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);

  // get message request
  const {
    data: newMessages,
    isLoading: messagesIsLoading,
    isSuccess: messageIsSucces,
    currentData: messagesCurrentData,
  } = useGetConversationMessagesQuery(selectedChat.conversation_id);

  useEffect(() => {
    setMessages([]);
    setIsWaiting(true)
    if(messageIsSucces) {
      setMessages(newMessages?.data);
      setIsWaiting(false)
    };
  }, [selectedChat, newMessages]);

  useEffect(() => {
    if (messageIsSucces || messagesCurrentData) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }

    return () => {};
  }, [messageIsSucces, messageListRef, messagesCurrentData]);

  return (
    <Container
      className="px-4 pt-8 flex flex-col space-y-4 overflow-y-auto flex-1 "
      ref={messageListRef}
    >
      {messageIsSucces &&
        messages?.map((item, index) => <Message {...item} key={index} />)
      }

      {(messagesIsLoading || isWaiting) && <Spinner className="m-auto h-100vh" />}
    </Container>
  );
};

export default ScrollableChat;
