import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
	modalType: null,
	modalProps: {},
};

// madal slice
const modal = createSlice({
	name: "modal",
	initialState,
	reducers: {
		// set modal
		setModal(state, action) {
			state.modalType = action.payload.modalType;
			state.modalProps = action.payload.modalProps;
		},
		// reset modal state
		initialModal(state, action) {
			return initialState;
		},
	},
});

// get modale type
export const getModalType = (state) => state.modal.modalType;

// get modal props
export const getModalProps = (state) => state.modal.modalProps;

export const { setModal, initialModal } = modal.actions;

export default modal.reducer;
