/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance product souscription card
 * @component MonAssuranceCard
 */
import Button from "../../../../../UI/button/Button";

import { BsHourglassSplit } from "../../../../../UI/svg/index";

const MonAssuranceCard = (props) => {
	const {
		titre,
		images,
		categorie,
		onClick,
		nbreConsultation,
		duree,
		restants,
	} = props;

	return (
		<>
			<div className=" group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
				<div className=" bg-gray-200 group-hover:opacity-75 sm:aspect-none h-40 sm:h-56">
					<img
						src={images}
						alt="images produits"
						className="h-full w-full object-cover object-center sm:h-full sm:w-full"
					/>
				</div>
				<div className="flex flex-1 flex-col space-y-2 p-4">
					<h3 className="text-lg font-medium text-gray-900 flex flex-1 flex-col justify-end">
						<span aria-hidden="true" />
						{titre}
						<small className="text-sm font-medium text-gray-600">
							{categorie}
						</small>
					</h3>
					<h4 className="text-lg font-medium text-gray-900 flex flex-1 flex-col justify-end">
						<span aria-hidden="true" />
						Nombre de consultation
						<small className="text-sm font-medium text-gray-600">
							{nbreConsultation} consultations restantes
						</small>
					</h4>
					<div className="flex flex-1  justify-between items-center">
						<p className="text-sm italic text-gray-500 flex justify-start items-center">
							<BsHourglassSplit className="h-5 w-5 text-green-400" />
							<span className="text-md ml-1 font-medium text-gray-800">
								Durée {duree} Jours
							</span>
						</p>
					</div>
					<div className="flex flex-1  justify-between items-center">
						<p className="text-sm italic text-gray-500 flex justify-start items-center">
							<BsHourglassSplit className="h-5 w-5 text-primary-800" />
							<span className="text-md ml-1 font-medium text-gray-800">
								Expire dans {restants}{" "}
							</span>
						</p>
					</div>
				</div>
				{parseInt(nbreConsultation) > 0 && (
					<div className="p-2">
						<Button
							children="Utilisez cette assurance"
							button="secondary"
							onClick={onClick}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default MonAssuranceCard;
