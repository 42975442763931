/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  menu chat
 * @component WelcomeChat
 */

import React, { useEffect, Fragment, useState } from "react";
import {
	useGetTypeSinistresQuery,
	useAddSinistresMutation,
	useGetAllSuscriberProductsQuery,
} from "../../../../features/api/apiSlice";
import Spinner from "../../../../UI/spinner/Spinner";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import {
	CheckIcon,
	ChevronUpDownIcon,
	XMarkIcon,
} from "@heroicons/react/24/solid";
import Select from "../../../../UI/form/Select";
import Input from "../../../../UI/form/Input";
import TextArea from "../../../../UI/form/TextArea";
import Button from "../../../../UI/button/Button";
import Alert from "../../../../UI/alert";
import { useSelector } from "react-redux";
import { getUserCode } from "../../../../features/user/user.slice";

export const SinistreModal = ({ openSinistreModal, setopenSinistreModal }) => {
	const code = useSelector(getUserCode);
	const {
		data: myproducts,
		isSuccess: myproductsIsSuccess,
		isLoading: myproductsIsLoading,
	} = useGetAllSuscriberProductsQuery(code);

	const [idcategorie, setidcategorie] = useState("");

	const {
		data: typeSinistre,
		isLoading,
		isSuccess,
		isFetching,
	} = useGetTypeSinistresQuery(idcategorie, { skip: !idcategorie });

	const [alert, setalert] = useState({
		text: "",
		error: false,
		isAlert: false,
	});

	const [addSinistre, { isLoading: addSinistreIsLoading }] =
		useAddSinistresMutation();

	const [data, setdata] = useState({});

	const [valueOption, setvalueOption] = useState([]);
	const [valueOptionCategorie, setvalueOptionCategorie] = useState([]);

	const [selected, setSelected] = useState("");
	const [products, setProduct] = useState([]);
	const [query, setQuery] = useState("");

	const filteredProduct =
		query === ""
			? products
			: products.filter((product) =>
					product.name
						.toLowerCase()
						.replace(/\s+/g, "")
						.includes(query.toLowerCase().replace(/\s+/g, ""))
			  );

	useEffect(() => {
		if (isSuccess || isFetching) {
			setvalueOption(
				typeSinistre?.data?.map((item) => {
					return {
						name: item.nom,
						id: item.typeSinistreId,
					};
				})
			);
		}

		return () => {};
	}, [idcategorie, isSuccess, isFetching, typeSinistre]);

	useEffect(() => {
		if (myproductsIsSuccess) {
			setvalueOptionCategorie(
				myproducts.data.map((item) => {
					return {
						name: item.categorie.nom,
						id: item.categorie.id,
					};
				})
			);

			setProduct(
				myproducts.data.map((item) => {
					return {
						name: item.nom,
						id: item.code,
					};
				})
			);
		}

		return () => {};
	}, [myproductsIsSuccess, myproducts]);

	const handleCloseModal = () => {
		setopenSinistreModal(false);
	};

	const categorieHandleChange = (e) => {
		const { value } = e.target;
		setidcategorie(value);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setdata((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	const addSinistreHandleClick = (e) => {
		e.preventDefault();
		console.log(typeSinistre.data)
		addSinistre({ ...data, produitCode: selected.id })
			.unwrap()
			.then((res) => {
				handleCloseModal();
			})
			.catch((err) => {
				console.log(err);
				setalert((prevState) => {
					return {
						...prevState,
						isAlert: true,
						error: true,
						text: err.data.message,
					};
				});
			});
	};
	
	return (
		<Transition.Root
			show={openSinistreModal}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setopenSinistreModal}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel
								as="form"
								onSubmit={addSinistreHandleClick}
								className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={() =>
											setopenSinistreModal(false)
										}>
										<span className="sr-only">Close</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button>
								</div>
								<Dialog.Title
									as="h1"
									className="text-xl font-semibold mb-2">
									Ajouter un sinistre
								</Dialog.Title>
								{myproductsIsLoading && (
									<div className="flex justify-center">
										<Spinner className="w-8 h-8 text-primary-600" />
									</div>
								)}

								{myproductsIsSuccess ? (
									myproducts?.data.length === 0 ? (
										<p>
											Vous devez souscrire au moins à un
											produit d'assurance pour déclarer un
											sinistre
										</p>
									) : (
										<>
											{alert.isAlert && (
												<Alert
													alert={alert}
													setalert={setalert}
												/>
											)}
											<div className=" mt-8 space-y-4">
												{/* select insurance categorie  */}
												{myproductsIsLoading ? (
													<div className="flex justify-center">
														<Spinner className="w-8 h-8 text-primary-600" />
													</div>
												) : (
													<Select
														label="Selectionner la categorie d'assurance"
														valueOption={
															valueOptionCategorie
														}
														name="categorie"
														defaultValue="default"
														disabled={false}
														required={false}
														onChange={
															categorieHandleChange
														}
													/>
												)}

												{/* select sinistre type  */}
												{isSuccess && !isFetching && (
													<Select
														label="Selectionner le type de sinistres"
														valueOption={
															valueOption
														}
														name="type"
														defaultValue="default"
														disabled={false}
														required={false}
														onChange={handleChange}
													/>
												)}

												{(isFetching || isLoading) && (
													<div className="flex justify-center">
														<Spinner className="w-8 h-8 text-primary-600" />
													</div>
												)}

												{/* libele  */}
												<Input
													label="Libelle"
													input={{
														id: "titre",
														type: "text",
														maxLength: "250",
													}}
													name="titre"
													disabled={false}
													required={false}
													onChange={handleChange}
												/>

												{/* insurance product  */}
												<Combobox
													value={selected}
													onChange={setSelected}>
													<div className="relative mt-1">
														<Combobox.Label className="block text-sm font-medium text-gray-700 mb-1">
															Selectionner le
															produit d'assurance
														</Combobox.Label>
														<div className="relative w-full cursor-default overflow-hidden rounded-md border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500 sm:text-sm disabled:bg-gray-100 ">
															<Combobox.Input
																className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500  focus:border-2 sm:text-sm disabled:bg-gray-100"
																displayValue={(
																	product
																) =>
																	product.name
																}
																onChange={(
																	event
																) =>
																	setQuery(
																		event
																			.target
																			.value
																	)
																}
															/>
															<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
																<ChevronUpDownIcon
																	className="h-5 w-5 text-gray-400"
																	aria-hidden="true"
																/>
															</Combobox.Button>
														</div>
														<Transition
															as={Fragment}
															leave="transition ease-in duration-100"
															leaveFrom="opacity-100"
															leaveTo="opacity-0"
															afterLeave={() =>
																setQuery("")
															}>
															<Combobox.Options className="absolute mt-2 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
																{filteredProduct.length ===
																	0 &&
																query !== "" ? (
																	<div className="relative cursor-default select-none py-2 px-4 text-gray-700">
																		Pas de
																		produit
																		trouvé
																	</div>
																) : (
																	filteredProduct.map(
																		(
																			product
																		) => (
																			<Combobox.Option
																				key={
																					product.id
																				}
																				className={({
																					active,
																				}) =>
																					`relative cursor-default select-none py-2 pl-10 pr-4 ${
																						active
																							? "bg-secondary-600 text-white"
																							: "text-gray-900"
																					}`
																				}
																				value={
																					product
																				}>
																				{({
																					selected,
																					active,
																				}) => (
																					<>
																						<span
																							className={`block truncate ${
																								selected
																									? "font-medium"
																									: "font-normal"
																							}`}>
																							{
																								product.name
																							}
																						</span>
																						{selected ? (
																							<span
																								className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																									active
																										? "text-white"
																										: "text-secondary-600"
																								}`}>
																								<CheckIcon
																									className="h-5 w-5"
																									aria-hidden="true"
																								/>
																							</span>
																						) : null}
																					</>
																				)}
																			</Combobox.Option>
																		)
																	)
																)}
															</Combobox.Options>
														</Transition>
													</div>
												</Combobox>

												{/* description  */}
												<TextArea
													name="description"
													label="Description"
													rows="6"
													id="description"
													required={true}
													onChange={handleChange}
												/>
											</div>
											<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
												<Button
													type="submit"
													button="primary"
													className="max-w-max sm:ml-3 			"
													children="Enregistrer"
													loading={
														addSinistreIsLoading
													}
												/>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
													onClick={() =>
														setopenSinistreModal(
															false
														)
													}>
													Annuler
												</button>
											</div>
										</>
									)
								) : null}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
