/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description condition step for insurance souscription
 * @component ConditionStep
 */
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../../UI/button/Button";
import styled from "styled-components";

// import for pdf viewing
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useValideCodeContratMutation } from "../../../../../features/api/apiSlice";
import { useSelector } from "react-redux";
import { getToken } from "../../../../../features/user/auth.slice";
import ModalWithoutAction from "../../../../feedback/ModalWithoutAction";
import Input from "../../../../../UI/form/Input";
import PayingModal from "../../../../../UI/modal/payingModal";
import axiosInstance from "../../../../../apis/inchAssur";
import pocket from "../../../../../assets/portefeuille.png";
import { useNavigate, useLocation } from "react-router-dom";

const ViewerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
	margin: auto;
`;

const SigningContainer = styled.div`
	// height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 20px;
	border: solid #0066DB 2px;
	background: #D9D9D9;
`;

const ConditionsCol = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.8em;
`;

const Separator = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	font-size: 1.6em;
	font-weight: 600;
`;

const SigningCol = styled.div`
	display: flex;
	justify-content: center;
	gap: 0.8em;
`;

const StyledButton = styled(Button)`
	color: white;
	padding: 10px 20px;
	border-radius: 8px;
`;

const PayingCol = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 0.8em;
`;

const ConditionStep = ({ documents, setStep, steps, handlePayement, totalPrice, setModalPopUp, assurance, setIsNextButtonWorking }) => {
	// Setting steps to localStorage
	localStorage.setItem("currentStep", "3");
	const payedStatus = localStorage.getItem("status");

	setIsNextButtonWorking(true);

	const navigate = useNavigate()
	const codeRef = useRef();
	const location = useLocation();
	const currenPath = location.pathname;

	const [accept, setAccept] = useState(false);
	const [numPages, setNumPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [documentPath, setDocumentPath] = useState(process.env.PUBLIC_URL + "/pdf_open.pdf");
	const [errorCode, setErrorCode] = useState({
		error: undefined,
		isError: false,
	});
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState(false);
	const [open, setOpen] = useState(false);
	const [isSignable, setIsSignable] = useState(false);
	const [isPaying, setIsPaying] = useState(false);
	const [isPaid, setIsPaid] = useState(false);
	
	// Payement modal states following --------------------------------
	const [openPay, setOpenPay] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [selectedMode, setSelectedMode] = useState(1);
    const [selectedCanal, setSelectedCanal] = useState(0);
	const [amountMode, setAmountMode] = useState({
		idPayOption: 1,
        mode: "Unique",
        amount: 0
    });
	const [payingCanal, setCanal] = useState({
        name: "Pocket",
        number: undefined,
        illustration: pocket,
		code: "PORTE_FEUILLE"
    });
	//-----------------------------------------------------------------

	// setting defaul amountMode value
	useEffect(() => {
		setAmountMode({
			idPayOption: 1,
			mode: "Unique",
			amount: totalPrice
		})
	}, [totalPrice]);

	useEffect(() => {
		if (payedStatus === "success") {
			setAccept(true);
			setIsPaid(true);
		}
		
		if (payedStatus === "cancelled") {
			setAccept(true);
			setIsPaying(true);
		}

		if (isPaid) {
			// navigate("/assurances/mes-produits");
		}
	}, [payedStatus, isPaid]);

	const [valideCode, {isLoading}] = useValideCodeContratMutation();

    const token = useSelector(getToken)
	const newPlugIn = defaultLayoutPlugin();

	let signingContent = (
		<StyledButton
			children="Signer le contrat"
			type="button"
			className="bg-secondary-600"
			loading={loading}
			onClick={getCode}
			id="codevalidate"
		/>
	);

	function criticalDocFounder(docType, documents) {
		let founded = {
			condition: false,
			contrat: false
		};

		console.log("documents :", documents)

		documents?.forEach((document) => {
			if (document.titre.toUpperCase() === "CONDITIONS D'UTILISATION") {
				founded.condition = document.url
			};

			if (document.titre.toUpperCase() === "CONTRAT DE SOUSCRIPTION") {
				founded.contrat = document.url;
			};
		});

		return founded[docType];
	};
	
	useEffect(() => {
		if (accept) {
			const contract = criticalDocFounder("contrat", assurance.documentation);
			setDocumentPath(process.env.PUBLIC_URL + "/docContrat.pdf");
		} else {
			const conditions = criticalDocFounder("condition", assurance.documentation);
			setDocumentPath(process.env.PUBLIC_URL + "/pdf_open.pdf");
		}
	}, [accept]);

	// get code
	async function getCode() {
		if (errorCode?.isError) {
			setErrorCode({
				...errorCode,
				error: "",
				isError: false,
			});
		}

		setLoading(true);

		axiosInstance
		.get("souscriptions/signature", {
			headers: {
				Authorization: "Bearer " + token
			},
		})
		.then((response) => {
			if(response.data.statut === "ok") {
				setCode(true);
				setOpen(true);
			}else {
				console.log("Unexpected response : ", response);
				setModalPopUp("Une erreur est survenue! Veuillez rééssayer", "failed");
			};
		})
		.catch((error) => {
			console.log("Fetch error :", error);
			setModalPopUp("Une erreur est survenue! Veuillez rééssayer", "failed");
			setLoading(false)
		})
		.then(() => {
			setLoading(false)
		});
	};

	// code handle change
	const codeHandleChange = (e) => {
		if (e.target.value.trim()) {
			setErrorCode({
				...errorCode,
				error: undefined,
				isError: false,
			});
		}
	};

	// send code
	const sendCode = (e) => {
		e.preventDefault();
		const code = codeRef.current.value;
		valideCode(code)
		.unwrap()
		.then((response) => {
			if(response.statut === "ok") {
				setOpenPay(true);
				setIsPaying(true);
				setModalPopUp(response.message || "Statut inconnu !", "success");
			} else {
				console.log("Unexpected response : ", response);
				setModalPopUp(response.data.message || "Statut inconnu !", "failed");
			};
		})
		.catch((err) => {
			setModalPopUp(err?.data?.message || err.error, "failed");
			setErrorCode({
				...errorCode,
				error: err?.data?.message || err.error,
				isError: true,
			});
		});
	};

	// accept terms and conditions
	const acceptHandleClick = (e) => {
		setAccept(e.target.checked);
	};

	if(isPaying && !isPaid) {
		signingContent = (
			<StyledButton
				children="Poursuivre le payement"
				type="button"
				className="bg-secondary-600"
				onClick={() => setOpenPay(true)}
				id="openpaymodal"
			/>
		);
	};

	if(code && !isPaying && !isPaid) {
		signingContent = (
			<form
				className=" justify-self-end flex items-start gap-x-4"
				onSubmit={sendCode}
				name="codevalidate"
			>
				<Input
					placeholder={"entrer le code pour signer "}
					onChange={codeHandleChange}
					inputError={errorCode?.isError}
					ariaInputError="code"
					// errorText={errorCode?.error}
					input={{ id: "code", type: "text" }}
					ref={codeRef}
					name={"code"}
					disabled={false}
					required={true}
				/>

				<Button
					type="submit"
					loading={loading || isLoading}
					children="Valider"
				/>
			</form>
		);
	};

	if(isPaid) {
		signingContent = (
			<StyledButton
				children="Payement effectué"
				type="button"
				className="bg-secondary-600"
				disabled={true}
				id="openpaymodal"
			/>
		);
	};

	return (
		<div className="border rounded-md shadow-sm mb-8">
			<ViewerContainer>
				<Worker workerUrl={`https://unpkg.com/pdfjs-dist@${window.pdfjsLib.version}/build/pdf.worker.min.js`}>
					<Viewer
						fileUrl={documentPath} plugins={[newPlugIn]}
						onPageLoadSuccess={({ numPages }) => setNumPages(numPages)}
        				onPageChange={({ pageIndex }) => setCurrentPage(pageIndex + 1)}
					/>
				</Worker>
			</ViewerContainer>
			
			<SigningContainer>
				<ConditionsCol>
					<input
						id="accept"
						name="accept"
						type="checkbox"
						onChange={acceptHandleClick}
						className="h-4 w-4 border-secondary-600 text-secondary-600 focus:ring-secondary-500"
						disabled={isPaid || payedStatus === "success"}
						checked={payedStatus === "success" ? true : undefined}
					/>
					<label
						htmlFor="accept"
						className="ml-2 block text-sm text-black-800">
						Lu & approuvé
					</label>
				</ConditionsCol>

				{accept && (
					<Separator>
						{">"}
					</Separator>
				)}

				<SigningCol>
					{accept && (
						signingContent
					)}
				</SigningCol>

				{code ? (
					<Separator>
						{">"}
					</Separator>
				) : (
					<div style={{ width: 32 }}></div>
				)}

				<PayingCol>
					{code ? (
						<Button
							children="Generer le code"
							type="button"
							loading={loading}
							onClick={getCode}
							disabled={isPaying}
							id="codevalidate"
						/>
					) : (
						<div style={{ width: 86 }}></div>
					)}
				</PayingCol>
			</SigningContainer>

			{/* modal */}
			{open && (
				<ModalWithoutAction
					openFeedBack={open}
					description="Un mail contenant un code de signature vous a été envoyé."
					title="Code de signature"
					setOpen={setOpen}
				/>
			)}

			{/* PayingModal */}
			{openPay && (
				<PayingModal
					open={openPay}
					setOpen={setOpenPay}
					amountMode={amountMode}
					setAmountMode={setAmountMode}
					payingCanal={payingCanal}
					setCanal={setCanal}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					selectedMode={selectedMode}
					setSelectedMode={setSelectedMode}
					selectedCanal={selectedCanal}
					setSelectedCanal={setSelectedCanal}
					setIsPaid={setIsPaid}
					setIsSignable={setIsSignable}
					handlePayement={handlePayement}
					currenPath={currenPath}
					totalPrice={totalPrice}
					setModalPopUp={setModalPopUp}
					payingOptions={{
						payingModes: assurance?.payOptions,
						minPrice: assurance?.prix,
						validity: assurance?.duree,
						discount: assurance?.reductions
					}}
				/>
			)}
		</div>
	);
};

export default ConditionStep;
