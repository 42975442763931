import styled from "styled-components";

function PaySettings() {

    return (
        <h1>Pay settings here</h1>
    );
};

export default PaySettings;
