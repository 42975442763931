
import React, { useState, useEffect } from "react";
import Input from "../../../../UI/form/Input";

import usePasswordToggle from '../../../../hooks/usePasswordToggle';
import TextArea from "../../../../UI/form/TextArea";

const EntrepriseInformation = (props) => {
	// props
	const { setUserInfo, entreprise, description } = props;





	// handle change
	const HandleChange = (e) => {

		let name = e.target.name;
		let value = e.target.value;

		setUserInfo((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};



	return (
		<section
			aria-labelledby="payment-details-heading "
			className="px-4 sm:p-8">
			{/* hearder contact  */}
			<div>
				<h2
					id="payment-details-heading"
					className="text-lg font-medium leading-6 text-gray-900 mt-6">
					Informations de l'entreprise
				</h2>

			</div>

			<div className="mt-8 grid grid-cols-4 gap-6">
				<div className="col-span-4 sm:col-span-2">
					<div className=" grid grid-cols-1 gap-8">
						{/* nom de l'entreprise  */}

						<div className="col-span-2 sm:col-span-1">
							<Input
								label="Nom de l'entreprise"
								input={{
									id: "entreprise",
									type: "text",
									maxLength: "150",
								}}
								
								name={"entreprise"}
								defaultValue={entreprise}
								disabled={true}
								required={false}
							/>
						</div>



						{/* description  */}
						<div className="col-span-2 sm:col-span-1">
							<TextArea
								label="Description de l'entreprise"
								
								rows="4"
								name="description"
								disabled={true}
								required={false}
								defaultValue={description}
							/>
						</div>


					</div>
				</div>

				<div className=" col-span-4 sm:col-span-2 ">
					<div className=" grid grid-cols-1 gap-8">
						{/* Tableau  de fichiers */}

						<div className="col-span-2 sm:col-span-1">
							<h2

								className="text-lg font-medium leading-6 text-gray-900 " style={{ marginTop: '-20px' }}>
								Document de l'entreprise
							</h2>
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-5">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
												Nom
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
												Description
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 sr-only">
												Action
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">

										<tr >
											<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold  text-gray-700 sm:pl-6">
												donnees
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-700 text-ellipsis w-96">
												données
											</td>

										</tr>

										<tr >
											<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold  text-gray-700 sm:pl-6">
												donnees
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-700 text-ellipsis w-96">
												données
											</td>

										</tr>

										<tr >
											<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold  text-gray-700 sm:pl-6">
												donnees
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-700 text-ellipsis w-96">
												données
											</td>

										</tr>

									</tbody>
								</table>
							</div>
						</div>


					</div>
				</div>
			</div>


		</section>
	);
};

export default EntrepriseInformation;