/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add skill
 * @component AddCompetence
 */
import {
	useAddSkillMedecinMutation,
	useCreateSkillMutation,
} from "../../../../../features/api/apiSlice";
import Select from "../../../../../UI/form/Select";
import TextArea from "../../../../../UI/form/TextArea";
import Button from "../../../../../UI/button/Button";
import Spinner from "../../../../../UI/spinner/Spinner";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModalProps, getModalType, initialModal, setModal } from "../../../../../features/feedBack/modal.slice";
import { Alert } from "../../UserRecharge/Recharge";


const AddCompetenceAdmin = () => {

    const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);
	

	 // skill state
	 const [skill, setSkill] = useState({});

     const dispatch = useDispatch();

    // add skill request
	const [addskill, { isLoading: loading }] = useCreateSkillMutation();

	

	// handle change
	const handleChange = (e) => {
		const { name, value } = e.target;

		setSkill((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	const navigate = useNavigate();


    // close alert
	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// close alert after 10 secondes
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 30000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);

	

	// add skill
	const handleSubmit = (e) => {
		e.preventDefault();
        // const response= Axios.post()
        //                 .then((response =>response.data),
        //                 console.log(response.data))
        addskill(skill)
        .unwrap()
			.then((res) => {
				// alert skill we add new skill
				dispatch(
					setModal({
						modalType: "skill",
						modalProps: {
							isOpen: true,
							status: "success",
							text: "La competence  a eté ajouté avec succès",
						},
					})
				);
				navigate("/consultations/les-competences");
			})
			.catch((err) => {
                dispatch(
					setModal({
						modalType: "skill",
						modalProps: {
							isOpen: true,
							status: "failed",
							text: "La competence existe deja ",
						},
					})
				);
            });
		
	};

	return (
        <>


            {/* alert componenet  */}
		 {modalType === "skill" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null} 

            <div>
                <h2 className=" text-center py-4 sm:py-10 text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl">
                    Ajouter une nouvelle competence.
                </h2>
                <form className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200" onSubmit={handleSubmit}>
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Ajouter une compétence 
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Bien vouloir ajouter une compétence
                                    correspondante
                                </p>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                

                                {/* nom  */}
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="description"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Nom de la competence
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            onChange={handleChange}
                                            type="text"
                                            name="nom"
                                            id="nom"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>

                                {/* description  */}
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="description"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Description
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <TextArea
                                            name="description"
                                            onChange={handleChange}
                                            id="description"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>

                             
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="bg-gray-50 flex justify-end px-4 py-3 text-right sm:px-6">
                            <Button
                                children="Enregistrer"
                                type="submit"
                                onClick={handleSubmit}
                                button="primary"
                                className=" max-w-max"
                                loading={loading}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
	);
};

export default AddCompetenceAdmin;
