/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add skill page
 * @component AddCompetencesPage
 */
import Main from "../../../components/layouts/main";
import AddCompetences from "../../../components/layouts/main/consultations/doctor/Add_competences";

const AddCompetencesPage = (props) => {
	return <Main children={<AddCompetences />} />;
};

export default AddCompetencesPage;
