/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance  souscribe by user page
 * @component MesProduitsPage
 */

import { useEffect, useState } from "react";
import {
	useAddMedecinAssureurMutation,
	useGetAllMedecinsQuery,
	useGetAllMedecinsAssureurQuery,
} from "../../../features/api/apiSlice";
import { EllipsisHorizontalIcon } from "../../../UI/svg";
import { getUserCode } from "../../../features/user/user.slice";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { Menu, Transition, Dialog, Combobox } from "@headlessui/react";
import {
	PencilSquareIcon,
	XMarkIcon,
	CheckIcon,
	ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import Spinner from "../../../UI/spinner/Spinner";
import usePagination from "../../../hooks/usePagination";
import Pagination from "../../../UI/pagination";
import Button from "../../../UI/button/Button";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const AddDoctorModal = ({ open, setOpen }) => {
	const code = useSelector(getUserCode);
	const { data: medecins, isSuccess } = useGetAllMedecinsQuery(null, {
		skip: !open,
	});

	const [addmedecinAssureur, { isLoading: addmedecinAssureurIsLoading }] =
		useAddMedecinAssureurMutation();

	const [query, setQuery] = useState("");
	const [medecinData, setmedecinData] = useState([]);
	const [selectMedecin, setselectMedecin] = useState(null);

	const filteredMedecin =
		query === ""
			? medecinData
			: medecinData.filter((module) => {
					return module.nom
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	useEffect(() => {
		if (isSuccess) {
			setmedecinData(
				medecins?.data?.map((items) => {
					return {
						name: `${items.nom}${" "}${items.prenom}`,
						id: items.code,
					};
				})
			);
		}

		return () => {};
	}, [isSuccess, medecins]);

	const handleChangeselectMedecin = (value) => {
		setselectMedecin(value);
	};

	const handleSubmitAddMedecin = (e) => {
		e.preventDefault();

		addmedecinAssureur({
			assureurCode: code,
			medecinCode: selectMedecin.id,
		})
			.then((res) => {
				console.log(res);
				setOpen(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<Transition.Root
				show={open}
				as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<Dialog.Panel
									as="form"
									onSubmit={handleSubmitAddMedecin}
									className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
										<button
											type="button"
											className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={() => setOpen(false)}>
											<span className="sr-only">
												Close
											</span>
											<XMarkIcon
												className="h-6 w-6"
												aria-hidden="true"
											/>
										</button>
									</div>
									<h1 className="font-bold text-xl text-gray-800">
										Ajouter un médecin
									</h1>
									<div className=" my-8 space-y-4">
										<Combobox
											as="div"
											value={selectMedecin}
											className="pb-2"
											onChange={
												handleChangeselectMedecin
											}>
											<Combobox.Label className="block text-sm font-medium text-gray-700">
												Saisir ou selectionner le
												médecin
											</Combobox.Label>
											<div className="relative mt-1">
												<Combobox.Input
													placeholder="Veillez saisir ou selectionner le médecin "
													className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
													onChange={(event) =>
														setQuery(
															event.target.value
														)
													}
													displayValue={(module) =>
														module?.name
													}
												/>
												<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
													<ChevronUpDownIcon
														className="h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</Combobox.Button>

												{filteredMedecin.length > 0 && (
													<Combobox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
														{filteredMedecin.map(
															(medecin) => (
																<Combobox.Option
																	key={
																		medecin.id
																	}
																	value={
																		medecin
																	}
																	className={({
																		active,
																	}) =>
																		classNames(
																			"relative cursor-default select-none py-2 pl-8 pr-4",
																			active
																				? "bg-secondary-600 text-white"
																				: "text-gray-900"
																		)
																	}>
																	{({
																		active,
																		selected,
																	}) => (
																		<>
																			<span
																				className={classNames(
																					"block truncate",
																					selected &&
																						"font-semibold"
																				)}>
																				{
																					medecin.name
																				}
																			</span>

																			{selected && (
																				<span
																					className={classNames(
																						"absolute inset-y-0 left-0 flex items-center pl-1.5",
																						active
																							? "text-white"
																							: "text-secondary-600"
																					)}>
																					<CheckIcon
																						className="h-5 w-5"
																						aria-hidden="true"
																					/>
																				</span>
																			)}
																		</>
																	)}
																</Combobox.Option>
															)
														)}
													</Combobox.Options>
												)}
											</div>
										</Combobox>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<Button
											type="submit"
											button="primary"
											className="max-w-max sm:ml-3 			"
											children="Enregistrer"
											loading={
												addmedecinAssureurIsLoading
											}
										/>

										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setOpen(false)}>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			;
		</>
	);
};

const AddAssuranceDoctorPage = () => {
	const code = useSelector(getUserCode);
	// const defaultProfil = useSelector(getUserDefaultProfil);
	const {
		data: members,
		isLoading,
		isSuccess,
	} = useGetAllMedecinsAssureurQuery(code);

	const itemsPerPage = 10;

	const { next, prev, jump, currentData, currentPage, maxPage, dataLength } =
		usePagination(members?.data, itemsPerPage);

	const [openModal, setopenModal] = useState(false);

	return (
		<>
			{openModal && (
				<AddDoctorModal
					open={openModal}
					setOpen={setopenModal}
				/>
			)}
			<div className="px-8 sm:px-6 lg:px-8 mt-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							Liste des Médecins
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							La liste de tous les médecins consultant pour vous.
						</p>
					</div>

					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<Button
							children="Ajouter un medecin"
							button="primary"
							type="button"
							onClick={() => setopenModal(true)}
						/>
					</div>
				</div>

				{isSuccess && !members?.data?.length ? (
					<div className="flex flex-col items-center justify-center">
						{" "}
						<p className="text-3xl text-center text-gray-500 mt-6">
							aucun médecin trouvé{" "}
						</p>
					</div>
				) : (
					<>
						<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
													Nom
												</th>
												<th
													scope="col"
													className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
													Prenom
												</th>

												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 sr-only">
													Action
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{currentData?.map(
												(person, index) => (
													<tr key={index}>
														<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold  text-gray-700 sm:pl-6">
															{person.nom}
														</td>
														<td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-700">
															{person.prenom}
														</td>
														{/* <td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-700">
															{person.codeMembre}
														</td> */}
														<td className="py-1">
															<Menu
																as="div"
																className=" inline-block text-left">
																<div>
																	<Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
																		<span className="sr-only">
																			Open
																			options
																		</span>
																		<EllipsisHorizontalIcon
																			className="h-7 w-7"
																			aria-hidden="true"
																		/>
																	</Menu.Button>
																</div>

																<Transition
																	as={
																		Fragment
																	}
																	enter="transition ease-out duration-100"
																	enterFrom="transform opacity-0 scale-95"
																	enterTo="transform opacity-100 scale-100"
																	leave="transition ease-in duration-75"
																	leaveFrom="transform opacity-100 scale-100"
																	leaveTo="transform opacity-0 scale-95">
																	<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																		<div className="py-1">
																			<Menu.Item>
																				{({
																					active,
																				}) => (
																					<span
																						href="#"
																						className={classNames(
																							active
																								? "bg-gray-100 text-gray-900"
																								: "text-gray-700",
																							"cursor-pointer group flex items-center px-4 py-2 text-sm"
																						)}>
																						<PencilSquareIcon
																							className=" mr-3 h-5 w-5 text-yellow-400 group-hover:text-yellow-500"
																							aria-hidden="true"
																						/>
																						Modifier
																					</span>
																				)}
																			</Menu.Item>

																			{/* <Menu.Item>
																				{({
																					active,
																				}) => (
																					<span
																						className={classNames(
																							active
																								? "bg-gray-100 text-gray-900"
																								: "text-gray-700",
																							"cursor-pointer group flex items-center px-4 py-2 text-sm"
																						)}>
																						<ArchiveBoxIcon
																							className=" mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
																							aria-hidden="true"
																						/>
																						Supprimer
																					</span>
																				)}
																			</Menu.Item> */}
																		</div>
																	</Menu.Items>
																</Transition>
															</Menu>
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						{dataLength > itemsPerPage && (
							<Pagination
								next={next}
								prev={prev}
								jump={jump}
								currentData={currentData}
								currentPage={currentPage}
								maxPage={maxPage}
								dataLength={dataLength}
								itemsPerPage={itemsPerPage}
							/>
						)}
					</>
				)}

				{isLoading && (
					<div className="flex flex-1 justify-center">
						<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
					</div>
				)}
			</div>
		</>
	);
};

export default AddAssuranceDoctorPage;
