import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
	type: "",
	ville: {},
	skill: {},
	assurance: {},
	motif: "",
	description: "",
	medecin: {},
	periode: "",
	canal: "",
	isAssur: false,
};

// consultation slice
const consultationSlice = createSlice({
	name: "consultation",

	initialState,

	reducers: {
		// set medecin consultation
		medecinConsultation: (state, action) => {
			return {
				...state,
				medecin: action.payload,
			};
		},
		// set assurance consultation
		assuranceConsultation: (state, action) => {
			return {
				...state,
				assurance: action.payload,
			};
		},
		// set motif consultation
		motifConsultation: (state, action) => {
			return {
				...state,
				motif: action.payload,
			};
		},
		// set type consultation
		typeConsultation: (state, action) => {
			return {
				...state,
				type: action.payload,
			};
		},
		// set description consultation
		descriptionConsultation: (state, action) => {
			return {
				...state,
				description: action.payload,
			};
		},
		// set periode consultation
		periodeConsultation: (state, action) => {
			return {
				...state,
				periode: action.payload,
			};
		},
		// set canal consultation
		canalConsultation: (state, action) => {
			return {
				...state,
				canal: action.payload,
			};
		},
		// set isAssur consultation
		isAssurConsultation: (state, action) => {
			return {
				...state,
				isAssur: action.payload,
			};
		},
		// set ville consultation
		villeConsultation: (state, action) => {
			return {
				...state,
				ville: action.payload,
			};
		},
		// set specialist consultation
		specialiteConsultation: (state, action) => {
			return {
				...state,
				skill: action.payload,
			};
		},
		// reset state of consultation
		resetConsultation: (state, action) => {
			return initialState;
		},
	},
});

export default consultationSlice.reducer;

// get consultation
export const getconsultation = (state) => state.consultation;

export const {
	typeConsultation,
	villeConsultation,
	medecinConsultation,
	assuranceConsultation,
	motifConsultation,
	descriptionConsultation,
	periodeConsultation,
	canalConsultation,
	isAssurConsultation,
	specialiteConsultation,
	resetConsultation,
} = consultationSlice.actions;
