import { useState } from "react";
import styled from "styled-components";
import { setModalPopUp } from "../../../../layoutLogics";
import { useDispatch } from "react-redux";
import Spinner from "../../../../../../UI/spinner/Spinner";

const ButtonBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const AddButton = styled.button`
    background: #0156b6;
    padding: 0.5em 0;
    border-radius: 4px;
    color: white;
    // font-weight: 500;
    // &:hover {
    //     font-weight: 600;
    // }
`;

function AddDoc({ setAssurance, assurance, addDoc, isAdding=false, setIsEdited }) {
    const [title, setTitle] = useState("");
    const [type, setType] = useState("document");
    const [file, setFile] = useState(null);

    const dispatch = useDispatch();

    function handleDocSubmittance() {
        if (title.length > 0 && type.length > 0 && file) {
            setAssurance({
                ...assurance,
                documentation: [...assurance.documentation, {
                    title: title,
                    type: type,
                    file: file
                }]
            });

            if (setIsEdited) {setIsEdited(true);};
    
            setTitle("");
            setType("document");
            setFile(null);

            // Réinitialisation de l'élément input de type file
            const fileInput = document.getElementById("doc-add-file");
            if (fileInput) fileInput.value = null;
        } else {
            setModalPopUp(dispatch, "veuillez renseigner tous les champs", "failed");
        };
    };

    return (
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
                <h3 className="text-base font-semibold leading-7 text-gray-500">Ajouter un document</h3>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="docTilte" className="block text-sm font-medium leading-6 text-gray-900">
                    Titre du document
                </label>
                <div className="mt-2">
                    <input
                        type="text"
                        name="docTilte"
                        id="docTilte"
                        autoComplete="docTilte"
                        placeholder="Donnez un titre au fichier..."
                        onChange={(event) => setTitle(event.target.value)}
                        value={title}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="docType" className="block text-sm font-medium leading-6 text-gray-900">
                    Type de document
                </label>
                <div className="mt-2">
                    <select
                        id="docType"
                        name="docType"
                        autoComplete="docType-name"
                        defaultChecked={"document"}
                        onChange={(event) => setType(event.target.value)}
                        value={type}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        <option disabled selected hidden>-- Choix du type de fichier --</option>
                        <option value="document">Document (Pdf/Xdocx)</option>
                        <option value="image">Image (Jpeg/PNG)</option>
                        {/* <option value="link">Lien (https://exemple.com)</option> */}
                    </select>
                </div>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="doc-add-file" className="block text-sm font-medium leading-6 text-gray-900">
                    Ajouter un fichier
                </label>
                <div className="mt-2 bg-white">
                    <input
                        type={type === "link" ? "text" : "file"}
                        name="doc-add-file"
                        id="doc-add-file"
                        autoComplete="doc-add-file"
                        placeholder={type === "link" ? "Veuillez coller un lien ici..." : null}
                        accept={type === "image" ? "image/*" : ".doc, .docx, .pdf"}
                        onChange={(event) => setFile(type === "link" ? event.target.value : event.target.files[0])}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div className="sm:col-span-3">
                <ButtonBox>
                    <AddButton
                        className="flex items-center justify-center gap-4 block text-sm font-medium leading-6 hover:font-bold"
                        onClick={() => addDoc ? addDoc(title, type, file, assurance, setAssurance, setTitle, setType, setFile, setModalPopUp, dispatch) : handleDocSubmittance()}
                    >
                        {isAdding && (<Spinner className="w-6 h-6 text-primary-800" />)}
                        Ajouter le fichier
                    </AddButton>
                </ButtonBox>
            </div>
        </div>
    );
};

export default AddDoc;
