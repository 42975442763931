/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurances
 * @component ListAssurances
 */
import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import backIllustration from "../../../../../../assets/beneficiary.png";
import { useGetAssurancesCategorieQuery } from "../../../../../../features/api/apiSlice";
import Spinner from "../../../../../../UI/spinner/Spinner";
import styled from "styled-components";

import FilterIllustration from "../../../../../../assets/Select-amico.svg";
// import FilterAnimatedIllust from '../../../../../../assets/animate-illustration.svg'

import testImage from '../../../../../../assets/QA engineers-bro.svg';
import healtImage from '../../../../../../assets/Medical care-amico.svg';
import carImage from '../../../../../../assets/Car driving-amico.svg';

import BackIllustration from "../../../../../Utilities/BackIllustration";
import CategoryCard from "./CategoryCard";
import SubscriptionBodyTitles from "../../../../../Utilities/subscriptionBodyTitles";
import SubscriptionBodyFilterIllustrated from "../../../../../Utilities/subscriptionBodyFilterIllustrated";
import SubscriptionCardsTitle from "../../../../../Utilities/subscriptionCardsTitle";

import { filterLogic } from "./logic";
import { getUser } from "../../../../../../features/user/user.slice";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  margin: auto;
  @media screen and (max-width: 500px) {
    gap: 2.5em;
  }
`;

const StyledBody = styled.div`
  width: 970px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  display: grid;
  gap: 7em;
  grid-template-columns: 450px 450px;
  grid-auto-rows: auto;
  @media screen and (max-width: 1023px) {
    grid-template-columns: 450px;
  }
  @media screen and (max-width: 479px) {
    grid-template-columns: 350px;
    gap: 5em;
  }
`;

function ListCategory() {
  // getting user
  const user = useSelector(getUser);

  // get insurance category request
  const { data, isSuccess, isLoading } = useGetAssurancesCategorieQuery();
  console.log(data?.data);
  const [categories, setCategories] = useState([]);
  const [queryCategorie, setQueryCategorie] = useState("");
  // selected category state
  const [categoriSelect, setCategoriSelect] = useState();

  // set data insurances
  useEffect(() => {
    if (isSuccess) {
      setCategories(data?.data);
    }

    return () => {};
  }, [isSuccess, data]);

  // set selected category
  useEffect(() => {
    if (isSuccess) {
      setCategoriSelect(data?.data[0]);
    }
  }, [setCategoriSelect, isSuccess, data]);

  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col justify-center items-center h-full">
        <Spinner className="h-10 w-10 text-primary-700" />
      </div>
    );
  }

  console.log("assurance category")

  if (isSuccess) {
    return (
      <div className="w-full">
        <BackIllustration />

        {/* radio category group  */}
        <Container>
          <SubscriptionBodyTitles
            bodyText={[`Hello ${user?.prenom} !`]}
            title="Choisis une catégorie !"
          />

          {/* <BreadCumbs /> */}

          <SubscriptionBodyFilterIllustrated
            setQueryData={setQueryCategorie}
            data={categories}
            filterProperty="nom"
            filterLabel="Choisissez une catégorie"
            filterIllustration={FilterIllustration}
            message="Tu as besoin de quel type d'assurance exactement ? 🤔 merci de nous le dire dans le champ ci-dessous... 👇😊."
          />

          <StyledBody>
            <SubscriptionCardsTitle content="Liste de catégories" />

            <RadioGroup value={categoriSelect} onChange={setCategoriSelect}>
              <CardContainer>
                {filterLogic(queryCategorie, categories, "nom").map((categorie, index) => (
                  <CategoryCard
                    key={`${categorie.idCategorie}-${index}`}
                    category={categorie}
                  />
                ))}
              </CardContainer>
            </RadioGroup>
          </StyledBody>
        </Container>
      </div>
    );
  } else {
    return <h1>An Error Occured</h1>;
  }
}

export default ListCategory;
