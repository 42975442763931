import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ControllableStates({ setQueryData, data, filterProperty, filterLabel, clearInput=false }) {
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  function removeDataProperty(data, property) {
    return data.map((item) => (
      item[property]
    ));
  }

  React.useEffect(() => {
    if (clearInput) {
      setValue(null);
    }
  });

  const options = removeDataProperty(data, filterProperty);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setQueryData(newInputValue);
      }}
      id="controllable-states"
      options={options}
      sx={{ width: "100%", background: "white" }}
      renderInput={(params) => <TextField {...params} label={filterLabel} />}
    />
  );
}
