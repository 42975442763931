import Button from "../../../UI/button/Button";
import Spinner from "../../../UI/spinner/Spinner";
import styled from "styled-components";
import { Paragraph } from "../../layouts/layoutStyle";

const Container = styled.div`
    width: 30%;
    height
    // background-color: rgba(255, 255, 255, 0.8);
    min-width: 300px;
    @media screen and (max-width: 1023px) {
        width: 100%;
    }
`;

const StyledTotal = styled.div`
    width: 100%;
    color: white;
    // font-size: 2em;
    font-weight: 600;
    padding: 10px;
    text-align: center;
    border-radius: 8px 8px 0 0;
    @media screen and (max-width: 1023px) {
        border-radius: 8px;
	}
`;

const StyledH4 = styled.h4`
    font-size: 1.2em;
    font-weight: 500;
`;

const StyledDetails = styled.div`
    ${({image}) => `
        background-image: url(${image});
    `}
    background-color: rgba(255, 255, 255, 0.8);
    min-height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    // background-size: cover;
    // padding: 50px;
    @media screen and (max-width: 1023px) {
		display: none;
	}
`;

function PricingBoard({ totalPrice, data, currentStep, IsLoading, handlePayement, image, message }) {

    return (
        <Container>
            <StyledTotal
                className="bg-secondary-700"
            >
                <StyledH4>TOTAL DES COÛTS</StyledH4>

                {IsLoading ? (
                    <Spinner className="h-8 w-8 m-auto text-primary-700 m-8 " />
                ) : (
                    <>
                        <span className="text-primary-500 text-4xl text-bold">
                            {totalPrice}
                        </span> <span className="text-xl text-bold">Fcfa</span>
                    </>
                )}
            </StyledTotal>

            <StyledDetails image={image}>
                <div style={{
                    background: "white",
                    padding: 10,
                    fontWeight: 400,
                    textAlign: "justify"
                }}>
                   <Paragraph>{message}</Paragraph>
                </div>
            </StyledDetails>

            {/* <div className="mt-9">{image}</div> */}
        </Container>
    );
};

export default PricingBoard;
