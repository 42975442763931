/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description professionnal information of user
 * @component ProfessionalInformation
 */

import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Transition, Dialog, Switch } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";

import Input from "../../../../UI/form/Input";
import TextArea from "../../../../UI/form/TextArea";
import Select from "../../../../UI/form/Select";
import Spinner from "../../../../UI/spinner/Spinner";
import Button from "../../../../UI/button/Button";

import { getUserDefaultProfil } from "../../../../features/user/user.slice";

import {
	useAddSkillMedecinMutation,
	useGetSkillsMedecinQuery,
	useGetSkillsQuery,
} from "../../../../features/api/apiSlice";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

// add skill modal
const AddSkillModal = ({ open, setOpen }) => {

	// get skill reqquest
	const { data: skills, isSuccess, isLoading } = useGetSkillsQuery();

	// option skill state
	const [valueOption, setvalueOption] = useState([]);

	// skill state
	const [skill, setSkill] = useState({});

	// is expert state
	const [isExpert, setIsExpert] = useState(false);

	// add skill request
	const [addSkillMedecin, { isLoading: addSkillMedecinLoading }] =
		useAddSkillMedecinMutation();

	// set skill option
	useEffect(() => {
		if (isSuccess && skills) {
			const data = skills?.data?.map((item) => {
				return {
					id: item.id_skill,
					name: item.nom,
				};
			});

			setvalueOption(data);
		}

		return () => { };
	}, [isSuccess, skills]);

	// handle change
	const handleChange = (e) => {
		const { name, value } = e.target;

		setSkill((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	// handle change is expert
	const handleChangeExpert = () => {
		setIsExpert(!isExpert);
	};

	// handle add skill
	const skillhandleSubmit = (e) => {
		e.preventDefault();
		const data = {
			...skill,
			isExpert,
		};
		addSkillMedecin(data)
			.unwrap()
			.then((res) => {
				setOpen(false);
			})
			.catch((err) => {
				// set err alert
			});
	};

	return (
		<>
			<Transition.Root
				show={open}
				as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
						onClose={setOpen}>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<Dialog.Panel
									onSubmit={(e) => skillhandleSubmit(e)}
									as="form"
									className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div>
										<div className="">
											<Dialog.Title
												as="h2"
												className="text-lg font-medium leading-6 text-primary-800">
												Ajouter une compétence à votre
												profil.
											</Dialog.Title>
											<Dialog.Description
												as="p"
												className="text-sm leading-6 text-gray-700">
												Bien vouloir choisir une
												compétence correspondant à votre
												profil
											</Dialog.Description>
										</div>
									</div>
									<div className="space-y-4 mt-8">
										{isLoading ? (
											<Spinner className="w-8 h-8 text-secondary-600 " />
										) : (
											<Select
												id="skill"
												defaultValue="default"
												name="id_skill"
												label="Choisir une Compétence"
												valueOption={valueOption}
												className="sm:max-w-xs w-full text-gray-700"
												onChange={handleChange}
											/>
										)}
										{/* price  */}
										<Input
											label="Montant lié à la compétence"
											input={{
												id: "prix",
												type: "number",
												min: "0",
												step: "500",
											}}
											onChange={handleChange}
											name="prix"
											disabled={false}
											required={false}
										/>
										{/* description  */}
										<TextArea
											label="Description"
											name="description"
											onChange={handleChange}
											id="description"
											placeholder=""
											row="4"
										/>

										{/* is expert  */}
										<Switch.Group
											as="div"
											className="flex items-center sm:mt-0 mt-8">
											<Switch.Label
												as="span"
												className="mr-3">
												<span className=" text-sm font-medium text-gray-700 cursor-pointer">
													Je consulte en tanque expert
													pour ce domaine
												</span>
											</Switch.Label>
											<Switch
												checked={isExpert}
												onChange={handleChangeExpert}
												className="group relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2">
												<span className="sr-only">
													Je consulte en tanque expert
													pour ce domaine
												</span>
												<span
													aria-hidden="true"
													className="pointer-events-none absolute h-full w-full rounded-md bg-white"
												/>
												<span
													aria-hidden="true"
													className={classNames(
														isExpert
															? "bg-secondary-600"
															: "bg-gray-200",
														"pointer-events-none absolute mx-auto h-3 w-7 rounded-full transition-colors duration-200 ease-in-out"
													)}
												/>
												<span
													aria-hidden="true"
													className={classNames(
														isExpert
															? "translate-x-5"
															: "translate-x-0",
														"pointer-events-none absolute left-0 inline-block h-4 w-4 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
													)}
												/>
											</Switch>
										</Switch.Group>

										{/* isEXpert price  */}
										{isExpert && (
											<Input
												label="Montant lié à l'expertise"
												input={{
													id: "prixExpert",
													type: "number",
													min: "0",
													step: "500",
												}}
												onChange={handleChange}
												name="prixExpert"
												disabled={false}
												required={false}
											/>
										)}
									</div>

									{/* button  */}
									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
										<div className="sm:col-start-2 ">
											<Button
												type="submit"
												id="skills"
												children="Envoyer"
												button="primary"
												loading={addSkillMedecinLoading}
											/>
										</div>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
											onClick={() => setOpen(false)}>
											Annuler
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

const ProfessionalInformation = (props) => {
	const { profession, specialisation, setUserInfo } = props;

	// const code = useSelector(getUserCode);
	const defaultProfil = useSelector(getUserDefaultProfil);

	// skip request state
	const [skip, setskip] = useState(true);

	// get skill medecin request
	const {
		data: skillsMedecin,
		isLoading: skillMedecinLoading,
		isSuccess: skillMedecinIsSuccess,
	} = useGetSkillsMedecinQuery(null, {
		skip,
	});

	// set kskip state
	useEffect(() => {
		if (defaultProfil.id === "IA7" || defaultProfil.id === "IA10") {
			setskip(false);
		}
		return () => { };
	}, [defaultProfil]);

	// set user info state
	const HandleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		setUserInfo((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	// display value state of Combobox skills
	const [skillMedecin, setSkillMedecin] = useState([]);

	// set data skill medecin
	useEffect(() => {
		if (skillMedecinIsSuccess) {
			setSkillMedecin(skillsMedecin.data);
		}

		return () => { };
	}, [skillMedecinIsSuccess, skillsMedecin]);

	// add skill modal state
	const [open, setOpen] = useState(false);

	return (
		<>
			{/* add skill modal  */}
			{open && (
				<AddSkillModal
					open={open}
					setOpen={setOpen}
				/>
			)}

			<section
				aria-labelledby="payment-details-heading "
				className="px-4 sm:p-8">
				<div>
					{/* header  */}
					<div>
						<h2
							id="payment-details-heading"
							className="text-lg font-medium leading-6 text-gray-900 mt-6">
							Informations Professionnelles
						</h2>
						
					</div>

					<div className="mt-8 grid grid-cols-2 gap-6">
						{/* profession  */}
						{profession !== undefined && (
							<div className="col-span-2 sm:col-span-1">
								<Input
									label="Profession"
									input={{
										id: "Profession",
										type: "text",
										maxLength: "150",
									}}
									onChange={HandleChange}
									defaultValue={profession}
									name={"profession"}
									placeholder="description de profession"

									disabled={false}
									required={false}
								/>
							</div>
						)}

						{/* specialisation */}
						{specialisation !== null && (
							<div className="col-span-2 sm:col-span-1">
								<Input
									label="Spécialisation"
									input={{
										id: "Spécialisation",
										type: "text",
										maxLength: "80",
									}}
									onChange={HandleChange}
									defaultValue={specialisation}
									name={"specialisation"}
									disabled={false}
									required={false}
								/>
							</div>
						)}
						{/* description  */}
						<div className="col-span-2 sm:col-span-2">
							<TextArea
								label="description"
								rows="6"
								name="description"
								placeholder="description d'information professionelles"
							/>
						</div>

						<div className="col-span-2 sm:col-span-2">
							{/* skill loader  */}
							{skillMedecinLoading && (
								<Spinner className="w-6 h-6 text-primary-600" />
							)}

							{
								<div className="mt-4">
									<h3 className="text-lg font-medium leading-6 text-gray-900 mt-6">
										Mes Compétences
									</h3>
									{/* list skills  */}
									<div className="mt-1 text-sm text-gray-900">
										<ul className=" flex gap-3 flex-wrap items-center">
											{skillMedecin?.map(
												(item, index) => {
													console.log(item);
													return (
														<li
															key={index}
															className={classNames(
																"flex items-center gap-x-4 pl-2 group rounded-xl cursor-pointer hover-black ",
																item.isExpert ===
																	"1"
																	? "bg-secondary-100"
																	: "bg-green-100"
															)}>

															<span title="Personnaliser les motifs de consultation pour cette competence"
																className="flex gap-x-1 items-center">
																<p
																	className={classNames(
																		" text-xs",
																		item.isExpert ===
																			"1"
																			? "text-secondary-900"
																			: "text-green-900"
																	)}>
																	{item.nom}{" "}
																</p>
															</span>

															<XCircleIcon
																title="personnaliser les motifs de consultations pour cette compétence"
																aria-hidden="true"
																className={classNames(
																	"cursor-pointer h-6 w-6",
																	item.isExpert ===
																		"1"
																		? " hover:text-secondary-900 text-secondary-700 "
																		: " hover:text-green-900 text-green-700 "
																)}
															/>
														</li>
													);
												}
											)}

											<li onClick={() => setOpen(true)}>
												<Button
													type="button"
													children="Ajouter une compétence"
													onClick={() =>
														setOpen(true)
													}
													title="Ajouter une nouvelle compétence"
												/>
											</li>
										</ul>
									</div>
								</div>
							}
						</div >
					</div >
				</div >
			</section >
		</>
	);
};

export default ProfessionalInformation;
