/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description verifiy email after registration
 * @component ValidateEmail
 */
import React from 'react'
import { useState, useLayoutEffect } from 'react'

import Logo from '../../components/logo/Logo'
import Title2 from '../../components/title/Title2'
import ModalWithoutAction from '../../components/feedback/ModalWithoutAction'
import ValidateEmailForm from '../../components/auth/ValidateEmailForm'

import Alert from '../../UI/alert'

const ValidateEmail = () => {
  // alert state 
  const [alert, setalert] = useState({
    text: "",
    error: false,
    isAlert: false
  })

  // close alert after 10 secondes
  useLayoutEffect(() => {
    if (alert.isAlert) {
      setTimeout(() => {
        setalert(prevState => {
          return {
            ...prevState,
            isAlert: false,
            error: false,
            text: ""
          }
        })
      }, 3000)
    }

    return () => {
      clearTimeout()
    };
  }, [alert.isAlert])

  // open modal 
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className=" mx-auto w-full max-w-sm lg:w-96 py-10 lg:py-20">
        {/* alert componenet  */}
        {
          alert.isAlert &&
          <Alert alert={alert} setalert={setalert} />
        }

        <div>
          <Logo />
          <Title2 text={"Validation de compte"} />
        </div>

        <div className="mt-4">
          <p className="text-sm font-medium text-gray-700">Veuillez entrer le code que nous vous avons envoyé par mail.</p>

          {/* validate email componenet  */}
          <div className="mt-6">
            <ValidateEmailForm openFeedBack={open} setOpen={setOpen} setalert={setalert} />
          </div>
        </div>
      </div>

      {/* modal componenet  */}
      {open && <ModalWithoutAction openFeedBack={open} description="Un mail contenant le code de validation de votre compte vous a été envoyé." title="Nouveau code envoyé" setOpen={setOpen} />}
    </>
  )
}



export default ValidateEmail