/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  medecin speciality for consultation
 * @component SpecialiteConsultationPage
 */

import Main from "../../../components/layouts/main";
import SpecialiteConsultation from "../../../components/layouts/main/consultations/SpecialiteConsultation";

const SpecialiteConsultationPage = (props) => {
	return <Main children={<SpecialiteConsultation />} />;
};

export default SpecialiteConsultationPage;
