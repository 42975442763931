/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add member for family account
 * @component AddMembers
 */

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Input from "../../../../UI/form/Input";
import Button from "../../../../UI/button/Button";

import { useCreatePaiementMutation } from "../../../../features/api/apiSlice";

import { setModal } from "../../../../features/feedBack/modal.slice";
import {
	ArchiveBoxIcon,
	LockOpenIcon,
	EyeIcon,
	LockClosedIcon,
	CheckCircleIcon,
	PhotoIcon 
} from "@heroicons/react/20/solid";
const AddModepaiement = () => {
	

	// add modepai request
	const [createMode, { isLoading: loading }] = useCreatePaiementMutation();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const [PaiementInfo, setpaiementInfo] = useState({});

	// handle change
	
	const HandleChange = (e) => {
		const type = e.target.type;
		if (type === "file") {
			console.log('InFile')
			let file = e.target.files[0];
			let imgUrl = URL.createObjectURL(file);
			console.log('file', file)
			setpaiementInfo((prevState) => {
				return {
					...prevState,
					imgUrl,
					image : file,
				};
			});
		} else {
			const name = e.target.name;
			const value = e.target.value.trim();
			console.log('name', value)
			setpaiementInfo((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		}

	};
	// add paiement mode
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(PaiementInfo);
		const dataForm = new FormData();

		const pushItemform = (item, key) => {
			return dataForm.append(`${key}`, item);
		};
		pushItemform(PaiementInfo?.nom, "nom");
		pushItemform(PaiementInfo?.image, "image");


		createMode(dataForm)
			.unwrap()
			.then((res) => {
				// alert user we add new user
				dispatch(
					setModal({
						modalType: "paiement",
						modalProps: {
							isOpen: true,
							status: "success",
							text: "Le paiement a ajouté avec succès",
						},
					})
				);
				navigate("/operations/Modepaiement");
			})
			.catch((err) => {});
	};

	return (
		<>
			<div className="p-4">
				<Button
					children="Retour"
					type="button"
					onClick={() => navigate("/operations/Modepaiement")}
					className="max-w-max"
				/>
			</div>
			<form
				
				className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
				<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
					<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
						{/* header  */}
						<div>
							<h3 className="text-lg font-medium leading-6 text-gray-900">
								Ajouter un mode de paiement
							</h3>
							<p className="mt-1 max-w-2xl text-sm text-gray-500">
								Bien vouloir remplir ce formulaire pour ajouter
								un mode de paiement
							</p>
						</div>

						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="nom"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Nom 
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<div className="sm:max-w-xs">
										<Input
											input={{
												id: "nom",
												type: "text",
												maxLength: "80",
											}}
											name="nom"
											disabled={false}
											required={false}
											onChange={HandleChange}
										/>
									</div>
								</div>
							</div>
						</div>
						
						<div className="ml-5 rounded-md shadow-sm">
										<div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
											<label
												htmlFor="image"
												className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700">
												<span>Ajouter une image</span>
												<span className="sr-only">
													photo dPaiement
												</span>
											</label>
											<Input
											input={{
												id: "image",
												type: "file",
												
											}}
											name="image"
											disabled={false}
											required={false}
											onChange={HandleChange}
										/>
										</div>
									</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
						<Button
							children="Ajouter un mode de paiement"
							button="primary"
							onClick={handleSubmit}
							type="submit"
							className="max-w-max"
							loading={loading}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default AddModepaiement;
