/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add member page
 * @component AddMembersPage
 */
import React from "react";

import AddMembers from "../../components/layouts/main/users/Addmembers";

const AddMembersPage = () => {
	return <AddMembers />;
};

export default AddMembersPage;
