/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  medecin agenda
 * @component MonAgendaPage
 */
import Main from "../../../components/layouts/main";
import MonAgenda from "../../../components/layouts/main/consultations/doctor/Mon_agenda";

const MonAgendaPage = (props) => {
	return <Main children={<MonAgenda />} />;
};

export default MonAgendaPage;
