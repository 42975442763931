/**
 * @author assan zidan
 * @email nsangouassanzidan@gmail.com
 * @description not found  page
 * @component  NotFoundPage
 */

import { Link } from "react-router-dom";

export default function NotFoundPage() {
	return (
		<>
			<main className="flex flex-col justify-center items-center gap-y-4 min-h-screen">
				<p className="text-4xl font-bold tracking-tight text-indigo-600 sm:text-8xl">
					404
				</p>
				<div className=" sm:border-gray-200 sm:pl-6">
					<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
						Page non trouvée
					</h1>
					<p className="mt-1 text-base text-gray-500 leading-8">
						Veillez verifier l'URL dans la bar d'addresse puis
						réessayer ?
					</p>
				</div>
				<div className="mt-10 flex space-x-3 sm:border-transparent sm:pl-6">
					<Link
						to="/dashboard"
						className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
						Aller à l'accueil
					</Link>
				</div>
			</main>
		</>
	);
}
