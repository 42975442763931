/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description new password page
 * @component NewPassword
 */

import React, { useState, useLayoutEffect } from 'react'

import Logo from '../../components/logo/Logo'
import Title2 from '../../components/title/Title2'
import NewpasswordForm from '../../components/auth/NewpasswordForm'

import Alert from '../../UI/alert'

const NewPassword = () => {
  // alert state 
  const [alert, setalert] = useState({
    text: "",
    error: false,
    isAlert: false
  })

  // close alert after 10 secondes 
  useLayoutEffect(() => {
    if (alert.isAlert) {
      setTimeout(() => {
        setalert(prevState => {
          return {
            ...prevState,
            isAlert: false,
            error: false,
            text: ""
          }
        })
      }, 10000)
    }

    return () => {
      clearTimeout()
    };
  }, [alert.isAlert])

  return (
    <div className="flex relative  flex-1 flex-col justify-center py-12 lg:py-20 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
      <div className=" mx-auto w-full max-w-sm lg:w-96 lg:py-20">
        {alert.isAlert &&
          <Alert alert={alert} setalert={setalert} />
        }

        <div>
          <Logo />
          <Title2 text={"Nouveau mot de passe"} />
        </div>

        {/* new password form component  */}
        <div className="mt-8">
          <NewpasswordForm setalert={setalert} />
        </div>
      </div>
    </div>
  )
}

export default NewPassword