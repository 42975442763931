/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  picker emoji modal
 * @component AddEmojiMessage
 */
import { Popover, Transition } from "@headlessui/react";
import { FaceSmileIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const AddEmojiMessage = ({ handleEmojiClck }) => {
	return (
		<>
			<Popover className="relative">
				{({ open }) => (
					<>
						<Popover.Button
							className={`
                ${open ? "" : "text-opacity-90"}
                -m-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500`}>
							<FaceSmileIcon
								className="h-6 w-6"
								aria-hidden="true"
							/>

							<span className="sr-only">Add your emoji </span>
						</Popover.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1">
							<Popover.Panel className="absolute bottom-1/2 z-10 -translate-y-24 max-w-sm transform px-4 sm:px-0 ">
								<Picker
									data={data}
									onEmojiSelect={(emoji) => {
										handleEmojiClck(emoji.native);
									}}
								/>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</>
	);
};

export default AddEmojiMessage;
