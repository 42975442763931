/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance souscription for inch product
 * @component AssurancesInchSouscribe
 */
import React, { useState, useEffect } from "react";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import {
	InformationCircleIcon,
	ArrowLongRightIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";

import MonAssuranceCard from "./MonAssuranceCard";

import {
	assuranceConsultation,
	isAssurConsultation,
	villeConsultation,
} from "../../../../../features/consultation/consultation.slice";
import { getUserCode } from "../../../../../features/user/user.slice";
import { getToken } from "../../../../../features/user/auth.slice";

import axiosInstance from "../../../../../apis/inchAssur";

import Button from "../../../../../UI/button/Button";
import Spinner from "../../../../../UI/spinner/Spinner";

// aletr component
const Alert = () => {
	return (
		<div className="rounded-md bg-blue-50 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<InformationCircleIcon
						className="h-5 w-5 text-blue-400"
						aria-hidden="true"
					/>
				</div>
				<div className="ml-3 flex-1 md:flex md:justify-between">
					<p className="text-sm text-blue-700">
						Vous devez souscrire à une assurance Inch pour
						continuer.
					</p>
					<p className="mt-3 text-sm md:mt-0 md:ml-6">
						<Link
							to="/assurances/inchpro"
							className="whitespace-nowrap group font-medium text-blue-700 hover:text-blue-600 flex gap-x-2 items-center">
							Souscrire maintenant
							<ArrowLongRightIcon
								aria-hidden="true"
								className="h-4 w-4 group-hover:translate-x-1 transition-transform"></ArrowLongRightIcon>
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

const AssurancesInchSouscribe = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// user code
	const code = useSelector(getUserCode);

	// token
	const token = useSelector(getToken);

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	// alert state
	const [alert, setAlert] = useState(false);

	// display alert if user not have inch insurance
	useEffect(() => {
		setLoading(true);
		axiosInstance(`adaProducts/subscriber/${code}`, {
			headers: {
				authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.data?.data.length === 0) {
					setData([]);
					setAlert(true);
				} else {
					setData(res.data?.data);
				}
			})
			.catch((err) => {
				// do something
			})
			.finally(() => {
				setLoading(false);
			});
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// select insurance for consultation
	const selectAssurHandle = (assur) => {
		dispatch(assuranceConsultation(assur));
		dispatch(isAssurConsultation(true));
		dispatch(villeConsultation({ nomVille: "Douala" }));
		// add assurance to consultation and set the query params
		navigate({
			pathname: "/consultations/motif",
			search: createSearchParams({
				withassurance: true,
				ville: "Douala",
				canal: "Présentiel",
				codeassur: assur.code,
			}).toString(),
		});
	};
	return (
		<>
			{/* alert component  */}
			{alert && <Alert />}
			<div className="bg-white">
			<div className="mt-8">
						<Button
							children="Etape Précédent"
							type="button"
							onClick={() => navigate(-1)}
						/>
			</div>
				<div className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
					<h1 className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl my-8">
						mes assurances Inch{" "}
					</h1>

					{loading ? (
						<Spinner className="w-6 h-6 text-primary-700 mt-4" />
					) : data?.length === 0 ? (
						<div className="text-center text-gray-500 font-medium text-md">
							Vous n'avez souscri à aucune assurance inch
						</div>
					) : (
						<div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
							{data?.map((item, index) => {
								return (
									<MonAssuranceCard
										key={index}
										titre={item.nom}
										images={item.images[0]?.uri}
										categorie={item.categorie.nom.toLowerCase()}
										onClick={selectAssurHandle.bind(
											null,
											item
										)}
									/>
								);
							})}
						</div>
					)}

				
				</div>
			</div>
		</>
	);
};

export default AssurancesInchSouscribe;
