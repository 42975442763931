import { useSelector, useDispatch } from "react-redux";
import {
	Fragment,
	useEffect,
	useState,
	useCallback,
	useLayoutEffect,
} from "react";
import Spinner from "../../../../../UI/spinner/Spinner";
import { Menu, Transition } from "@headlessui/react";
import {
	getUserCode,
	getUserDefaultProfil,
} from "../../../../../features/user/user.slice";
import {
	getModalProps,
	getModalType,
	initialModal,
	setModal,
} from "../../../../../features/feedBack/modal.slice";
import {
	EyeIcon,
	ArchiveBoxIcon,
	ArrowRightCircleIcon,
	EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { useDeleteConsultationMutation } from "../../../../../features/api/apiSlice";
import axiosInstance from "../../../../../apis/inchAssur";
import { getToken } from "../../../../../features/user/auth.slice";
import { Alert } from "../../UserRecharge/Recharge";
import { Link, useNavigate } from "react-router-dom";
import StartConsult from "./StartConsult";
import Pagination from "../../../../../UI/pagination";
import usePagination from "../../../../../hooks/usePagination";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}
const ListConsultations = () => {
	const code = useSelector(getUserCode);
	const token = useSelector(getToken);
	const profil = useSelector(getUserDefaultProfil);
	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const profilID = profil?.id;

	const [consultations, setConsultations] = useState([]);

	const [currentCode, setCurrentCode] = useState();

	const [verifconsultationIsLoading, setVerifconsultationIsLoading] =
		useState(false);

	const [isAlert, setIsAlert] = useState({
		isError: false,
		message: "",
	});

	const [isLoading, setisLoading] = useState(false);
	const [openConsult, setOpenConsult] = useState(false);

	const [selectedConsultations, setSelectedConsultations] = useState(null);
	const [rowClick, setRowClick] = useState(true);

	// verify is code is true

	useEffect(() => {
		if (profilID === "IA7") {
			setisLoading(true);
			axiosInstance(`/consultations/medecin/${code}`, {
				headers: {
					authorization: `Bearer ${token}`,
				},
			})
				.then((res) => {
					setConsultations(res.data?.data);
				})
				.catch((err) => {
					// do something
				})
				.finally(() => setisLoading(false));
		} else {
			setisLoading(true);
			axiosInstance(`/consultations/patient/${code}`, {
				headers: {
					authorization: `Bearer ${token}`,
				},
			})
				.then((res) => {
					console.log("ccc" ,res)
					setConsultations(res.data?.data);
				})
				.catch((err) => {
					// do something
				})
				.finally(() => setisLoading(false));
		}
	
		return () => {};
	}, [profilID, token, code]);
    console.log(consultations)
	const [selectedConsultation, setSelectedConsultation] = useState(null);
	//data tabele
	const [filters, setFilters] = useState({
		global: {value: null, matchMode: FilterMatchMode.CONTAINS}
	});
    const [globalFilterValue, setGlobalFilterValue] = useState('');

	// delete skill request
	const [deleteConsultation, { isLoading: deleteConsulIsLoading }] =
		useDeleteConsultationMutation();



	// deleteSkill
	const deleteConsultationHandleClick = (consultation) => {
		const id = consultation.id_skill
		deleteConsultation(id)
			.unwrap()
			.then((res) => {

				dispatch(
					setModal({
						modalType: "consultations",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `La consultation du medécin ${consultation.medecin} prévue le ${consultation.date}  à ${consultation.heure} a été supprimée avec success`,
						},
					})
				);


			})

			.catch((err) => {
				console.log(err);
			});
	};


	const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
	const paginatorRight = <Button type="button" icon="pi pi-download" text />;

	//show header
	const renderHeader = () => {
		return (
			<div className="flex flex-wrap gap-2 justify-content-end align-items-end" style={{ justifyContent: 'end' }}>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="rechercher" />
				</span>
			</div>
		);
	};

	//set value to filter

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const header = renderHeader();

	// open options parameters(edit and delete)
	const actionBodyTemplate = (rowData) => {
		return <Menu
			as="div"
			className=" inline-block text-left">
			<div>
				<Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
					<span className="sr-only">
						Open options
					</span>
					<EllipsisHorizontalIcon
						className="h-7 w-7"
						aria-hidden="true"
					/>
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95">
				<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						<Menu.Item>
							{({
								active,
							}) => (
								<Link
									to={`${rowData.id}`}>
									<span
										className={classNames(
											active
												? "bg-gray-100 text-gray-900"
												: "text-gray-700",
											"group flex items-center px-4 py-2 text-sm cursor-pointer"
										)}>
										<EyeIcon
											className="mr-3 h-5 w-5 text-green-400 group-hover:text-green-500"
											aria-hidden="true"
										/>
										Détails
									</span>
								</Link>
							)}
						</Menu.Item>

						{profilID ===
							"IA7" && (
								<>
									{rowData.statut ===
										"Terminé" ||
										rowData.statut ===
										"En Cours" ? null : (
										<Menu.Item>
											{({
												active,
											}) => (
												<span
													onClick={startConsultationHandleClick.bind(
														null,
														rowData.code
													)}
													className={classNames(
														active
															? "bg-gray-100 text-gray-900"
															: "text-gray-700",
														"group flex items-center px-4 py-2 text-sm cursor-pointer"
													)}>
													<ArrowRightCircleIcon
														className="mr-3 h-5 w-5 text-indigo-400 group-hover:text-indigo-500"
														aria-hidden="true"
													/>
													Démarrer
												</span>
											)}
										</Menu.Item>
									)}
									{rowData.statut ===
										"En Cours" ? (
										<Menu.Item>
											{({
												active,
											}) => (
												<span
													onClick={continueConsultationHandleClick.bind(
														null,
														rowData.code
													)}
													className={classNames(
														active
															? "bg-gray-100 text-gray-900"
															: "text-gray-700",
														"group flex items-center px-4 py-2 text-sm cursor-pointer"
													)}>
													<ArrowRightCircleIcon
														className="mr-3 h-5 w-5 text-indigo-400 group-hover:text-indigo-500"
														aria-hidden="true"
													/>
													Continuer
												</span>
											)}
										</Menu.Item>
									) : null}
								</>
							)}

						{profilID ===
							"IA10" && (
								<Menu.Item>
									{({
										active,
									}) => (
										<span onClick={() => {

											deleteConsultationHandleClick(rowData)
										}
										}

											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"cursor-pointer group flex items-center px-4 py-2 text-sm"
											)}>
											<ArchiveBoxIcon
												className=" mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
												aria-hidden="true"
											/>
											Supprimer
										</span>
									)}
								</Menu.Item>
							)}


					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	};


	//show consultations.durée
	const timeTemplate = (rowData) => {

		return (

			rowData.duree + " minutes"

		)


	}


	//show consultations.status
	const statusTemplate = (rowData) => {

		return (

			<td
				className={statusClassName(
					rowData.statut
				)}>
				{rowData.statut}
			</td>

		)


	}

	//show consultations.date du rendez-vous
	const rendezVousTemplate = (rowData) => {

		return (

			rowData.date + " à " + rowData.heure

		)


	}
	
	const statusClassName = (statut ) => {
		switch (statut ) {
			case "En Attente":
				return "inline-flex rounded-full bg-indigo-100 px-2 text-xs font-semibold leading-5 text-indigo-800 whitespace-nowrap my-3";
			case "Reporté":
				return "inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800 whitespace-nowrap my-3";
			case "Validé":
				return "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap my-3";
			case "Refusé":
				return "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap my-3 ";
			case "Annulé":
				return "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap my-3 ";
			case "En Cours":
				return "inline-flex rounded-full bg-secondary-100 px-2 text-xs font-semibold leading-5 text-secondary-800 whitespace-nowrap my-3 ";
			case "Terminé":
				return "inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800 whitespace-nowrap my-3 ";

			default:
				break;
		}
	};
	const statusItemTemplate = (rowData) => {
        return  (<div className={statusClassName(rowData.statut)}>
			          {rowData.statut}
			    </div>
    )};
	const Daterendez =(rowData)=>{
	     return (
			<div>
				<span> {rowData.date} a {rowData.heure}</span>
			</div>
		 )
	}
	 const MedecinTemplate =(rowData)=>{
		  return (
			<div>
	            	{profilID === "IA7"
					? rowData.patient.nom
					: rowData.medecin}
			</div>
		  )
	 }
	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);
	
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);

	let content;

	if (isLoading) {
		content = (
			<div className="flex flex-1 justify-center">
				<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
			</div>
		);
	} else {
		if (consultations.length === 0) {
			content = (
				<div className="flex flex-col items-center justify-center">
					{" "}
					<p className="text-3xl text-center text-gray-500">
						aucune consultation{" "}
					</p>
				</div>
			);
		} else {
			content = (
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<DataTable value={consultations} selectionMode={rowClick ? null : 'checkbox'} selection={selectedConsultations} onSelectionChange={(e) => setSelectedConsultations(e.value)} header={header} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
							paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
							filters={filters} globalFilterFields={['medecin', 'patient.nom', 'objet', 'duree', 'canal', 'statut', 'code']}
							emptyMessage="No customers found."
							currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} style={{ borderWidth: '1px', borderStyle: 'solid' }}>
							<Column selectionMode="multiple" headerStyle={{ width: '1%' }}></Column>
							{profilID === "IA1" ? (
								<Column field="code" header="Code" style={{ width: '6%' }}></Column>
							) : null}
							{profilID === "IA7" ? (
								<Column field="patient.nom" header="Patient" sortable style={{ width: '8%' }}></Column>
							) :
								<Column field="medecin" header="Medecin" sortable style={{ width: '8%' }}></Column>
							}
							<Column field="objet" header="Motif" style={{ width: '12%' }}></Column>

							<Column header="Durée" body={timeTemplate} style={{ width: '6%' }}></Column>

							<Column field="canal" header="Canal" style={{ width: '6%' }}></Column>

							<Column header="Date du rendez-vous" body={rendezVousTemplate} style={{ width: '12%' }}></Column>

							<Column header="Status" style={{ width: '1%' }} body={statusTemplate} />

							<Column header="Action" style={{ width: '1%' }} body={actionBodyTemplate} />

						</DataTable>

					</div>
				</div>
			);
		}
	}

	// verifcode code consultation open modal
	const startConsultationHandleClick = () => {
		setOpenConsult(true);
	};

	// contine  consultation
	const continueConsultationHandleClick = (code) => {
		navigate(`/consultations/${code}/avis-expert`);
	};

	// verifcode code consultation
	const verifyCodehandleClick = () => {
		setVerifconsultationIsLoading(true);

		axiosInstance(`/verifconsultation/${currentCode}`, {
			headers: {
				authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				navigate(`/consultations/${currentCode}/avis-expert`);
			})
			.catch((err) => {
				switch (err?.response?.status) {
					case 417:
						setIsAlert({
							isError: true,
							message: err?.response?.data?.message,
						});
						break;

					default:
						break;
				}
			})
			.finally(() => setVerifconsultationIsLoading(false));
	};

	return (
		<>
			{openConsult && (
				<StartConsult
					open={openConsult}
					setOpen={setOpenConsult}
					verifyCodehandleClick={verifyCodehandleClick}
					isLoading={verifconsultationIsLoading}
					setCurrentCode={setCurrentCode}
					isAlert={isAlert}
					setIsAlert={setIsAlert}
				/>
			)}
			{modalType === "consultation" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}
			<div className="bg-white">
				<div className="px-1 sm:px-2 lg:px-2 mt-8">
					<h2 className="text-xl font-bold tracking-tight text-primary-800 sm:text-2xl mb-8">
						Mes Consultations{" "}
					</h2>
					{content}

				</div>
			</div>
		</>
	);
};

export default ListConsultations;
