/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component SpinnerEllipsi
 * @description loader pour le menu d'un element de tableau
 * @props classNameSpin, classNameEllipsi, className
 * @
 */

import React from "react";

import { classNames } from "../../styles";
import { AiOutlineLoading3Quarters, EllipsisHorizontalIcon } from "../svg";

const SpinnerEllipsi = ({ classNameSpin, classNameEllipsi, className }) => {
	return (
		<div className={classNames("relative w-8 h-8", className)}>
			<AiOutlineLoading3Quarters
				className={classNames(
					" absolute inset-0 animate-spin",
					classNameSpin // AiOutlineLoading3Quarters className
				)}
			/>

			<EllipsisHorizontalIcon
				className={classNames(
					"text-gray-400 hover:text-gray-600 bg-gray-100 rounded-full absolute inset-0.5",
					classNameEllipsi // EllipsisHorizontalIcon classNAme
				)}
			/>
		</div>
	);
};

export default SpinnerEllipsi;
