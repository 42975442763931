/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description select expert consultation  page
 * @component ChoixExpertPage
 */

import Main from "../../../components/layouts/main";
import ChoixExpert from "../../../components/layouts/main/consultations/ChoixExpert";

const ChoixExpertPage = (props) => {
	return <Main children={<ChoixExpert />} />;
};

export default ChoixExpertPage;
