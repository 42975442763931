import { useLocation, Link } from "react-router-dom";
import {
    CheckCircleIcon,
    MinusCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon
} from "@heroicons/react/20/solid";

function ViewCategory() {
    const location = useLocation();
    const state = location?.state

    function formatDate(inputDate) {
        const date = new Date(inputDate);

        const monthNames = [
            "janvier", "février", "mars", "avril", "mai", "juin", "juillet"
            , "août", "septembre", "octobre", "novembre", "decembre"
        ]

        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const hour = date.getHours();
        const minutes = date.getMinutes();

        const formatedTime = `${hour.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}min`;

        return {
            day,
            month,
            year,
            hour: formatedTime
        };
    };

    const { day, month, year, hour } = formatDate(state?.dateModification);

    return (
        <div
            className="flex p-12 text-lg font-medium text-gray-600 flex-col gap-10"
        >
            <div
                className="flex flex-row justify-between border-b-2 border-secondary-600 pb-7 items-center"
            >
                <h1
                    className="text-3xl font-bold text-secondary-600"
                >
                    Catégorie d'assurance
                </h1>

                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <Link
                        to="/parametres/categories-assurance/add?usage=edit"
                        state={state}
                    >
                        <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                        >
                            Modifier la catégorie
                        </button>
                    </Link>
                </div>
            </div>

            <div
                className="flex flex-col gap-8 py-5 px-3 w-[90%] min-w-[400px] self-center"
            >
                <div
                    className="flex flex-col gap-5 bg-white-800 py-[10px] px-[15px] rounded-md shadow-md"
                >
                    <div
                        className="flex flex-row justify-between text-secondary-800 border border-secondary-300 my-[-10px] mx-[-15px] p-4 pr-8 rounded-t-md bg-secondary-200"
                    >
                        <h2>Détails</h2>

                        <div
                            className={`flex items-center border-2 rounded-full text-white text-sm px-2
                                ${state?.statut === "Active"
                                    ? "border-green-600 bg-green-500"
                                : state?.statut === "Inactive"
                                    ? "border-yellow-600 bg-yellow-500"
                                : state?.statut === "Supprimee"
                                    ? "border-red-600 bg-red-500"
                                : "border-blue-600 bg-blue-500"}
                            `}
                        >
                            {state?.statut === "Active"
                                ? (<CheckCircleIcon className="mr-1 w-5" aria-hidden="true" />)
                            : state?.statut === "Inactive"
                                ? (<MinusCircleIcon className="mr-1 w-5" aria-hidden="true" />)
                            : state?.statut === "Supprimee"
                                ? (<XCircleIcon className="mr-1 w-5" aria-hidden="true" />)
                            : (<ExclamationCircleIcon className="mr-1 w-5" aria-hidden="true" />)}

                            { state?.statut }
                        </div>
                    </div>

                    <div>
                        <h3 className="text-gray-800">Nom de la catégorie :</h3>

                        <p
                            className="border px-2 py-1 rounded bg-gray-200"
                        >{ state?.nom }</p>
                    </div>

                    <div>
                        <h3 className="text-gray-800">Description :</h3>

                        <p className="border px-2 py-1 rounded bg-gray-200">
                            { state?.description }
                        </p>
                    </div>
                </div>

                <div
                    className="flex flex-col gap-5 bg-gray-200 py-[10px] px-[15px] rounded-md shadow-md"
                >
                    <h2
                        className="text-secondary-800 border border-secondary-300 my-[-10px] mx-[-15px] p-4 rounded-t-md bg-secondary-200"
                    >Tags associés</h2>

                    <div className="flex flex-row gap-10">
                        {Array.isArray(state?.tag) && state?.tag.length > 0
                        ? state?.tag.map((item, index) => (
                            <p key={`${item}-${index}`}>#{item}</p>
                        ))
                        : ( <p>Auncun tag fournit...</p> )}
                    </div>
                </div>
            </div>

            <div className="self-center">
                <ul className="text-secondary-500 text-sm list-disc">
                    <li>Dernière mise à jour survenue le {day} {month} {year} à {hour}</li>
                </ul>
            </div>
        </div>
    )
};

export default ViewCategory;
