import styled from "styled-components";
import defaultCover from "../../../../../../assets/defaultProduct.jpg"
import { useState } from "react";
import ActionMenuCicle from "../../../../../../UI/actionMenuCircle";
import { ArchiveBoxIcon, ArrowTopRightOnSquareIcon, CheckCircleIcon, PlayCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useGetUserSinistresDetailsQuery, useUpdateSinisterMutation } from "../../../../../../features/api/apiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setModalPopUp } from "../../../../layoutLogics";
import Spinner from "../../../../../../UI/spinner/Spinner";

const TitleTableContainer = styled.div`
    display: flex;
    flex-direction: row;
    background: white;
    width: 100%;
    border-radius: 4px;
    padding: 0.5em;
    font-size: 1.7em;
    font-weight: 500;
`;

const TableCode = styled.h2`
    width: 25%;
    padding: 0 1em;
`;

const TableCardBody = styled.h2`
	// width: 390px;
    flex-grow: 1;
    padding: 0 1em;
`;

const TatbleStatus = styled.h2`
    width: 15%;
    padding: 0 1em;
    text-align: right;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    background: white;
    width: 100%;
    min-width: 300px;
    border-radius: 4px;
    padding: 2em 1em;
    ${({ isExtented }) => isExtented ? "" : "cursor: pointer;"}
    transition: all 0.3s ease-out;
    &:hover {
        box-shadow: 0 1px 8px gray;
    }
`;

const Code = styled.h2`
    display: flex;
    align-items: center;
    width: 25%;
    font-size: 2em;
    font-weight: 600;
    padding: 0 1em;
`;

const CardBody = styled.div`
	display: flex;
	flex-direction: column;
    align-items: center;
    gap: 3em;
    flex-grow: 1;
    max-width: 530px;
    padding: 0 1em;
`;

const Title = styled.h3`
    font-size: 2em;
    font-weight: 400;
`;


const Type = styled.p`
    font-size: 1.7em;
    font-weight: 400;
`;

const Description = styled.h3`
    font-size: 1.7em;
    font-weight: 400;
    border-top: solid #c3c3c3 1px;
    border-bottom: solid #c3c3c3 1px;
    padding: 1em 0.5em;
    width: 100%;
`;

const FilesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
`;

const File = styled.a`
    width: auto,
    align-items: center,
    cursor: pointer,
`;

const StatusColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-start;
    width: 17%;
    gap: 0.2em;
    font-size: 1.5em;
    font-weight: 500;
    padding: 1em 1em;
    color: ${({status}) => status === "active" ? "green" : status === "pending" ? "#FFA500" : "#FF0000"};
`;

const Button = styled.button`
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
`;

function DeclaretedCard({ isTable, idSinistre, isProvider, code, title, type, status, description, images, documents, chat }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isExtented, setIsExtended] = useState(false);
    const currentStatus = status === "Actif" ? "pending" : status;

    // const { data: sinsterDetails, isLoading, isSuccess } = useGetUserSinistresDetailsQuery(idSinistre);
    const [ terminateSinister, { isLoading: isTerminating } ] = useUpdateSinisterMutation();

    function statusProvider(status) {
		if (status === "succeded") {
			return {
				status: "active",
				value: "Approuvé",
                icon: (<CheckCircleIcon className="w-5 h-5" />)
			};
		} else if (status === "pending") {
			return {
				status: "pending",
				value: "En cours",
                icon: (<PlayCircleIcon className="w-5 h-5" />)
			}
		} else if (status === "rejected") {
			return {
				status: "rejected",
				value: "Rejeté",
                icon: (<XCircleIcon className="w-5 h-5" />)
			}
		} else {
			return {
				status: "pending",
				value: "Terminé",
                icon: (<ArchiveBoxIcon className="w-5 h-5" />)
			}
		};
	};

    async function handleClick(event) {
        event.preventDefault();
        event.stopPropagation();

        console.log("Close button clicked !");

        try {
            const response = await terminateSinister({ idSinster: idSinistre, neededState: "Inactif" }).unwrap();

            if (response.statut === "ok") {
                setModalPopUp(dispatch, "Sinistre terminé !", "success");
            } else {
                setModalPopUp(dispatch, "Une erreur est survenue", "failed");
            }
        } catch (error) {
            setModalPopUp(dispatch, "Une erreur est survenue", "failed");
            console.log("Sinister termination error : ", error)
        }
    }

    function actionProvider(isExtented, isProvider, currentStatus, Button, handleClick) {
        if (isExtented && isProvider) {
            return (
                <Button
                    onClick={handleClick}
                    className="flex justify-center items-center gap-2 bg-primary-800 hover:bg-primary-700"
                >
                    {isTerminating && (
                        <Spinner className="w-6 h-6 text-white" />
                    )}
                    Terminer
                </Button>
            );
        } else {
            return (
                <>
                    {statusProvider(currentStatus).icon}
                    {statusProvider(currentStatus).value}
                </>
            );
        };
    };

    function handleLinkClick(event) {
        event.stopPropagation();
    }

    return (
        <>
            {isTable ? (
                <TitleTableContainer>
                    <TableCode>Code</TableCode>
                    <TableCardBody>Infos...</TableCardBody>
                    <TatbleStatus>statut</TatbleStatus>
                </TitleTableContainer>
            ) : (
                <Container
                    isExtented={isExtented}
                    onClick={() => { setIsExtended(!isExtented) }}
                >
                    <Code>{code}</Code>

                    <CardBody>
                        <div style={{ alignSelf: "flex-start" }}>
                            <Title>{title}</Title>
                            <Type>Type : {type?.nom}</Type>
                        </div>

                        {(isExtented && description && description.length > 0) && (
                            <Description>{description}</Description>
                        )}

                        {(isExtented && documents && documents.length > 0) && (
                            <>
                                <Type className="self-start">Documents associés :</Type>

                                <FilesContainer className="self-start">
                                    {documents.map((document, index) => (
                                        <File
                                            key={index}
                                            className='text-base p-2 flex font-bold border border-2 border-secondary-800'
                                            href={document.url}
                                            onClick={handleLinkClick}
                                            // href={process.env.PUBLIC_URL + "/docContrat.pdf"}
                                            target='_blank'
                                        >
                                            {document.titre}
                                            <ArrowTopRightOnSquareIcon className='ml-2 w-6 h-6 text-secondary-900' />
                                        </File>
                                    ))}
                                </FilesContainer>
                            </>
                        )}

                        {(isExtented && images && images.length > 0) && (
                            <>
                                <Type className="self-start">Images associées :</Type>

                                <FilesContainer className="self-start">
                                    {images.map((image, index) => (
                                        <File
                                            key={index}
                                            className='text-base p-2 flex font-bold border border-2 border-secondary-800'
                                            href={image.url}
                                            onClick={handleLinkClick}
                                            // href={process.env.PUBLIC_URL + "/docContrat.pdf"}
                                            target='_blank'
                                        >
                                            {
                                                // image.titre
                                                "Image associée"
                                            }
                                            <ArrowTopRightOnSquareIcon className='ml-2 w-6 h-6 text-secondary-900' />
                                        </File>
                                    ))}
                                </FilesContainer>
                            </>
                        )}

                        {(isExtented && chat) && (
                            <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => navigate(`/echanges?idConversation=${chat}`)}
                            >
                                Acceder à la conversation
                            </button>
                        )}
                    </CardBody>

                    <StatusColumn status={statusProvider(currentStatus).status}>
                        {actionProvider(isExtented, isProvider, currentStatus, Button, handleClick)}
                    </StatusColumn>
                </Container>
            )}
        </>
    );
};

export default DeclaretedCard;
