/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description contact user information
 * @component ContactInformation
 */

import React from "react";
import Input from "../../../../UI/form/Input";

const ContactInformation = (props) => {
	// props
	const { email, tel1, tel2, facebook, twitter,profession, setUserInfo } = props;

	// handle change
	const HandleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		setUserInfo((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	return (
		<section
			aria-labelledby="payment-details-heading "
			className="px-4 sm:p-8">
			{/* hearder contact  */}
			<div>
				<h2
					id="payment-details-heading"
					className="text-lg font-medium leading-6 text-gray-900 mt-6">
					Adresses
				</h2>
				
			</div>

			<div className="mt-8 grid grid-cols-4 gap-6">
				<div className="col-span-4 sm:col-span-2">
					<div className=" grid grid-cols-1 gap-8">
						{/* email  */}
						{email !== undefined && (
							<div className="col-span-1 sm:col-span-1">
								<Input
									label="Addresse mail"
									input={{
										id: "email",
										type: "email",
										maxLength: "80",
									}}
									defaultValue={email}
									name={"email"}
									disabled={true}
									required={false}
								/>
							</div>
						)}

						{/* phone 1  */}
						{tel1 !== undefined && (
							<div className="col-span-2 sm:col-span-1">
								<Input
									label="Téléphone 1"
									input={{
										id: "tel1",
										type: "tel",
										maxLength: "80",
									}}
									icon="+237"
									defaultValue={tel1}
									name={"tel1"}
									disabled={true}
									required={false}
								/>
							</div>
						)}

						{/* phone 2  */}
						{tel2 !== undefined && (
							<div className="col-span-2 sm:col-span-1">
								<Input
									label="Téléphone 2"
									icon="+237"
									input={{
										id: "tel2",
										type: "tel",
										maxLength: "80",
									}}
									onChange={HandleChange}
									defaultValue={tel2}
									name="tel2"
									disabled={false}
									required={false}
								/>
							</div>
						)}
					</div>
				</div>

				<div className=" col-span-4 sm:col-span-2 ">
					<div className=" grid grid-cols-1 gap-8">
						{/* facebook  */}
						{facebook !== undefined && (
							<div className="col-span-1 sm:col-span-1">
								<Input
									label="Facebook"
									input={{
										id: "facebook",
										type: "url",
										maxLength: "250",
									}}
									onChange={HandleChange}
									name={"facebook"}
									defaultValue={facebook}
									disabled={false}
									required={false}
								/>
							</div>
						)}

						{/* twitter  */}
						{twitter !== undefined && (
							<div className="col-span-1 sm:col-span-1">
								<Input
									label="Twitter"
									input={{
										id: "twitter",
										type: "url",
										maxLength: "250",
									}}
									onChange={HandleChange}
									name={"twitter"}
									disabled={false}
									required={false}
									defaultValue={twitter}
								/>
							</div>
						)}

						{/* profession  */}
						{profession !== undefined && (
							<div className="col-span-1 sm:col-span-1">
								<Input
									label="Proféssion"
									input={{
										id: "profession",
										type: "text",
										maxLength: "250",
									}}
									onChange={HandleChange}
									name={"profession"}
									disabled={false}
									required={false}
									defaultValue={profession}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactInformation;
