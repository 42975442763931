/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description   list sinistre chat
 * @component SinistreChats
 */
import React from "react";
import { Disclosure } from "@headlessui/react";
import SinistreItemChat from "./SinistreItemChat";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../../styles";

const SinistreChats = ({ listChatSinistre, selectChat, defaultOpen }) => {
	
	return (
		<Disclosure defaultOpen={defaultOpen} >
			{({ open }) => (
				<>
					<Disclosure.Button
						className={classNames(
							"flex w-full justify-between rounded-md px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75",
							open ? "bg-gray-100" : ""
						)}
					>
						<span className="flex gap-x-2">
							<span>Sinistres</span>
							<span>{`(${listChatSinistre?.length})`}</span>
						</span>
						<ChevronUpIcon
							className={`${
								open ? "rotate-180 transform" : ""
							} h-5 w-5 text-gray-500`}
						/>
					</Disclosure.Button>

					<Disclosure.Panel
						as="ul"
						className="space-y-1 mx-4">
						{listChatSinistre.map((item, index) => (
							<SinistreItemChat
								key={index}
								{...item}
								selectChat={selectChat}
							/>
						))}
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
};

export default SinistreChats;
