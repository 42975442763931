/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance page
 * @component AddAssurancePage
 */

import styled from "styled-components";
import AddAssurance from "../../../components/layouts/main/assurances/provider/addAssurance";

const StyledMain = styled.div`
	position: relative;
	height: 100vh;
	width: 100%;
	min-width: 250px;
	max-width: 1820px;
	padding: 30px 70px;
	font-size: 10px;
	font-family: 'Montserrat', sans-serif;
	@media screen and (max-width: 500px) {
		padding: 20px;
	}
`

const AddAssurancePage = () => {
	return (
		<StyledMain>
			<AddAssurance />
		</StyledMain>
	);
};

export default AddAssurancePage;
