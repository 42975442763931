/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description day
 * @component Day
 */
import { MinusIcon } from "../../../../UI/svg";
import { useDispatch } from "react-redux";
import { periodeConsultation } from "../../../../features/consultation/consultation.slice";
import { useNavigate } from "react-router-dom";

const Day = ({ day, slots, maxlenght, minlength, selectDoctor }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let goodSlot = [];
	for (let i = 0; i < maxlenght; i++) {
		goodSlot.push(slots[i]);
	}

	// get day
	const getDayRdv = (date) => {
		const options = {
			weekday: "long",
		};

		const dateRdv = new Date(date);
		const longDayRdv = new Intl.DateTimeFormat("fr-FR", options).format(
			dateRdv
		);

		return longDayRdv;
	};

	// get date
	const getDateRdv = (date) => {
		const dateRdv = new Date(date);
		return dateRdv.getDate();
	};

	// get short month
	const getMonthShort = (date) => {
		const options = {
			month: "short",
		};

		const dateRdv = new Date(date);
		const montRdv = new Intl.DateTimeFormat("fr-FR", options).format(
			dateRdv
		);

		return montRdv;
	};

	// get hour
	const getHourRdv = (slot) => {
		const options = {
			hour: "numeric",
			minute: "numeric",
		};

		const dateRdv = new Date(slot);
		const hourRdv = new Intl.DateTimeFormat("fr-FR", options).format(
			dateRdv
		);

		return hourRdv;
	};

	// get date time
	const getDateTimeRdv = (slot) => {
		const options = {
			day: "numeric",
			weekday: "long",
			month: "short",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
		};

		const dateRdv = new Date(slot);
		const dateTime = new Intl.DateTimeFormat("fr-FR", options).format(
			dateRdv
		);

		return dateTime;
	};

	// get long date
	const getLongDateRdv = (slot) => {
		const options = {
			day: "numeric",
			weekday: "long",
			month: "short",
			year: "numeric",
		};

		const dateRdv = new Date(slot);
		const dateTime = new Intl.DateTimeFormat("fr-FR", options).format(
			dateRdv
		);

		return dateTime;
	};

	// take rdv
	const takeRDVHandleClick = (slot, day) => {
		const hour = `${day} ${getHourRdv(slot)}`;
		selectDoctor();
		dispatch(periodeConsultation(hour));

		navigate("/consultations/fiche_rdv");
	};

	return (
		<div className="w-20 text-center">
			<div className="flex flex-col gap-y-4">
				<div className="flex flex-col">
					<h6
						className="font-bold text-gray-700"
						title={`${getLongDateRdv(`${day}`)}`}>
						{getDayRdv(`${day}`)}
					</h6>
					<span
						className="text-base text-gray-500"
						title={`${getLongDateRdv(`${day}`)}`}>
						{getDateRdv(`${day}`)} {getMonthShort(`${day}`)}
					</span>
				</div>

				{goodSlot?.slice(0, minlength).map((slot, index) => {
					if (slot?.hour) {
						return (
							<div
								key={index}
								className="flex items-center justify-center  px-2">
								<button
									onClick={takeRDVHandleClick.bind(
										null,
										slot?.hour,
										day
									)}
									title={`${getDateTimeRdv(`${slot?.hour}`)}`}
									className="border flex-1 flex justify-center items-center bg-secondary-200 h-[34px] text-secondary-900 hover:bg-secondary-300 rounded-md  font-medium cursor-pointer">
									{getHourRdv(slot?.hour)}
								</button>
							</div>
						);
					} else {
						return (
							<div className="flex items-center justify-center px-2 ">
								<div className=" flex-1 flex justify-center items-center h-[34px] rounded-md ">
									<MinusIcon className="w-4 text-gray-500" />
								</div>
							</div>
						);
					}
				})}
			</div>
		</div>
	);
};

export default Day;
