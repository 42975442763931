/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add member for family account
 * @component AddMembers
 */

import { useDispatch, useSelector} from "react-redux";
import { useState  } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Input from "../../../../UI/form/Input";
import Button from "../../../../UI/button/Button";

import { useSetPaiementMutation,useGetModeInfoQuery,useGetAllPaiementQuery } from "../../../../features/api/apiSlice";

import { setModal } from "../../../../features/feedBack/modal.slice";
import {
	ArchiveBoxIcon,
	LockOpenIcon,
	EyeIcon,
	LockClosedIcon,
	CheckCircleIcon,
} from "@heroicons/react/20/solid";

const SetModepaiement = () => {
    const navigate = useNavigate();
	const dispatch = useDispatch();
    const {id_modePaiement}=useParams();
    	//get allpaiement request
	const { data: paiements, isLoading, isSuccess } = useGetAllPaiementQuery();
	
	const mode=paiements?.data?.filter((paiement) =>paiement.id_modePaiement ===id_modePaiement);

	    const [PaiementInfo, setpaiementInfo] = useState(mode[0]);

		console.log(PaiementInfo)
	const [setMode, { isLoading:loading }] = useSetPaiementMutation();
   

	// handle change
	const handleChange = (e) => {
		
			const name = e.target.name;
			const value = e.target.value.trim();
            setpaiementInfo((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		
    }

	const handleSubmit = (e) => {
		e.preventDefault();
		setMode({data:PaiementInfo,id_modePaiement:id_modePaiement})
			.unwrap()
			.then((data) => {
				dispatch(
					setModal({
						modalType: "paiement",
						modalProps: {
							isOpen: true,
							status: "success",
							text: `Le Mode de paiement a été mis à jour avec succéss`,
						},
					})
				);
				navigate("/operations/Modepaiement");
			})
			.catch((err) => {
				// alert error message
			});
	};

	return (
		<>
			<div className="p-4">
				<Button
					children="Retour"
					type="button"
					onClick={() => navigate("/listmembers")}
					className="max-w-max"
				/>
			</div>
			<form
				onSubmit={handleSubmit}
				className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
				<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
					<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
						{/* header  */}
						<div>
							<h3 className="text-lg font-medium leading-6 text-gray-900">
								modifier ce mode de paiement
							</h3>
							<p className="mt-1 max-w-2xl text-sm text-gray-500">
								Bien vouloir remplir ce formulaire pour Modifier
								un mode de paiement
							</p>
						</div>

						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="nom"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Nom 
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<div className="sm:max-w-xs">
										<Input
											input={{
												id: "nom",
												type: "text",
												maxLength: "80",
                                                
											}}
											name="nom"
											disabled={false}
											required={false}
											defaultValue={PaiementInfo?.nom}
											value={PaiementInfo?.nom}
											onChange={handleChange}
                                       
										/>
									</div>
								</div>
							</div>
						</div>
                       
							
					</div>
				</div>

				<div className="pt-5">
					<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
						<Button
							children="Modifier"
							button="primary"
							type="submit"
							className="max-w-max"
							loading={loading}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default SetModepaiement;
