/**
 * @author assan zidan
 * @email nsangouassanzidan@gmail.com
 * @description composant button paramettrable via ces props
 * @component Button
 * @props children, onClick, type, loading, button, id, className, disabled
 */

import clsx from "clsx"

import { btn } from "../../styles"
import { AiOutlineLoading3Quarters } from "../svg/index.js"

const Button = (props) => {
    const { children, onClick, type, loading, button, id, className, disabled } = props

    // classe du button 
    const btnClass = button === "primary" ? btn.primary : button === "secondary" ? btn.secondary : btn.default

    return (
        <button
            id={id}
            type={type}
            className={clsx(btnClass, `${loading && "disabled:cursor-not-allowed"}`, className)} disabled={disabled} onClick={onClick} 
        >
            {/* affiche le loader si loading est à true  */}
            {loading && <AiOutlineLoading3Quarters className="animate-spin h-5 w-5 mr-3 disabled:opacity-85 " />}

            {children}
        </button>
    )
}

export default Button