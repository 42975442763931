import React, { useState } from 'react';
import styled from 'styled-components';
import Payment from '../../../../../../assets/pay.png';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Spinner from '../../../../../../UI/spinner/Spinner';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import Button from '../../../../../../UI/button/Button';
import { useGetAssuranceFullDetailsQuery } from '../../../../../../features/api/apiSlice';
import PageTitle from '../../../../../Utilities/subscriptionBodyTitles/PageTitle';
import checkedGaranty from "../../../../../../assets/check2-square.svg";
import BackIllustration from '../../../../../Utilities/BackIllustration';
import defaultCover from "../../../../../../assets/defaultProduct.jpg";
import defaultProviderCover from "../../../../../../assets/profile.png";


const ButtonDiv = styled.div`
  width: 300px;
  margin-inline: auto;
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`

const StyledBanner = styled.div`
  position: relative;
  height: 300px;
  overflow: hidden;
  margin-top:-30px;
  margin-inline:-70px;
  background: white;

  &:before {
    background: #ff9903;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 70%) rotate(-5deg);
    width: 200%;
    height: 50%;
    z-index: 3;
  }
`

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  top: 40%;
  transform: translateY(-35%);
  object-fit: cover;
`

const ProviderInfos = styled.div`
  position: relative;
  transform: translateY(-120%);
  z-index: 4;
  color: white;
`

export default function DescriptionAssurance() {
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);

  const {
    data: assurance,
    isSuccess,
    isLoading
  } = useGetAssuranceFullDetailsQuery(params?.id);
  console.log(assurance?.data);

  function handleContiue(id) {
    setloading(true);
    navigate(`/assurances/${id}`, {state: {questionnaire: assurance?.data?.questionnaire}});
  };

  if (isLoading) {
    return (
      <div className='flex flex-1 flex-col justify-center items-center h-full'>
        <Spinner className='h-10 w-10 text-primary-700' />
      </div>
    );
  };

  return (
    <div className='pb-10'>
      <BackIllustration />

      {isSuccess && (
        <div>
          <StyledBanner>
            <BannerImage src={assurance?.data.image?.url ? assurance.data.image.url : defaultCover} alt='InchClass banner' />
          </StyledBanner>

          <ProviderInfos className='flex items-center justify-end gap-5 text-xl font-bold text-gray-900'>
            <h4>{assurance?.data?.assureur ? assurance.data.assureur.nom : 'Assureur inconnu'}</h4>
            <img
              src={assurance?.data?.assureur?.logo.url || defaultProviderCover}
              alt='Provider logo'
              className="h-10 w-10 object-cover border border-black rounded-full bg-gray-300"
            />
          </ProviderInfos>

          <div
            style={{ display: "flex", flexDirection: "column", gap: 32, width: 970, minWidth: 350, margin: "auto" }}
          >
            <div
              style={{ padding: "0 40px" }}
            >
              <div className='justify-items-center'>
                <PageTitle content={assurance?.data?.nom} />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                  className='text-xl font-meduim text-gray-900'
                >
                  <h2>
                    <b>{assurance?.data?.categorie.nom}</b>, de type <b>{assurance?.data?.type ? assurance.data.type.nom : 'de type inconnu'}</b>
                  </h2>

                  <ButtonDiv style={{ margin: "unset" }}>
                    <Button
                      children='Acheter Maintenant'
                      button='primary'
                      loading={loading}
                      onClick={() => handleContiue(params?.id)}
                    />
                  </ButtonDiv>
                </div>
              </div>

              <p
                style={{ marginTop: 50, marginBottom: 20, textAlign: "justify" }}
                className='text-xl'
              >
                {assurance?.data?.description}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                gap: "10em",
                flexWrap: "wrap",
                padding: 70
              }}
              className='bg-secondary-800'
            >
              <div style={{
                  width: 360,
                  minWidth: 250
                }}
              >
                <h2 className='text-3xl font-bold text-primary-800 mb-5 xs:text-xl'>
                  Garanties Offertes
                </h2>

                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2em"
                }}>
                  {assurance?.data?.listeServices.length === 0 ? (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1em",
                        padding: 10,
                        borderRadius: 4,
                      }}
                      className='bg-primary-800'
                    >
                      <ul className='list-disc text-xl text-white ml-4'>
                        <li><p className='font-meduim'> Aucune garantie spécifiée pour se produit</p></li>
                      </ul>
                    </div>
                  ) : (
                    assurance?.data?.listeServices.map((service, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1em"
                        }}
                        className='text-white text-base'
                        key={index}
                      >
                        <div style={{
                          display: "flex",
                          gap: 5,
                          alignItems: "center",
                        }}>
                          <img src={checkedGaranty} alt='checked garanty' style={{ width: 30, height: 30 }} />
                          <h3 className='underline font-bold text-xl'>{service?.nom}</h3>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                            padding: 10,
                            borderRadius: 4,
                          }}
                          className='bg-primary-800'
                        >
                          {service?.description || "Aucune description n'est fournie pour ce produit"}

                          <ul className='ml-8 list-disc'>
                            <li>Quantité du produit : {service?.quantite || 'inconnue'}</li>
                            <li>Taux de couverture : {service?.taux || 'indéteminé'}</li>
                          </ul>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div
                style={{
                  width: 360,
                  minWidth: 250
                }}
              >
                <h2 className='text-3xl font-bold text-primary-800 mb-5 xs:text-xl'>
                  Documents Nécessaire
                </h2>

                <div 
                  style={{ padding: 10, }}
                >
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                    }}
                    className='list-decimal text-white text-xl'
                  >
                    {assurance?.data?.pieces && assurance.data.pieces.length > 0 ? (
											assurance?.data?.pieces.map((piece, index) => (
												<li
													className='ml-6'
													key={index}
												>
                          <span className='underline font-bold'>
													  {piece.nom}
                          </span>
													<p>{piece.description}</p>
												</li>
											))
										) : (
											<li className='list-disc ml-4'>
												<p>Aucun document ne vous sera exigé</p>
											</li>
										)}
                  </ul>
                </div>
              </div>

              {/* third party */}
              <div style={{
                  width: "100%",
                  padding: "2em 7em",
                  margin: "0 -70px",
                  flexGrow: 1
                }}
                className='bg-primary-800'
              >
                <h2 className='text-3xl font-bold text-white mb-5 xs:text-xl'>
                  Modes de Paiement
                </h2>

                <p className='text-xl mb-5'>Vous trouverez çi-dessous la liste des options de payement acceptées par le promoteur de ce produit</p>
                
                <div style={{
                  display: "flex",
                  gap: "3em"
                }}>
                  {assurance?.data?.payOptions && assurance.data.payOptions.length !== 0 ? (
										<div style={{
											display: "flex",
											flexDirection: "column",
											gap: "5em",
											justifyContent: "space-between"
										}}>
											<ul
												style={{
													display: "flex",
													flexDirection: "column",
													gap: "1em",
													width: '50%',
												}}
												className='list-disc mx-12 text-black'
											>
												{assurance?.data?.payOptions.map((option, index) => (
												<li
													key={index}
													className='text-xl'
												>
													<span className='underline font-bold'>
														{option.nom}
													</span>
													<p className='text-base'>{option.description}</p>
												</li>
												))}
											</ul>
											
											<ButtonDiv>
                        <Button
                          children='Acheter Maintenant'
                          button='primary'
                          loading={loading}
                          onClick={() => handleContiue(params?.id)}
                          className='bg-secondary-800 hover:bg-secondary-600'
                        />
                      </ButtonDiv>
										</div>
									) : (
										<ul className='list-disc text-xl text-white ml-4'>
											<li><p className='font-meduim'> Aucune option de payement spécifiée pour ce produit</p></li>
										</ul>
									)}

                  <div
                    style={{
                      alignSelf:"center"
                    }}
                  >
                    <img
                      src={Payment}
                      style={{ width: '250px' }}
                      alt='pay illustration'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ padding: 40 }}
            >
              <h2 className='text-3xl font-bold text-black mb-5 xs:text-xl'>
                Documentation Associée au Produit
              </h2>

              {assurance?.data?.documentation.length !== 0 ? (
                <div className='text-gray-800'>
                  <p className='text-xl mb-5'>Veuillez consulter les liens çi-dessous pour acceder à la documentation relative à votre produit.</p>

                  <ul style={{
                      display: "flex",
                      gap: "4em",
                      flexWrap: "wrap",
                    }}
                  >
                    {assurance?.data?.documentation.map((document, index) => (
                      <li
                        key={`${index}-${document.id}`}
                      >
                        <a
                          style={{ width: "auto", alignItems: "center", cursor: "pointer" }}
                          className='text-base p-3 flex font-bold border border-2 border-secondary-800'
                          href={document.url}
                          // href={process.env.PUBLIC_URL + "/docContrat.pdf"}
                          target='_blank'
                        >
                          {/* Contrat de couverture */}
                          {document.titre}
                          <ArrowTopRightOnSquareIcon
                            className='ml-4 w-6 h-6 text-secondary-900'
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <ul className='list-disc text-xl ml-4'>
                  <li><p className='font-bold'> Aucun document fourni pour ce produit</p></li>
                </ul>
              )}
            </div>

            <ButtonDiv>
              <Button
                children='Acheter Maintenant'
                button='primary'
                loading={loading}
                onClick={() => handleContiue(params?.id)}
              />
            </ButtonDiv>
          </div>
        </div>
      )}
    </div>
  );
};
