/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description axios instance for inch api
 * @component axiosInstance
 */

import axios from "axios";

//  base url api inch
import { REACT_APP_API_BASE_URL } from "../config/config";

const axiosInstance = axios.create({
	baseURL: REACT_APP_API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		'Access-Control-Allow-Origin': '*',
	},
});

export default axiosInstance;
