import { useDispatch, useSelector } from "react-redux";

import { useAddSinistresMutation, useGetAllProductsWithSinisterTypeQuery, useGetTypeSinistresQuery } from "../../../../../../features/api/apiSlice";
import { getUserCode } from "../../../../../../features/user/user.slice";

import Spinner from "../../../../../../UI/spinner/Spinner";
import BackIllustration from "../../../../../Utilities/BackIllustration";
import SubscriptionBodyTitles from "../../../../../Utilities/subscriptionBodyTitles";
import SubscriptionCardsTitle from "../../../../../Utilities/subscriptionCardsTitle";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AddDoc from "../../provider/addAssurance/AddDoc";
import AddedDoc from "../../provider/addAssurance/AddedDoc";
import cover from "../../../../../../assets/assurance.svg";
import ProduitCard from "../mesProduits/ProduitCard";
import productIllustration from "../../../../../../assets/productIllustrated.svg";
import { setModalPopUp } from "../../../../layoutLogics";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4em;
    margin: auto;
    @media screen and (max-width: 500px) {
      gap: 4em;
    }
    @media screen and (max-width: 1023px) {
      gap: 4em;
    }
`;

const StyledBody = styled.div`
    width: 80%;
    max-width: 850px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    @media screen and (max-width: 1023px) {
    //   width: 100%;
    //   min-width: 800px;
    }
`;

const DescriptionPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5em;
  width: 60%;
`;

const StyledMessage = styled.p`
    text-align: justify;
    margin: 0 0 2em 0;
    font-size: 1.5em;
    font-weight: 400;
`;

const Illustration = styled.img`
    width: 23em;
    height: auto;
    scale: 1.2;
    @media screen and (max-width: 1023px) {
        scale: 1.1;
    }
    @media screen and (max-width: 500px) {
        scale: 1;
    }
`;

const CreateSinister = () => {
	// user code
	const userCode = useSelector(getUserCode);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [sinister, setSinister] = useState({
        documentation: []
    });
    const [attachedProduct, setAttachedProduct] = useState([]);

	// get sinistres request
	const { data: sinisterTypes, isLoading: typeLoading, isSuccess: typeSuccess } = useGetTypeSinistresQuery();
	const { data: products, isLoading: productsLoading, isSuccess: productSuccess } = useGetAllProductsWithSinisterTypeQuery();
    const [ addSinister, { isLoading, isSuccess } ] = useAddSinistresMutation();

    useEffect(() => {
        if (attachedProduct?.length > 0) {
            setSinister({
                ...sinister,
                souscription: attachedProduct[0]
            });
        };
    }, [attachedProduct])

    function removeDoc(docIndex) {
		const newDocs = sinister.documentation.filter((doc, index) => index !== docIndex);

		setSinister({
			...sinister,
			documentation: newDocs
		});
	};

    function handleInputChanges(event, type, options) {
        const name = event.target.name;
        const value = event.target.value;

        if (type === "select") {
            const selectedOption = options.find(option => option.nom === value);

            if (selectedOption) {
                setSinister({
                    ...sinister,
                    [name]: selectedOption
                });
            };

            const attachables = products?.data.filter(product => product.typeSinistres.find(item => parseInt(item.idTypeSinistre) === parseInt(selectedOption.idTypeSinistre)));
            setAttachedProduct(attachables);
        } else if (type === "radio") {
            if (value) {
                setSinister({
                    ...sinister,
                    souscription: options
                });
            };
        } else {
            setSinister({
                ...sinister,
                [name]: value
            });
        };
    };

    function daysLeftProvider(endDate) {
		const dateFin = new Date(endDate);
		const dateActuelle = new Date();

		const differenceEnMillisecondes = dateFin - dateActuelle;
		const joursRestants = Math.ceil(differenceEnMillisecondes / (1000 * 60 * 60 * 24));

		return joursRestants;
	};

    async function handleSubmitSinister() {
        const data = {
            sujet: sinister?.titre,
            description: sinister?.description,
            idTypeSinistre: sinister?.type?.idTypeSinistre,
            idSouscription: sinister?.souscription?.idSouscription,
        };

        const formData = new FormData();
        
        const images = sinister?.documentation.filter(item => item.type === "image");
        if (images.length > 0) {
            data.images = images.map((image, index) => {
                formData.append(`images[]`, image.file);
                return image.file
            });
        };
        
        const documents = sinister?.documentation.filter(item => item.type === "document");
        if (documents.length > 0) {
            data.documents = documents.map((document, index) => {
                formData.append(`documents[]`, document.file);
                return document.file
            });
        };

        if (!data?.sujet || data?.sujet?.length < 8) {
            setModalPopUp(dispatch, "Veuillez ajouter un titre à votre déclaration", "failed");
            return null;
        };

        if (!data?.idTypeSinistre) {
            setModalPopUp(dispatch, "Veuillez sélectionner le type de sinistre que vous déclarez", "failed");
            return null;
        };

        if (!data?.idSouscription || daysLeftProvider(sinister?.souscription?.dateFinValidite) < 1) {
            setModalPopUp(dispatch, "Votre déclation doit être attachée à une souscription valide", "failed");
            return null;
        };

        formData.append("sujet", data.sujet);
        formData.append("description", data.description);
        formData.append("idTypeSinistre", data.idTypeSinistre);
        formData.append("idSouscription", data.idSouscription);

        console.log("Sinister :", data);
            
        try {
            const response = await addSinister(formData).unwrap();

            if (response.statut === "ok") {
                setModalPopUp(dispatch, "Sinistre déclaré avec succes.", "success");
                console.log("sinister declaration response :", response);
                navigate("/assurances/sinistres");
            } else {
                setModalPopUp(dispatch, "Erreur lors de la déclaration du sinistre", "failed");
                console.log("sinister declaration response :", response);
            };
        } catch (error) {
            setModalPopUp(dispatch, "Erreur lors de la déclaration du sinistre", "failed");
            console.log("sinister declaration error :", error);
        }
    };

	return (
		<div>
			<BackIllustration />

			<Container>
				<SubscriptionBodyTitles
					bodyText={[`Besoin d'être couvert ? Faites nous part de la situation...`]}
					title="Déclaration de Sinistres"
				/>

                <DescriptionPanel>
					<StyledMessage>Merçi de nous renseigner sur les détails du sinistre que vou ssouhaitez déclarer...</StyledMessage>
					<Illustration src={productIllustration} alt="Product page illustration" />
				</DescriptionPanel>

				<StyledBody>
                    {/* <Spinner className="mt-8 w-10 h-10 m-auto text-primary-700 h-full" /> */}

					<div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Souscriptions accociées</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Veuillez choisir dans la liste des soucription associées au type de sinistre séléctioné celle que vous souhaitez utiliser pour votre déclaration...</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                                        Sélectionnez-en la catégorie
                                    </label>
                                    <div className="mt-2">
                                        {typeLoading ? (
                                            <Spinner className="h-8 w-8 text-primary-700 m-auto h-full" />
                                        ) : (
                                            <select
                                                id="type"
                                                name="type"
                                                autoComplete="type-name"
                                                // defaultValue={assurance?.type?.name}
                                                onChange={(event) => handleInputChanges(event, "select", sinisterTypes?.data)}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            >
                                                <option disabled selected hidden>-- Choisissez une type --</option>
                                                {sinisterTypes?.data && sinisterTypes.data.length > 0 ? (
                                                    sinisterTypes.data.map((option, index) => (
                                                        <option key={index} value={option.nom} title={option.description}>{option.nom}</option>
                                                    ))
                                                ) : (
                                                    <option>-- Aucune catégorie trouvée --</option>
                                                )}
                                            </select>
                                        )}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="titre" className="block text-sm font-medium leading-6 text-gray-900">
                                        Titre du sinistre
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="titre"
                                            id="titre"
                                            autoComplete="Titre"
                                            defaultValue={""}
                                            placeholder="intitulé de votre déclaration..."
                                            onChange={handleInputChanges}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <fieldset>
                                        <div className="mt-6 space-y-6">
                                            {productsLoading ? (
                                                <Spinner className="h-8 w-8 text-primary-700 m-auto h-full" />
                                            ) : attachedProduct && attachedProduct?.length > 0 ? (
                                                attachedProduct.map((product, index) => {
                                                    return (
                                                        <div key={`${product.idSouscription}-${index}`} className="flex items-center gap-x-3">
                                                            <input
                                                                id={product.assurance.nom}
                                                                name={product.assurance.nom}
                                                                type="radio"
                                                                onChange={(event) => handleInputChanges(event, "radio", product)}
                                                                checked={index === 0 ? true : undefined}
                                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            />
                                                            <label htmlFor={product.assurance.nom} className="block text-sm font-medium leading-6 text-gray-900 w-full" style={{cursor: "pointer"}}>
                                                                <ProduitCard
                                                                    key={`${product.idSouscription}-${index}`}
                                                                    titre={product.assurance.nom}
                                                                    restants={daysLeftProvider(product.dateFinValidite)}
                                                                    images={product.assurance.images[0]?.url}
                                                                    id={product.idSouscription}
                                                                    code={product.code}
                                                                    isTotalCard={false}
                                                                />
                                                            </label>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <h3 className="block text-sm font-medium leading-6">
                                                    Aucune de vos souscriptions ne correspond au type de sinistre que vous déclarez...
                                                </h3>
                                            )}
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Description du sinistre</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Veuillez renseigner ici les détails et motifs du sinistre que vous souhaitez déclarer</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="col-span-full">
                                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                        Description complete
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="description"
                                            name="description"
                                            rows={3}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={""}
                                            placeholder="Décrivez nous les circonstances de votre sinistre..."
                                            onChange={handleInputChanges}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Documentation</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Veuillez nous fournir tous les élement justificatifs de votre sinistre...</p>

                            <AddDoc setAssurance={setSinister} assurance={sinister} />
				            <AddedDoc addedDocs={sinister.documentation} removeDoc={removeDoc} />
                        </div>
                    </div>

                    <div class="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            onClick={handleSubmitSinister}
                            className="flex gap-2 items-center rounded-md bg-secondary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-600"
                        >
                            {isLoading && (<Spinner className="h-5 w-5 text-white m-auto h-full" />)}
                            Soumettre ma déclaration
                        </button>
                    </div>
				</StyledBody>
			</Container>
		</div>
	);
};

export default CreateSinister;
