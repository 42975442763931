/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description select doctor consultation  page
 * @component ChoixMedecinPage
 */

import Main from "../../../components/layouts/main";
import ChoixMedecin from "../../../components/layouts/main/consultations/ChoixMedecin";

const ChoixMedecinPage = (props) => {
	return <Main children={<ChoixMedecin />} />;
};

export default ChoixMedecinPage;
