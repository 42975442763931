/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance product card
 * @component ProduitCard
 */

import { MinusCircleIcon } from "@heroicons/react/24/solid";
import Button from "../../../../../../UI/button/Button";
import { BsHourglassSplit, EllipsisHorizontalIcon } from "../../../../../../UI/svg/index";
import { formatPrix } from "../../../../../../lib/Assurance";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Menu, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import ActionMenuCicle from "../../../../../../UI/actionMenuCircle";

const Container = styled.div`
	display: flex;
	flex-direction: row;
`;

const Cover = styled.img`
	width: 200px;
	height: 150px;
	cover: fit;
	border-radius: 8px 0 0 8px;
`;

const CardBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: white;
	// gap: 1em;
	padding: 2em 3em;
	width: 390px;
`;

const StyledH2 = styled.h2`
	font-size: 2.2em;
	font-weight: 600;
`;

const StyledH3 = styled.h3`
	font-size: 2em;
	font-weight: 500;
	color: gray;
`;

const CardStatus = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const Status = styled.p`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.5em;
	font-weight: 600;
	color: ${({status}) => status === "active" ? "green" : status === "warning" ? "#FFA500" : "#FF0000"};
`;

const StatusIcon = styled(MinusCircleIcon)`
	width: 20px;
	margin-right: 5px;
`;

const Validity = styled.p`
	font-size: 1.5em;
	font-weight: 400;
`;

function SinisterCard ({ titre, code, images, id, categorie, prix, duree, des, restants }) {
	// const currency = formatPrix(prix);
	const [isActionOpen, setIsActionOpen] = useState(false);
	const navigate = useNavigate();

	function statusProvider(daysLeft) {
		if (daysLeft > 10) {
			return {
				status: "active",
				value: "Active"
			};
		} else if (daysLeft <= 10 && daysLeft > 0) {
			return {
				status: "warning",
				value: "Expire bientôt"
			}
		} else {
			return {
				status: "expired",
				value: "Expirée"
			}
		};
	};

	function onDetailsClick(event) {
		event.preventDefault();
		navigate(`/assurances/details/${id}`);
	};

	return (
		<Container
			onClick={() => setIsActionOpen(false)}
		>
			<Cover src={images} alt="suscription cover" />

			<CardBody>
				<div>
					<StyledH2>{titre}</StyledH2>
					<StyledH3><span style={{ fontWeight: 700 }}>Code :</span> {code}</StyledH3>
				</div>

				<CardStatus>
					<Status status={statusProvider(restants).status}>
						<StatusIcon />
						{statusProvider(restants).value}
					</Status>
					<Validity><span style={{ fontWeight: 600 }}>Validité :</span> {restants} jour(s)</Validity>
				</CardStatus>
			</CardBody>

			<ActionMenuCicle
				title="Actions"
				children={[
					{title: "Détails", icon: null, onButtonClicked: onDetailsClick},
					{title: "Echéancier", icon: null, onClick: null},
					{title: "Renouveller", icon: null, onClick: null}
				]}
				isActionOpen={isActionOpen}
				setIsActionOpen={setIsActionOpen}
			/>
		</Container>
	);
};

export default SinisterCard;
