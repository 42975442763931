/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description update insurance page
 * @component SetAssurancePage
 */

import React from "react";

import DetailAssurance from "../../../components/layouts/main/assurances/provider/SetDetailAssurance";

const SetAssuranceDetails = () => {
	return <DetailAssurance />;
};

export default SetAssuranceDetails;