/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  menu chat
 * @component WelcomeChat
 */
import { Menu, Transition } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { classNames } from "../../../../styles";
import { useSelector } from "react-redux";
import { getUserDefaultProfil } from "../../../../features/user/user.slice";
import { USERCODE } from "../../../../config/config";

const StartChatButton = ({
	setopenIncidentModal,
	setopenSinistreModal,
	setopenNewChatGroup,
	setopenNewChat,
}) => {
	const ADMIN = USERCODE?.ADMIN;
	// default profil
	const selector = useSelector(getUserDefaultProfil);
	const id = selector?.id;

	return (
		<Menu
			as="div"
			className="relative inline-block text-left">
			<div>
				<Menu.Button className=" flex items-center text-gray-700 hover:bg-gray-100 p-2 rounded-full w-10 h-10">
					<span className="sr-only">Open options</span>
					<PencilIcon aria-hidden="true" />
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95">
				<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{id === ADMIN && (
							<>
								{/* new chat  */}
								<Menu.Item>
									{({ active }) => (
										<span
											onClick={() => setopenNewChat(true)}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"flex px-4 py-2 text-sm cursor-pointer"
											)}>
											<span>Nouvelle conversation</span>
										</span>
									)}
								</Menu.Item>

								{/* new chat group  */}
								<Menu.Item>
									{({ active }) => (
										<span
											onClick={() =>
												setopenNewChatGroup(true)
											}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"flex px-4 py-2 text-sm cursor-pointer"
											)}>
											<span>Créer un groupe</span>
										</span>
									)}
								</Menu.Item>
							</>
						)}

						{id !== ADMIN && (
							<Menu.Item>
								{({ active }) => (
									<span
										onClick={() =>
											setopenIncidentModal(true)
										}
										className={classNames(
											active
												? "bg-gray-100 text-gray-900"
												: "text-gray-700",
											"flex px-4 py-2 text-sm cursor-pointer"
										)}>
										<span>Déclarer un incident</span>
									</span>
								)}
							</Menu.Item>
						)}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default StartChatButton;
