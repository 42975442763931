import { MinusIcon } from "@heroicons/react/20/solid";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
`;

export const StyledH2 = styled.h2`
    font-size: 1.5em;
    font-weigth: 600;
    text-align: center;
`;

export const StyledH3 = styled.h3`
    padding: 20px 40px;
    border-radius: 8px 8px 0 0;
    font-size: 1.6em;
    font-weight: 600;
`;

export const Description = styled.p`
    text-align: justify;
    font-size: 1.1em;
    font-weight: 400;
`;

export const ModalButton = styled.button`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: ${(({usage}) => usage === "change" ? "80%" : usage === "cancel" ? "auto" : "10em")};
    padding: 10px;
    border-radius: 8px;
    font-size: ${(({usage}) => usage === "cancel" ? "1em" : "1.5em")};
    ${(({usage}) => usage === "cancel" &&
        `
        text-decoration: underline;`
    )}
    color: ${(({usage}) => usage === "cancel" ? "black" : "white")};
    transition: all 0.3s ease-in-out;
    &:hover {
        scale: 1.03;
    }
`;

export const StyledUList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 125px;
    background: white;
    padding: 30px;
`;

export const StyledListElements = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 17px;
    border: solid #0066DB 2px;
    border-radius: 4px;
    font-size: 1.3em;
    font-weight: 600;
`;

export const StyledEmptyListElements = styled.ul`
    list-style-type: disc;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 17px;
    font-size: 1.5em;
    font-weight: 600;
`;

export const StyledMinusIcon = styled(MinusIcon)`
    width: 40px;
    margin: -10px;
    cursor: pointer;
    color: #FF2000;
`;
