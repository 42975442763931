/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description select motif without insurance for consultation
 * @component MotifAssurance
 */
import React, { useEffect } from "react";
import Button from "../../../../UI/button/Button";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { Transition, Listbox } from "@headlessui/react";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import { useGetMotifsQuery } from "../../../../features/api/apiSlice";
import Spinner from "../../../../UI/spinner/Spinner";
import {
	motifConsultation,
	getconsultation,
} from "../../../../features/consultation/consultation.slice";
import { useDispatch, useSelector } from "react-redux";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Motif_consultation() {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [selected, setSelected] = useState({});

	const { ville, canal, isAssur } = useSelector(getconsultation);

	// get motif request
	const { data: motifs, isSuccess, isLoading } = useGetMotifsQuery();

	// select motif
	const addMotifHandle = (value) => {
		setSelected(value);
		dispatch(motifConsultation(value));
	};

	// set motif state
	useEffect(() => {
		if (isSuccess) {
			setSelected(motifs?.data[0]);
			dispatch(motifConsultation(motifs?.data[0]));
		}

		return () => {};
	}, [isSuccess, motifs, dispatch]);

	// path
	const medecinPath = {
		pathname: "/consultations/specialite",
		search: createSearchParams({
			withassurance: isAssur,
			ville: ville?.nomVille,
			canal: canal?.name,
			motif: selected.nomMotif,
		}).toString(),
	};

	return (
		<>
			<div>
				<div>
					<h2 className=" text-center py-4 sm:py-10 text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl">
						Sélectionnez le motif de votre consultation.
					</h2>

					<div className="max-w-lg mx-auto">
						{isSuccess && (
							<Listbox
								value={selected[0]}
								onChange={addMotifHandle}>
								{({ open }) => (
									<>
										<Listbox.Label className="block text-sm font-medium text-gray-700">
											Motif de la consultation
										</Listbox.Label>
										<div className="relative mt-1">
											<Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm">
												<span className="block truncate">
													{selected.nomMotif}
												</span>
												<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
													<ChevronUpDownIcon
														className="h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</span>
											</Listbox.Button>

											<Transition
												show={open}
												as={Fragment}
												leave="transition ease-in duration-100"
												leaveFrom="opacity-100"
												leaveTo="opacity-0">
												<Listbox.Options className="absolute z-50 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
													{motifs?.data?.map(
														(motif) => (
															<Listbox.Option
																key={
																	motif.id_motif
																}
																className={({
																	active,
																}) =>
																	classNames(
																		active
																			? "text-white bg-secondary-600"
																			: "text-gray-900",
																		"relative cursor-default select-none py-2 pl-3 pr-9"
																	)
																}
																value={motif}>
																{({
																	selected,
																	active,
																}) => (
																	<>
																		<span
																			className={classNames(
																				selected
																					? "font-semibold"
																					: "font-normal",
																				"block truncate"
																			)}>
																			{
																				motif.nomMotif
																			}
																		</span>

																		{selected ? (
																			<span
																				className={classNames(
																					active
																						? "text-white"
																						: "text-secondary-600",
																					"absolute inset-y-0 right-0 flex items-center pr-4"
																				)}>
																				<CheckIcon
																					className="h-5 w-5"
																					aria-hidden="true"
																				/>
																			</span>
																		) : null}
																	</>
																)}
															</Listbox.Option>
														)
													)}
												</Listbox.Options>
											</Transition>
										</div>
									</>
								)}
							</Listbox>
						)}

						{isLoading && (
							<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
						)}

						<div className="mt-8 flex items-center justify-between">
							<Button
								children="Etape précédent"
								type="button"
								onClick={() =>
									navigate("/consultations/prendre-rdv")
								}
							/>
							<Link
								to={medecinPath}
								disabled
								className="flex justify-center rounded-md border border-transparent bg-primary-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
								{" "}
								Continuer
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
