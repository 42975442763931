/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurance product of user
 * @component MesProduits
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ProduitCard from "./ProduitCard";
import { useGetAllSuscriberProductsQuery } from "../../../../../../features/api/apiSlice";
import { getUserCode } from "../../../../../../features/user/user.slice";
import Spinner from "../../../../../../UI/spinner/Spinner";
import SubscriptionBodyTitles from "../../../../../Utilities/subscriptionBodyTitles";
import SubscriptionCardsTitle from "../../../../../Utilities/subscriptionCardsTitle";
import productIllustration from '../../../../../../assets/productIllustrated.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
  margin: auto;
  @media screen and (max-width: 500px) {
    gap: 2.5em;
  }
`;

const DescriptionPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5em;
  width: 60%;
  @media screen and (max-width: 800px) {
	flex-direction: column;
  }
`;

const StyledMessage = styled.p`
    text-align: justify;
    margin: 0 0 2em 0;
    font-size: 1.5em;
    font-weight: 400;
`;

const Illustration = styled.img`
    width: 23em;
    height: auto;
    scale: 1.2;
    @media screen and (max-width: 1023px) {
        scale: 1.1;
    }
    @media screen and (max-width: 500px) {
        scale: 1;
    }
	@media screen and (max-width: 800px) {
		display: none;
	}
`;

const StyledBody = styled.div`
  width: 725px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const CardContainer = styled.div`
    display: flex;
	flex-direction: column;
	gap: 5em;
	width: 100%;
`;

function MesProduits() {
	const code = useSelector(getUserCode);
	const { data, isSuccess, isLoading } = useGetAllSuscriberProductsQuery(code);
	const [mesproduits, setMesproduits] = useState([]);

	function validityProvider(startDate, endDate) {
		const dateDebut = new Date(startDate);
		const dateFin = new Date(endDate);
	
		const differenceEnJours = Math.ceil((dateFin - dateDebut) / (1000 * 60 * 60 * 24));
	
		return differenceEnJours;
	};

	function daysLeftProvider(endDate) {
		const dateFin = new Date(endDate);
		const dateActuelle = new Date();

		const differenceEnMillisecondes = dateFin - dateActuelle;
		const joursRestants = Math.ceil(differenceEnMillisecondes / (1000 * 60 * 60 * 24));

		return joursRestants;
	};

	// set product insurance
	useEffect(() => {
		if (isSuccess) {
			const products = data?.data?.map((item) => {
				return {
					id: item.idSouscription,
					code: item.code,
					description: item.assurance.description,
					duree: validityProvider(item.dateDebutValidite, item.dateFinValidite),
					images: item.assurance.images,
					titre: item.assurance.nom,
					restants: daysLeftProvider(item.dateFinValidite),
					statut: item.etat,
					prix: parseInt(item.cout),
					// fournisseur: item.fournisseur,
					services: item.assurance.listeServices,
					pieces_a_joindre: item.assurance.piecesAJoindre,
					type_contrat: item.assurance.type,
					categorie: item.assurance.categorie,
					// documents: item.documents,
				};
			});
			setMesproduits(products);
		}

		return () => {};
	}, [isSuccess, data]);

	if (isLoading) {
		return (
			<Spinner className="h-10 w-10 text-primary-700 m-auto h-full" />
		);
	};

	if(isSuccess) {
		return (
			<Container>
				<SubscriptionBodyTitles
					bodyText={[`Bienvenue dans votre espace`]}
					title="Mes souscriptions"
				/>
				
				<DescriptionPanel>
					<StyledMessage>Vous trouverez çi-dessous la liste des produits auquels vous avez souscrit.</StyledMessage>
					<Illustration src={productIllustration} alt="Product page illustration" />
				</DescriptionPanel>

				<StyledBody>
					<SubscriptionCardsTitle content="Liste des vos souscriptions" />

					{mesproduits?.length === 0 ? (
						<div className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
							<p className="text-2xl text-center text-gray-500">
								{" "}
								<i>Vous n'avez souscrit à aucun produit</i>{" "}
							</p>
						</div>
					) : (
						<CardContainer>
							{mesproduits?.map((item, index) => {
								return (
									<ProduitCard
										key={`${index}-${item.id}`}
										titre={item.titre}
										restants={item.restants}
										images={item.images[0]?.url}
										id={item.id}
										code={item.code}
									/>
								);
							})}
						</CardContainer>
					)}
				</StyledBody>
			</Container>
		);
	} else {

		return (
			<h1>An unknown error occure</h1>
		)
	};
};

export default MesProduits;
