/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add skill page
 * @component AddCompetencesAdminPage
 */
import Main from "../../../components/layouts/main";
import AddCompetenceAdmin from "../../../components/layouts/main/consultations/doctor/AddCompetencesAdmin";

const AddCompetencesAdminPage = (props) => {
	return <Main children={<AddCompetenceAdmin />} />;
};

export default AddCompetencesAdminPage;