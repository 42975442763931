/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description   incident chat
 * @component IncidentItemChat
 */
import React from "react";
import { useSelector } from "react-redux";
import { getSelectedChat } from "../../../../features/chats/chats.slice";
import { classNames } from "../../../../styles";

const IncidentItemChat = ({
	idConversation,
	nom,
	description,
	type,
	image,
	code,
	selectChat,
}) => {
	// chat
	const chat = {
		nom,
		conversation_id: idConversation,
		type,
		img: image,
		code,
		description
	};

	// get selected chat
	const selectedChat = useSelector(getSelectedChat);

	// is chat selected
	const chatIsSelected = idConversation === selectedChat?.conversation_id;

	return (
		<li
			onClick={() => selectChat(chat)}
			className={classNames(
				"overflow-hidden rounded-md flex group items-start gap-x-3 w-full px-2 py-3 cursor-pointer hover:bg-secondary-100",
				chatIsSelected && "bg-secondary-100"
			)}>
			<span className="px-2 rounded-full bg-red-500 text-white text-center text-xl font-semibold">
				#
			</span>
			<div className=" flex-1">
				<div className=" flex items-center justify-between w-full">
					<p
						className="text-sm font-bold text-secondary-700 truncate w-3/5 xl:w-64"
						title={nom}>
						{`${code ? code : ""}${nom}`}
					</p>
				</div>
				<div className="flex items-center justify-between">
					<p className="text-xs font-bold text-gray-500 ">En cours</p>
				</div>
			</div>
		</li>
	);
};

export default IncidentItemChat;
