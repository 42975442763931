/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description medecin profil for consultation
 * @component Doctor
 */
import DoctorRdv from "./DoctorRdv";
import DoctorSlot from "./DoctorSlot";
import { useDispatch } from "react-redux";
import { medecinConsultation } from "../../../../features/consultation/consultation.slice";
import ExpertRDV from "./ExpertRDV";

const Doctor = ({ doctor, expert }) => {
	const dispatch = useDispatch();

	// select medecin
	const selectDoctor = () => {
		dispatch(medecinConsultation(doctor));
	};

	return (
		<div className="max-w-max mt-8 gap-x-3 border hover:border-secondary-600 rounded-md shadow flex md:flex-row flex-col items-start">
			{/* medecin profil  */}
			<DoctorRdv
				nom={doctor.nom}
				prenom={doctor.prenom}
				image={doctor.photo_profil}
				localisation={doctor.localisation}
			/>

			{expert ? (
				// expert
				<ExpertRDV
					skills={doctor.skills}
					codeMedecin={doctor.code}
				/>
			) : (
				// disponibility
				<DoctorSlot
					agenda={doctor?.agenda}
					selectDoctor={selectDoctor}
				/>
			)}
		</div>
	);
};

export default Doctor;
