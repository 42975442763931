/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description bilan of consultation
 * @component SingleConsultation
 */
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../../../UI/spinner/Spinner";
import Button from "../../../../../UI/button/Button";

import Fiche from "../Fiche";
import SingleBilanRDVConsultation from "./SingleBilanRDVConsultation";
import { MailtoModal } from "./MailtoModal";

import axiosInstance from "../../../../../apis/inchAssur";

import { getToken } from "../../../../../features/user/auth.slice";
import {
	assuranceConsultation,
	canalConsultation,
	isAssurConsultation,
	motifConsultation,
	resetConsultation,
	specialiteConsultation,
	typeConsultation,
	villeConsultation,
} from "../../../../../features/consultation/consultation.slice";
import { useGetFicheRendezVousQuery } from "../../../../../features/api/apiSlice";
import {
	getUserCode,
	getUserDefaultProfil,
} from "../../../../../features/user/user.slice";

const SingleConsultation = ({ goBack = true }) => {
	// mailto modal state
	const [mailtoModalIsOpen, setmailtoModalIsOpen] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { code } = useParams();

	// user code
	const userCode = useSelector(getUserCode);
	// toke
	const token = useSelector(getToken);
	// default profil
	const profilID = useSelector(getUserDefaultProfil)?.id;

	const [secondAvisLoading, setsecondAvisLoading] = useState(false);

	// get info consultation request
	const { data, isLoading, isSuccess } = useGetFicheRendezVousQuery(code);

	// get request for opinion
	const getSeconAvis = () => {
		setsecondAvisLoading(true);
		dispatch(resetConsultation());
		axiosInstance(`/products/subscriber/${userCode}`, {
			headers: {
				authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				const assuranceSuscribe = res.data?.data?.find(
					(item) => item.soucription_id === data?.data?.souscriptionID
				);

				dispatch(
					typeConsultation({
						type: "second avis",
						previousCode: data?.data?.code,
						isSecondAdvice: true,
					})
				);
				dispatch(canalConsultation(data?.data?.canal));
				dispatch(villeConsultation(data?.data?.ville));
				dispatch(specialiteConsultation(data?.data?.skill));
				dispatch(assuranceConsultation(assuranceSuscribe));
				dispatch(isAssurConsultation(data?.data?.isAssured));
				dispatch(motifConsultation({ nomMotif: data?.data?.objet }));

				// medecin path
				const medecinPath = {
					pathname: "/consultations/medecin",
					search: createSearchParams({
						withassurance: data?.data?.isAssured,
						ville: data?.data?.ville?.nomVille,
						canal: data?.data?.canal?.name,
						code_fournisseur: assuranceSuscribe?.code_fournisseur,
						skill: data?.data?.skill?.nom,
					}).toString(),
				};

				navigate(medecinPath, { state: data?.data });
			})
			.catch((err) => {
				// do something
			})
			.finally(() => setsecondAvisLoading(false));
	};

	return (
		<>
			{/* mailto modal  */}
			{mailtoModalIsOpen && (
				<MailtoModal
					openModal={mailtoModalIsOpen}
					setOpenModal={setmailtoModalIsOpen}
					user={data?.data?.medecin}
				/>
			)}
			{isLoading && (
				<div className="flex flex-1 justify-center">
					<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
				</div>
			)}
			{isSuccess && (
				<>
					{goBack && (
						<div className="py-3 flex justify-between items-center ">
							<Button
								children="Retour"
								type="button"
								onClick={() => navigate(-1)}
							/>

							<div className="flex gap-x-4 items-center">
								{data?.data?.isSecondAdvice && (
									<Button
										children="Transferer les documents"
										button="secondary"
										className="max-w-max"
										onClick={() =>
											setmailtoModalIsOpen(true)
										}
									/>
								)}

								{profilID === "IA1" && (
									<Button
										children="Demander un second avis"
										type="button"
										className="max-w-max"
										button="primary"
										onClick={getSeconAvis}
										loading={secondAvisLoading}
									/>
								)}
							</div>
						</div>
					)}
					<div className="overflow-hidden bg-white shadow sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6">
							<h3 className="text-lg font-medium leading-6 text-gray-900 flex flex-col justify-start">
								{data?.data?.isSecondAdvice && (
									<span className="text-xs text-primary-800 font-bold">
										second avis
									</span>
								)}
								Bilan de votre rendez-vous
							</h3>
						</div>

						{/* fiche  */}
						<div className="border-t border-gray-200  py-5 ">
							<Fiche
								nom={data?.data?.patient.nom}
								prenom={data?.data?.patient.prenom}
								periode={`${data?.data?.date}T${data?.data?.heure}`}
								prix={data?.data?.prix}
								canal={data?.data?.canal?.name}
								ville={data?.data?.ville.nomVille}
								motif={data?.data?.objet}
								isAssur={data?.data?.isAssured}
								code={data?.data?.patient.code}
								duree={data?.data?.duree}
							/>
						</div>
					</div>

					{/* bilan  */}
					<SingleBilanRDVConsultation bilan={data?.data?.bilan} />
				</>
			)}
		</>
	);
};

export default SingleConsultation;
