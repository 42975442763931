import { Navigate, Outlet } from "react-router-dom";

import { useSelector } from "react-redux";

import { getToken } from "../../../../features/user/auth.slice";

const Islogin = () => {
	const auth = useSelector(getToken);

	return auth ? (
		<Navigate
			to="/dashboard"
			replace
		/>
	) : (
		<Outlet />
	);
};

export default Islogin;
