/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  select skill for consultation
 * @component SkillsExpertPage
 */
import Main from "../../../components/layouts/main";
import SkillExpert from "../../../components/layouts/main/consultations/SkillExpert";

const SkillsExpertPage = () => {
	return <Main children={<SkillExpert />} />;
};

export default SkillsExpertPage;
