/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  opinion request page
 * @component MesDemandeAvisPage
 */
import Main from "../../../components/layouts/main";
import MesDemandeAvis from "../../../components/layouts/main/consultations/doctor/MesDemandeAvis";

const MesDemandeAvisPage = () => {
	return <Main children={<MesDemandeAvis />} />;
};

export default MesDemandeAvisPage;
