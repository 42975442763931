/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  resume of consultation
 * @component FicheRDVPage
 */

import Main from "../../../components/layouts/main";
import FicheRDV from "../../../components/layouts/main/consultations/Fiche_rdv";

const FicheRDVPage = (props) => {
	return <Main children={<FicheRDV />} />;
};

export default FicheRDVPage;
