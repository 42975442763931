/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list  insurance product
 * @component ListAssurancesOwner
 */
import { useLayoutEffect, Fragment, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "../../UserRecharge/Recharge";
import {
	useGetAllOwnerAssurancesQuery,
	useDisactivateAssuranceMutation,
	useActivateAssuranceMutation,
} from "../../../../../features/api/apiSlice";
import { getUserCode } from "../../../../../features/user/user.slice";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import {
	EllipsisHorizontalIcon,
	LockOpenIcon,
	LockClosedIcon,
	EyeIcon,
} from "@heroicons/react/20/solid";
import Spinner from "../../../../../UI/spinner/Spinner";
import {
	getModalProps,
	getModalType,
	initialModal,
	setModal,
} from "../../../../../features/feedBack/modal.slice";
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import BackIllustration from "../../../../Utilities/BackIllustration";
import styled from "styled-components";
import SubscriptionBodyTitles from "../../../../Utilities/subscriptionBodyTitles";
import { setModalPopUp } from "../../../layoutLogics";
import SpinnerEllipsi from "../../../../../UI/spinner/SpinnerEllipsi";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    margin: auto;
    @media screen and (max-width: 500px) {
      gap: 2em;
    }
    @media screen and (max-width: 1023px) {
      gap: 1.3em;
    }
`;

const StyledBody = styled.div`
    width: 850px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    @media screen and (max-width: 1023px) {
      width: 100%;
      min-width: 800px;
    }
`;

const ListAssurancesOwner = () => {
	const dispatch = useDispatch();

	// code user
	const code = useSelector(getUserCode);

	// get  insurance request
	const {
		data: assurances,
		isSuccess,
		isLoading,
	} = useGetAllOwnerAssurancesQuery(code);

	const [ disactivateAssur, { isLoading: disactivating } ] = useDisactivateAssuranceMutation();
	const [ activateAssur, { isLoading: activating } ] = useActivateAssuranceMutation();

	const [selectedAssurance, setSelectedAssurance] = useState(null);
	const [rowClick, setRowClick] = useState(true);
	const [currentProduct, setCurrentProduct] = useState("");



	const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
	const paginatorRight = <Button type="button" icon="pi pi-download" text />;


	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const [globalFilterValue, setGlobalFilterValue] = useState('');

	//show header
	const renderHeader = () => {
		return (
			<div className="flex flex-wrap gap-2 justify-content-end align-items-end" style={{ justifyContent: 'end' }}>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="rechercher" />
				</span>
			</div>
		);
	};

	//set value to filter
	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	//show user.status
	const statusTemplate = (rowData) => {
		return (
			<td
				className={statusClassName(
					rowData.etat
				)}>
				{rowData.etat}
			</td>
		);
	};

	const header = renderHeader();

	// open options parameters(edit and delete)
	const actionBodyTemplate = (rowData) => {
		return <>
			{(activating || disactivating) && currentProduct === rowData.code ? (
				<SpinnerEllipsi
					className="inline-block text-left"
					classNameEllipsi="h-6 w-6 "
					classNameSpin="h-7 w-7 text-secondary-500"
				/>
			) : (
				<Menu
					as="div"
					className=" inline-block text-left"
				>
					<Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
						<span className="sr-only">
							Open
							options
						</span>
						<EllipsisHorizontalIcon
							className="h-7 w-7"
							aria-hidden="true"
						/>
					</Menu.Button>

					{console.log("row data value :", rowData)}

					<Transition
						as={
							Fragment
						}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								<Menu.Item>
									{({
										active,
									}) => (
										<button
											onClick={updateAssue.bind(
												null,
												rowData.code
											)}
											href="#"
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"group flex items-center w-full px-4 py-2 text-sm"
											)}
										>
											<EyeIcon
												className="mr-3 h-5 w-5 text-indigo-400 group-hover:text-indigo-500"
												aria-hidden="true"
											/>
											Consulter
										</button>
									)}
								</Menu.Item>
							</div>
							<div className="py-1">
								{rowData.etat ===
									"Inactif" ? (
									<Menu.Item>
										{({
											active,
										}) => (
											<button
												onClick={() => activationStatusToggler("activate", rowData.code)}
												href="#"
												className={classNames(
													active
														? "bg-gray-100 text-gray-900"
														: "text-gray-700",
													"group flex items-center w-full px-4 py-2 text-sm"
												)}
											>
												<LockOpenIcon
													className="mr-3 h-5 w-5 text-green-400 group-hover:text-green-500"
													aria-hidden="true"
												/>
												Activer
											</button>
										)}
									</Menu.Item>
								) : rowData.etat ===
									"Actif" ? (
									<Menu.Item>
										{({
											active,
										}) => (
											<button
												onClick={() => activationStatusToggler("disactivate", rowData.code)}
												href="#"
												className={classNames(
													active
														? "bg-gray-100 text-gray-900"
														: "text-gray-700",
													"group flex items-center w-full px-4 py-2 text-sm"
												)}
											>
												<LockClosedIcon
													className="mr-3 h-5 w-5 text-yellow-400 group-hover:text-yellow-500"
													aria-hidden="true"
												/>
												Désactiver
											</button>
										)}
									</Menu.Item>
								) : null}
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			)}
		</>
	};


	const navigate = useNavigate();

	// update insurance
	const updateAssue = (code) => {
		navigate(`/mes-assurances/${code}`);
	};

	// update insurance status
	async function activationStatusToggler(action, idCode) {
		setCurrentProduct(idCode);

		try {
			const response = action === "disactivate" ?
			await disactivateAssur(idCode).unwrap() :
			await activateAssur(idCode).unwrap();

			if (response.statut === "ok") {
				// setProductAction(action === "activate" ? "disactivate" : "activate");
				setModalPopUp(dispatch, `Produit ${action === "disactivate" ? "désactivé" : "activé"} avec success`, "success");
			} else {
				setModalPopUp(dispatch, "Une erreur est survenue, veuillez réessayer ou contacter l'administrateur", "failed");
			};
		} catch (error) {
			setModalPopUp(dispatch, "Une erreur est survenue, veuillez réessayer ou contacter l'administrateur", "failed");
		}
	};

	const statusClassName = (statusTransaction) => {
		switch (statusTransaction) {
			case "Actif":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "Inactif":
				return "inline-flex rounded-full bg-yellow-100 px-2 my-4 text-xs font-semibold leading-5 text-yellow-800 whitespace-nowrap ";
			default:
				break;
		}
	};

	if (isLoading) {
		return (
			<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700 h-full" />
		);
	};

	if (isSuccess) {
		return (
			<div>
				<BackIllustration />

				<Container>
					<SubscriptionBodyTitles
						bodyText={[`Gérez vos differents produits ici...`]}
						title="Mes Assurance"
					/>

					<StyledBody>
						{/* header  */}
						<div className="w-full flex items-center justify-end">
							<Link to="nouveau/add">
								<button
									type="button"
									className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
									Ajouter une assurance
								</button>
							</Link>
						</div>

						{(assurances && assurances?.data?.length > 0) ? (
							<DataTable value={assurances?.data} selectionMode={rowClick ? null : 'checkbox'} selection={selectedAssurance} onSelectionChange={(e) => setSelectedAssurance(e.value)} header={header} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
								paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
								filters={filters} globalFilterFields={['nom', 'prenom', 'role', 'email', 'statut']}
								emptyMessage="No customers found."
								currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} style={{ borderWidth: '1px', borderStyle: 'solid' }}
							>
								<Column selectionMode="multiple" headerStyle={{ width: '1%' }}></Column>

								<Column field="code" header="Code" style={{ width: '2%' }}></Column>

								<Column field="nom" sortable header="Assurance" style={{ width: '4%' }}></Column>

								{/* <Column field="fournisseur" header="Fournisseur" style={{ width: '2%' }}></Column> */}

								<Column field="categorie.nom" header="Categorie" style={{ width: '2%' }}></Column>
								<Column field="prix" header="Prix" style={{ width: '1%' }}></Column>
								{/* <Column field="dateCreation" header="Date de creation" style={{ width: '4%' }}></Column> */}

								<Column header="Status" style={{ width: '1%' }} body={statusTemplate} />


								<Column header="Action" style={{ width: '1%' }} body={actionBodyTemplate} />

							</DataTable>
						) : (
							<div className="flex flex-col items-center justify-center">
								{" "}
								<p className="text-3xl text-center text-gray-500">
									aucune assurance{" "}
								</p>
							</div>
						)}
					</StyledBody>
				</Container>

			</div>
		);
	} else {
		return (
			<h1>An Error Occured</h1>
		);
	};
};

export default ListAssurancesOwner;
