import { RadioGroup } from "@headlessui/react";
import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import defaultCover from "../../../../../../assets/QA engineers-bro.svg"

const arrowHoverAnimation = keyframes`
    0% {
        transform: rotate(90deg);
    }
    25% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0deg);
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    background-color: white;
    border-radius: 8px;
    box-shadow: 3px 0 16px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.5s ease;
    overflow: hidden;
    &:hover {
        > div:last-child > div:last-child {
            animation: ${arrowHoverAnimation} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        }

        transform: scale(1.03);
    }
    ${({active}) => active && 
    `
        flex-direction: column;
    `}
}
`;

const Cover = styled.div`
    width: ${({active}) => active ? "100%" : "150px"};
    height: ${({active}) => active ? "250px" : "auto"};
    align-self: stretch;
    flex-grow: 1;
    overflow: hidden;
    & > :first-child {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const CardBody = styled.div`
    display: flex;
    flex-direction: ${({active}) => active ? "column" : "row"};
    align-items: center;
    flex-grow: 1;
    margin: 15px;
    padding: 20px;
    gap: 10px;
    width: 100%;
`;

const CardBodyName = styled.h3`
    text-align: left;
    flex-grow: 1;
    font-size: 2em;
    font-weight: 500;
    text-transform: capitalize;
`;

const Description = styled.p`
    text-align: justify;
    font-size: 1.5em;
    font-weight: 400;
    width: 90%;
    display: ${({active}) => active ? "inline" : "none"};
`;

const CardBodyArrow = styled.div`
    width: 51px;
    height: 51px;
    display: ${({active}) => active ? "none" : "flex"};
    align-items: center;
    justify-content: center;
`;

const CardButton = styled.div`
    width: 80%;
    padding: 10px;
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    border-radius: 8px;
    margin-top: 15px;
    transition: all 0.3s ease-in-out;
    &:hover {
        scale: 1.02;
    }
    ${({active}) => !active && `display: none;`};
`;

function CategoryCard({ category }) {
    const navigate = useNavigate();

    function handleContiue(id) {
        navigate(`${id}`, {cover});
    };

    const cover = category?.image?.url ? category.image.url : defaultCover;

    return (
        <RadioGroup.Option
            value={category}
        >
            {({ checked, active }) => (
                <Container active={active}>
                    
                    <Cover active={active}>
                        <img src={cover}/>
                    </Cover>

                    <CardBody active={active}>
                        <CardBodyName className="text-secondary-600">{category.nom}</CardBodyName>

                        <Description active={active}><strong>Description:</strong> {category.description}</Description>

                        <CardButton
                            active={active}
                            className="border-primary-800 border-2 text-primary-800 bg-primary-100"
                            onClick={() => handleContiue(category.idCategorie)}
                        >Consulter la liste</CardButton>

                        <CardBodyArrow active={active}>
                            <ArrowRightCircleIcon className="text-secondary-600" style={{ width: 30, height: 30 }}/>
                        </CardBodyArrow>
                    </CardBody>
                </Container>
            )}
        </RadioGroup.Option>
    )
};

export default CategoryCard;
