/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product description
 * @component Description
 */
import { PhotoIcon } from "@heroicons/react/24/outline";
import { useGetAssurancesCategorieQuery, useGetAssurancesTypesQuery } from "../../../../../features/api/apiSlice";
import Spinner from "../../../../../UI/spinner/Spinner";
import styled from "styled-components";

const CoverContainer = styled.div`
	position: relative;
	overflow: hidden;
`;

const CoverImage = styled.img`
	z-index: -1;
	width: 100%;
	height: auto;
	position: absolute;
	top: 40%;
	transform: translateY(-35%);
	object-fit: cover;
`;

const Description = ({
	assurance,
	cover,
	handleSelectChange,
	handleTextChange,
	handleFileChange
}) => {

	// get insurance category
	const { data, isLoading } = useGetAssurancesCategorieQuery();

	const { data: typeData, isLoading: typeLoading, isSuccess } = useGetAssurancesTypesQuery();
	// list of assurance types
	const typeOptions = typeData?.data?.map((item) => {
		return {
			id: item.idTypeAssurance,
			name: item.nom,
			description: item.description
		};
	}) || [];

	// list of assurance categorie
	const valueOption = data?.data?.map((item) => {
		return {
			id: item.idCategorie,
			name: item.nom,
		};
	}) || [];

	return (
		<div className="space-y-12">
			{/* Cover form section */}
			<div className="border-b border-gray-900/10 pb-12">
				<h2 className="text-base font-semibold leading-7 text-gray-900">Photo de couverture</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600">
					Veuillez fournir une image qui sera utilisée pour illustrer votre produit auprès de nos utilisateurs
				</p>

				<div className="mt-10 col-span-full">
					<CoverContainer className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
						{cover || assurance.image && (<CoverImage src={cover || assurance.image} alt="test cover" />)}
						<div className="text-center">
							<PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
							<div className="mt-4 flex text-sm leading-6 text-gray-600">
								<label
									htmlFor="image"
									className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
								>
									<span>Upload a file</span>
									<input id="image" name="image" type="file" accept="image/*" className="sr-only" onChange={handleFileChange} />
								</label>

								<p className="pl-1">or drag and drop</p>
							</div>

							<p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
						</div>
					</CoverContainer>
				</div>
			</div>

			{/* Description form section */}
			<div className="border-b border-gray-900/10 pb-12">
				<h2 className="text-base font-semibold leading-7 text-gray-900">Description</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600">Ces informations serviront d'aperçus de votre produit pour les utilisateurs</p>

				<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
					<div className="sm:col-span-3">
						<label htmlFor="nom" className="block text-sm font-medium leading-6 text-gray-900">
							Nom du produit
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="nom"
								id="nom"
								autoComplete="name"
								defaultValue={assurance?.nom}
								placeholder="nom du produit..."
								onChange={handleTextChange}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="categorie" className="block text-sm font-medium leading-6 text-gray-900">
							Sélectionnez-en la catégorie
						</label>
						<div className="mt-2">
							{isLoading ? (
								<Spinner className="h-8 w-8 text-primary-700 m-auto h-full" />
							) : (
								<select
									id="categorie"
									name="categorie"
									autoComplete="categorie-name"
									defaultValue={assurance?.categorie?.name}
									onChange={(event) => handleSelectChange(event, valueOption)}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								>
									<option disabled selected hidden>-- Choisissez une categorie --</option>
									{valueOption.length > 0 ? (
										valueOption.map((option, index) => (
											<option key={index} title={option.description} value={option.name}>{option.name}</option>
										))
									) : (
										<option>-- Aucune catégorie trouvée --</option>
									)}
								</select>
							)}
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="shortDescription" className="block text-sm font-medium leading-6 text-gray-900">
							Courte description
						</label>
						<div className="mt-2">
							<input
								type="text"
								name="shortDescription"
								id="shortDescription"
								autoComplete="shortDescription"
								defaultValue={assurance?.shortDescription}
								placeholder="Courte description..."
								onChange={handleTextChange}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
							Description complete
						</label>
						<div className="mt-2">
							<textarea
								id="description"
								name="description"
								rows={3}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								defaultValue={assurance?.description}
								placeholder="Description complete du produit..."
								onChange={handleTextChange}
							/>
						</div>
					</div>

					<div className="sm:col-span-2 sm:col-start-1">
						<label htmlFor="prix" className="block text-sm font-medium leading-6 text-gray-900">
							Prix de base (Fcfa)
						</label>
						<div className="mt-2">
							<input
								type="number"
								name="prix"
								id="prix"
								autoComplete="base-price"
								defaultValue={assurance?.prix}
								placeholder="0"
								onChange={handleTextChange}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-2">
						<label htmlFor="duree" className="block text-sm font-medium leading-6 text-gray-900">
							Durée (Jours)
						</label>
						<div className="mt-2">
							<input
								type="number"
								name="duree"
								id="duree"
								autoComplete="duree"
								defaultValue={assurance?.duree}
								placeholder="0"
								onChange={handleTextChange}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-2">
						<label htmlFor="accountType" className="block text-sm font-medium leading-6 text-gray-900">
							Type de contrat
						</label>
						<div className="mt-2">
							{typeLoading ? (
								<Spinner className="h-8 w-8 text-primary-700 m-auto h-full" />
							) : (
								<select
									id="accountType"
									name="accountType"
									autoComplete="accountType-name"
									defaultValue={assurance?.accountType?.name}
									onChange={(event) => handleSelectChange(event, typeOptions)}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								>
									<option disabled selected hidden>-- Choix du type --</option>
									{typeOptions.length > 0 ? (
										typeOptions.map((type, index) => (
											<option className="flex gap-4 items-center" key={index} value={type.name} title={type.description}>{type.name}</option>
										))
									) : (
										<option>-- Aucune type trouvée --</option>
									)}
								</select>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Description;
