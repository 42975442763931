import styled from "styled-components";
import PageTitle from "./PageTitle";
import TitleBodyText from "./TitleBodyText";

const Container = styled.div`
    width: 100%;
`

function SubscriptionBodyTitles({ bodyText, title }) {

    return (
        <Container>
            <TitleBodyText
                contents={bodyText}
            />

            <PageTitle content={title} />
        </Container>
    );
};

export default SubscriptionBodyTitles;
