/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description users list
 * @component ListUsersPage
 */

import React from "react";
import Listusers from "../../components/layouts/main/users/Listusers";

const ListUsersPage = () => {
	return <Listusers />;
};

export default ListUsersPage;
