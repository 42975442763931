/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description input ville component
 * @component VilleComponent
 */

import React, { useState, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/24/outline";

import { useGetCityQuery } from "../features/api/apiSlice";

import Spinner from "../UI/spinner/Spinner";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const VilleComponent = ({ setVilleSelected }) => {
	// get cities request
	const {
		data: cities,
		isLoading: citiesIsLoading,
		isSuccess: citiesIsSuccess,
	} = useGetCityQuery();

	// query state
	const [query, setQuery] = useState("");

	// cities state
	const [city, setCity] = useState([]);

	// selected city state
	const [selectedVille, setSelectedVille] = useState(null);

	// filtered city
	const filteredVille =
		query === ""
			? city
			: city.filter((city) => {
					return city.ville
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	// set cities state
	useEffect(() => {
		if (citiesIsSuccess) {
			setCity(cities?.data);
		}

		return () => {};
	}, [citiesIsSuccess, cities]);

	// handle change
	const handleChangeSelectedVille = (value) => {
		setSelectedVille(value);
		setVilleSelected(value);
	};

	if (citiesIsLoading) {
		return <Spinner className="h-10 w-10 text-primary-700 text-center " />;
	}

	return (
		<>
			{citiesIsSuccess && (
				<Combobox
					as="div"
					value={selectedVille}
					onChange={handleChangeSelectedVille}>
					<Combobox.Label className="block text-sm font-medium text-gray-700">
						Saisir ou selectionner la ville
					</Combobox.Label>
					<div className="relative mt-1">
						<Combobox.Input
							placeholder="Veillez saisir ou selectionner la ville pour votre consultation"
							className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
							onChange={(event) => setQuery(event.target.value)}
							displayValue={(city) => city?.ville}
						/>
						<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
							<ChevronUpDownIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</Combobox.Button>

						{filteredVille.length > 0 && (
							<Combobox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{filteredVille.map((city, index) => (
									<Combobox.Option
										key={index}
										value={city}
										className={({ active }) =>
											classNames(
												"relative cursor-default select-none py-2 pl-8 pr-4",
												active
													? "bg-secondary-600 text-white"
													: "text-gray-900"
											)
										}>
										{({ active, selected }) => (
											<>
												<span
													className={classNames(
														"block truncate",
														selected &&
															"font-semibold"
													)}>
													{city.ville}
												</span>

												{selected && (
													<span
														className={classNames(
															"absolute inset-y-0 left-0 flex items-center pl-1.5",
															active
																? "text-white"
																: "text-secondary-600"
														)}>
														<CheckIcon
															className="h-5 w-5"
															aria-hidden="true"
														/>
													</span>
												)}
											</>
										)}
									</Combobox.Option>
								))}
							</Combobox.Options>
						)}
					</div>
				</Combobox>
			)}
		</>
	);
};

export default VilleComponent;
