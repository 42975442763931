/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description motif consultation
 * @component MotifAssurance
 */
import React, { useState } from "react";

import { getconsultation } from "../../../../features/consultation/consultation.slice";
import { useSelector } from "react-redux";
import MotifAssurance from "./MotifAssurance";
import MotifConsultation from "./Motif_consultation";

const Motif = () => {
	// is assur state
	const { isAssur } = useSelector(getconsultation);

	const [withAssur, setwithAssur] = useState(isAssur);

	let content;

	if (withAssur) {
		// select motif with insurance
		content = <MotifAssurance setwithAssur={setwithAssur} />;
	} else {
		// select motif without insurance
		content = <MotifConsultation setwithAssur={setwithAssur} />;
	}

	return content;
};

export default Motif;
