/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list opinion list consultatio
 * @component MesAvisExpert
 */
import React, { Fragment } from "react";
import { useGetMesAvisExperQuery } from "../../../../../features/api/apiSlice";
import { Menu, Transition } from "@headlessui/react";
import Spinner from "../../../../../UI/spinner/Spinner";
import { Link } from "react-router-dom";
import {
	EllipsisHorizontalIcon,
	EyeIcon,
	ArrowRightCircleIcon,
} from "@heroicons/react/24/solid";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const MesAvisExpert = () => {
	const { data: avis, isLoading, isSuccess } = useGetMesAvisExperQuery();
	let content;

	if (isLoading) {
		content = (
			<div className="flex flex-1 justify-center">
				<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
			</div>
		);
	} else if (isSuccess) {
		if (avis?.data.length === 0) {
			content = (
				<div className="flex flex-col items-center justify-center">
					{" "}
					<p className="text-3xl text-center text-gray-500">
						aucun avis expert{" "}
					</p>
				</div>
			);
		} else {
			content = (
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
											Medecin
										</th>

										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
											Spécialité
										</th>

										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
											Date du rendez-vous
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
											Status
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 sr-only">
											Action
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{avis?.data?.map((item, index) => (
										<tr key={index}>
											<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold  text-gray-700 sm:pl-6">
												<div className="flex items-center">
													<div className="h-10 w-10 flex-shrink-0">
														<img
															className="h-10 w-10 rounded-full object-cover"
															src={
																item.expert
																	.photo_profil
															}
															alt=""
														/>
													</div>
													<div className="ml-4">
														<div className="font-medium text-gray-900">
															{item.expert.nom}
														</div>
														<div className="text-gray-500">
															{item.expert.email}
														</div>
													</div>
												</div>
											</td>

											<td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-700">
												{item.skill}
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
												{item.date}
											</td>

											<td className="py-2">
												<Menu
													as="div"
													className=" inline-block text-left">
													<div>
														<Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
															<span className="sr-only">
																Open options
															</span>
															<EllipsisHorizontalIcon
																className="h-7 w-7"
																aria-hidden="true"
															/>
														</Menu.Button>
													</div>

													<Transition
														as={Fragment}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95">
														<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
															<div className="py-1">
																<Menu.Item>
																	{({
																		active,
																	}) => (
																		<Link
																			to={`${item.codeConsultation}`}>
																			<span
																				className={classNames(
																					active
																						? "bg-gray-100 text-gray-900"
																						: "text-gray-700",
																					"group flex items-center px-4 py-2 text-sm cursor-pointer"
																				)}>
																				<EyeIcon
																					className="mr-3 h-5 w-5 text-green-400 group-hover:text-green-500"
																					aria-hidden="true"
																				/>
																				Détails
																			</span>
																		</Link>
																	)}
																</Menu.Item>

																<Menu.Item>
																	{({
																		active,
																	}) => (
																		<span
																			className={classNames(
																				active
																					? "bg-gray-100 text-gray-900"
																					: "text-gray-700",
																				"group flex items-center px-4 py-2 text-sm cursor-pointer"
																			)}>
																			<ArrowRightCircleIcon
																				className="mr-3 h-5 w-5 text-secondary-400 group-hover:text-secondary-500"
																				aria-hidden="true"
																			/>
																			Demarrer
																		</span>
																	)}
																</Menu.Item>
															</div>
														</Menu.Items>
													</Transition>
												</Menu>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			);
		}
	} else {
		return;
	}

	return (
		<>
			<div className="bg-white">
				<div className="px-1 sm:px-2 lg:px-2 mt-8">
					<h2 className="text-xl font-bold tracking-tight text-primary-800 sm:text-2xl mb-8">
						Mes avis experts{" "}
					</h2>
					{content}
				</div>
			</div>
		</>
	);
};

export default MesAvisExpert;
