/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  verifiy email after registration when user click to the link in email
 * @component VerifCodePage
 */
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import axiosInstance from "../../apis/inchAssur";

import Button from "../../UI/button/Button";
import Spinner from "../../UI/spinner/Spinner";
import Logo from "../../components/logo/Logo";

import { setModal } from "../../features/feedBack/modal.slice";

const VerifCodePage = () => {
	// searchParams state
	const [searchParams] = useSearchParams();
	// get query params
	const query = Object.fromEntries([...searchParams]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isAccountVerified, setIsAccountVerified] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// const [isError, setIsError] = useState(false);

	useEffect(() => {
		const data = {
			code: query?.token,
		};

		if (!isAccountVerified) {
			setIsLoading(true);

			axiosInstance
				.post("/account/confirm", data, {
					headers: {
						Authorization: "Bearer " + query?.token,
					},
				})
				.then((res) => {
					setIsAccountVerified(true);
				})
				.catch((err) => {
					if (err.response.status === 400 && !isAccountVerified) {
						dispatch(
							setModal({
								modalType: "account",
								modalProps: {
									isOpen: true,
									status: "success",
									text: `Ce compte à déjà été vérifié. Veillez vous connecter  `,
								},
							})
						);
						navigate(`/connexion`, { replace: true });
					} else {
						dispatch(
							setModal({
								modalType: "account",
								modalProps: {
									isOpen: true,
									status: "success",
									text: `Une erreur est survenue`,
								},
							})
						);

						console.log(err);
					};

					// setIsError(true);
					// setIsLoading(false);
				}).finally(() => {
					setIsLoading(false);
				});
		}

		return () => {};
	}, [dispatch, navigate, isAccountVerified, query?.token, query?.code]);

	return (
		<>
			{isLoading ? (
				<div className="flex flex-col items-center justify-center h-full max-h-full min-h-screen">
					<Spinner className="w-32 h-32 text-primary-700" />
				</div>
			) : (
				<>
					{isAccountVerified ? (
						<div className=" min-h-screen ">
							<div className="container max-w-lg mx-auto pt-12 space-y-8 ">
								<Logo />
		
								<div className="border shadow-md p-4 flex flex-col justify-center items-center space-y-6 rounded-lg">
									<h1 className="text-2xl font-bold text-primary-800 ">
										Verification de compte
									</h1>
		
									<p className="text-base ">
										{" "}
										Votre compte a été vérifié avec success. Veillez
										vous connecter pour acceder à votre ce dernier.
									</p>
		
									<Button
										children="Se connecter"
										button="primary"
										type="button"
										onClick={() => navigate(`/connexion`)}
										className="max-w-max"
									/>
								</div>
							</div>
						</div>
					) : (
						<div className=" min-h-screen ">
							<div className="container max-w-lg mx-auto pt-12 space-y-8 ">
								<Logo />
		
								<div className="border shadow-md p-4 flex flex-col justify-center items-center space-y-6 rounded-lg">
									<h1 className="text-2xl font-bold text-primary-800 ">
										Verification de compte
									</h1>
		
									<p className="text-base ">
										{" "}
										Une erreur est survenue empêche la vérification de votre compte. Veillez
										essayer avec votre code ou contacter votre administrateur.
									</p>
		
									<Button
										children="Utiliser mon code"
										button="primary"
										type="button"
										onClick={() => navigate(`/validation/${query?.token}`)}
										className="max-w-max"
									/>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default VerifCodePage;
