/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product
 * @component AddAssurance
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Description from "../Description";
import Services from "../Services";

import {
	useAddAssuranceAttachementsMutation,
	useAddAssurancePayOptionMutation,
	useAddAssuranceReductionsMutation,
	useAddAssuranceServicesMutation,
	useAddProductAssuranceMutation,
	useGetAssuranceFullDetailsQuery,
	useSetFormAssuranceMutation,
	useUpdateAssuranceMutation
} from "../../../../../../features/api/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import SubscriptionBodyTitles from "../../../../../Utilities/subscriptionBodyTitles";

// Stepper import start here -------------------------------------------------------
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DonneesClient from "./DonneesClient";
import Spinner from "../../../../../../UI/spinner/Spinner";
import { setModalPopUp } from "../../../../layoutLogics";
import BackIllustration from "../../../../../Utilities/BackIllustration";
// ---------------------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5em;
  margin: auto;
  @media screen and (max-width: 500px) {
    gap: 2.5em;
  }
`;

const StyledBody = styled.div`
  width: 970px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const AddAssurance = () => {
	const [cover, setCover] = useState(null);
	const [isEdited, setIsEdited] = useState(false)
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// add product insurance request
	const [addProduct, { isLoading }] = useAddProductAssuranceMutation();
	const [updateProduct, {isLoading: isUpdating}] = useUpdateAssuranceMutation();
	
	// second step adding -----------------------------------------------------------------------------
	// add services to insurance request
	const [addServices, { isLoading: serviceLoading }] = useAddAssuranceServicesMutation();
	// add pays options to insurance request
	const [addPayOptions, { isLoading: payOptionsLoading }] = useAddAssurancePayOptionMutation();
	// ------------------------------------------------------------------------------------------------

	// third step adding -----------------------------------------------------------------------------
	// add questions to insurance request
	const [addQuestions, { isLoading: questionsLoading }] = useSetFormAssuranceMutation();
	// add documentation to insurance request
	const [addAttachements, { isLoading: attachementsLoading }] = useAddAssuranceAttachementsMutation();
	// add pays options to insurance request
	const [addReductions, { isLoading: reductionsLoading }] = useAddAssuranceReductionsMutation();
	// ------------------------------------------------------------------------------------------------

	//initial product state
	const [dataAssurance, SetDataAssurance] = useState({
		document: {},
		services: [],
		pieces: [],
		imagePreview: [],
		images: [],
		categorie: "default",
	});

	const [assurance, setAssurance] = useState({
		documentation: [],
		payOptions: [{
            id: "1",
            name: "Unique",
            description: "Payer en une seule fois le montnant requis."
        }],
		form: []
	});

	const [idAssurance, setIdAssurance] = useState(null);

	// getting assurance data if the page where with the edit parameter
	const {
		data: editAssurance,
		isSuccess: editSuccess,
		isLoading: editLoading,
	} = useGetAssuranceFullDetailsQuery(params?.action);

	console.log("edit assur data :", editAssurance?.data);
	console.log("assurance :", assurance);
	console.log("id assurance :", idAssurance);

	useEffect(() => {
		if (params.action !== "add" && editSuccess && editAssurance.data.image.url) {
			const servicesList = editAssurance.data.listeServices.map((service) => {
				return {
					id: service.idService,
					label: service.nom,
					prix: service.prixCouverture.toString(),
					taux: service.tauxCouverture.toString(),
					value: service.nom
				};
			});

			const documentList = editAssurance.data.documentation.map((document) => {
				function getLastPartOfUrl(url) {
					return url.split('/').pop();
				};

				return {
					id: document.idDocument,
					title: document.titre,
					type: document.type,
					file: {
						name: getLastPartOfUrl(document.url)
					},
					extension: document.extension,
					isLink: document.isLink,
					url: document.url
				};
			});

			const payList = editAssurance.data.payOptions.filter(option => option.idPayOption !== 1).map((option) => {
				return {
					id: option.idPayOption,
					description: option.description,
					name: option.type,
					dureeEtape: option.dureeEtape,
					longueurCycle: option.longueurCycle,
					montantCible: option.montantCible,
					nombreCycle: option.nombreCycle,
					tauxDepotInitial: option.tauxDepotInitial,
					tauxCycle: option.tauxCycle,
					type: option.nom
				};
			});

			const formList = editAssurance.data.questionnaire.map((question) => {
				const optionsList = question.options.map((option) => {
					return {
						format: option.format,
						idOption: option.idOption,
						label: option.label,
						prix: option.prix,
						subquestions: option.subquestions,
					};
				});

				return {
					id: question.idQuestion,
					description: question.description,
					fieldType: question.fieldType,
					isRequired: question.isRequired,
					libelle: question.libelle,
					options: optionsList,
					submited: true,
					tarifType: question.tarifType,
					idAuteur: question.idAuteur,
				};
			});

			const attachementList = editAssurance.data.pieces.map((option) => {
				return {
					description: option.description,
					id: option.id,
					label: option.nom,
					value: option.nom
				};
			});

			const reductionList = editAssurance.data.reductions.map((item) => {
				return {
					description: item.description,
					id: item.idReduction,
					name: item.code,
					taux: item.taux,
					valeur: item.valeur,
					auteur: item.auteur,
					dateExpiration: item.dateExpiration,
					nombreUsageMax: item.nombreUsageMax,
					nombreUtilise: item.nombreUtilise
				};
			});

			setAssurance({
				...assurance,
				id: editAssurance.data.idAssurance,
				image: editAssurance.data.image.url,
				nom: editAssurance.data.nom,
				description: editAssurance.data.description,
				shortDescription: editAssurance.data.shortDescription,
				prix: editAssurance.data.prix,
				duree: editAssurance.data.duree,
				categorie: {
					id: editAssurance.data.categorie.idCategorie,
					name: editAssurance.data.categorie.nom,
					description: editAssurance.data.categorie.description
				},
				accountType: {
					id: editAssurance.data.type.idTypeAssurance,
					name: editAssurance.data.type.nom,
					description: editAssurance.data.type.description
				},
				services: servicesList,
				documentation: documentList,
				payOptions: payList,
				form: [
					...formList,
					{
						id: 0,
						submited: false,
						libelle: "",
						fieldType: "text",
						tarifType: "Addition",
						isRequired: false,
						description: "",
						options: [
							{
								label: "",
								prix: 0,
								format: null,
								subquestions: []
							}
						]
					}
				],
				pieces: attachementList,
				reduction: reductionList
			});

			setIdAssurance(editAssurance.data.idAssurance)
		} else {
			setAssurance({
				...assurance,
				documentation: [
					{
						title: "Conditions d'utilisation",
						type: "document",
						file: `${process.env.PUBLIC_URL}/pdf_open.pdf`
					},
					{
						title: "Contrat de souscription",
						type: "document",
						file: `${process.env.PUBLIC_URL}/docContrat.pdf`
					}
				]
			})
		};
	}, [editSuccess, editAssurance])

	// add product insurance
	async function handleSubmit(event, action) {
		event.preventDefault();

		if (
			(assurance?.nom && assurance.nom.length) < 1
			|| (assurance?.description && assurance.description.length) < 1
			|| (assurance?.duree && parseInt(assurance.duree)) < 1
			|| (assurance?.categorie && assurance.categorie?.id)
			|| (assurance?.prix && parseInt(assurance.prix)) < 1
			|| (assurance?.accountType && assurance.accountType?.id)
		) {
			if (assurance?.nom.length < 1) {
				setModalPopUp(dispatch, `Veuillez renseigner le champ Nom du produit`, "failed");
				const status = false;
				return status;
			};

			if (assurance?.description.length < 1) {
				setModalPopUp(dispatch, `Veuillez renseigner le champ Description complète`, "failed");
				const status = false;
				return status;
			};

			if (parseInt(assurance.duree) < 1) {
				setModalPopUp(dispatch, `Veuillez renseigner le champ Durée`, "failed");
				const status = false;
				return status;
			};

			if (!assurance?.categorie?.id) {
				setModalPopUp(dispatch, `Veuillez renseigner le champ Catégorie`, "failed");
				const status = false;
				return status;
			};

			if (parseInt(assurance.prix) < 1) {
				setModalPopUp(dispatch, `Veuillez renseigner le champ Prix`, "failed");
				const status = false;
				return status;
			};

			if (!assurance?.accountType?.id) {
				setModalPopUp(dispatch, `Veuillez renseigner le champ Type de contrat`, "failed");
				const status = false;
				return status;
			};
		};

		const formData = new FormData();

		if (assurance.imageBin) {formData.append("image", assurance.imageBin);}
		formData.append("nom", assurance.nom);
		formData.append("description", assurance.description);
		formData.append("shortDescription", assurance.shortDescription);
		formData.append("duree", parseInt(assurance.duree));
		formData.append("categorie", assurance.categorie?.id);
		formData.append("prix", parseInt(assurance.prix));
		formData.append("type", assurance.accountType?.id);

		try {
			const response = action === "add" ?
			await addProduct(formData).unwrap() :
			await updateProduct({idCode: assurance?.id, data: formData}).unwrap();

			if (response.statut === 'ok') {
				setIdAssurance(response?.data?.idAssurance || assurance?.id)
				const status = true;
				return status;
			} else {
				console.log("product creation response :", response);
				setModalPopUp(dispatch, "Une erreur interne c'est protuite", "failed");
				const status = false;
				return status;
			};
		} catch (error) {
			console.log("product creation error :", error);
			setModalPopUp(dispatch, "Une erreur inconnue c'est produite", "failed");
			const status = false;
			return status;
		};
	};

	async function handleSecondStepSubmit(event) {
		event.preventDefault();

		// adding services -------------------------------------------------------------------------------------------------
		const services = assurance.services.map((service) => {
			return service.id;
		});
		// -----------------------------------------------------------------------------------------------------------------

		// adding documents ------------------------------------------------------------------------------------------------
		const payOptions = assurance.payOptions.map((option) => {
			return parseInt(option.id);
		});
		// -----------------------------------------------------------------------------------------------------------------

		try {
			const serviceResponse = await addServices({idAssurance: idAssurance, data: {services: services}}).unwrap();
			const payOptionsResponse = await addPayOptions({idAssurance: idAssurance, data: {payOptions: payOptions}}).unwrap();

			if (
				serviceResponse.statut === 'ok' ||
				payOptionsResponse.statut === 'ok'
			) {	
				if (serviceResponse.statut !== 'ok') {
					setModalPopUp(dispatch, "Impossible d'ajouter vos garanties, veuillez les ajouter plustard", "failed");
				};

				if (payOptionsResponse.statut !== 'ok') {
					setModalPopUp(dispatch, "Impossible d'ajouter vos options de payement, veuillez les ajouter plustard", "failed");
				};

				console.log("product creation response :", {
					serviceResponse,
					payOptionsResponse
				});

				const status = true;
				return status;
			} else {
				setModalPopUp(dispatch, "Une erreur est survenue! veuilez rééssayer plus tard.", "failed");
				const status = false;
				return status
			};
		} catch (error) {
			console.log("product creation error :", error);
			setModalPopUp(dispatch, "Une erreur est survenue! veuilez rééssayer plus tard.", "failed");
			const status = false;
			return status
		};
		// ------------------------------------------------------------------------------------------------------------------
	};

	async function handleTirdStepSubmit(event) {
		event.preventDefault();

		// adding questions ------------------------------------------------------------------------------------------------
		const questions = assurance?.form?.map((question, index) => {
			return question.id;
		});
		const selectedQuestions = questions.filter((question) => question)
		// -----------------------------------------------------------------------------------------------------------------

		// adding attachements ---------------------------------------------------------------------------------------------
		const pieces = assurance.pieces.map((piece) => {
			return parseInt(piece.id);
		});
		// -----------------------------------------------------------------------------------------------------------------

		// adding reductions -----------------------------------------------------------------------------------------------
		const reductions = assurance?.reduction?.id ? [assurance.reduction.id] : [assurance.reduction[0].id];
		// -----------------------------------------------------------------------------------------------------------------

		try {
			const attachementsResponse = await addAttachements({idAssurance: idAssurance, data: {piecesAJoindre: pieces}}).unwrap();
			const reductionsResponse = await addReductions({idAssurance: idAssurance, data: {reductions: reductions}}).unwrap();
			const questionsResponse = await addQuestions({idAssurance: idAssurance, data: {questions: selectedQuestions}}).unwrap();

			if (
				questionsResponse.statut === 'ok' ||
				attachementsResponse.statut === 'ok' ||
				reductionsResponse.statut === 'ok'
			) {	
				setModalPopUp(dispatch, "Assurance enregistrée avec succes.", "success");

				console.log("product creation response :", {
					questionsResponse,
					attachementsResponse,
					reductionsResponse
				});

				const status = true;
				return status;
			} else {
				setModalPopUp(dispatch, "Une erreur est survenue! veuilez rééssayer plus tard.", "failed");
				console.log("status error ---------------------------------------");
				const status = false;
				return status
			};
		} catch (error) {
			console.log("product creation error :", error);
			setModalPopUp(dispatch, error?.data?.message ? error.data.message : "Une erreur est survenue! veuilez rééssayer plus tard.", "failed");
			const status = false;
			return status
		};
		// ------------------------------------------------------------------------------------------------------------------
	};

	// Stepper fonctions start here --------------------------------------------
	const steps = ['Description', 'Services/Documentation', 'Données client'];

	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());

	const isStepOptional = (step) => {
		return step === 10;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
		// You probably want to guard against something like this,
		// it should never occur unless someone's actively trying to break something.
		throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
	};
	// -------------------------------------------------------------------------

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file) {
			setAssurance({
				...assurance,
				image: URL.createObjectURL(file),
				imageBin: file
			});
		} else {
			// On supprime l'image
			setCover(null);

			// retrait de l'image
			setAssurance({
				...assurance,
				image: null
			});
		}

	};

	function handleTextChange(event) {
		const name = event.target.name;
		const value = event.target.value;

		setAssurance({
			...assurance,
			[name]: value
		});

		setIsEdited(true);
	};

	function handleSelectChange(event, options) {
		const name = event.target.name;
		const value = event.target.value;

		const selectedOption = options.find(option => option.name === value);

		if (selectedOption) {
			setAssurance({
				...assurance,
				[name]: selectedOption
			});
		};

		setIsEdited(true);
	};

	function handleCkeckboxChange(event, options) {
		const name = event.target.name;
		const value = event.target.checked;

		
		if (value) {
			const selectedOption = options.find(option => option.name === name);

			if (selectedOption) {
				setAssurance({
					...assurance,
					payOptions: [...assurance.payOptions, {...selectedOption}]
				});
			};
		} else {
			const newPayOptions = assurance.payOptions.filter((option) => option.name !== name);
			setAssurance({
				...assurance,
				payOptions: newPayOptions
			});
		};

		setIsEdited(true);
	};

	// remove service and piece
	const removeItem = (index, url, type) => {
		switch (type) {
			case "services":
				const newTableServices = assurance?.services.filter((item) => item !== url);
				setAssurance((prevState) => {
					return {
						...prevState,
						services: newTableServices,
					};
				});

				setIsEdited(true);
				break;

			case "pieces":
				const newTablePieces = assurance?.pieces.filter((item) => item !== url);
				setAssurance((prevState) => {
					return {
						...prevState,
						pieces: newTablePieces,
					};
				});

				setIsEdited(true);
				break;

			default:
				break;
		};
	};

	function criticalDocsChecker(documents) {
		let avalaibleDocs = {
			condition: false,
			contrat: false
		};

		documents.forEach((document) => {
			if (document.title.toUpperCase() === "CONDITIONS D'UTILISATION") {
				avalaibleDocs.condition = true;
			};

			if (document.title.toUpperCase() === "CONTRAT DE SOUSCRIPTION") {
				avalaibleDocs.contrat = true;
			};
		})

		if (avalaibleDocs.condition && avalaibleDocs.contrat) {
			return {value: true, case: "both"};
		} else if (avalaibleDocs.condition || avalaibleDocs.contrat) {
			return {value: false, case: avalaibleDocs.condition ? "Contrat de souscription" : "Conditions d'utilisation"}
		} else {
			return {value: false, case: null}
		}
	};

	async function handleNextClic(event) {
		if (activeStep === 0) {
			if (isEdited) {
				const status = await handleSubmit(event, params.action);
	
				if (status) {
					handleNext();
				} else {
					console.log("first step submitting error");
				};
			} else {
				handleNext();
			}
		} else if (activeStep === 1) {
			// const documentCheck = criticalDocsChecker(assurance?.documentation);
			// if (documentCheck.value) {
				if (isEdited) {
					const status = await handleSecondStepSubmit(event);
		
					if (status) {
						handleNext();
					} else {
						console.log("second step submitting error");
					};
				} else {
					handleNext();
				};
			// } else {
			// 	setModalPopUp(dispatch, documentCheck.case
			// 		? `Merci de rajouter le/s document/s '${documentCheck.case}'`
			// 		: "Veuillez rajouter les documents suivant : 'Conditions d'utilisation' et 'Contrat de souscription'"
			// 	, "failed");
			// }
		} else if (activeStep === 2) {
			if (isEdited) {
				const status = await handleTirdStepSubmit(event);
	
				if (status) {
					navigate("/mes-assurances");
					setModalPopUp(dispatch, "Assurance enregistrée avec succes.", "success");
					console.log("tird step submited succesfully");
				} else {
					setModalPopUp(dispatch, "Une erreur est survenue! veuilez rééssayer plus tard.", "failed");
					console.log("tird step submitting error");
				};
			} else {
				navigate("/mes-assurances");
			};
		} else {
			handleNext();
		}
	};

	if (params?.action !== "add" && editLoading) {
		return (
			<div className='flex flex-1 flex-col justify-center items-center h-full'>
				<Spinner className='h-10 w-10 text-primary-700' />
			</div>
		);
	};

	return (
		<div>
			<BackIllustration />

			<Container>
				<SubscriptionBodyTitles
					bodyText={[`Bienvenue, Mr/Mme Doe`]}
					title="Ajouter une assurance"
				/>
				
				<StyledBody>
					<Box sx={{ width: '100%' }}>
						<Stepper activeStep={activeStep}>
							{steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							if (isStepOptional(index)) {
								labelProps.optional = (
								<Typography variant="caption">Optional</Typography>
								);
							}
							if (isStepSkipped(index)) {
								stepProps.completed = false;
							}
							return (
								<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
							})}
						</Stepper>
						{activeStep === steps.length ? (
							<>
								<Typography sx={{ mt: 2, mb: 1 }}>
									All steps completed - you&apos;re finished
								</Typography>
								<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
									<Box sx={{ flex: '1 1 auto' }} />
									<Button onClick={handleReset}>Reset</Button>
								</Box>
							</>
						) : (
							<>
								<Typography sx={{ mt: 2, mb: 1, padding: "10px 0" }}>{activeStep === 0 ? (
									<Description
										assurance={assurance}
										cover={cover}
										handleSelectChange={handleSelectChange}
										handleTextChange={handleTextChange}
										handleFileChange={handleFileChange}
									/>
								) : activeStep === 1 ? (
									<Services
										assurance={assurance}
										setAssurance={setAssurance}
										removeItem={removeItem}
										handleCkeckboxChange={handleCkeckboxChange}
										setIsEdited={setIsEdited}
										isEdited={isEdited}
									/>
								) : (
									<DonneesClient
										removeItem={removeItem}
										assurance={assurance}
										setAssurance={setAssurance}
										handleSelectChange={handleSelectChange}
										setIsEdited={setIsEdited}
										isEdited={isEdited}
									/>
								)}</Typography>
								
								<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
									<Button
										color="inherit"
										disabled={activeStep === 0}
										onClick={handleBack}
										sx={{ mr: 1 }}
									>
										RETOUR
									</Button>

									<Box sx={{ flex: '1 1 auto' }} />

									{isStepOptional(activeStep) && (
										<Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
											Skip
										</Button>
									)}

									<Button className="flex items-center gap-2 bg-secondary-600 text-white" onClick={handleNextClic}>
										{(
											isLoading ||
											isUpdating ||
											serviceLoading ||
											payOptionsLoading ||
											questionsLoading ||
											attachementsLoading ||
											reductionsLoading
										) && (
											<Spinner className="h-6 w-6 text-primary-700 m-auto h-full" />
										)}
										{activeStep === steps.length - 1 ? 'TERMINER' : 'SUIVANT'}
									</Button>
								</Box>
							</>
						)}
					</Box>
				</StyledBody>
			</Container>
		</div>
	);
};

export default AddAssurance;
