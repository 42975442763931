/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description dashboard page
 * @component dashboard
 */
import Main from "../../components/layouts/main";

import Construction from "../../UI/Construction";

const Index = () => {
	return <Main children={<Construction />} />;
};

export default Index;
