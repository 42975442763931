/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add skill
 * @component AddCompetence
 */
import {
	useAddSkillMedecinMutation,
	useGetSkillsQuery,
} from "../../../../../features/api/apiSlice";
import Select from "../../../../../UI/form/Select";
import TextArea from "../../../../../UI/form/TextArea";
import Button from "../../../../../UI/button/Button";
import Spinner from "../../../../../UI/spinner/Spinner";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AddCompetence = () => {

	//retourner le parametre type pour identifier quel formulaire envoyer
	const location = useLocation();	
	const type = new URLSearchParams(location.search);
	const rowData = location.state?.from
    const typeValue = type.get('type');
	
	
	
	const { data: skills, isSuccess, isLoading } = useGetSkillsQuery();

	// skill option skill
	const [valueOption, setvalueOption] = useState([]);

	// skill state
	const [skill, setSkill] = useState(rowData);
	
	// set skill state
	useEffect(() => {
		if (isSuccess && skills) {
			const data = skills?.data?.map((item) => {
				return {
					id: item.id_skill,
					name: item.nom,
				};
			});

			setvalueOption(data);
		}

		return () => {};
	}, [isSuccess, skills]);


	// handle change
	const handleChange = (e) => {
		const { name, value } = e.target;
		
		setSkill((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	// handle change
	const handleDescriptionChange = (e) => {
		const { name, value } = e.target;

		setSkill((prevState) => {
			return {
				...prevState,
				description_perso: value,
				description: value,
			};
		});
	};

	const navigate = useNavigate();

	// add skill request
	const [addSkillMedecin, { isLoading: addSkillMedecinLoading }] =
		useAddSkillMedecinMutation();

	// add skill
	const handleSubmit = (e) => {
		e.preventDefault();

		addSkillMedecin(skill)
			.unwrap()
			.then((res) => {
				navigate("/consultations/competences");
				console.log(skill)
			})
			.catch((err) => {
				// set err alert
			});
	};

	return (
		<div>
			<h2 className=" text-center py-4 sm:py-10 text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl">
				{typeValue==="add"?"Assigner une compétence à votre profil.":"Modifier la competence assignée à votre profil"}
			</h2>
			<form className=" ml-[30px] mt-[30px] space-y-6 divide-y divide-gray-200">
				<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
					<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
						<div>
							<h3 className="text-lg font-medium leading-6 text-gray-900">
								{typeValue==="add"?'Ajouter une compétence':'Modifier une competence'}
							</h3>
							<p className="mt-1 max-w-2xl text-sm text-gray-500">
								Bien vouloir choisir une compétence
								correspondant à votre profil
							</p>
						</div>
						<div className="space-y-6 sm:space-y-5">
							{/* skill  */}
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="skill"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Compétence:
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									{isLoading ? (
										<Spinner className="w-8 h-8 text-secondary-600 " />
									) : (
										<Select
											id="skill"
											defaultValue="default"
											name="id_skill"
											label={skill?.nom ? skill?.nom : "selectionner une competence"}
											valueOption={valueOption}
											className="sm:max-w-xs w-full text-gray-700"
											read={true}
											onChange={handleChange}
											disabled={skill?.nom ? true : false}
										/>
									)}
								</div>
							</div>

							{/* description  */}
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="description"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Description
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<TextArea
										value={skill?.description_perso}
										name="description"
										onChange={handleDescriptionChange}
										id="description"
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500 sm:max-w-xs sm:text-sm"
									/>
								</div>
							</div>

							{/* price  */}
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
								<label
									htmlFor="prix"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									Prix de la consultation
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<input
										value={skill?.prix}
										onChange={handleChange}
										type="number"
										name="prix"
										id="prix"
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-secondary-500 focus:ring-secondary-500 sm:max-w-xs sm:text-sm"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="bg-gray-50 flex justify-end px-4 py-3 text-right sm:px-6">
						<Button
							children="Enregistrer"
							type="submit"
							onClick={handleSubmit}
							button="primary"
							className=" max-w-max"
							loading={addSkillMedecinLoading}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default AddCompetence;
