/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list category insuranece page
 * @component ListCategoriePage
 */
import React from "react";
import styled from "styled-components";

import ListAssurancesByIdCategory from "../../../components/layouts/main/assurances/client/listAssurabceByIdCategory";

const ListAssurancesByIdCategoryPage = () => {
	const StyledMain = styled.div`
		position: relative;
		height: 100vh;
		width: 100%;
		min-width: 250px;
		max-width: 1820px;
		padding: 30px 70px;
		font-size: 10px;
		font-family: 'Montserrat', sans-serif;
		@media screen and (max-width: 500px) {
			padding: 20px;
		}
	`;

	return (
		<StyledMain>
			<ListAssurancesByIdCategory />
		</StyledMain>
	);
};

export default ListAssurancesByIdCategoryPage;
