import { useParams } from "react-router-dom";
import { useGetAssuranceByCodeQuery } from "../../../../../features/api/apiSlice";
import { Fragment, useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import Description from "./Description";
import Formulaire from "./Formulaire";
import Services from "./Services";
import Document from "./Document";
import {
	getFormsEntities,
	initialForm,
	resetForm,
} from "../../../../../features/form/form.slice";
import Spinner from "../../../../../UI/spinner/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { useSetProductAssuranceMutation } from "../../../../../features/api/apiSlice";
import { useNavigate } from "react-router-dom";
import { setModal } from "../../../../../features/feedBack/modal.slice";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

// tab data
const tabs = [
	{
		title: "Description",
	},
	{
		title: "Services et pieces à joindres",
	},
	{
		title: "Documents associés",
	},
	{
		title: "Formulaire associé",
	},
];

const SetAssurance = () => {
	const navigate = useNavigate();
	const { code } = useParams();
	const dispatch = useDispatch();

	const dispatchForm = useDispatch();

	// get insurance request
	const {
		data,
		isError,
		isSuccess,
		isLoading: loading,
	} = useGetAssuranceByCodeQuery(code);

	// get question form
	const formsEntities = useSelector(getFormsEntities);

	//initial product state
	const [dataAssurance, SetDataAssurance] = useState({
		document: {
			document1: {
				id: 1,
				title: "",
				file: "",
				old: false,
				preview: undefined,
			},
		},
		services: [],
		pieces: [],
		imagePreview: [],
		images: [],
	});

	// destructuration
	const {
		services,
		pieces,
		nom,
		prix,
		duree,
		images,
		document,
		description,
		categorie,
	} = dataAssurance;

	useEffect(() => {
		if (isSuccess && !isError && !loading) {
			// set default form entities request
			const setFormEntities = data?.data?.form?.reduce(
				(obj, item, index) => {
					return {
						...obj,
						[item.id_question]: {
							id: item.id_question,
							isActive: false,
							isRequired: item.requis,
							name: item.question,
							tarifType: item.tarifType,
							type: item.fieldType,
							options: item.options,
							actionForm: item.actionForm,
						},
					};
				},
				{}
			);

			// set default form request
			const formulaire = {
				ids: data?.data?.form?.map((item) => item.id_question),
				entities: setFormEntities,
			};

			// dispatch formulaire state
			dispatchForm(initialForm(formulaire));

			// set default document request
			const setDocument = data?.data?.documents?.reduce(
				(obj, item, index) => {
					return {
						...obj,
						[`document${item.id}`]: {
							id: item.id,
							title: item.nom,
							file: "",
							old: true,
							preview: item.uri,
						},
					};
				},
				{}
			);

			// set default images request
			const setImgPreview = data?.data?.images?.map((item) => {
				return {
					...item,
					old: true,
				};
			});

			// set default services request
			const setlisteServices = data?.data?.listeServices?.map((item) => {
				return {
					value: item.nom,
					description: item.description,
					label: item.nom,
					id: item.id,
				};
			});

			// set default services request
			const setlistePieces = data?.data?.pieces_a_joindre?.map((item) => {
				return {
					value: item.nomPiece,
					description: item.description,
					label: item.nomPiece,
					id: item.id,
				};
			});

			if (data?.data?.pieces_a_joindre) {
				SetDataAssurance((prevState) => {
					return {
						...prevState,
						pieces: setlistePieces,
					};
				});
			}

			// set default dataAssurrance request
			SetDataAssurance((prevState) => {
				return {
					...prevState,
					nom: data?.data?.nom,
					description: data?.data?.description,
					prix: parseInt(data?.data?.prix),
					duree: parseInt(data?.data?.duree),
					imagePreview: setImgPreview,
					services: setlisteServices,
					document: setDocument,
					categorie: data?.data?.categorie.id,
				};
			});
		}

		return () => {};
	}, [isSuccess, data, dispatchForm, isError, loading]);
	// end side effect

	const documentArray = Object.values(document).filter(
		(item) => item.old !== true
	);

	const formDate = new FormData();

	// code add cette fonction ajoute les object à form data
	function getFormData(item, index, name) {
		const formTransForm = Object.keys(item).map((key) => {
			if (key === "options") {
				const options = item[key].map((option, indexOption) => {
					return Object.keys(option).map((keyOption) =>
						formDate.append(
							`${name}[${index}][${key}][${indexOption}][${keyOption}]`,
							option[keyOption]
						)
					);
				});

				return options;
			} else {
				return formDate.append(`${name}[${index}][${key}]`, item[key]);
			}
		});

		return formTransForm;
	}
	// end

	const pushDataformulaire = (array, name) => {
		const newformData = array.map((form, index) => {
			return getFormData(form, index, name);
		});

		return newformData;
	};

	// transform data to formData with blob object
	const pushTableform = (array, key, object) => {
		if (object) {
			if (array?.length) {
				const documentName = array.map((item, index) => {
					return formDate.append(`${key}[${index}]`, item.title);
				});

				const documentFile = array.map((item, index) => {
					return formDate.append(
						`${key}[${index}]`,
						item.file,
						`file${index}.pdf`
					);
				});

				return [documentName, documentFile];
			} else {
				return [];
			}
		} else {
			if (array?.length) {
				return array.map((item, index) => {
					return formDate.append(`${key}[${index}]`, item);
				});
			} else {
				return [];
			}
		}
	};

	// add data to formdata
	const pushItemform = (item, key) => {
		return formDate.append(`${key}`, item);
	};

	// services array
	const serviceID = services.map((item) => {
		return item.id;
	});

	// pieces array
	const piecesName = pieces.map((item) => {
		return item.id;
	});

	// get form question
	const entities = Object.values(formsEntities);

	const form = entities.map((form) => {
		return {
			options: form.options,
			isRequired: form.isRequired,
			name: form.name,
			tarifType: form.tarifType,
			type: form.type,
			id_question: form.id,
			actionForm: form.actionForm,
		};
	});

	// display alert add products
	const dispacthModal = () => {
		dispatch(
			setModal({
				modalType: "assur",
				modalProps: {
					isOpen: true,
					status: "success",
					text: "Produit d'assurance modifier avec succès",
				},
			})
		);
	};

	// set insurance
	const [setInsuranceProduct, { isLoading }] =
		useSetProductAssuranceMutation();

	const handleSubmit = async (e) => {
		e.preventDefault();
		pushTableform(images, "images");
		pushTableform(documentArray, "documents", true);
		pushTableform(serviceID, "services");
		pushTableform(piecesName, "pieces");
		pushDataformulaire(form, "form");
		pushItemform(nom, "nom");
		pushItemform(description, "description");
		pushItemform(prix, "prix");
		pushItemform(duree, "duree");
		pushItemform(categorie, "categorie");

		setInsuranceProduct({ formDate, code })
			.unwrap()
			.then((data) => {
				dispacthModal();
				dispatchForm(resetForm());
				navigate("/mes-assurances");
			})
			.catch((err) => {
				console.error("Failed to save the assur: ", err);
			});
	};

	// tab index state
	const [selectedIndex, setSelectedIndex] = useState(0);

	return (
		<>
			{loading && (
				<Spinner className="w-10 h-10 m-auto text-primary-600" />
			)}
			{isSuccess && data && (
				<Tab.Group
					as={"form"}
					className="space-y-8 mt-8"
					onSubmit={handleSubmit}
					selectedIndex={selectedIndex}
					onChange={setSelectedIndex}>
					{/* tab list  */}
					<Tab.List className="isolate flex divide-x divide-gray-200 rounded-lg shadow mx-4 ">
						{tabs.map((item, index) => {
							return (
								<Tab
									as={Fragment}
									key={index}>
									{({ selected }) => (
										<div
											className={classNames(
												selected
													? "text-gray-900 outline-none"
													: "text-gray-500 hover:text-gray-700",
												"group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer"
											)}>
											<span>{item.title}</span>
											<span
												aria-hidden="true"
												className={classNames(
													selected
														? "bg-secondary-500"
														: "bg-transparent",
													"absolute inset-x-0 bottom-0 h-0.5  focus:bg-secondary-500"
												)}></span>
										</div>
									)}
								</Tab>
							);
						})}
					</Tab.List>

					<Tab.Panels className="mx-4  lg:p-4 p-2  ">
						{/* description panel  */}
						<Tab.Panel>
							<Description
								SetDataAssurance={SetDataAssurance}
								dataAssurance={dataAssurance}
								setSelectedIndex={setSelectedIndex}
								modif={true}
								code={code}
								skip={false}
							/>
						</Tab.Panel>

						{/* panel services  */}
						<Tab.Panel>
							<Services
								SetDataAssurance={SetDataAssurance}
								dataAssurance={dataAssurance}
								setSelectedIndex={setSelectedIndex}
							/>
						</Tab.Panel>

						{/* document panel  */}
						<Tab.Panel>
							<Document
								SetDataAssurance={SetDataAssurance}
								dataAssurance={dataAssurance}
								setSelectedIndex={setSelectedIndex}
								modif={true}
								code={code}
							/>
						</Tab.Panel>

						{/* form question panel  */}
						<Tab.Panel>
							<Formulaire
								isLoading={isLoading}
								handleSubmit={handleSubmit}
								setSelectedIndex={setSelectedIndex}
								modif={true}
							/>
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			)}
		</>
	);
};

export default SetAssurance;
