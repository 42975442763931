/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description profil user
 * @component User profil
 */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import ContactInformation from "./ContactInformation";
import PersonnalInformation from "./PersonnalInformation";
import PasswordInformation from "./PasswordInformation";
import Addresse from "./Addresse";
import ProfessionalInformation from "./ProfessionalInformation";
import sha256 from "sha256"

import {
	useGetUserQuery,
	useSetMyProfilMutation,
} from "../../../../features/api/apiSlice";

import { getUserAction } from "../../../../features/user/user.slice";

import Button from "../../../../UI/button/Button";
import EntrepriseInformation from "./EntrepriseInformation";

import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";




const UserProfil = () => {
	const [open, setOpen] = useState(false);


	// user info state
	const [userInfo, setUserInfo] = useState();

	// get user request
	const {
		data: user,
		isLoading,
		isSuccess,
		// error,
		// isEror,
	} = useGetUserQuery();

	// dispatch
	const dispatch = useDispatch();

	// set global user state
	if (isSuccess) {
		dispatch(getUserAction(user.data));
	}

	// set profil user request
	const [setMyProfil, { isLoading: loading }] = useSetMyProfilMutation();

	useEffect(() => {
		if (isSuccess && user) {
			setUserInfo(user?.data);
		}

		return () => { };
	}, [isSuccess, user]);

	// handle submit user info
	const handleSubmitInfo = (e) => {
		e.preventDefault();
		const formData = new FormData();

		// add item to formData
		const pushItemform = (item, key) => {
			return formData.append(`${key}`, item);
		};

		pushItemform(userInfo?.nom, "nom");
		pushItemform(userInfo?.sexe, "sexe");
		pushItemform(userInfo?.twitter, "twitter");
		pushItemform(userInfo?.prenom, "prenom");
		pushItemform(userInfo?.photo_profil, "photo_profil");
		pushItemform(userInfo?.date_naissance, "date_naissance");
		pushItemform(userInfo?.facebook, "facebook");
		pushItemform(userInfo?.profession, "profession");
		pushItemform(userInfo?.specialisation, "specialisation");
		pushItemform(userInfo?.civilite, "civilite");
		pushItemform(userInfo?.nbr_enfant, "nbr_enfant");
		pushItemform(userInfo?.ville, "ville");
		pushItemform(userInfo?.entreprise, "entreprise");
		pushItemform(userInfo?.description, "description");

		setMyProfil({ data: formData, code: user?.data?.code })

			.unwrap()
			.then(() => {
				// navigate("/dashboard");
				// set alert

			})
			.catch((err) => { });
	};

	return (
		<div className="shadow sm:overflow-hidden sm:rounded-md  mt-8 ">
			<>
				<Transition.Root
					show={open}
					as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setOpen}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
							onClose={setOpen}>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
									<Dialog.Panel

										as="form"
										className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div>
											<div className="">
												<Dialog.Title
													as="h2"
													className="text-lg font-medium leading-6 text-primary-800">
													Voullez vous vraiment supprimer votre compte utilisateur sur cette application?
												</Dialog.Title>

											</div>
										</div>


										{/* button  */}
										<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
											<div className="sm:col-start-2 ">
												<Button
													type="submit"
													id="delete"
													children="supprimer"
													button="primary"
												/>
											</div>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
												onClick={() => setOpen(false)}>
												Annuler
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</>
			<h1 className="text-3xl font-bold tracking-tight text-gray-800 pl-4 mt-4">
				Mon compte
			</h1>
			<form onSubmit={handleSubmitInfo}>
				<div className="bg-white divide-y-blue-gray-200 space-y-8 divide-y  ">
					{/* personnal information  */}
					<PersonnalInformation
						{...user?.data}
						isLoading={isLoading}
						setUserInfo={setUserInfo}
					/>
					{/* contact information  */}
					<ContactInformation
						{...user?.data}
						setUserInfo={setUserInfo}
					/>


					{/* professionnal information  */}
					{user?.data?.defaultProfil?.id === "IA7" && (
						<ProfessionalInformation
							{...user?.data}
							setUserInfo={setUserInfo}
						/>
					)}

					{/* adresse information  */}
					{user?.data?.defaultProfil?.id === "IA7" && (
						<Addresse
							{...user?.data}
							setUserInfo={setUserInfo}
						/>
					)}
					{/* entreprise information  */}
					{(user?.data?.defaultProfil?.id === "IA7" || user?.data?.defaultProfil?.id === "IA8") && (
						<EntrepriseInformation

							setUserInfo={setUserInfo}
							entreprise={userInfo?.entreprise}
							description={userInfo?.description}

						/>
					)}

					<PasswordInformation
						code={user?.data?.code}
					/>

					{/* button  */}
					<div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
						<Button
							button="primary"
							type="submit"
							loading={loading}
							children="Mettre à jour mon compte "
							className="max-w-max"
						/>
					</div>
				</div>
			</form>
			{/* button  */}
			{/* <a className="text-xs leading-5 text-red-500 "
					type="submit"
					loading={loading}
					children="supprimer mon compte"
				/>	 */}
			<ul>
				<li onClick={() => setOpen(true)}>
					<a
						className="text-red-500 cursor-pointer text-decoration-line"
						type="button"
						children="supprimer mon compte"
						onClick={() =>
							setOpen(true)
						}
						title="voullez vous vraiment supprimer votre compte utilisateur sur cette application?"
					/>
				</li>
			</ul>
		</div>

	);
};

export default UserProfil;
