/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component InputTextQuestion
 * @description composant champs d'entre de type text pour la generation automatique d'un formulaire
 * @props title, idform, formsEntities
 * @
 */

const InputTextQuestion = ({ title, currentQuestion, setCurrentQuestion }) => {
	function textQuestionHandler(event) {
		const value = event.target.value;

		setCurrentQuestion({
			...currentQuestion,
			options: [{
				...currentQuestion?.options[0],
				prix: parseInt(value)
			}]
		});
	};

	return (
		<div className="flex flex-col sm:flex-row gap-y-4 mt-2">
			<div className="sm:w-2/3  text-sm border-0 border-b border-gray-400 border-dotted text-gray-400 ">
				{title}
			</div>

			<label
				htmlFor="prix"
				className=" sm:w-1/3  sm:pl-8 ">
				<input
					onChange={textQuestionHandler}
					type="number"
					name="prix"
					id="prix"
					value={currentQuestion?.options && currentQuestion.options[0].prix > 0 ? currentQuestion.options[0].prix : ""}
					min={0}
					className="block w-full border-0 border-b border-gray-500 bg-gray-50 focus:border-secondary-600 focus:ring-0 sm:text-md text-gray-700 sm:h-10 h-8"
					placeholder="Prix (Fcfa / %)"
				/>
			</label>
		</div>
	);
};

export default InputTextQuestion;
