/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  header conversation
 * @component HeaderSingleChat
 */
import {
	ArrowLongLeftIcon,
	EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useDispatch } from "react-redux";
import { resetChat } from "../../../../features/chats/chats.slice";
import { classNames } from "../../../../styles";

const HeaderSingleChat = ({ selectedChat }) => {
	const dispatch = useDispatch();

	// close conversation
	const goBackHandleClick = () => {
		dispatch(resetChat());
	};

	return (
		<div className="flex items-center justify-between border-b px-4 sm:px-6 py-4 box-border">
			<div className="flex space-x-3">
				{/* selected chat in small width  */}
				{selectedChat && (
					<span
						onClick={goBackHandleClick}
						className={classNames(
							"hover:bg-gray-100 p-2 rounded-full w-10 h-10",
							selectedChat ? "block md:hidden" : "hidden"
						)}>
						<ArrowLongLeftIcon className=" text-gray-700 " />
					</span>
				)}

				<div className="flex items-center">
					<span className="relative inline-block">
						<img
							className="h-8 w-8 md:h-10 md:w-10 rounded-full object-cover"
							src={selectedChat.img}
							alt=""
						/>
					</span>
					<div className="ml-3 w-64 lg:max-w-lg">
						<p className="text-md font-semibold text-secondary-700 group-hover:text-gray-900 truncate ">
							{selectedChat.nom}
						</p>
						<p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
							En cours
						</p>
					</div>
				</div>
			</div>

			<div className="flex items-center gap-x-2">
				<span className="hover:bg-gray-100 p-2 rounded-full w-10 h-10">
					<EllipsisHorizontalIcon className=" text-gray-700 " />
				</span>
			</div>
		</div>
	);
};

export default HeaderSingleChat;
