import styled, { keyframes } from "styled-components";

const Paragraph = styled.p`
    font-weight: 500;
    font-size: 1.7em;
    @media screen and (max-width: 1023px) {
        font-size : 1.5em;
    }
`;

function TitleBodyText({ contents }) {
    
    return (
        <div>
            {contents.map((content, index) => (
                <Paragraph key={index}>
                    { content }
                </Paragraph>
            ))}
        </div>
    )
};

export default TitleBodyText;
