/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add incident chat modal
 * @component Message
 */
import React from "react";
import { useSelector } from "react-redux";
import { getUserCode } from "../../../../features/user/user.slice";
import { classNames } from "../../../../styles";
import { useGetUserInfoQuery } from "../../../../features/api/apiSlice";
import Spinner from "../../../../UI/spinner/Spinner";
import { format, isToday, isYesterday, isSameDay, subDays } from "date-fns";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

const Message = (props) => {
  // user code
  const codeUser = useSelector(getUserCode);

  
  // props
  const { idAuteur, message, dateCreation, document_id, images, documents } = props;

  const { data: userData, isLoading: senderLoading } = useGetUserInfoQuery(parseInt(idAuteur));
  const sender = userData?.data;

  // is comming message
  const recevedMessage = sender?.code !== codeUser;

  function formatDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    const formattedTime = format(date, 'HH:mm');
  
    if (isToday(date)) {
      return `Aujourd'hui à ${formattedTime}`;
    } else if (isYesterday(date)) {
      return `Hier à ${formattedTime}`;
    } else if (isSameDay(date, subDays(new Date(), 2))) {
      return `Avant-hier à ${formattedTime}`;
    } else {
      return `Le ${format(date, 'dd/MM/yyyy')} à ${formattedTime}`;
    }
  }

  if (senderLoading) {
    return (
      <div className=" animate-pulse flex gap-x-2 items-center">
        <span className=" h-10 w-10 bg-slate-300 rounded-full"></span>
        <span className=" w-4/5 flex flex-col gap-y-2 ">
          <span className="bg-slate-300 h-2 rounded-md w-2/5"></span>
          <span className="bg-slate-300 h-2 rounded-md w-4/5"></span>
        </span>
      </div>
    );
  }

  console.log("test test")

  return (
    <div
      className={classNames(
        "flex items-start gap-x-1",
        !recevedMessage && "justify-end"
      )}
    >
      {/* profil  */}
      {!recevedMessage && (
        <img
          className="inline-block h-6 w-6 rounded-full object-cover"
          src={sender?.photoProfil?.url}
          alt="Photo de profil"
        />
      )}

      <div className="flex flex-col items-start gap-y-1 max-w-xs ">
        {/* name */}
        {!recevedMessage && (
          <span className="truncate text-[10px] lowercase text-gray-600">
            {sender?.prenom} {sender?.nom}
          </span>
        )}

        <div
          className={classNames(
            "flex flex-col px-4 rounded-lg rounded-tl-none space-y-1 py-1",
            !recevedMessage ? "bg-secondary-100" : "bg-slate-100"
          )}
        >
          {/* images  */}
          {images && images?.length > 0 && (
            images.map((shownImage) => (
              <a
                href={shownImage.url}
                target="_blank"
              >
                <img
                  src={shownImage.url}
                  alt={"Conversation file"}
                  height="96px"
                  width="96px"
                  className="border object-center rounded-lg mb-1 w-24 h-24"
                />
              </a>
            ))
          )}

          {/* documents  */}
          {documents && documents?.length > 0 && (
            documents.map((doc) => (
              <div className="flex items-center gap-x-2 text-white order object-center rounded-lg mb-1 p-2 bg-secondary-800 hover:bg-secondary-600">
                <a
                  href={doc.url}
                  target="_blank"
                >
                  {doc?.titre ? doc.titre : "Document fournis"}
                </a>

                <ArrowTopRightOnSquareIcon className='w-6 h-6' />
              </div>
            ))
          )}

          {/* text  */}
          <div className=" text-xs font-medium text-gray-700">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </div>

          {/* hour */}
          <div className="flex justify-end space-x-1 text-[10px] leading-3 w-full ">
            <span className="text-gray-500 font-medium">{formatDateTime(dateCreation)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
