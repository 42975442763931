/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component TextArea
 * @description menu de selection du type champs de formulaire à generer
 * @props  id,
		name,
		value,
		onChange,
		label,
		placeholder,
		rows,
		cols,
		defaultValue,
		className,
 */

import { forwardRef } from "react";

import { classNames } from "../../styles";
import { styles } from "./styles";

const TextArea = forwardRef((props, ref) => {
	const {
		id,
		name,
		value,
		onChange,
		label,
		disabled,
		placeholder,
		rows,
		cols,
		defaultValue,
		className,
	} = props;
	return (
		<div>
			{/* label  */}
			<label
				className={classNames(`${styles.label}`, className)}
				htmlFor={id}>
				{label}
			</label>

			{/* champs de saisi  */}
			<textarea
				className={styles.input}
				ref={ref}
				defaultValue={defaultValue}
				cols={cols}
				rows={rows}
				disabled={disabled}
				placeholder={placeholder}
				id={id}
				name={name}
				value={value}
				onChange={onChange}>	
			</textarea>
		</div>
	);
});
export default TextArea;
