import { useSelector } from "react-redux";

import { useGetUserSinistresQuery } from "../../../../../../features/api/apiSlice";
import { getUserCode } from "../../../../../../features/user/user.slice";

import Spinner from "../../../../../../UI/spinner/Spinner";
import BackIllustration from "../../../../../Utilities/BackIllustration";
import SubscriptionBodyTitles from "../../../../../Utilities/subscriptionBodyTitles";
import SubscriptionCardsTitle from "../../../../../Utilities/subscriptionCardsTitle";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DeclaretedCard from "./DeclaratedCard";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4em;
    margin: auto;
    @media screen and (max-width: 500px) {
      gap: 4em;
    }
    @media screen and (max-width: 1023px) {
      gap: 4em;
    }
`;

const StyledBody = styled.div`
    width: 80%;
    max-width: 850px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    @media screen and (max-width: 1023px) {
    //   width: 100%;
    //   min-width: 800px;
    }
`;

const DescriptionPanel = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 2em;
	width: 80%;
	max-width: 850px;
	min-width: 350px;
`;

const StyledMessage = styled.p`
    text-align: justify;
    font-size: 1.5em;
    font-weight: 400;
`;

const UserSinisters = () => {
	// user code
	const userCode = useSelector(getUserCode);

	// get sinistres request
	const {
		data: sinistres,
		isLoading,
		isSuccess,
	} = useGetUserSinistresQuery(userCode);

	console.log("sinister data :", sinistres?.data)

	return (
		<div>
			<BackIllustration />

			<Container>
				<SubscriptionBodyTitles
					bodyText={[`Un souci ? Gérez toutes vos demandes de couverture ici...`]}
					title="Sinistres Déclarés"
				/>

				<DescriptionPanel>
					<StyledMessage>Veuillez déclarer votre sinistre ou faire le suivis des déclarations en cours...</StyledMessage>
					<Link to="create-sinister">
						<button
							type="button"
							className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
							Déclarer un sinistre
						</button>
					</Link>
				</DescriptionPanel>

				<StyledBody>
					<SubscriptionCardsTitle content="Liste des sinstres déclarés" />
					
					{isLoading ? (
						<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700 h-full" />
					) : (
						isSuccess ? (
							<>
								<DeclaretedCard
									isTable={true}
									code="XDGHXD3021"
								/>

								{(sinistres?.data && sinistres.data.length > 0) ? (
									sinistres.data.map((item, index) => (
										<DeclaretedCard
											key={index}
											code={item?.code}
											title={item?.titre}
											type={item?.type}
											status={item.etat}
											description={item.description}
											documents={item.documents}
											images={item.images}
											chat={item.idConversation}
										/>
									))
								) : (
									<div className="mx-auto my-10 max-w-2xl px-4 lg:max-w-7xl lg:px-8">
										<p className="text-2xl text-center text-gray-500">
											{" "}
											<i>Aucun sinistre déclaré pour un de vos produits...</i>{" "}
										</p>
									</div>
								)}
							</>
						) : (
							<h1>An error occured</h1>
						)
					)}
				</StyledBody>
			</Container>
		</div>
	);
};

export default UserSinisters;
