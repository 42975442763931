import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("token");

// initial state
const initialState = {
	token: token || null,
	signUp: {
		isLoading: false,
		error: null,
	},
	signIn: {
		isLoading: false,
		isSuccessful: false,
		error: null,
	},
};

// auth slice
const auth = createSlice({
	name: "auth",
	initialState,
	reducers: {
		// login
		logIn(state, action) {
			
			const token = action.payload;
			localStorage.setItem("token", token);
			state.token = token;
			state.signIn.isSuccessful = !!token;
		},
		// logout
		logOut(state) {
			state.signIn.isSuccessful = false;
			state.token = null;
			localStorage.clear();
		},
	},
});

// get token
export const getToken = (state) => state.auth.token;

export const { logIn, logOut } = auth.actions;

export default auth.reducer;
