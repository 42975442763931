/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product form
 * @component Formulaire
 */
import React, { useEffect, useState } from "react";
import Question from "./Question";

const Formulaire = ({ assurance, setAssurance, setIsEdited }) => {
	const [questions, setQuestions] = useState([{
		id: 0,
		submited: false,
		libelle: "",
		fieldType: "text",
		tarifType: "Addition",
		isRequired: false,
		description: "",
		options: [
			{
				label: "",
				prix: 0,
				format: null,
				subquestions: []
			}
		]
	}]);

	useEffect(() => {
		setAssurance({
			...assurance,
			form: questions
		})

		setIsEdited(true);
	}, [questions]);

	return (
		<div className="space-y-8">
			{/* list question  */}
			{assurance?.form?.map((question, index) => {
				return (
					<Question
						key={question.id}
						index={index}
						questions={assurance.form}
						setQuestions={setQuestions}
						question={question}
						assurance={assurance}
					/>
				);
			})}
		</div>
	);
};

export default Formulaire;
